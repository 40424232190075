export default {
  question1: 'ខ្ញុំមិនអាចចុះឈ្មោះបាន។ វាចេញ Error រហូត។',
  question2: 'តើខ្ញុំអាចផ្លាស់ប្តូរភាសាពេលកំពុងរៀនបានដែរឬទេ?',
  question3: 'តើខ្ញុំអាចផ្អាកវគ្គសិក្សាសិនបានឬអត់?',
  question4: 'តើខ្ញុំអាចទទួលបានវិញ្ញាប័ន​បត្រដែរឬទេបើសិនជាខ្ញុំមិនបានបញ្ចប់វគ្គសិក្សា?',
  question5: 'បើសិនជាមានបញ្ហាណាមួយ តើខ្ញុំអាចទំនាក់ទំនងទៅអ្នកណាបាន?',

  answer1: 'សូមបញ្ជាក់ថាអុីម៉ែលរបស់អ្នកទាំងអស់គ្នាគឺត្រឹមត្រូវ។',
  answer2: 'ប្រាកដជាបាន។ អ្នកនឹងមិនបាត់បង់ទិន្នន័យអ្វីនោះទេពេលផ្លាស់ប្តូរភាសា។',
  answer3: 'ប្រាកដជាបាន។ អ្នកអាចផ្អាកការសិក្សាពេលណាក៏បានដែរ។ ពួកយើងលើកទឹកចិត្តឱ្យអ្នកមានកាលវិភាគរៀនជាប្រចាំមួយដើម្បីឱ្យការសិក្សាមានប្រសិទ្ធិភាព។',
  answer4: 'មិនអាចទេ។អ្នកតម្រូវឱ្យបញ្ចប់រាល់សកម្មភាព​ក្នុងមេរៀនទាំងអស់ដើម្បីទទួលបានវិញ្ញាប័ន​បត្របញ្ចប់វគ្គសិក្សា។',
  answer5: 'អ្នកអាចទំនាក់ទំនងមកពូកយើងបានតាមកន្លែង "ទំនាក់ទំនងមកកាន់យើង" នៅខាងក្រោម​ ឬក៏អាចទំនាក់ទំនងមកក្រុមការងារយើងផ្ទាល់តាម chhim.tithamatak@impacthub.net ឬ លេខទូរស័ព្ទ 010908988',
  frequently_asked_questions: 'សំណួរដែលពេញនិយម',
  how_can_we_help_you: 'តើពួកយើងអាចជួយអ្វីអ្នកបាន?',
  hi: 'សួស្តី',
  submit_your_questions: 'មានសំណួរបន្ថែម',
  video1: 'របៀបបង្កើតគណនី',
  video2: 'ធ្វើដូចម្តេចដើម្បីចូលរៀនវគ្គសិក្សា?',
  video3: 'ធ្វើដូចម្តេចដើម្បីចូលរួមសហគមន៍អនឡាញ?',
  video4: 'តើផ្លាស់ប្តូរភាសាដោយរបៀបណា?',
  video5: 'តើទាញយកការ​ចែក​វិញ្ញាប័ន​បត្របញ្ចប់ការសិក្សាដោយរបៀបណា?',
  video6: 'តើអាចរាយការណ៍បញ្ហាផ្សេងៗបានដោយរបៀបណា?'
};