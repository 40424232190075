/* eslint-disable react/jsx-no-target-blank */
import React, {Fragment} from 'react';
import {Row, Container, Col} from 'reactstrap';
import PropTypes from 'prop-types';
import {CERTIFICATE_DOWNLOAD_ICON} from '../../constants/images';
import {
  WrapComponent,
  ButtonDownloadCertificate,
  WrapTitleReport,
  WrapCardComponent,
  H3,
  H1,
  H2,
  H6,
  P,
  Span,
} from './styled';
import IntlMessages from '../../lib/IntlMessages';
import {QUIZ_COMPLETE_PERCENTAGE} from '../../constants/default-values';
import {PRINT_CERTIFICATE} from '../../constants/routes';

const formatQuizResult = (percentage) => {
  if (percentage === undefined) return 'na';
  if (percentage >= QUIZ_COMPLETE_PERCENTAGE) return 'passed';
  return 'not_passed';
};

function GradePage({dataSource}) {
  return (
    <Fragment>
      <Container
        fluid
        className="mb-3"
        style={{
          background: '#E8E8E8',
          // minHeight: '50vh',
        }}
      >
        <Container>
          <WrapTitleReport>
            <Row>
              <Col md="6">
                <H3 className="fs-18">
                  <IntlMessages id="course" />
                </H3>
              </Col>
              <Col md="6">
                <H3 className="fs-18">
                  <IntlMessages id="performance" />
                </H3>
              </Col>
            </Row>
          </WrapTitleReport>
        </Container>
      </Container>
      <div style={{minHeight: '60vh'}}>
        {dataSource.map((course) => {
          const {activityPercentage, activityQuizPercentage, quizzes, title} = course;

          return (
            <Container fluid className="bg-white" key={course.courseId}>
              <Container className="p-2">
                <Row>
                  <Col md="12">
                    <WrapComponent className="mb-4 text-white fs-24 ff-medium">
                      <H2>{title}</H2>
                    </WrapComponent>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" sm="12">
                    <Row>
                      <Col md="5" sm="6">
                        <WrapCardComponent className="p-2  bg-info text-center rounded text-white">
                          <Col md="12">
                            <H6 className="text-white m-3">
                              <IntlMessages id="activity" />
                            </H6>
                          </Col>
                          <Col md="12">
                            <H1 className="text-white">
                              {activityPercentage}
                              <Span>%</Span>
                            </H1>
                          </Col>
                          <Col md="12">
                            <H6 className="text-white m-3">
                              <IntlMessages id="completed" />
                            </H6>
                          </Col>
                        </WrapCardComponent>
                      </Col>
                      <Col md="5" sm="6">
                        <WrapCardComponent
                          className={`p-2 bg-info text-center rounded text-white ${
                            !quizzes.length ? 'd-none' : ''
                          }`}
                        >
                          <Col md="12">
                            <H6 className="text-white m-3">
                              <IntlMessages id="quizzes" />
                            </H6>
                          </Col>
                          <Col md="12">
                            <H1 className="text-white ">
                              {activityQuizPercentage}
                              <Span>%</Span>
                            </H1>
                          </Col>
                          <Col md="12">
                            <H6 className="text-white  m-3">
                              <IntlMessages id="completed" />
                            </H6>
                          </Col>
                        </WrapCardComponent>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6" sm="12">
                    <Row>
                      <Col md="10">
                        {quizzes.map((quiz) => {
                          return (
                            <Row className="p-0" key={quiz.id}>
                              <Col md="8" sm="8" xs="9">
                                <WrapComponent className="p-0">
                                  <Col md="12" className="p-0">
                                    <P className="fs-13">{quiz.title}</P>
                                  </Col>
                                </WrapComponent>
                              </Col>
                              <Col md="4" sm="4" xs="3">
                                <WrapComponent className="p-2">
                                  <Col md="12">
                                    <P className="fs-13">
                                      <IntlMessages id={formatQuizResult(quiz.percentage)} />
                                    </P>
                                  </Col>
                                </WrapComponent>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                      <Col md="2" sm="12">
                        {activityPercentage === 100 &&
                        activityQuizPercentage >= QUIZ_COMPLETE_PERCENTAGE ? (
                          <WrapComponent className="p-2 text-center">
                            <a
                              href={PRINT_CERTIFICATE.replace(':courseId', course.courseId)}
                              target="_blank"
                            >
                              <ButtonDownloadCertificate>
                                <img
                                  src={CERTIFICATE_DOWNLOAD_ICON}
                                  alt="NoImage"
                                  style={{height: '2rem'}}
                                />
                                <br />
                                <small className="text-info fs-12">
                                  <IntlMessages id="download_certificate" />
                                </small>
                              </ButtonDownloadCertificate>
                            </a>
                          </WrapComponent>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />
              </Container>
            </Container>
          );
        })}
      </div>
    </Fragment>
  );
}
GradePage.propTypes = {
  dataSource: PropTypes.array,
  handleCertificate: PropTypes.func,
};

export default GradePage;
