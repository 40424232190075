import React from 'react';
import {Row, Col} from 'reactstrap';
import IntlMessages from '../../lib/IntlMessages';
import {WrapElement, WrapTitleSection} from '../about-us/styles';
import {WrapPoint, WrapUl, WrapSpan} from './styles';

function SectionTwo() {
  return (
    <Row>
      <Col md="12">
        <WrapElement className="mt-5 mb-5">
          <WrapTitleSection className="fs-20 ff-bold">
            <IntlMessages id="contact_body_description_contact_us" />
          </WrapTitleSection>

          <WrapTitleSection className="fs-16 ml-5">
            <WrapUl>
              <WrapPoint className="">
                <WrapSpan className="ff-bold">
                  <IntlMessages id="contact_ask_us_questions_supports" />
                </WrapSpan>
                <WrapSpan className="lh-200 ff-normal">
                  <IntlMessages id="contact_ask_us_questions_supports_des" />
                </WrapSpan>
              </WrapPoint>
            </WrapUl>
          </WrapTitleSection>

          <WrapTitleSection className="fs-16 ml-5">
            <WrapUl>
              <WrapPoint className="">
                <WrapSpan className="ff-bold">
                  <IntlMessages id="contact_report_a_bug" />
                </WrapSpan>
                <WrapSpan className="lh-200 ff-normal">
                  <IntlMessages id="contact_report_a_bug_des" />
                </WrapSpan>
              </WrapPoint>
            </WrapUl>
          </WrapTitleSection>

          <WrapTitleSection className="fs-16 ml-5">
            <WrapUl>
              <WrapPoint className="">
                <WrapSpan className="ff-bold">
                  <IntlMessages id="contact_request_a_new_topic" />
                </WrapSpan>
                <WrapSpan className="lh-200 ff-normal">
                  <IntlMessages id="contact_request_a_new_topic_des" />
                </WrapSpan>
              </WrapPoint>
            </WrapUl>
          </WrapTitleSection>

          <WrapTitleSection className="fs-16 ml-5">
            <WrapUl>
              <WrapPoint className="">
                <WrapSpan className="ff-bold">
                  <IntlMessages id="contact_insert_the_course_to_the_company" />
                </WrapSpan>
                <WrapSpan className="lh-200 ff-normal">
                  <IntlMessages id="contact_insert_the_course_to_the_company_des" />
                </WrapSpan>
              </WrapPoint>
            </WrapUl>
          </WrapTitleSection>

          <WrapTitleSection className="fs-16 ml-5">
            <WrapUl>
              <WrapPoint className="">
                <WrapSpan className="ff-bold">
                  <IntlMessages id="contact_partner_with_us" />
                </WrapSpan>
                <WrapSpan className="lh-200 ff-normal">
                  <IntlMessages id="contact_partner_with_us_des" />
                </WrapSpan>
              </WrapPoint>
            </WrapUl>
          </WrapTitleSection>
          <WrapTitleSection className="fs-16 ml-5">
            <WrapUl>
              <WrapPoint className="">
                <WrapSpan className="ff-bold">
                  <IntlMessages id="contact_others" />
                </WrapSpan>
              </WrapPoint>
            </WrapUl>
          </WrapTitleSection>
        </WrapElement>
      </Col>
    </Row>
  );
}

export default SectionTwo;
