import React from 'react';
import { UncontrolledCollapse, Button } from 'reactstrap';
import PropTypes from 'prop-types';
//import {COURSE_POINTER} from '../../../constants/images';
import IntlMessages from '../../../lib/IntlMessages';
import { FullWidthButton } from './styled';
import { COURSE_TYPE_COHORT } from '../../../constants/default-values';

function ExpendableButton({ transcript, handleEnrollCourse, type }) {
  return (
    <FullWidthButton>
      <Button id="expendable">
        <IntlMessages id="watch_the_video_or_click" />
      </Button>
      <UncontrolledCollapse
        toggler="#expendable"
        dangerouslySetInnerHTML={{ __html: transcript }}
      ></UncontrolledCollapse>
      {type !== COURSE_TYPE_COHORT && (
        <div>
          <Button onClick={handleEnrollCourse}>
            <span>
              <IntlMessages id="learn_now" />
            </span>
          </Button>
        </div>
      )}
    </FullWidthButton>
  );
}

ExpendableButton.propTypes = {
  transcript: PropTypes.string,
  handleEnrollCourse: PropTypes.func,
  type: PropTypes.string
};

export default ExpendableButton;
