import {all} from 'redux-saga/effects';
import authSaga from './auth/sagas';
import userSaga from './user/sagas';
import appSaga from './app/sagas';
import categorySaga from './category/sagas';
import otherSaga from './other/sagas';
import courseSaga from './course/sagas';
import provinceSaga from './province/sagas';
import profileSaga from './profile/sagas';
import slideSaga from './slide/sagas';

export default function* rootSaga() {
  yield all([
    appSaga(),
    authSaga(),
    categorySaga(),
    courseSaga(),
    otherSaga(),
    profileSaga(),
    provinceSaga(),
    userSaga(),
    slideSaga(),
  ]);
}
