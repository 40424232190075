import React from 'react';
import {Row, Col, Container} from 'reactstrap';
import {MainTitle, SponsorBox, SponsorFlexContainer} from './styled';
import IntlMessages from '../../../lib/IntlMessages';
import {
  PARTNER_AUTRALIAN,
  PARTNER_BRITISH,
  PARTNER_EDEMY,
  PARTNER_EZECOM,
  PARTNER_SMART,
  PARTNER_SPRING,
  PARTNER_UNIVERSITY,
} from '../../../constants/images';

function Sponsor() {
  return (
    <Container>
      <Row>
        <Col>
          <MainTitle>
            <h4>
              <IntlMessages id="sponsorship" />
            </h4>
          </MainTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <SponsorFlexContainer>
            <SponsorBox>
              <img src={PARTNER_AUTRALIAN} alt="partner" />
            </SponsorBox>
            <SponsorBox>
              <img src={PARTNER_BRITISH} alt="partner" />
            </SponsorBox>
            <SponsorBox>
              <img src={PARTNER_EDEMY} alt="partner" />
            </SponsorBox>
            <SponsorBox>
              <img src={PARTNER_EZECOM} alt="partner" />
            </SponsorBox>
            <SponsorBox>
              <img src={PARTNER_SMART} alt="partner" />
            </SponsorBox>
            <SponsorBox>
              <img src={PARTNER_SPRING} alt="partner" />
            </SponsorBox>
            <SponsorBox>
              <img src={PARTNER_UNIVERSITY} alt="partner" />
            </SponsorBox>
          </SponsorFlexContainer>
        </Col>
      </Row>
    </Container>
  );
}

export default Sponsor;
