import { getCollectionSnapshotsRealTime, getCollectionDocRealTime } from '../../lib/firebase';
import { all, call, fork, put, takeLatest, take } from 'redux-saga/effects';
import { CATEGORY_GET_CATEGORY, CATEGORY_GET_CATEGORIES } from './types';
import { setCategoryError, setCategorySuccess, setCategory, setCategoryRequest, setCategories } from './actions';

const getCategoriesAsync = async () => getCollectionSnapshotsRealTime('categories', [], ['created', 'desc']);

const getCategoryAsync = async (id) => getCollectionDocRealTime('categories', id);

function* getCategoriesSaga() {
  try {
    const channel = yield call(getCategoriesAsync);
    while (true) {
      yield put(setCategoryRequest());
      const categories = yield take(channel);
      yield put(setCategories(categories));
      yield put(setCategorySuccess());
    }
  } catch (error) {
    yield put(setCategoryError(error.message));
  }
}

function* getCategorySaga({ payload }) {
  try {
    const { id } = payload;
    const channel = yield call(getCategoryAsync, id);
    while (true) {
      yield put(setCategoryRequest());
      const category = yield take(channel);
      yield put(setCategory(category));
      yield put(setCategorySuccess());
    }
  } catch (error) {
    yield put(setCategoryError(error.message));
  }
}

export function* watchGetCategories() {
  yield takeLatest(CATEGORY_GET_CATEGORIES, getCategoriesSaga);
}

export function* watchGetCategory() {
  yield takeLatest(CATEGORY_GET_CATEGORY, getCategorySaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetCategories), fork(watchGetCategory)]);
}
