import {
  FEATURE1,
  FEATURE2,
  FEATURE3,
  FEATURE4,
  FEATURE5,
  FEATURE6,
} from '../../constants/images';

export const groupFeature1 = [
  {
    icon: FEATURE1,
    label: 'lable_feature_one',
    description: 'description_feature_one',
  },
  {
    icon: FEATURE2,
    label: 'lable_feature_tow',
    description: 'description_feature_tow',
  },
  {
    icon: FEATURE3,
    label: 'lable_feature_three',
    description: 'description_feature_three',
  },
];


export const groupFeature2 = [
  {
    icon: FEATURE4,
    label: 'lable_feature_four',
    description: 'description_feature_four',
  },
  {
    icon: FEATURE5,
    label: 'lable_feature_five',
    description: 'description_feature_five',
  },
  {
    icon: FEATURE6,
    label: 'lable_feature_six',
    description: 'description_feature_six',
  },
];