import styled from 'styled-components';

export const CardWrapperStyle = styled.div`
    text-align: center;
    display: block;
    position: relative;
    margin-top: 20px;
    .card {
        background-color: #F4F4F4;
        border-width: 0px;
        border-image-width: 0px;
        border-radius: 0px;
        height: 550px;
        
        .card-body {
          padding-bottom: 10px;
        }
    }

    .card-img, .card-img-top{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: 200px;
        object-fit: cover;
        width:100%;
    }

    .card-title{
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
        font-size: 18px;
    }

    .course-bottom {
        position: absolute;
        bottom: 0;
        background: #f4f4f4;
        width: 86%;
    }
    .card-text {
        font-size: 14px;
        height: ${({ cardTextHeight }) => cardTextHeight || '145px'};
        overflow: hidden;
        word-break: break-word;
    }
`;

export const ElementWrapperStyle = styled.div`
    text-align:center;
    /* border-top: 2px solid #0002; */
    display: flex;
    justify-content: space-around;
`;

export const TagAvailable = styled.div`
  font-size: 14px;
  text-align: left;
  color: ${(props) => props.isCohort ? '#C90000' : '#00C938'}; 
  font-weight:bold;
`;

export const PriceTag = styled.div`
  font-size: 16px;
  color: #C90000;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const CourseTag = styled.div`
  font-size: 13px;
  background: ${(props) => props.isMaster ? '#ECAA3B' : '#00C938'};
  display: inline-block;
  color: #fff;
  padding: 2px 8px;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

export const SpanWrapper = styled.span`
    display: inline-block;
    margin: 5px;

    img {
        display: inline-block;
        width: 20px;
        height: auto;
    }

    label {
        margin-left: 8px;
        font-size: 15px;
    }
`;