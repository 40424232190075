import styled from 'styled-components';

export const Group = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;

    h2 {
        color: #F5B42F;
        text-align: center;
        width: 100%;
    }
`;

export const Body = styled.p`
    color: #333;
    font-size: 15px;
`;

export const Title = styled.p`
    color: #00B0BB;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    margin-top: 0px;
    margin-bottom:0px;
    font-size: 15px;
`;