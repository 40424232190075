import React, {useEffect} from 'react';
import {Container} from 'reactstrap';
import {useSelector, useDispatch} from 'react-redux';
import {CertificateContainer, ContainerBlog, Image} from './styled';
import './style.scss';
import {LOGO_IMPACT_HUB, SIGNATURE, STAMP} from '../../constants/images';
import {formatDate} from '../../lib';
import useRouter from '../../hook/useRouter';
import {selectorCourse} from '../../stores/root-selector';
import {getCourse, getUserCourse} from '../../stores/root-action';
import PageNotFound from '../../components/not-found';
import Loading from '../../components/loading';
import {getUserAsync} from '../../stores/user/sagas';
import {useState} from 'react';

function Certificate() {
  const course = useSelector(selectorCourse);
  const {userId} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {courseId, userId: userIdQuery} = useRouter();
  const {user} = useSelector((state) => state.user);
  const [userQuery, setUserQuery] = useState({});

  useEffect(() => {
    if (courseId) {
      dispatch(getCourse(courseId));
    }
  }, [dispatch, courseId]);

  useEffect(() => {
    if (userId && courseId) {
      dispatch(getUserCourse(userIdQuery || userId, courseId));
    }
  }, [dispatch, userId, courseId, userIdQuery]);

  useEffect(() => {
    if (userIdQuery) {
      const getUserFunc = async () => {
        const user = await getUserAsync(userIdQuery);
        setUserQuery(user);
      };
      getUserFunc();
    }
  }, [userIdQuery]);

  useEffect(() => {
    if (course.isComplete && course.id) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  }, [course.isComplete, course.id]);

  if (!course.id) return <Loading />;

  if (!course.isComplete && course.id) return <PageNotFound />;

  const showUser = userIdQuery ? userQuery : user;

  return (
    <Container fluid className="p-0">
      <Container className="p-0">
        <br />
        <CertificateContainer>
          <ContainerBlog className="certificate-title">
            <h6>
              Certificate <span>of</span> Acknowledgement
            </h6>
          </ContainerBlog>
          <ContainerBlog className="title">
            <h5>IMPACT HUB PHNOM PENH | ONLINE LEARNING</h5>
          </ContainerBlog>
          <ContainerBlog className="logo-impact-hub">
            <center>
              <img src={LOGO_IMPACT_HUB} alt="NoImage" />
            </center>
          </ContainerBlog>
          <ContainerBlog className="certify-that">
            <h6>THIS IS TO CERTIFY THAT</h6>
          </ContainerBlog>
          <ContainerBlog className="certify-for">
            <h1>{`${showUser.firstName} ${showUser.lastName}`}</h1>
          </ContainerBlog>
          <ContainerBlog className="certify-that">
            <h6>has successfully completed</h6>
          </ContainerBlog>
          <ContainerBlog className="refer-to">
            <h1>{course.title}</h1>
          </ContainerBlog>
          <ContainerBlog className="description">
            <p>
              an online course study by Impact Hub Phnom Penh Online
              <br />
              Learning, an initiative of Impact Hub Phnom Penh
            </p>
          </ContainerBlog>
          <ContainerBlog className="signature">
            <img className="stamp" src={STAMP} alt="NoImage" />
            <center>
              <Image className="signature" src={SIGNATURE} alt="NoImage" />
            </center>
          </ContainerBlog>
          <ContainerBlog className="certify-by">
            <p>
              Nipoan CHHENG-CHAVIGNY
              <br />
              <span>Managing Director of Impact Hub Phnnom Penh</span>
            </p>
          </ContainerBlog>
          <ContainerBlog className="footer-certificate">
            <ContainerBlog className="footer-left">
              <p>
                Certificate ID:
                <br />
                <span>4@3JDG89099df</span>
              </p>
            </ContainerBlog>
            <ContainerBlog className="footer-right text-right">
              <p>
                {formatDate('DD MMMM YYYY')}
                <br />
                <span>www.impacthubphnompenh.online</span>
              </p>
            </ContainerBlog>
          </ContainerBlog>
        </CertificateContainer>
      </Container>
    </Container>
  );
}

export default Certificate;
