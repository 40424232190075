import _ from 'lodash';
import { STORAGE_UPLOAD_IMAGE_PATH, STORAGE_UPLOAD_ICON_PATH } from '../constants/default-values';
import { storageRef } from './firebase';
import { message } from 'antd';
import U from 'uuid';

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const uploadPhotoImage = async (avatar, imageState = '') => {
  return await uploadPhoto(avatar, STORAGE_UPLOAD_IMAGE_PATH, imageState);
};

export const uploadPhotoIcon = async (avatar, imageState = '') => {
  return await uploadPhoto(avatar, STORAGE_UPLOAD_ICON_PATH, imageState);
};

export const uploadPhotoEditor = async avatar => {
  avatar.file = avatar;
  return await uploadPhoto(avatar, 'editor', 'N/A');
};

export const uploadShowErrorMessage = file => {
  return new Promise((resolve, reject) => {
    message.error(
      `Only 2MB jpg/png/gif files are accepted! Instead, received a ${(file.size / 1024000).toFixed(1)}MB (${
        file.type
      })!`,
      5
    );
    reject(file);
  });
};

export const uploadPhoto = async (avatar, uploadPath, imageState = '') => {
  if (!avatar || _.isString(avatar) || !imageState) {
    return {
      path: '',
      type: ''
    };
  }

  let type = avatar['file']['type'].replace('image/', '');
  let name = U.v4();
  let nameAndExt = name + '.' + type;
  let path = uploadPath + '/' + nameAndExt;

  const uploadTask = storageRef.ref(path).put(avatar['file']);
  await uploadTask.on(
    'state_changed',
    snapshot => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      console.log(progress);
    },
    error => {
      console.log(error);
    },
    () => {
      console.log('success');
    }
  );
  return {
    path: name,
    type
  };
};

export const imageDefault = async ({ data, values }) => {
  data.image = values.image;
  data.imageType = values.imageType;

  if (!values.imageUrl) {
    data.image = '';
    data.imageType = '';
  }

  let objImage = await uploadPhotoImage(values.image, values.imageUrl);

  if (objImage.path) {
    data.image = objImage.path;
    data.imageType = objImage.type;
  }

  return data;
};

export const iconDefault = async ({ data, values }) => {
  data.icon = values.icon;
  data.iconType = values.iconType;

  if (!values.iconUrl) {
    data.icon = '';
    data.iconType = '';
  }

  let objIcon = await uploadPhotoIcon(values.icon, values.iconUrl);

  if (objIcon.path) {
    data.icon = objIcon.path;
    data.iconType = objIcon.type;
  }

  return data;
};
