import styled from 'styled-components';

export const MainTitle = styled.div`
    margin-top: 50px;
    h4 {
        color: #333;
        text-align: center;
        width: 100%;
        font-size: 18px;
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    }
`;

export const SponsorFlexContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
`;

export const SponsorBox = styled.div`
    width: 135px;
    height: 100%;
    background-color: transparent;
    margin: 20px 10px 20px 10px;

    img {
        width: 100%;
        height: auto;
    }
`;