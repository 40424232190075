import React from 'react';
import Layout from '../../components/layout';
import {ContainerDefault, Section} from './styles';
import {Container} from 'reactstrap';
import SectionOne from './section_one';
import SectionTwo from './section_two';
import {ReactTypeformEmbed} from 'react-typeform-embed';
import {CONTACT_US_TYPE_FORM} from '../../constants/default-values';

function ContactUs() {
  const style = {
    width: '100%',
    height: '100%',
    position: 'relative',
  };
  return (
    <Layout>
      {/* Section One */}
      <ContainerDefault>
        <Section label="section1" className="bg-warning">
          <Container>
            <SectionOne />
          </Container>
        </Section>
      </ContainerDefault>
      {/* Section Two */}
      <ContainerDefault>
        <Section label="section2" className="bg-white">
          <Container>
            <SectionTwo />
          </Container>
        </Section>
      </ContainerDefault>
      {/* Section Three */}
      <ContainerDefault>
        <Section label="section3" style={{height: '700px'}}>
          <ReactTypeformEmbed style={style} url={CONTACT_US_TYPE_FORM} />
        </Section>
      </ContainerDefault>
    </Layout>
  );
}

export default ContactUs;
