import React from 'react';
import {Row, Col} from 'reactstrap';
import IntlMessages from '../../lib/IntlMessages';
import { WrapH3, WrapH1, WrapH5, WrapElement } from './styles';
import { useSelector } from 'react-redux';

function SectionOne() {
  const { locale } = useSelector((state) => state.locale);
  
  return (
    <Row>
      <Col md="12">
        <WrapElement>
          {locale === 'en' && 
            <WrapH3 className="text-white">
              <IntlMessages id="view_courses_first" />
            </WrapH3>
          }
          <WrapH1 className="mb-4 mt-4 text-white">
            <IntlMessages id="view_courses_two" />
          </WrapH1>
          <WrapH5 className="text-white">
            <IntlMessages id="view_courses_three" />
          </WrapH5>
        </WrapElement>
      </Col>
    </Row>
  );
}

export default SectionOne;
