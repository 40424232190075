export default {
  learn: 'Learn',
  collaborate: 'Collaborate',
  community: 'Community',
  platform_guide: 'Platform guides & FAQs',
  join_facebook_group: 'Join Facebook Group',
  ask_question: 'Ask questions',
  share_with_your_friends: 'Share with your friends',
  request_new_course: 'Request new course',
  report_bug: 'Report a bug',
  partner_with_as: 'Partner with us',
  get_support_from_us: 'Get support from us',
  contact_us: 'Contact Us'
}; 
