import React from 'react';
import {Row, Col} from 'reactstrap';
import {
  Group,
  Text,
  SubText,
  ToggleImage,
  QuoteImage,
  BlueBackgroundColor,
  BackgroundGreyWrapper,
} from './styled';
import Paragraph from '../paragraph';
import {QUOTATION, RIGHT_PHOTO} from '../../../../constants/images';
import IntlMessages from '../../../../lib/IntlMessages';
import {HOME_DATA_SOURCES} from '../../../../constants/home';

function SectionBottom() {
  return (
    <BackgroundGreyWrapper>
      <Row>
        <Col sm="0" md="2"></Col>
        <Col sm="12" md="4">
          {HOME_DATA_SOURCES.map((item, i) => (
            <Paragraph dataSource={item} key={i} />
          ))}
        </Col>
        <Col sm="0" md="1"></Col>
        <Col sm="12" md="5">
          <BlueBackgroundColor>
            <Group>
              <ToggleImage src={RIGHT_PHOTO} alt="toggle" />
              <QuoteImage src={QUOTATION} alt="Quotation" />
              <Text>
                <IntlMessages id="frankly" />
              </Text>
              <SubText>
                <IntlMessages id="bottom_frankly" />
              </SubText>
            </Group>
          </BlueBackgroundColor>
        </Col>
      </Row>
    </BackgroundGreyWrapper>
  );
}
export default SectionBottom;
