import styled from 'styled-components';

export const Section = styled.section`
width: 100% !important;
left: 0px !important;
right: 0px !important;
`;

export const WrapElement = styled.div`
  margin-top: 4rem;
  margin-bottom: 8rem;
  h1{
    font-family: PoppinsBold;
    font-weight: bold;
  }
`;

export const WrapTitleSection = styled.h5`
  /* text-align:center; */
  letter-spacing: 0.1 rem;
  font-weight: bold;
`;

export const WrapTitleSectionSpecial = styled.h5`
  letter-spacing: 0.1 rem;
  font-weight: bold;
  width: 550px;
  text-align: center;
`;

export const WrapContainer = styled.div`
  background: linear-gradient(to right, #17a2b8 50%, #fcc107 50%); 
  width: 100%;
  overflow-x:hidden;
  min-height: 10rem;
  padding: 0px;
`;
export const WrapContainer2 = styled.div`
  background: linear-gradient(to right, #fcc107 50%, #17a2b8 50%); 
  width: 100%;
  overflow-x:hidden;
  min-height: 10rem;
  padding: 0px;
`;

export const CustomHr = styled.hr`
  height: 0.5rem;
  border-radius:0.25rem;
  width: 25%;
  margin:auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
export const ContainerDefault = styled.div`
  width: 100%;
`;

export const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
`;