export default {
  learn: 'រៀន',
  collaborate: 'សហការជាមួយពួកយើង',
  community: 'សហគមន៌',
  platform_guide: 'ការប្រើប្រាស់គេហទំព័រ និងសំណួរចម្លើយ',
  join_facebook_group: 'ចូលរួមក្រុម Facebook របស់ពួកយើង',
  ask_question: 'សួរសំណួរផ្សេងៗ',
  share_with_your_friends: 'ចែករំលែកទៅមិត្តភក្តិរបស់អ្នក',
  request_new_course: 'ស្នើសុំវគ្គសិក្សាថ្មី',
  report_bug: 'រាយការណ៌បញ្ហា',
  partner_with_as: 'ចូលរួមសហការជាមួយពួកយើង',
  get_support_from_us: 'ទទួលបានជំនួយពីពួកយើង',
  contact_us: 'ទំនាក់ទំនងមកកាន់យើង'
}; 
