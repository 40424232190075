export default {
  course: 'វគ្គសិក្សា',
  performance: 'របាយការណ៍',
  activity: 'ចំនុចមេរៀន',
  completed: 'មេរៀនបានបញ្ចប់',
  quizzes: 'សំនួរ',
  na: 'N/A',
  passed: 'ជាប់',
  not_passed: 'ធ្លាក់',
  download_certificate: 'ទាញយកវិញ្ញាបនប័ត្រ',
}; 