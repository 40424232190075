import React from 'react';
import PropTypes from 'prop-types';
import { ICON_DONE, ICON_VIDEO, ICON_SURVEY, ICON_SLIDE, ICON_QUIZ } from '../../../constants/images';
import { MediaGroupStyle, BackgroundWrapperStyle } from './styled';
const ActivityItem = ({ id, index, actIndex, title, type, activity, isComplete, duration, data, module, handleActivity }) => {
  return (
    <BackgroundWrapperStyle>
      <MediaGroupStyle onClick={() => handleActivity(module, data)}
        className={activity.id === id && activity.moduleId === module.id ? 'selected padding' : 'padding hover'}>
        <div>
          {isComplete && (
            <img src={ICON_DONE} alt="NoImage" />
          )}
        </div>
        <div>
          <img alt="NoImage"
            src={
              type === 'VIDEO' ? ICON_VIDEO : (type === 'SURVEY' ? ICON_SURVEY : (type === 'SLIDE' ? ICON_SLIDE : ICON_QUIZ))
            }
          />
        </div>
        <div>{`${index}.${actIndex}`}</div>
        <div>{title}</div>
        <div><p>{duration}</p></div>
      </MediaGroupStyle>
    </BackgroundWrapperStyle>
  );
};

ActivityItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  handleActivity: PropTypes.func,
  item: PropTypes.object,
  duration: PropTypes.string,
  activity: PropTypes.object,
  type: PropTypes.string,
  data: PropTypes.object,
  module: PropTypes.object,
  isComplete: PropTypes.bool,
  isActive: PropTypes.bool,
  actIndex: PropTypes.number,
  index: PropTypes.number
};

export default ActivityItem;
