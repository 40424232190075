import React from 'react';
import PropTypes from 'prop-types';
import {MediaGroup} from './styled';
import MediaItem from '../media-item';

function CourseMedia({widgets}) {
  return <MediaGroup>{widgets && widgets.map((item, i) => <MediaItem key={i} {...item} />)}</MediaGroup>;
}

CourseMedia.propTypes = {
  widgets: PropTypes.array,
};

export default CourseMedia;
