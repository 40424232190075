/*eslint-disable react/jsx-no-target-blank */
import React, {Fragment} from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {CustomSlider} from './styled';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import CourseCard from '../../../../components/course-card';

let settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function CourseSlider({dataSources}) {
  return (
    <CustomSlider>
      <Slider {...settings} arrows={true}>
        {dataSources.map((item) => (
          <Fragment key={item.id}>
            {item.comingSoon && (
              <a target="_blank" href={item.typeformRedirect}>
                <CourseCard dataSource={item} />
              </a>
            )}
            {!item.comingSoon && (
              <Link to={`/course/detail/${item.id}`}>
                <CourseCard dataSource={item} />
              </Link>
            )}
          </Fragment>
        ))}
      </Slider>
    </CustomSlider>
  );
}

CourseSlider.propTypes = {
  dataSources: PropTypes.array,
};

export default CourseSlider;
