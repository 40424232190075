import React, {Fragment, useState} from 'react';
import {Text, TextWrapperStyle, TextButtonStyle} from './styled';
import IntlMessages from '../../../../lib/IntlMessages';
import {PLAY_BUTTON} from '../../../../constants/images';
import {Button, Label} from 'reactstrap';
import {VIDEO_QA_GUIDE} from '../../../../constants/default-values';
import ModalLoadVideo from '../../../../components/video-modal';

function SectionRight() {
  const [visible, setVisible] = useState(false);
  const handleModal = () => {
    setVisible(true);
  };
  return (
    <Fragment>
      {visible && (
        <ModalLoadVideo videoUrl={VIDEO_QA_GUIDE} visible={visible} setVisible={setVisible} />
      )}
      <TextWrapperStyle>
        <Text>
          <IntlMessages id="impact_hub_online_learning_answer" />
        </Text>
      </TextWrapperStyle>
      <TextButtonStyle>
        <Button onClick={handleModal}>
          <img src={PLAY_BUTTON} alt="button" />
        </Button>
        <Label>
          <IntlMessages id="click_play_to_learn_how_to_use_the_platform" />
        </Label>
      </TextButtonStyle>
    </Fragment>
  );
}

export default SectionRight;
