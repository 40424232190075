import React, {useEffect} from 'react';
import {Row, Col, Button} from 'reactstrap';
import LayoutUserSetting from '../../../components/user-setting-layout';
import {WrapText, Profile} from './styled';
import {NavLink} from 'react-router-dom';
import {USER_PROFILE_EDIT, USER_AVATAR_EDIT} from '../../../constants/routes';
import {useSelector, useDispatch} from 'react-redux';
import {getUser, getProvince, getOthers} from '../../../stores/root-action';
import {translate, getStoragePathProfile} from '../../../lib';
import IntlMessages from '../../../lib/IntlMessages';
import {selectorUser} from '../../../stores/user/selectors';

function UserSetting() {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.auth.userId);
  const user = useSelector((state) => state.user.user);
  const {
    name,
    sex,
    organizationTypeName,
    city,
    positionName,
    phone,
    dataOfBirth,
    organizationName,
    image,
  } = useSelector(selectorUser);

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (user) {
      dispatch(getProvince(user.city));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      dispatch(getOthers());
    }
  }, [dispatch, user]);

  return (
    <LayoutUserSetting title={translate('profile')}>
      <Row>
        {/* Info */}
        <Col md="7">
          <Row className="mt-4">
            <Col md="5">
              <WrapText className="fs-15 ff-bold">
                <IntlMessages id="phone" />
              </WrapText>
            </Col>
            <Col md="7">
              <WrapText className="fs-15">{phone}</WrapText>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="5">
              <WrapText className="fs-15 ff-bold">
                <IntlMessages id="organization" />
              </WrapText>
            </Col>
            <Col md="7">
              <WrapText className="fs-15">{organizationName}</WrapText>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="5">
              <WrapText className="fs-15 ff-bold">
                <IntlMessages id="position" />
              </WrapText>
            </Col>
            <Col md="7">
              <WrapText className="fs-15">{positionName}</WrapText>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="5">
              <WrapText className="fs-15 ff-bold">
                <IntlMessages id="type_organization" />
              </WrapText>
            </Col>
            <Col md="7">
              <WrapText className="fs-15">{organizationTypeName}</WrapText>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="5">
              <WrapText className="fs-15 ff-bold">
                <IntlMessages id="province" />
              </WrapText>
            </Col>
            <Col md="7">
              <WrapText className="fs-15">{city}</WrapText>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="5">
              <WrapText className="fs-15 ff-bold">
                <IntlMessages id="gender" />
              </WrapText>
            </Col>
            <Col md="7">
              <WrapText className="fs-15">
                <IntlMessages id={sex || 'male'} />
              </WrapText>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="5">
              <WrapText className="fs-15 ff-bold">
                <IntlMessages id="year_of_birth" />
              </WrapText>
            </Col>
            <Col md="7">
              <WrapText className="fs-15">{dataOfBirth}</WrapText>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="7">
              <NavLink to={USER_PROFILE_EDIT.replace(':id', id)}>
                <Button color="info" className="bg-blue w-100">
                  <IntlMessages id="update_profile" />
                </Button>
              </NavLink>
            </Col>
            <Col md="5">
              {/* <Button color="secondary">
                <IntlMessages id="cancel" />
              </Button> */}
            </Col>
          </Row>
        </Col>

        {/* Profile */}
        <Col md="5">
          <center>
            <Profile src={getStoragePathProfile(image)} alt="NoImage" />
            <br />
            <br />
            <WrapText className="fs-15">{name}</WrapText>
            <br />
            <br />
            <WrapText className="fs-15 ff-bold">
              <NavLink to={USER_AVATAR_EDIT.replace(':id', id)}>
                <u>
                  <IntlMessages id="update_avatar" />
                </u>
              </NavLink>
            </WrapText>
          </center>
        </Col>
      </Row>
    </LayoutUserSetting>
  );
}

export default UserSetting;
