import {
  PROFILE_SET_PROFILES,
  PROFILE_GET_PROFILE,
  PROFILE_GET_PROFILES,
  PROFILE_SET_SUCCESS,
  PROFILE_SET_REQUEST,
  PROFILE_SET_ERROR,
  PROFILE_SET_PROFILE,
  PROFILE_ADD_PROFILE,
  PROFILE_SET_PROFILE_BY_ID,
  PROFILE_UPDATE_PROFILE,
  PROFILE_DELETE_PROFILE,
  PROFILE_SET_ADD_PROFILE,
  PROFILE_SET_UPDATE_PROFILE,
  PROFILE_SET_DELETE_PROFILE,
} from './types';

export const setProfiles = (profiles) => ({
  type: PROFILE_SET_PROFILES,
  payload: {profiles},
});

export const setProfile = (profile) => ({
  type: PROFILE_SET_PROFILE,
  payload: {profile},
});

export const setProfileById = (profileId) => ({
  type: PROFILE_SET_PROFILE_BY_ID,
  payload: {profileId},
});

export const setAddProfile = (profile) => ({
  type: PROFILE_SET_ADD_PROFILE,
  payload: {...profile},
});

export const setUpdateProfile = (profile) => ({
  type: PROFILE_SET_UPDATE_PROFILE,
  payload: {...profile},
});

export const setDeleteProfile = (id) => ({
  type: PROFILE_SET_DELETE_PROFILE,
  payload: {id},
});

export const addProfile = (profile) => ({
  type: PROFILE_ADD_PROFILE,
  payload: {profile},
});

export const deleteProfile = (id) => ({
  type: PROFILE_DELETE_PROFILE,
  payload: {id},
});

export const updateProfile = (profile) => ({
  type: PROFILE_UPDATE_PROFILE,
  payload: {profile},
});

export const getProfiles = () => ({
  type: PROFILE_GET_PROFILES,
});

export const getProfile = (id) => ({
  type: PROFILE_GET_PROFILE,
  payload: {id},
});

export const setProfileSuccess = () => ({
  type: PROFILE_SET_SUCCESS,
});

export const setProfileRequest = () => ({
  type: PROFILE_SET_REQUEST,
});

export const setProfileError = (message) => ({
  type: PROFILE_SET_ERROR,
  payload: {message},
});
