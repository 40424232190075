import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import Layout from '../../components/layout';
import RichTextEditor from '../../components/redux-form/rich-text-editor';

function Editor() {


  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <RichTextEditor />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Editor;
