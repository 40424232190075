import {translate} from '../lib';

export const LOCALE_EN = 'en';
export const LOCALE_KM = 'km';

export const STORAGE_PREFIX_PATH = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_STORAGE_BUCKET}/o/@@@?alt=media`;

export const STORAGE_UPLOAD_IMAGE_PATH = 'images';
export const STORAGE_UPLOAD_ICON_PATH = 'icons';

export const STATUS_DISABLED = 0;
export const STATUS_ACTIVATED = 1;

export const TABLE_PAGINATION_PAGE_SIZE = {
  defaultPageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '25', '50', '100', '250', '500', '1000'],
  showTotal: (total) => `${translate('total')}: ${total}   `,
  size: 'small',
};

export const USER_ROLE_ADMIN = 'admin';
export const USER_ROLE_CREATOR = 'creator';
export const USER_ROLE_EDITOR = 'editor';

export const GENDERS = [
  {id: 'male', name: 'Male'},
  {id: 'female', name: 'Female'},
];

export const VIMEO_API_TOKEN = '615594e8da77206f639085bd2b96ce37';
export const VIMEO_VIDEO_TAG = 'tesdopi-premium';
export const VIMEO_PER_PAGE = 100;
export const VIMEO_BASE_PATH = 'https://vimeo.com';

export const ACTIVITY_VIDEO = 'VIDEO';
export const ACTIVITY_QUIZ = 'QUIZ';
export const ACTIVITY_SURVEY = 'SURVEY';
export const ACTIVITY_SLIDE = 'SLIDE';

export const configTypeForm = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_CLOUD_FUNCTION_API_KEY}`,
  },
};

// Link
export const SUBSCRIPT_NEWSLETTER =
  'https://impacthub.us3.list-manage.com/subscribe?u=156ad4ba512802991791656a6&id=5175d2db72';
export const QUIZ_COMPLETE_PERCENTAGE = 100;
export const CONTACT_US_TYPE_FORM = 'https://form.typeform.com/to/BTZhGG7h';

//Video
export const VIDEO_QA_GUIDE = 'https://vimeo.com/577601032/e54f4de1b2';

export const COURSE_TYPE_COHORT = 'COHORT';
export const COURSE_TYPE_REGULAR = 'REGULAR';
export const COURSE_TYPE_MASTERCLASS = 'MASTERCLASS';

export const ButtonStatus = {
  ENROLL: 'ENROLL',
  ALREADY_ENROLL: 'ALREADY_ENROLL',
  LEARN_MORE: 'LEARN_MORE',
  CONTINUE: 'CONTINUE',
  FINISHED: 'FINISHED',
};

export const Privacy = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
};

export const PayType = {
  ABA: 'ABA',
  MANUAL: 'MANUAL',
  OTHER: 'OTHER',
};

export const PaymentStatus = {
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
};

export const PayWayType = {
  MANUAL: 'MANUAL',
  INVITED: 'INVITED',
  ABA_PAY: 'ABA_PAY',
  KHQR: 'KHQR',
};
