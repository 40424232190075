import {createSelector} from 'reselect';
import _ from 'lodash';

const categories = (state) => state.category.categories;

export const selectCategories = createSelector(categories, (categories) =>
  _.filter(categories, (category) => {
    category.name = category.translate.en.title;

    return category;
  })
);
