import React from 'react';
import {Row, Col, Container} from 'reactstrap';
import CardFeature from '../../components/card-feature';
import {groupFeature1, groupFeature2} from './data';
import SectionOne from './section1';
import SectionTwo from './section2';
import SectionFive from './section5';
import {
  Section,
  WrapTitleSection,
  CustomHr,
  ContainerDefault,
  FlexWrapper,
  WrapTitleSectionSpecial,
} from './styles';
import Layout from '../../components/layout';
import IntlMessages from '../../lib/IntlMessages';

function AboutUs() {
  return (
    <Layout>
      {/* SectionOne*/}
      <ContainerDefault>
        <Section label="section1" className="bg-warning">
          <Container>
            <SectionOne />
          </Container>
        </Section>
      </ContainerDefault>
      {/* SectionTwo */}
      <ContainerDefault>
        <Section label="section2" className="bg-white">
          <SectionTwo />
        </Section>
      </ContainerDefault>
      {/* SectionThree */}
      <ContainerDefault>
        <Section label="section3" className="bg-dark">
          <div className="mt-0 mb-0">
            <Container>
              <Row>
                {groupFeature1.map((featuer, index) => {
                  const {label, icon, description} = featuer;
                  return (
                    <Col md="4" key={index} className="mt-5">
                      <CardFeature
                        label={label}
                        icon={icon}
                        description={description}
                        descriptionColor="white"
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </div>
        </Section>
      </ContainerDefault>

      {/* SectionFour */}
      <ContainerDefault>
        <Section label="section4" className="bg-white">
          <div className="mt-5 mb-5">
            <Container>
              <Row>
                {groupFeature2.map((featuer, index) => {
                  const {label, icon, description} = featuer;
                  return (
                    <Col md="4" key={index}>
                      <CardFeature
                        label={label}
                        icon={icon}
                        description={description}
                        descriptionColor="black"
                      />
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col md="12">
                  <WrapTitleSection className="text-center text-warning mt-3 mb-3 fs-50">
                    <IntlMessages id="future_generation" />
                  </WrapTitleSection>
                  <CustomHr className="bg-info" />
                  <FlexWrapper>
                    <WrapTitleSectionSpecial className="text-center text-warning mt-3 mb-3 ff-bold fs-30">
                      <IntlMessages id="we_are_the_future_of_cambodia" />
                    </WrapTitleSectionSpecial>
                  </FlexWrapper>
                </Col>
              </Row>
            </Container>
          </div>
        </Section>
      </ContainerDefault>
      {/* SectionFive */}
      <ContainerDefault>
        <Section label="section5">
          <SectionFive />
        </Section>
      </ContainerDefault>
    </Layout>
  );
}

export default AboutUs;
