// PROVINCE
export const PROVINCE_SET_REQUEST = 'PROVINCE_SET_REQUEST';
export const PROVINCE_SET_SUCCESS = 'PROVINCE_SET_SUCCESS';
export const PROVINCE_SET_ERROR = 'PROVINCE_SET_ERROR';
export const PROVINCE_SET_PROVINCES = 'PROVINCE_SET_PROVINCES';
export const PROVINCE_GET_PROVINCES = 'PROVINCE_GET_PROVINCES';
export const PROVINCE_SET_PROVINCE = 'PROVINCE_SET_PROVINCE';
export const PROVINCE_SET_ADD_PROVINCE = 'PROVINCE_SET_ADD_PROVINCE';
export const PROVINCE_SET_UPDATE_PROVINCE = 'PROVINCE_SET_UPDATE_PROVINCE';
export const PROVINCE_SET_DELETE_PROVINCE = 'PROVINCE_SET_DELETE_PROVINCE';
export const PROVINCE_GET_PROVINCE = 'PROVINCE_GET_PROVINCE';
export const PROVINCE_ADD_PROVINCE = 'PROVINCE_ADD_PROVINCE';
export const PROVINCE_DELETE_PROVINCE = 'PROVINCE_DELETE_PROVINCE';
export const PROVINCE_UPDATE_PROVINCE = 'PROVINCE_UPDATE_PROVINCE';
export const PROVINCE_SET_PROVINCE_BY_ID = 'PROVINCE_SET_PROVINCE_BY_ID';
