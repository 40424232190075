/*eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import Layout from '../../components/layout';
import {Row, Col, Container, Button, Media} from 'reactstrap';
import {
  Title,
  Video,
  ExpendableGroup,
  TitleNote,
  ButtonContainer,
  StylePartner,
  StyleImage,
  FullBackground,
  Border,
  MediaGroup,
  UnderLineText,
} from './styled';
import IntlMessages from '../../lib/IntlMessages';
import ReactPlayer from 'react-player';
import MasterCourseCard from '../../components/master-course-card';
import {getStoragePathImage} from '../../lib';
import {WrapPaidButton} from '../../views/course-detail/course-header/styled';
import {calculatePercentage} from '../../lib/utils';

export default function MasterCourseDetail({course, handleEnrollCourse}) {
  const {name, photo, position} = course.speakers[0];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <Title>{course.title}</Title>
            <UnderLineText className="b-warning" />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md="12" lg="8" style={{minHeight: '550px'}}>
            <Video>
              <ReactPlayer
                key={course.introVideo}
                url={course.introVideo}
                width="100%"
                height="100%"
                className="player-wrapper"
                controls={true}
              />
            </Video>
            <TitleNote>
              <IntlMessages id="about_the_course" />
            </TitleNote>
            <ExpendableGroup dangerouslySetInnerHTML={{__html: course.description}} />
          </Col>

          <Col md="12" lg="4">
            <MasterCourseCard
              height="550"
              dataSource={{image: photo, title: name, speaker: position}}
            />
            <ButtonContainer>
              {course.isPaid ? (
                <WrapPaidButton>
                  <div className="d-flex justify-content-center">
                    <div className="amount-pay">
                      ${parseFloat(course.price - course.discount).toFixed(2)}
                      {course.discount > 0 && (
                        <div className="original-price">
                          <del>${parseFloat(course.price).toFixed(2)}</del>
                        </div>
                      )}
                    </div>
                    {course.discount > 0 && (
                      <div className="discount-price">
                        {calculatePercentage(course.price, course.discount)}% OFF
                      </div>
                    )}
                  </div>

                  <div>
                    <Button
                      className="rounded-pill"
                      onClick={() => handleEnrollCourse()}
                      style={{textTransform: 'uppercase'}}
                    >
                      <IntlMessages
                        id={(() => {
                          if (course.isComplete) return 'complete_course';
                          if (course.isEnrolled) return 'continue_course';
                          return 'start_course';
                        })()}
                      />
                    </Button>
                  </div>
                </WrapPaidButton>
              ) : (
                <Button
                  className="rounded-pill"
                  onClick={() => handleEnrollCourse()}
                  style={{textTransform: 'uppercase'}}
                >
                  <IntlMessages
                    id={(() => {
                      if (course.isComplete) return 'complete_course';
                      if (course.isEnrolled) return 'continue_course';
                      return 'start_course';
                    })()}
                  />
                </Button>
              )}
            </ButtonContainer>
          </Col>
        </Row>
      </Container>
      <FullBackground>
        <Container>
          <Row>
            <Col>
              <TitleNote className="no-bt">
                <IntlMessages id="testimonials" />
              </TitleNote>
              <Border>
                <hr />
              </Border>
              <Row>
                <Col>
                  <MediaGroup>
                    <Media className="media-item">
                      <Media className="media-image">
                        <Media
                          object
                          src={course.testimonialPhoto}
                          alt="Placeholder"
                          className="rounded-circle"
                        />
                      </Media>
                      <Media
                        body
                        className="media-text"
                        dangerouslySetInnerHTML={{__html: course.testimonialDescription}}
                      />
                    </Media>
                  </MediaGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </FullBackground>
      <Container>
        <Row>
          <Col>
            <StylePartner>
              <h4>
                <IntlMessages id="our_partners" />
              </h4>
              <Border>
                <hr />
              </Border>
              <StyleImage>
                <img
                  src={getStoragePathImage(course.partnerImage)}
                  className="responsive"
                  alt="partner"
                />
              </StyleImage>
            </StylePartner>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
