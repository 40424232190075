import React from 'react';
import { Row, Container, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import FlipCard from './flipCard';
import { ContainerButton } from './styled';
import { HOME_TESTIMONIALS } from '../../../constants/home';
import IntlMessages from '../../../lib/IntlMessages';
import { getLocalStorage } from '../../../lib';

function Reference({ handleGetStart }) {
  return (
    <Container>
      <Row>
        {HOME_TESTIMONIALS.map((item, i) => (
          <FlipCard dataSource={item} key={i} />
        ))}
      </Row>
      <Row>
        <ContainerButton>
          {!getLocalStorage('Authorize') && (
            <Button onClick={handleGetStart}>
              <IntlMessages id="get_started" />
            </Button>
          )}
        </ContainerButton>
      </Row>
    </Container>
  );
}

Reference.propTypes = {
  handleGetStart: PropTypes.func,
};
export default Reference;
