import styled from 'styled-components';

export const MainTitle = styled.div`
    /* margin-top: 20px; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    
    h2 {
        color: #F5B42F;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 0px;
        font-size: 40px;
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    }
    
    hr {
        height: 8px;
        width: 230px;
        background-color: #00A2AD;
        margin-top: 0;
        margin-bottom: 0;
        border: 0;
        border-top: 0;
    }
`;

export const ContainerWrapperStyle = styled.div`
    position: relative;
    .row-top-up {
        
        img {
            max-width: 75% !important;
            margin-left: 25px !important;
            height: auto !important;
        }

        @media (min-width: 300px) {
            position: relative;
            display: none
        }

        @media (min-width: 768px) {
            position: relative;
            display: none
        }

        @media (min-width: 1024px) {
            display: flex;
            position: absolute !important;
            bottom: 300px !important;
            left: 20px !important;
            right: 20px !important;
        }
    }
`;