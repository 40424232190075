import styled from 'styled-components';

export const FullBackground = styled.div`
    width: 100%;
    background-color: #FD5353;
    padding-top: 30px;
    padding-bottom: 30px;

    h4 {
        text-align: center;
    }
}
`;

export const Title = styled.h4`
    color: white;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
`;

export const Border = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    hr {
        background-color: white;
        width: 100px;
        height: 2px;
    }
`;