import styled from 'styled-components';

export const MediaGroup = styled.div`
    margin-top: 0px;
    margin-bottom: 0px;

    .media-body {
        font-size: 16px;
        color: white;
    }

    .media-item {
        display: flex;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        @media (min-width: 300px) {
            flex-direction: column;
        }

        @media (min-width: 768px) {
            flex-direction: column;
        }

        @media (min-width: 992px) {
            flex-direction: row;
        }

        @media (min-width: 1024px) {
            flex-direction: row;
        }  

        .media-image {
            height: 100%;
            width: 170px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-width: 300px) {
                margin-right: 0px;
                margin-bottom: 20px;
            }

            @media (min-width: 768px) {
                margin-right: 0px;
                margin-bottom: 20px;
            }

            @media (min-width: 992px) {
                margin-right: 20px;
                margin-bottom: 0px;
            }   

            @media (min-width: 1024px) {
                margin-right: 20px;
                margin-bottom: 0px;
            }  

            img {
                width: 100%;
                height: 100%;
                margin-top:0px;
            }
        }
        .media-text {
            height: 100%;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;