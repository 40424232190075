import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Title, Video, SubtitleWrapperStyle, TitleMethodology } from './styled';
import ExpendableText from '../expendable-text';
import ExpendableButton from '../expendable-button';
import IntlMessages from '../../../lib/IntlMessages';

function AboutCourse({ course, handleEnrollCourse }) {
  return (
    <Fragment>
      <Row>
        <Col>
          <Title><IntlMessages id="about_the_course" /></Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <ExpendableText {...course} />
        </Col>
      </Row>
      <Row>
        <Col>
          <TitleMethodology><IntlMessages id="course_methodology" /></TitleMethodology>
        </Col>
      </Row>
      <Row>
        <Col>
          <SubtitleWrapperStyle><IntlMessages id="subtitle" /></SubtitleWrapperStyle>
        </Col>
      </Row>
      <Row>
        <Col>
          <Video>
            <ReactPlayer
              key={course.methodVideo}
              url={course.methodVideo}
              width="100%"
              height="100%"
              className="player-wrapper"
              controls={true}
            />
          </Video>
        </Col>
      </Row>
      <Row>
        <Col>
          <ExpendableButton {...course} handleEnrollCourse={handleEnrollCourse} />
        </Col>
      </Row>
    </Fragment>
  );
}

AboutCourse.propTypes = {
  course: PropTypes.object,
  handleEnrollCourse: PropTypes.func,
};

export default AboutCourse;
