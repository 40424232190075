import React from 'react';
import ReactCardFlip from 'react-card-flip';
import PropTypes from 'prop-types';
import {
  BackCard,
  FrontCard,
  Flip,
  ImageWrapperStyle,
  MediaWrapperStyle,
  BackCardWrapperStyle,
} from './styled';
import {Col} from 'reactstrap';
import IntlMessages from '../../../../lib/IntlMessages';

class FlipCard extends React.Component {
  constructor() {
    super();
    this.state = {
      isFlipped: false,
    };
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({isFlipped: !prevState.isFlipped}));
  };

  render() {
    const {photo, description, fullName, position} = this.props.dataSource;
    return (
      <Col xs="12" sm="12" md="6" lg="4" xl="3">
        <Flip>
          <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal">
            <FrontCard onMouseEnter={this.handleClick}>
              <ImageWrapperStyle>
                <img src={photo} alt="flip" />
                <MediaWrapperStyle>
                  <h6>
                    <IntlMessages id={fullName} />
                  </h6>
                  <p>
                    <IntlMessages id={position} />
                  </p>
                </MediaWrapperStyle>
              </ImageWrapperStyle>
            </FrontCard>
            <BackCard onMouseLeave={this.handleClick}>
              <BackCardWrapperStyle>
                <p>
                  <IntlMessages id={description} />
                </p>
                <MediaWrapperStyle>
                  <h6>
                    <IntlMessages id={fullName} />
                  </h6>
                  <p>
                    <IntlMessages id={position} />
                  </p>
                </MediaWrapperStyle>
              </BackCardWrapperStyle>
            </BackCard>
          </ReactCardFlip>
        </Flip>
      </Col>
    );
  }
}

FlipCard.propTypes = {
  dataSource: PropTypes.object,
};

export default FlipCard;
