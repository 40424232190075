export default {
  home: 'Home',
  exploration: 'Exploration',
  log_in: 'Log In',
  sign_up: 'Sign Up',
  about_us: 'About Us',
  profile: 'Profile',
  my_dashboard: 'My Dashboard',
  account_setting: 'Account Setting',
  purchase_history: 'Purchase History',
  help: 'Help',
  logout: 'Logout',
  en: 'EN',
  kh: 'ខ្មែរ',
  what_do_you_want_to_learn_today: 'What do you want to learn today?',
  impact_hub_online_learning: 'Impact Hub Online Learning',
  impact_hub_online_learning_answer: 'Here at Impact Hub Phnom Penh we’ve been supporting and enabling young changemakers for over 3 years, with over 300 aspiring entrepreneurs coming to us to learn how to grow their businesses and impact. We decided it was time to take our learnings, and resources, and community and make them available for impact makers across the country to access online! So whether you are starting a venture in Battambang, working as a leader in Siem Reap, or looking for inspiration in Kampong Cham, you are invited to learn, grow, and create impact with us.',
  click_play_to_learn_how_to_use_the_platform: 'Click PLAY to learn how to use the platform',
  frankly: '"Frankly, I’ve really promoted this program to everyone I knew because it’s the first online course for Cambodian people to have an opportunity to build their capacity development. Furthermore, I want this program to still continue to have a resource for all of them."',
  bottom_frankly: 'Samean Vin, Executive Director at Cambodian Volunteers for Community Development Organization',
  reasonable_one: 'High-Quality Content made for Cambodia',
  answer_one: 'Quality is our highest priority, and we include our diverse network of experts, leaders and entrepreneurs (both local and international) to provide the best case studies, resources and content to teach you new, 21st century skills. Plus, our content is fun!',
  reasonable_two: 'Learn in Your Language',
  answer_two: 'We teach in Khmer language, but blend English terms in where necessary (e.g. when a direct translation is difficult) to ensure you have the best learning experience, and help you learn specific business terms. No more confusing translations! ',
  reasonable_three: 'Graduate with a certificate',
  answer_three: 'Graduate with a verified certificate from Impact Hub Phnom Penh when you finish all the course content to share with your network',
  testimonial_one_description: '“I thought running a business was a big thing; after learning the course I totally changed my mind“',
  testimonial_one_full_name: 'Vandara Sin',
  testimonial_one_position: 'President of CAM - Meet Corner Toastmasters',
  testimonial_two_description: '“I found the online training is a useful tool for providing the concept of good leadership. It has clear steps to go through from one mindset to others with more explained provided video.”',
  testimonial_two_full_name: 'Roeub Poch Nita',
  testimonial_two_position: 'Data Assistant at University Research Co LLC',
  testimonial_three_description: '“I was very happy to register to the course which is useful to learn from other experience. I want to have more courses.”',
  testimonial_three_full_name: 'Nget Sovanmaly',
  testimonial_three_position: 'Former graduate of “21st Century Leadership Course',
  testimonial_four_description: '“I learnt the differences between business and entrepreneurship. I love this course the most... compared to others. I can not imagine before, but this course has allowed me to increase my awareness about problems and opportunities and how we can be part of the solution. Now I realized how big the world is; as well as the problems.”',
  testimonial_four_full_name: 'Muy Nging',
  testimonial_four_position: '3rd Year Student at The Royal University of Law and Economics',
  first_time_learning_online: 'First time learning online? Check out our guide here!',
  first_time_learning_online_answer: 'With the power of the internet, you can now learn anything at any time and become whoever you want to be. However, it might be a challenge if you are a first-time online learner.',
  want_to_insert_the_course_to_your_company: 'Want your employees to grow their skills?',
  want_to_insert_the_course_to_your_company_answer: 'Allow your team members to receive the best experience out of the course with direct support from Impact Hub Phnom Penh. We are here to help.',
  reach_out_to_us_here: 'Reach out to us here!',
  enjoy_learning_course: 'Enjoy Learning Course',
  get_started_for_free: 'Get Started for Free',
  get_started: 'Let\'s Get Started',
  sponsorship: 'Trusted by partners.',
  slogant: 'Create Account to Learn Now! It\'s FREE',
  your_limit: 'BREAKTHROUGH YOUR LIMIT!',
  check_out_here: 'Check out here',
  check_out_here_continue: 'to see the guide on how to learn on our platform and tips to effective online learning.',
  view_all_course: 'View all Courses',
  view_all_masterclass: 'View all Masterclass',
  view_courses: 'Browse',
  explore_all: 'Browse All',
  faqs: 'FAQs',
  view_courses_first: 'Knowledge isn\'t power,',
  view_courses_two: 'Applied knowledge is power.',
  view_courses_three: 'Choose your interesting categories to learn from our related video content, articles, resources, and courses.',
  download_worksheet: 'DOWNLOAD WORKSHEET',
  join_the_community: 'JOIN THE COMMUNITY',
}; 
