import { createSelector } from 'reselect';

const slides = state => state.slide.slides;

export const selectSlidesDesktop = createSelector(slides, (slides) => {
  const results = slides.filter((item)=> item.device === 'DESKTOP');
  return results;
});

export const selectSlidesMobile = createSelector(slides, (slides) => {
  const results = slides.filter((item)=> item.device === 'MOBILE');
  return results;
});

