import enLang from '../lang/locales/en_US';
import kmLang from '../lang/locales/km_KH';
import { getLocalStorage } from './utils';

export const translate = (key = '') => {
  const lang = getLocalStorage('lang', 'en');
  let dataLang = lang === 'en' ? enLang : kmLang;
  if (dataLang[key]) return dataLang[key];
  return key;
};
