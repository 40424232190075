// Libs
import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import AboutUs from '../views/about-us';
import ContactUs from '../views/contact-us';
import SignIn from '../views/user-management/sign-in';
import NotFound from '../components/not-found';
import UserRegister from '../views/user-management/user-register';
import EmailForgetPassword from '../views/user-management/email-forget-password';
import EmailVerification from '../views/user-management/email-verification';
import MessageRegisterSuccessPage from '../views/user-management/user-register/messageSuccess';
import {
  SING_IN,
  COURSE_DETAIL,
  // MASTER_COURSE_DETAIL,
  FORGET_PASSWORD,
  REGISTER,
  ABOUT_US,
  CONTACT_US,
  VIEW_ALL_COURSES,
  QUESTION_ANSWER
} from '../constants/routes';
import Home from '../views/home';
import CourseDetail from '../views/course-detail';
// import MasterCourseDetail from '../views/master-course-detail';
import ViewAllCourses from '../views/view-courses/index';
import QuestionAnswerPage from '../views/​q-a';

function Public() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path={ABOUT_US} component={AboutUs} />
      <Route exact path={CONTACT_US} component={ContactUs} />
      <Route exact path={VIEW_ALL_COURSES} component={ViewAllCourses} />
      <Route exact path={SING_IN} component={SignIn} />
      <Route exact path={COURSE_DETAIL} component={CourseDetail} />
      {/* <Route exact path={MASTER_COURSE_DETAIL} component={MasterCourseDetail} /> */}
      <Route exact path={QUESTION_ANSWER} component={QuestionAnswerPage} />
      <Route exact path={REGISTER} component={UserRegister} />
      <Route exact path="/auth-action" component={EmailVerification} />
      <Route exact path="/register/message/success" component={MessageRegisterSuccessPage} />
      <Route exact path={FORGET_PASSWORD} component={EmailForgetPassword} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Public;
