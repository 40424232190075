import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Video from './video';
import Slide from './slide';
import TypeForm from './typeform';
import {
  ACTIVITY_VIDEO,
  ACTIVITY_SLIDE,
  ACTIVITY_QUIZ,
  ACTIVITY_SURVEY,
} from '../../../constants/default-values';
import { ActionWrapper } from './styled';
import StartCourse from './start-course';
import Certificate from './certificate';
import { getLocalStorage } from '../../../lib';

function LearnSection({
  activity,
  handleTypeForm,
  formResult,
  handleStartCourse,
  handleRetakeQuiz,
  uniqId,
  course,
  userId,
  handlePrintCertificate
}) {
  return (
    <Fragment>
      <ActionWrapper>
        {(() => {

          if (course.isCertificate) return <Certificate handlePrintCertificate={handlePrintCertificate} {...course} />;
          switch (activity.type) {
          case ACTIVITY_VIDEO:
            return (
              <Video {...activity} key={`${activity.id}${uniqId}${getLocalStorage('lang')}`} />
            );
          case ACTIVITY_SLIDE:
            return (
              <Slide {...activity} key={`${activity.id}${uniqId}${getLocalStorage('lang')}`} />
            );
          case ACTIVITY_QUIZ:
          case ACTIVITY_SURVEY:
            return (
              <TypeForm
                key={`${activity.id}${uniqId}${getLocalStorage('lang')}`}
                {...activity}
                handleTypeForm={handleTypeForm}
                formResult={formResult}
                activity={activity}
                handleRetakeQuiz={handleRetakeQuiz}
                uniqId={uniqId}
                userId={userId}
              />
            );
          default:
            return <StartCourse handleStartCourse={handleStartCourse} title={course.title} />;
          }
        })()}
      </ActionWrapper>
    </Fragment>
  );
}

LearnSection.propTypes = {
  activity: PropTypes.object,
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func,
  handleTypeForm: PropTypes.func,
  formResult: PropTypes.array,
  handleStartCourse: PropTypes.func,
  handleRetakeQuiz: PropTypes.func,
  uniqId: PropTypes.string,
  course: PropTypes.object,
  userId: PropTypes.string,
  handlePrintCertificate: PropTypes.func
};

export default LearnSection;
