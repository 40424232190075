import React, {useState, useEffect} from 'react';
import {Row, Col, Container, Button} from 'reactstrap';
// import moment from 'moment';
import {Icon} from 'antd';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {
  Video,
  TitleWrapperStyle,
  ButtonContainer,
  TitleWrapperResponsive,
  WrapperSlider,
  // WrapperCourseSelected,
  CohortButton,
  WrapPaidButton,
} from './styled';
import Module from '../module';
import IntlMessages from '../../../lib/IntlMessages';
import Slider from '../../../components/slider';
import {COURSE_TYPE_COHORT, ButtonStatus} from '../../../constants/default-values';
import {
  compareBetweenDate,
  compareDate,
  compareBetweenDateDefault,
  calculatePercentage,
} from '../../../lib/utils';

function CourseHeader({modules, cohorts, course, handleEnrollCourse, isAdmin}) {
  const [activeID, setActiveID] = useState(null);
  // const [currentCohort, setCurrentCohort] = useState({});

  useEffect(() => {
    if (course.type === COURSE_TYPE_COHORT) {
      // let found = cohorts.find(item => item.id === course.cohortId);
      setActiveID(course.cohortId);
      // setCurrentCohort(found || {});
    }
  }, [cohorts, course]);

  // const handleSelect = (id) => {

  //   let found = cohorts.find(item => item.id === id);
  //   if (found) {
  //     if (moment() > moment(found.endDateFormat, 'DD MMM YYYY')) {
  //       message.warn('Date not available now!');
  //       setActiveID('');
  //       // setCurrentCohort({});
  //       return false;
  //     }
  //     setActiveID(id);
  //     // setCurrentCohort(found || {});
  //   }
  // };

  const getStatus = (course, cohort) => {
    if (compareDate(cohort.endDateFormat, 'gt')) {
      return ButtonStatus.FINISHED;
    } else if (
      course.isEnrolled &&
      compareBetweenDateDefault(cohort.startDateFormat, cohort.endDateFormat)
    ) {
      return ButtonStatus.CONTINUE;
    } else if (!course.isEnrolled && !cohort.isRegistered) {
      return ButtonStatus.ENROLL;
    } else if (
      !course.isEnrolled &&
      cohort.isRegistered &&
      compareBetweenDateDefault(cohort.startDateFormat, cohort.endDateFormat)
    ) {
      return ButtonStatus.LEARN_MORE;
    } else if (
      (!course.isEnrolled && cohort.isRegistered) ||
      (course.isEnrolled &&
        cohort.isRegistered &&
        !compareBetweenDate(cohort.startDateFormat, cohort.endDateFormat))
    ) {
      return ButtonStatus.ALREADY_ENROLL;
    }

    return ButtonStatus.ENROLL;
  };

  const getButtonText = (status) => {
    if (status === ButtonStatus.ENROLL) return 'enroll_now';
    else if (status === ButtonStatus.ALREADY_ENROLL) return 'already_enroll';
    else if (status === ButtonStatus.LEARN_MORE) return 'learn_more';
    else if (status === ButtonStatus.CONTINUE) return 'continue';
    else if (status === ButtonStatus.FINISHED) return 'finished';

    return 'enroll_now';
  };

  const disabledButton = (status) => {
    if (isAdmin) return false;
    if (status === ButtonStatus.FINISHED || status === ButtonStatus.ALREADY_ENROLL) return true;
    return false;
  };

  return (
    <Container>
      <Row>
        <Col md="0" lg="7"></Col>
        <Col md="12" lg="5">
          <TitleWrapperStyle>
            <center>
              <h4>
                <IntlMessages id="content" />
              </h4>
              <hr />
            </center>
          </TitleWrapperStyle>
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="7">
          <Video>
            <ReactPlayer
              key={course.introVideo}
              url={course.introVideo}
              width="100%"
              height="100%"
              className="player-wrapper"
              controls={true}
            />
          </Video>
        </Col>
        <Col md="12" lg="5">
          <TitleWrapperResponsive>
            <center>
              <h4>
                <IntlMessages id="content" />
              </h4>
              <hr />
            </center>
          </TitleWrapperResponsive>
          <Module modules={modules} />
        </Col>
      </Row>
      <Row className="my-3">
        <Col md="12" lg="7"></Col>
        <Col md="12" lg="5">
          {course.type === COURSE_TYPE_COHORT && (
            <>
              <WrapperSlider>
                <Slider>
                  {cohorts.map((cohort) => {
                    return (
                      <div
                        key={cohort.id}
                        // onClick={() => handleSelect(cohort.id)}
                        // className={(activeID === cohort.id) ? 'active' : ''}
                        // style={{ pointerEvents: 'none' }}
                      >
                        <h4>{cohort.title}</h4>
                        <p>
                          {cohort.startDateFormat} <Icon type="arrow-right" />{' '}
                          {cohort.endDateFormat}
                        </p>
                        {activeID === cohort.id && (
                          <span className="tick-mark">
                            <Icon type="check" />
                          </span>
                        )}
                        {/* {getStatus(course, cohort)} */}
                        {/* {cohort.isRegistered ? 'isRegistered ture' : 'isRegistered fasel'} */}
                        {/* {course.isEnrolled ? 'course.isEnrolled true' : 'course.isEnrolled false'} */}
                        <CohortButton
                          onClick={() => handleEnrollCourse(cohort, getStatus(course, cohort))}
                          disabled={disabledButton(getStatus(course, cohort))}
                          status={getStatus(course, cohort)}
                        >
                          <IntlMessages id={getButtonText(getStatus(course, cohort))} />
                        </CohortButton>
                      </div>
                    );
                  })}
                </Slider>
              </WrapperSlider>
              {/* {
                currentCohort.startDateFormat && (
                  <WrapperCourseSelected>
                    <h4>{'Available '}</h4>
                    <p>{currentCohort.startDateFormat} <Icon type="arrow-right" /> {currentCohort.endDateFormat}</p>
                  </WrapperCourseSelected>
                )
              } */}
            </>
          )}

          {course.isEnrolled === false && course.type !== COURSE_TYPE_COHORT && (
            <ButtonContainer>
              {course.isPaid ? (
                <WrapPaidButton>
                  <div className="d-flex justify-content-center">
                    <div className="amount-pay">
                      ${parseFloat(course.price - course.discount).toFixed(2)}
                      {course.discount > 0 && (
                        <div className="original-price">
                          <del>${parseFloat(course.price).toFixed(2)}</del>
                        </div>
                      )}
                    </div>
                    {course.discount > 0 && (
                      <div className="discount-price">
                        {calculatePercentage(course.price, course.discount)}% OFF
                      </div>
                    )}
                  </div>

                  <div>
                    <Button
                      onClick={() => handleEnrollCourse()}
                      // disabled={activeID ? false : true}
                      className="shadow mb-5 rounded btn-enroll"
                    >
                      <IntlMessages
                        id={(() => {
                          return 'enroll_course';
                        })()}
                      />
                    </Button>
                  </div>
                </WrapPaidButton>
              ) : (
                <Button
                  onClick={() => handleEnrollCourse()}
                  // disabled={activeID ? false : true}
                  className="shadow mb-5 rounded btn-enroll"
                >
                  <IntlMessages
                    id={(() => {
                      return 'enroll_course';
                    })()}
                  />
                </Button>
              )}
            </ButtonContainer>
          )}

          {course.isEnrolled && course.type !== COURSE_TYPE_COHORT && (
            <ButtonContainer>
              <Button onClick={() => handleEnrollCourse()} className="shadow rounded">
                <IntlMessages
                  id={(() => {
                    if (course.isComplete) return 'complete_course';
                    if (course.isEnrolled) return 'continue_course';
                    return 'start_course';
                  })()}
                />
              </Button>
            </ButtonContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
}

CourseHeader.propTypes = {
  modules: PropTypes.array,
  course: PropTypes.object,
  cohorts: PropTypes.array,
  handleEnrollCourse: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default CourseHeader;
