import React from 'react';
import {Row, Col} from 'reactstrap';
import {WrapElement, WrapTitleSection} from './styles';
import IntlMessages from '../../lib/IntlMessages';

function LastFeature() {
  return (
    <Row>
      <Col md="12">
        <WrapElement>
          <h1 className="mb-4 text-white">
            <IntlMessages id="about_us" />
          </h1>
          <WrapTitleSection className="text-white">
            <IntlMessages id="learn_how_to" />
          </WrapTitleSection>
        </WrapElement>
      </Col>
    </Row>
  );
}

export default LastFeature;
