
import styled from 'styled-components';

export const ContainerDefault = styled.div`
  width: 100%;

  .react-slidedown.my-dropdown-slidedown {
    transition-duration: .25s;
    /* transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1); */
  }
`;
export const Section = styled.section`
    width: 100% !important;
    left: 0px !important;
    right: 0px !important;
`;

export const WrapElement = styled.div`
  margin-top: 4rem;
  margin-bottom: 8rem;

  h1 {
    font-family: PoppinsBold;
    font-weight: bold;
  }
`;
export const WrapTitleSection = styled.h5`
  /* letter-spacing: 0.1rem; */
  /* color: white */
  /* font-weight: bold; */
`;

export const Collapse = styled.div`
  display: flex; 
  cursor: pointer;
  font-weight: 500;
  &.border-info{
    border-left: 5px solid #41bfd0;
     background: #f0f0f0;
  }
  &.border-warning{
    border-left: 5px solid #f9c108;
  }
`;

export const CollapseTitle = styled.div`
  padding: 10px;
  width: 50px;
  font-weight:bold;
  font-family: PoppinsBold;
`;

export const CollapseBody = styled.div`
  padding: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  transition-delay: 3s;
`;
export const CardContent = styled.div`
  height: 90px;  
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
`;

export const CardHeader = styled.div`
  min-height: 190px;
  background-repeat: no-repeat repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const CardWrap = styled.div`
  border: none;
  cursor: pointer;

  &.card {
    position: relative;
    border-radius: 5px;
    background-color: #fff;
    /* box-shadow: 0 1px 2px rgba(0,0,0,0.15); */
    transition: all 0.3s ease-in-out;
  }

  .card::after {
    content: '';
    position: absolute;
    z-index: -1;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: opacity 0.3s ease-in-out;
  }

  /* &.card:hover{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  } */

  &.card:hover {
    transform: scale(1.05, 1.05);
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  }

  &.card:hover::after {
    opacity: 1;
  }

  &.card:hover:after{
    
  }
`;
