import React from 'react';
import PropTypes from 'prop-types';
import {ModuleListStyle} from './styled';
import ModuleItem from './module-item';
const ModuleList = ({modules, module, handleActivity, activity}) => {
  return (
    <ModuleListStyle>
      {modules &&
        modules.map((item,index) => {
          return (
            <ModuleItem
              key={item.id}
              index={index+1}
              {...item}
              data={item}
              module={module}
              handleActivity={handleActivity}
              activity={activity}
            />
          );
        })}
    </ModuleListStyle>
  );
};

ModuleList.propTypes = {
  activity: PropTypes.object,
  modules: PropTypes.array,
  handleActivity: PropTypes.func,
  module: PropTypes.object,
};
export default ModuleList;
