import React from 'react';
import {Row, Col, Container, Button} from 'reactstrap';
import PropTypes from 'prop-types';
import {HeaderSlider} from './styled';
import CourseSlider from './courseSlider/index';
import IntlMessages from '../../../lib/IntlMessages';
import {VIEW_ALL_COURSES} from '../../../constants/routes';
import {Link} from 'react-router-dom';
function Slider({dataSources}) {
  return (
    <Container>
      <Row>
        <Col>
          <HeaderSlider className="text-deep-blue">
            <IntlMessages id="what_do_you_want_to_learn_today" />
            <hr className="text-deep-blue bg-deep-blue" />
          </HeaderSlider>
        </Col>
      </Row>
      <Row>
        <Col>
          <CourseSlider dataSources={dataSources} />
        </Col>
      </Row>
      <Row>
        <Col className="mt-5">
          <center>
            <Link
              to={`${VIEW_ALL_COURSES}?tab=course`}
              style={{width: '100%'}}
              className="text-white"
            >
              <Button className="text-center view-course-button-customer text-deep-blue bg-deep-blue">
                <IntlMessages id="view_all_course" />
              </Button>
            </Link>
          </center>
        </Col>
      </Row>
    </Container>
  );
}

Slider.propTypes = {
  dataSources: PropTypes.array,
};

export default Slider;
