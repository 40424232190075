/*eslint-disable no-unreachable*/

import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// import moment from 'moment';
import _ from 'lodash';
import CourseTitle from './course-title';
import CourseHeader from './course-header';
import CourseBody from './course-body';
import Testimonial from './testimonial';
import PaymentModal from './payment-modal';
// import PaymentCompleteModal from "./payment-complete";
import Partner from './partner';
import useRouter from '../../hook/useRouter';
import {
  getCourse,
  getModules,
  addUserCourse,
  getUserCourse,
  getCohorts,
  addCohortUser,
  getCohortUsers,
  getUserPayment,
} from '../../stores/root-action';
import {
  selectorCourse,
  selectorModules,
  selectorCohorts,
  selectorCohortUserInMasterClass,
} from '../../stores/root-selector';
import {getUserPaymentWithCohortAsync} from '../../stores/user/sagas';
import {COURSE_LEARNING, SING_IN} from '../../constants/routes';
import Layout from '../../components/layout';
import {
  COURSE_TYPE_COHORT,
  COURSE_TYPE_REGULAR,
  Privacy,
  ButtonStatus,
  COURSE_TYPE_MASTERCLASS,
} from '../../constants/default-values';
import {message} from 'antd';
import {compareBetweenDate} from '../../lib';
import MasterCourseDetail from '../master-course-detail';

function CourseDetail() {
  const course = useSelector(selectorCourse);
  const modules = useSelector(selectorModules);
  const cohorts = useSelector(selectorCohorts);
  const isAvailableMasterClass = useSelector(selectorCohortUserInMasterClass);
  // const cohortUsers = useSelector(selectorCohortUsers);
  const {userId, isAdmin} = useSelector((state) => state.auth);
  const {user} = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {courseId, history} = useRouter();
  const [paymentVisible, setPaymentVisible] = useState(false);
  // const [paymentCompleteVisible, setPaymentCompleteVisible] = useState(true);

  useEffect(() => {
    if (courseId) dispatch(getCourse(courseId));
  }, [dispatch, courseId]);

  useEffect(() => {
    if (courseId && userId) dispatch(getCohortUsers(courseId, userId));
  }, [dispatch, courseId, userId]);

  useEffect(() => {
    if (courseId && userId) dispatch(getUserCourse(userId, courseId));
  }, [dispatch, courseId, userId]);

  useEffect(() => {
    if (courseId && userId) dispatch(getUserPayment(userId, courseId));
  }, [dispatch, courseId, userId]);

  useEffect(() => {
    if (courseId) dispatch(getModules(courseId));
  }, [dispatch, courseId]);

  useEffect(() => {
    if (courseId) dispatch(getCohorts(courseId));
  }, [dispatch, courseId]);

  const handleEnrollCourse = async (cohort = {}, status = '') => {
    if (userId) {
      if (!_.get(course, 'status') && !isAdmin) {
        message.warn('Course not available now.');
        return false;
      }

      if (course.type === COURSE_TYPE_REGULAR) {
        if (course.isPaid && !course.isUserPaid && !course.isEnrolled) {
          setPaymentVisible(true);
          return;
        }

        dispatch(addUserCourse({courseId}));
        history.push(COURSE_LEARNING.replace(':courseId', courseId));
        return;
      }

      if (course.type === COURSE_TYPE_COHORT) {
        const cohortId = cohort.id;

        // Will open
        if (isAdmin) {
          dispatch(addUserCourse({courseId}));

          if (cohortId) dispatch(addCohortUser(userId, courseId, cohortId));

          history.push(COURSE_LEARNING.replace(':courseId', courseId));
          return false;
        }

        if (cohort.privacy === Privacy.PRIVATE && !cohort.isRegistered) {
          message.warn('Sorry, this is an invitation-only course.');
          return false;
        }

        if (status === ButtonStatus.ENROLL) {
          dispatch(addCohortUser(userId, courseId, cohortId));
          message.success('You have success pre-enroll course.');
          return false;
        }

        if (status === ButtonStatus.FINISHED || status === ButtonStatus.ALREADY_ENROLL) {
          message.success('Sorry! You can not learn now.');
          return false;
        }

        const isUserPaid = await getUserPaymentWithCohortAsync(user.id, course.id, cohortId);

        if (course.isPaid && !isUserPaid && !course.isEnrolled) {
          setPaymentVisible(true);
          return;
        }

        if (compareBetweenDate(cohort.startDateFormat, cohort.endDateFormat)) {
          dispatch(addCohortUser(userId, courseId, cohortId));
          dispatch(addUserCourse({courseId}));
          history.push(COURSE_LEARNING.replace(':courseId', courseId));
          return false;
        }

        return false;
      } else if (course.type === COURSE_TYPE_MASTERCLASS) {
        if (
          course.privacy === Privacy.PUBLIC ||
          (course.privacy === Privacy.PRIVATE && isAvailableMasterClass)
        ) {
          if (course.isPaid && !course.isUserPaid && !course.isEnrolled) {
            setPaymentVisible(true);
            return;
          }

          dispatch(addUserCourse({courseId}));
          history.push(COURSE_LEARNING.replace(':courseId', courseId));
          return false;
        }
        message.warn('You do not have permission to access this course.');
        return false;
      }
    } else {
      history.push(SING_IN);
    }
  };

  if (_.get(course, 'type') === COURSE_TYPE_MASTERCLASS) {
    console.log(5);

    return (
      <>
        {/* <PaymentCompleteModal
          visible={paymentCompleteVisible}
          onCancel={() => setPaymentCompleteVisible(false)}
          user={user}
          course={course}
        /> */}
        <PaymentModal
          visible={paymentVisible}
          onCancel={() => setPaymentVisible(false)}
          user={user}
          course={course}
        />
        <MasterCourseDetail course={course} handleEnrollCourse={handleEnrollCourse} />
      </>
    );
  }

  return (
    <Layout>
      <CourseTitle title={course.title} />
      <CourseHeader
        modules={modules}
        cohorts={cohorts}
        course={course}
        isAdmin={isAdmin}
        handleEnrollCourse={handleEnrollCourse}
      />
      {/* <PaymentCompleteModal
        visible={paymentCompleteVisible}
        onCancel={() => setPaymentCompleteVisible(false)}
        user={user}
        course={course}
      /> */}
      <PaymentModal
        visible={paymentVisible}
        onCancel={() => setPaymentVisible(false)}
        user={user}
        course={course}
      />
      <CourseBody course={course} handleEnrollCourse={handleEnrollCourse} />
      <Testimonial course={course} />
      <Partner handleEnrollCourse={handleEnrollCourse} {...course} />
    </Layout>
  );
}

export default CourseDetail;
