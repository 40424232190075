// User
export const USER_SET_REQUEST = 'USER_SET_REQUEST';
export const USER_SET_SUCCESS = 'USER_SET_SUCCESS';
export const USER_SET_ERROR = 'USER_SET_ERROR';
export const USER_SET_USERS = 'USER_SET_USERS';
export const USER_SET_ADD_USER = 'USER_SET_ADD_USER';
export const USER_SET_UPDATE_USER = 'USER_SET_UPDATE_USER';
export const USER_SET_USER = 'USER_SET_USER';
export const USER_GET_USERS = 'USER_GET_USERS';
export const USER_GET_USER = 'USER_GET_USER';
export const USER_ADD_USER = 'USER_ADD_USER';
export const USER_UPDATE_USER = 'USER_UPDATE_USER';
export const USER_SET_USER_COURSES = 'USER_SET_USER_COURSES';
export const USER_ADD_USER_COURSE = 'USER_ADD_USER_COURSE';
export const USER_SET_USER_COURSE = 'USER_SET_USER_COURSE';
export const USER_SET_USER_PAYMENT = 'USER_SET_USER_PAYMENT';
export const USER_GET_USER_PAYMENT = 'USER_GET_USER_PAYMENT';
export const USER_GET_USER_COURSES = 'USER_GET_USER_COURSES';
export const USER_GET_USER_COURSE = 'USER_GET_USER_COURSE';
export const USER_GET_USER_MODULES = 'USER_GET_USER_MODULES';
export const USER_ADD_USER_MODULE = 'USER_ADD_USER_MODULE';
export const USER_GET_USER_ACTIVITIES = 'USER_GET_USER_ACTIVITIES';
export const USER_GET_USER_COURSE_HISTORIES = 'USER_GET_USER_COURSE_HISTORIES';
export const USER_ADD_USER_ACTIVITY = 'USER_ADD_USER_ACTIVITY';
export const USER_SET_USER_MODULES = 'USER_SET_USER_MODULES';
export const USER_SET_USER_ACTIVITIES = 'USER_SET_USER_ACTIVITIES';
export const USER_SET_FORM_RESULT = 'USER_SET_FORM_RESULT';
export const USER_SET_FORM_DATA = 'USER_SET_FORM_DATA';
export const USER_GET_FORM_RESULT = 'USER_GET_FORM_RESULT';
export const USER_GET_FORM_DATA = 'USER_GET_FORM_DATA';
