import {
  OTHER_SET_OTHERS,
  OTHER_GET_OTHER,
  OTHER_GET_OTHERS,
  OTHER_SET_SUCCESS,
  OTHER_SET_REQUEST,
  OTHER_SET_ERROR,
  OTHER_SET_OTHER,
} from './types';

export const setOthers = (others) => ({
  type: OTHER_SET_OTHERS,
  payload: { others },
});

export const setOther = (other) => ({
  type: OTHER_SET_OTHER,
  payload: { other },
});

export const getOthers = () => ({
  type: OTHER_GET_OTHERS,
});

export const getOther = (id) => ({
  type: OTHER_GET_OTHER,
  payload: { id },
});

export const setOtherSuccess = () => ({
  type: OTHER_SET_SUCCESS,
});

export const setOtherRequest = () => ({
  type: OTHER_SET_REQUEST,
});

export const setOtherError = (message) => ({
  type: OTHER_SET_ERROR,
  payload: { message },
});
