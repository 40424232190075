export const NOT_FOUND_404 = '/404';
export const UNAUTHORIZED_401 = '/401';

export const ABOUT_US = '/about-us';
export const CONTACT_US = '/contact-us';
export const SING_IN = '/sign-in';
export const SING_OUT = '/sign-out';
export const DASHBOARD = '/dashboard';
export const REGISTER = '/register';
export const VIEW_ALL_COURSES = '/courses';
export const QUESTION_ANSWER = '/q-a';
export const COURSE_DETAIL = '/course/detail/:courseId';
// export const MASTER_COURSE_DETAIL = '/master-class/detail/:courseId';
export const COURSE_LEARNING = '/course/learning/:courseId';
export const MASTER_CLASS_LEARNING = '/master-class/learning/:courseId';
export const USER_DASHBOARD = '/user/dashboard';
export const USER_PROFILE = '/user/profile';
export const ACCOUNT_SETTING = '/user/setting';
export const PURCHASE_HISTORY = '/user/purchase-history';
export const USER_PROFILE_EDIT = '/user/profile/info/edit/:id';
export const USER_AVATAR_EDIT = '/user/profile/avatar/edit/:id';
export const FORGET_PASSWORD = '/forget-password';
export const EDITOR = '/system/editor/preview';
export const PRINT_CERTIFICATE = '/print-certificate/:courseId';

