import React from 'react';
import PropTypes from 'prop-types';
import SpeakerItem from './speaker-item';
import {SpeakerBackgroundStyle, SpeakerTitle} from './styled';
import IntlMessages from '../../../lib/IntlMessages';

function SpeakerList({speakers}) {
  return (
    <SpeakerBackgroundStyle>
      <SpeakerTitle>
        <IntlMessages id="speakers" />
      </SpeakerTitle>
      {speakers && speakers.map((item, i) => <SpeakerItem key={i} {...item} />)}
    </SpeakerBackgroundStyle>
  );
}

SpeakerList.propTypes = {
  speakers: PropTypes.array,
};

export default SpeakerList;
