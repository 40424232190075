import React from 'react';
import PropTypes from 'prop-types';
import {WrapForm, ImageFrame} from './styled';
import {GRUOPCOVERREGISTER} from '../../constants/images';

function Title({children, title}) {
  return (
    <WrapForm>
      <ImageFrame className="img-register" src={GRUOPCOVERREGISTER} alt="Register" />
      <center>
        <h2 className="text-black mb-4 mt-2">{title}</h2>
      </center>
      {children}
    </WrapForm>
  );
}

Title.propTypes = {
  children: PropTypes.node,
  title: PropTypes.any,
};

export default Title;
