import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Icon} from 'antd';
import {Field} from 'redux-form';
import classnames from 'classnames';
import {FormText} from 'reactstrap';

const FormItem = Form.Item;

function InputField(props) {
  const onChange = (event) => {
    const {
      input: {onChange},
      handleChange,
    } = props;
    const {
      target: {value},
    } = event;

    onChange(value);
    if (handleChange) {
      handleChange(value);
    }
  };

  const onBlur = () => {
    const {
      input: {onChange, value, onBlur},
    } = props;
    onChange(value);
    onBlur();
  };

  const style = {color: 'rgba(0,0,0,.25)'};
  const {
    tip,
    label,
    icon,
    type,
    autosize,
    placeholder,
    required,
    disabled,
    onPressEnter,
    hide,
    className,
    defaultValue,
    meta: {touched, error},
    input: {value, onFocus},
  } = props;
  let InputComponent = Input;
  if (type) {
    InputComponent = Input[type];
  }

  let tmpValue = defaultValue ? defaultValue : value;

  const validateStatus = classnames(touched && error ? 'error' : 'success');
  return (
    <Fragment>
      <FormItem label={label} help={error} required={required} validateStatus={validateStatus}>
        <InputComponent
          type={type}
          value={tmpValue}
          autoComplete="off"
          autosize={autosize}
          disabled={disabled}
          placeholder={placeholder}
          className={`${className} ${hide ? 'd-none' : ''}`}
          prefix={icon && <Icon type={icon} style={style} />}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          onPressEnter={onPressEnter}
        />
      </FormItem>
      {tip && (
        <FormText color="muted" className="mt-0 mb-2">
          {tip}
        </FormText>
      )}
    </Fragment>
  );
}

InputField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.any,
  handleChange: PropTypes.func,
  meta: PropTypes.any,
  disabled: PropTypes.bool,
  onPressEnter: PropTypes.func,
  hide: PropTypes.bool,
  tip: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  autosize: PropTypes.any,
};

InputField.defaultProps = {
  required: false,
  hide: false,
};

function InputRedux(props) {
  return <Field {...props} component={InputField} />;
}

export default InputRedux;
