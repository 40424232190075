import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import MainSection from './main-section';
import SubSection from './sub-section';
import {
  selectorCourse,
  selectorModulesActivities,
  selectorModule,
  selectorActivity,
  selectorCohortUserInMasterClass,
} from '../../stores/root-selector';
import useRouter from '../../hook/useRouter';
import {
  getCourse,
  getActivities,
  getModules,
  setActivity,
  setModule,
  addUserActivity,
  getUserCourse,
  getUserModules,
  getUserActivities,
  setFormResult,
  setFormData,
  setLoading,
  addUserCourse,
  getCohorts,
  getCohortUsers,
} from '../../stores/root-action';
import Layout from '../../components/layout';
import { COURSE_LEARNING, PRINT_CERTIFICATE } from '../../constants/routes';
import {
  ACTIVITY_VIDEO,
  ACTIVITY_SLIDE,
  ACTIVITY_QUIZ,
  QUIZ_COMPLETE_PERCENTAGE,
  COURSE_TYPE_COHORT,
  Privacy,
  COURSE_TYPE_MASTERCLASS,
  STORAGE_UPLOAD_IMAGE_PATH,
} from '../../constants/default-values';
import { getFormResultAsync, getFormDataAsync } from '../../stores/user/sagas';
import {
  selectorUserCourse,
  selectorQuizResults,
  selectorUserActivities,
  getScore,
} from '../../stores/user/selectors';
import Portal from '../../components/portal';
import Loading from '../../components/loading';
import { calculatePercentage, compareBetweenDate } from '../../lib';
import PageNotFound from '../../components/not-found';
import LearnMasterDashboard from '../learn-master-class-dashboard';
import { downloadViaUrl } from '../../lib/firebase';
const routeCourse = (courseId, moduleId, activityId = '') => {
  return `${COURSE_LEARNING.replace(
    ':courseId',
    courseId
  )}?moduleId=${moduleId}&activityId=${activityId}`;
};

const getResponse = async (typeformId, responseId) => {
  let response = {};
  let chk = true;
  while (chk) {
    response = await getFormResultAsync(typeformId, responseId);
    if (response) {
      // let { key } = getReferer(response);
      // const cond = (uniqId) ? response.response_id && key === uniqId : response.response_id;
      if (response.response_id) chk = false;
    }
  }

  // await sleep(1000);

  return response;
};

function LearnDashboard() {
  const [done, setDone] = useState(false);
  const [moduleIndex, setModuleIndex] = useState(0);
  const [isCertificate, setIsCertificate] = useState(false);
  const [activityIndex, setActivityIndex] = useState(0);
  const [uniqId, setUniqId] = useState(Math.random().toString());
  const { loading } = useSelector((state) => state.app);
  const { userId, isAdmin } = useSelector((state) => state.auth);
  const course = useSelector(selectorCourse);
  const userCourse = useSelector(selectorUserCourse);
  const modules = useSelector(selectorModulesActivities);
  const isAvailableMasterClass = useSelector(selectorCohortUserInMasterClass);
  const module = useSelector(selectorModule);
  const activity = useSelector(selectorActivity);
  const quizResults = useSelector(selectorQuizResults);
  const userActivities = useSelector(selectorUserActivities);
  const { locale } = useSelector((state) => state.locale);
  const [worksheetUrl, setWorksheetUrl] = useState('');
  const dispatch = useDispatch();
  const { courseId, history, moduleId } = useRouter();

  useEffect(() => {
    dispatch(setActivity({}));
    dispatch(setModule({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCourse(courseId));
    dispatch(getActivities(courseId));
    dispatch(getModules(courseId));
    dispatch(getCohorts(courseId));
  }, [dispatch, courseId]);

  useEffect(() => {
    if (_.get(activity, 'worksheet')) {
      downloadViaUrl(`${STORAGE_UPLOAD_IMAGE_PATH}/${_.get(activity, 'worksheet')}`).then(url => {
        setWorksheetUrl(url);
      });
    }
  }, [activity]);

  useEffect(() => {
    if (userId && courseId) {
      dispatch(getUserCourse(userId, courseId));
      dispatch(getUserModules(userId, courseId));
      dispatch(getUserActivities(userId, courseId));
      dispatch(getCohortUsers(courseId, userId));
    }
  }, [dispatch, userId, courseId]);

  useEffect(() => {
    dispatch(setActivity({}));
  }, [locale, dispatch]);

  useEffect(() => {
    if (userCourse.lastActiveActivityId && modules.length && _.size(userActivities) && !activity.id) {

      let mIndex = modules.findIndex((item) => item.id === userCourse.lastActiveModuleId);
      let aIndex = 0;

      if (modules[mIndex])
        aIndex = modules[mIndex].activities.findIndex(
          (item) => item.id === userCourse.lastActiveActivityId
        );

      setModuleIndex(mIndex);
      setActivityIndex(aIndex);
      dispatch(setModule(modules[mIndex]));
      let act = _.get(modules, `${mIndex}.activities.${aIndex}`, { id: 'n/a' });

      dispatch(setActivity({ ...act }));
    } else if (
      userCourse.lastActiveActivityId &&
      modules.length &&
      activity.id &&
      !done &&
      userActivities[activity.id]
    ) {
      const {
        isRetake,
        isComplete,
        responseId,
        updated,
        totalQuestion,
        score,
        percentage,
      } = userActivities[activity.id];

      dispatch(
        setActivity({
          ...activity,
          isRetake,
          isComplete,
          responseId,
          updated,
          totalQuestion,
          score,
          percentage,
        })
      );
      setDone(true);
    }
  }, [userCourse, modules, activity, userActivities, dispatch, done]);

  useEffect(() => {
    (async () => {
      if (
        activity.id &&
        activity.type === ACTIVITY_QUIZ &&
        activity.isRetake === false &&
        activity.responseId
      ) {
        let formData = await getFormDataAsync(activity.typeformId);
        let response = await getResponse(activity.typeformId, activity.responseId);
        dispatch(setFormResult(response || {}));
        dispatch(setFormData(formData || {}));
      }
    })();
  }, [activity, dispatch]);

  const handleActivity = useCallback(
    (valueModule, valueActivity) => {
      // dispatch(setLoading(true));
      let mIndex = modules.findIndex((item) => item.id === valueModule.id);
      let aIndex = modules[mIndex].activities.findIndex((item) => item.id === valueActivity.id);

      dispatch(setModule({ ...valueModule }));
      dispatch(setActivity({ ...valueActivity }));
      setUniqId(Math.random().toString());
      setModuleIndex(mIndex);
      setActivityIndex(aIndex);
      setIsCertificate(false);
      let isComplete = valueActivity.isComplete;
      if (valueActivity.type === ACTIVITY_VIDEO || valueActivity.type === ACTIVITY_SLIDE)
        isComplete = true;

      dispatch(addUserActivity({ isComplete }));
      history.push(routeCourse(courseId, valueModule.id, valueActivity.id));
    },
    [dispatch, courseId, history, modules]
  );

  const handleNext = useCallback(() => {
    if (
      moduleIndex + 1 >= modules.length &&
      activityIndex + 1 >= modules[moduleIndex].activities.length
    )
      return false;

    let mIndex = moduleIndex;
    let activityLength = modules[mIndex].activities.length;
    let aIndex = (activityIndex + 1 + activityLength) % activityLength;

    if (modules[moduleIndex].activities.length - 1 <= activityIndex) {
      mIndex = (moduleIndex + 1 + modules.length) % modules.length;
      aIndex = 0;
    }

    let foundActivity = modules[mIndex].activities[aIndex] || {};

    setModuleIndex(mIndex);
    setActivityIndex(aIndex);

    handleActivity(modules[mIndex], foundActivity);
  }, [modules, moduleIndex, activityIndex, handleActivity]);

  const handlePrevious = useCallback(() => {
    if (moduleIndex - 1 < 0 && activityIndex - 1 < 0) return false;

    let mIndex = moduleIndex;
    let activityLength = modules[mIndex].activities.length;
    let aIndex = (activityIndex - 1 + activityLength) % activityLength;

    if (
      modules[moduleIndex].activities.length >=
      activityIndex + modules[moduleIndex].activities.length ||
      !activityLength
    ) {
      mIndex = (moduleIndex - 1 + modules.length) % modules.length;
      aIndex = modules[mIndex].activities.length ? modules[mIndex].activities.length - 1 : 0;
    }

    let foundActivity = modules[mIndex].activities[aIndex] || {};

    setModuleIndex(mIndex);
    setActivityIndex(aIndex);

    handleActivity(modules[mIndex], foundActivity);
  }, [modules, moduleIndex, activityIndex, handleActivity]);

  const handleTypeForm = useCallback(
    async (responseId) => {
      dispatch(setLoading(true));

      let data = {
        isComplete: true,
        responseId,
        isRetake: false,
        isSubmit: true,
      };

      if (activity.type === ACTIVITY_QUIZ) {
        let formData = await getFormDataAsync(activity.typeformId);
        let response = await getResponse(activity.typeformId, responseId);
        let score = response.response_id ? response.calculated.score : 0;

        // let fieldMultipleChoice = formData.fields.filter((item) =>
        //   ['yes_no', 'multiple_choice', 'file_upload', 'long_text'].includes(item.type)
        // );
        // let totalQuestion = formData.fields.length;
        let totalQuestion = getScore(formData);
        // let percentage = calculatePercentage(formData.fields.length, score);
        let percentage = calculatePercentage(totalQuestion, score);
        data.score = score;
        data.percentage = percentage;
        data.isComplete = percentage >= QUIZ_COMPLETE_PERCENTAGE;
        data.totalQuestion = totalQuestion;
      }

      dispatch(addUserActivity(data));
      dispatch(setActivity({ ...activity, ...data }));
    },
    [dispatch, activity]
  );

  const handleStartCourse = useCallback(() => {
    if (modules.length) {
      let activity = modules[0].activities[0] || {};
      dispatch(setModule(modules[0]));
      if (activity.type) {
        dispatch(setActivity({ ...activity }));
        let isComplete = activity.type === ACTIVITY_VIDEO || activity.type === ACTIVITY_SLIDE;
        dispatch(addUserActivity({ isComplete }));
      }
    }
  }, [dispatch, modules]);

  const handleRetakeQuiz = useCallback(() => {
    setUniqId(Math.random().toString());
    dispatch(setModule(modules.find((item) => item.id === activity.moduleId)));
    dispatch(setActivity({ ...activity, isRetake: true }));
    dispatch(addUserActivity({ isComplete: false, isRetake: true }));
  }, [dispatch, activity, modules]);

  const handleCertificate = useCallback(() => {
    setIsCertificate(true);
    dispatch(
      addUserCourse({ lastActiveActivityId: '', lastActiveModuleId: '', isCertificate: true })
    );
  }, [dispatch]);

  const handlePrintCertificate = useCallback(() => {
    setIsCertificate(true);
    history.push(PRINT_CERTIFICATE.replace(':courseId', courseId));
  }, [courseId, history]);

  if (!modules.length) return <Loading />;

  if (!isAdmin) {
    if (!_.get(course, 'status')) {
      return <PageNotFound />;
    }

    if (_.get(course, 'type') === COURSE_TYPE_COHORT) {
      if (!compareBetweenDate(course.startDateFormat, course.endDateFormat)) {
        return <PageNotFound />;
      }

      if ((_.get(course, 'privacy') === Privacy.PRIVATE && !course.isRegistered)) {
        return <PageNotFound />;
      }
    }

    if (_.get(course, 'type') === COURSE_TYPE_MASTERCLASS) {

      if ((_.get(course, 'privacy') === Privacy.PRIVATE && !isAvailableMasterClass)) {
        return <PageNotFound />;
      }
    }
  }

  if (_.get(course, 'type') === COURSE_TYPE_MASTERCLASS) {

    return <LearnMasterDashboard
      loading={loading}
      course={course}
      modules={modules}
      module={module}
      activity={activity}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleActivity={handleActivity}
      handleTypeForm={handleTypeForm}
      moduleId={moduleId}
      formResult={quizResults}
      handleStartCourse={handleStartCourse}
      handleRetakeQuiz={handleRetakeQuiz}
      uniqId={uniqId}
      handleCertificate={handleCertificate}
      isCertificate={isCertificate}
      userId={userId}
      handlePrintCertificate={handlePrintCertificate}
      worksheetUrl={worksheetUrl}
    />;
  }

  return (
    <Layout>
      {loading && (
        <Portal>
          <Loading />
        </Portal>
      )}
      <MainSection
        course={course}
        modules={modules}
        module={module}
        activity={activity}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        handleActivity={handleActivity}
        handleTypeForm={handleTypeForm}
        moduleId={moduleId}
        formResult={quizResults}
        handleStartCourse={handleStartCourse}
        handleRetakeQuiz={handleRetakeQuiz}
        uniqId={uniqId}
        handleCertificate={handleCertificate}
        isCertificate={isCertificate}
        userId={userId}
        handlePrintCertificate={handlePrintCertificate}
      />
      <SubSection course={course} activity={activity} isCertificate={isCertificate} />
    </Layout>
  );
}

export default LearnDashboard;
