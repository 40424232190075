import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	
	body {
		
	}

	a {
		text-decoration: none;
		color: black;
	}

	* {
		box-sizing: border-box;
	}
`;
