import styled from 'styled-components';

export const WrapComponent = styled.div``;
export const WrapCardComponent = styled.div`
  margin-bottom: 1rem;
  
`;
export const ButtonDownloadCertificate = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;
export const WrapTitleReport = styled.div`
  padding-top: 1.7rem;
  padding-bottom: 1rem;
  @media(max-width:768px){
    display: none;
  };
`;
export const H1 = styled.h1`
  font-size: 4.5rem;
  @media(min-width: 768px) and (max-width: 1000px){
    font-size: 2rem;
  }
`;
export const H2 = styled.h2`
  @media(max-width:768px){
    font-size: 1rem;
  };
`;
export const H3 = styled.h3``;
export const H4 = styled.h4``;
export const H5 = styled.h5``;
export const H6 = styled.h6`
  @media(min-width: 768px) and (max-width: 1000px){
    font-size: 0.7rem;
  }
`;
export const P = styled.p`
  @media(max-width:768px){
    font-size: 0.7rem;
  };
`;
export const Span = styled.span`
  font-size: 1.5rem;
`;
