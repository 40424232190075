import styled from 'styled-components';

export const ImagLogo = styled.img`
    width: 12rem;
`;
export const HrCustom = styled.hr`
    height: 0.4rem;
    width: 70%;
    background: #ffc107;
    margin: auto;
    margin-top:1rem;
    margin-bottom:1rem;
    border-radius:0.2rem;
`;
export const SocialIcon = styled.img`
    font-size: 2rem;
    margin-left: 1rem;
    height: 5rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
`;
export const CustomHr = styled.hr`
  height: 0.3rem;
  border-radius:0.25rem;
  width: 100%;
  margin:auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
export const ContainerDefault = styled.div`
  width: 100%;

  a:hover{
    text-decoration:underline !important;
    color: #ccc !important;
  }
`;
export const GuideAction = styled.p`
  cursor: pointer;
`;