import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardImg, CardBody, CardTitle, CardText, Label} from 'reactstrap';
import {CardWrapperStyle, ElementWrapperStyle, SpanWrapper} from './styled';
import Element from '../element';
import {Badge} from 'antd';
import {BOOK_ICON, LABEL_ICON} from '../../constants/images';

function CourseCard({dataSource}) {
  const {image, type, tag, price, description, title, elements} = dataSource;

  return (
    <CardWrapperStyle>
      <div style={{zIndex: '1', position: 'absolute', top: '-15px', display: 'block'}}>
        <Badge
          count={type}
          style={{
            backgroundColor: '#404040',
            color: 'white',
            width: '100px',
          }}
        />
      </div>
      <Card>
        <CardImg top width="100%" src={image} alt="Card image cap" />
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <CardText dangerouslySetInnerHTML={{__html: description}} />
          <div className="course-bottom">
            <ElementWrapperStyle>
              {elements
                .filter((item, i) => i < 3)
                .map((item) => (
                  <Element dataSource={item} key={item.icon} />
                ))}
            </ElementWrapperStyle>
            <ElementWrapperStyle>
              <SpanWrapper>
                <img src={LABEL_ICON} alt="icon" />
                <Label>{price ? `$${price}` : 'Free'}</Label>
              </SpanWrapper>
              <SpanWrapper>
                <img src={BOOK_ICON} alt="icon" />
                <Label>{tag || 'Available Now'}</Label>
              </SpanWrapper>
            </ElementWrapperStyle>
          </div>
        </CardBody>
      </Card>
    </CardWrapperStyle>
  );
}

CourseCard.propTypes = {
  dataSource: PropTypes.object,
};

export default CourseCard;
