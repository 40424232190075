import homePage from '../view/home_en';
import aboutUs from '../view/about_us_en';
import footer from '../view/footer_en';
import userAuthentication from '../view/user_authentication_en';
import report from '../view/report_en';
import courseDetail from '../view/course_detail_en';
import learn from '../view/learn_en';
import contactUs from '../view/contact_us_en';
import qa from '../view/qa_en';

export default {
  ...homePage,
  ...aboutUs,
  ...footer,
  ...userAuthentication,
  ...report,
  ...courseDetail,
  ...learn,
  ...contactUs,
  ...qa,
};
