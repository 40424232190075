import styled from 'styled-components';
import { CERTIFICATE } from '../../constants/images';

export const CertificateContainer = styled.div`
  width: 596px;
  height: 842.96px;
  margin: auto;
  background: white;
  border: 1px solid gray;
  background-image: url(${ CERTIFICATE}) !important;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  @media print{
    width: 95vw; 
    height: 90vh;
    page-break-after:always;
    margin: auto;
    background: white;
    border: none;
    background-image: url(${ CERTIFICATE}) !important;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
  }
`;

export const ContainerBlog = styled.div``;

export const Image = styled.img`
  width: 150px !important;
  margin-top: 40px !important;
`;
