import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import PropTypes from 'prop-types';
import {FullBackground, Title, Border} from './styled';
import TestimonialItem from '../testimonial-item';
import IntlMessages from '../../../lib/IntlMessages';

function Testimonial({course}) {
  return (
    <FullBackground>
      <Container>
        <Row>
          <Col>
            <Title>
              <IntlMessages id="testimonials" />
            </Title>
            <Border>
              <hr />
            </Border>
            <Row>
              <Col md="2" sm="0"></Col>
              <Col>
                <TestimonialItem {...course} />
              </Col>
              <Col md="2" sm="0"></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </FullBackground>
  );
}

Testimonial.propTypes = {
  course: PropTypes.object,
};

export default Testimonial;
