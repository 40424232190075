export default {
  course: 'COURSES',
  performance: 'PERFORMANCES',
  activity: 'ACTIVITIES',
  completed: 'COMPLETED',
  quizzes: 'QUIZZES',
  na: 'N/A',
  passed: 'Passed',
  not_passed: 'Not Passed',
  download_certificate: 'Download Certificate',
}; 