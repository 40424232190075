import React from 'react';
import {Label} from 'reactstrap';
import PropTypes from 'prop-types';
import {SessionGroup} from './styled';
import IntlMessages from '../../../lib/IntlMessages';

function ItemSession({rank, title}) {
  return (
    <SessionGroup>
      <Label><IntlMessages id="session" /> {rank}:</Label>
      <Label>{title}</Label>
    </SessionGroup>
  );
}
ItemSession.propTypes = {
  rank: PropTypes.number,
  title: PropTypes.string,
};

export default ItemSession;
