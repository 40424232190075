import styled, { css } from 'styled-components';

export const MainSectionBackground = styled.div`
  background-color: #f8f8f8;
`;

// Learn Header

export const GroupTitle = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
`;

export const Title = styled.h4`
  text-transform: uppercase;
  color: #000000;
`;

export const Subtitle = styled.h6`
  color: #333;
`;

// Learn Section
export const NextPreviousGroup = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const PreviousStyle = styled.div``;

// Video

export const VideoWrapper = styled.div`
  @media (min-width: 300px) {
    width: auto;
    height: 240px;
  }

  @media (min-width: 576px) {
    width: auto;
    height: 360px;
  }

  @media (min-width: 768px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 992px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 1024px) {
    width: auto;
    height: 460px;
  }

  @media (min-width: 1200px) {
    width: auto;
    height: 460px;
  }

  @media (min-width: 1400px) {
    width: auto;
    height: 560px;
  }

  @media (min-width: 1500px) {
    width: auto;
    height: 560px;
  }

  @media (min-width: 1600px) {
    width: auto;
    height: 700px;
  }
`;

export const ActionWrapper = styled.div`
  width: 100%;
  background-color: #FFF;
  height: 100%;
`;

// Learn Module

export const HeaderStyle = styled.div`
  border-top: 2px solid #00bbc3;
  border-bottom: 2px solid #00bbc3;
  background-color: #e8e8e8;
  .col h5 {
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .col:first-child h5 {
    margin-left: 20px;
  }
  .col:nth-child(2) h5 {
    text-align: right;
    margin-right: 20px;
  }
`;

export const BackgroundWrapperStyle = styled.div`
  background: #C5FAFA;
  .selected{
    background-color:#00BBC3;
    transition: 0.3s;
  }

  .padding{
    padding: 10px 10px;
    transition: 0.3s;
  }
`;

export const MediaGroupStyle = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  display:flex;
  align-items: center;
  flex-wrap: nowrap;

  .hover:hover {
    background-color:#e4e4e4;
    transition: 0.3s;
  }

  div:first-child {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40px;
    img {
      width: 18px;
      height: auto;
    }
  }

  div:nth-child(2) {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50px;
    img {
      width: 18px;
      height: auto;
    }
  }

  div:nth-child(3) {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40px;
    color: #333;
    font-size: 14px;
  }

  div:nth-child(4) {
    color: #333;
    margin-right: 25px;
    font-size: 14px;
  }

  div:nth-child(5) {
    margin-left: auto;
    margin-right: 0px;
    color: #333;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    flex-grow: 0;
    flex-shrink: 0;

    p {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }
  

`;

export const ActivityListStyle = styled.div`
  background-color: #E6F4F4;
  border-top: 1px solid #EAD3D3;
  border-bottom: 1px solid #EAD3D3;
`;

export const ActivityTitle = styled.div`
  margin-top: 1px;
  font-size: 17px;
  color: #555;
`;

export const ActivityDuration = styled.div`
  text-align: right;
`;

export const ModuleListStyle = styled.div`
  
`;

export const customNextPrevious = css`
  width: 60px;
  height: 60px;
  fill: #00bbc3;
`;

export const CustomActivityIcon = css`
  width: 30px;
  height: 30px;
  fill: #b9b9b8;
`;

export const CustomerDoneIcon = css`
  fill: #26b3bc;
  width: 30px;
  height: 30px;
`;

export const NextButtonIcon = styled.svg`
  ${customNextPrevious}
`;

export const PreviousButtonIcon = styled.svg`
  ${customNextPrevious}
`;

export const TypeFormView = styled.div`
  width: 100%;
  height: inherit;
`;

export const ModuleScroll = styled.div` 
  overflow-y: scroll;
  border-top: 2px solid #00BBC3;

  @media (min-width: 300px) {
    width: auto;
    height: 100%;
  }

  @media (min-width: 576px) {
    width: auto;
    height: 100%;
  }

  @media (min-width: 768px) {
    width: auto;
    height: 100%;
  }

  @media (min-width: 992px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 1024px) {
    width: auto;
    height: 460px;
  }

  @media (min-width: 1200px) {
    width: auto;
    height: 460px;
  }

  @media (min-width: 1400px) {
    width: auto;
    height: 560px;
  }

  @media (min-width: 1500px) {
    width: auto;
    height: 560px;
  }

  @media (min-width: 1600px) {
    width: auto;
    height: 700px;
  }
`;

export const ModuleItemWrapperStyle = styled.div`
  
`;

export const ModuleButtonWrapperStyle = styled.div`
  width: 100%;
  background-color:#F8F8F8;
  padding: 12px 15px;
  display: flex;
  cursor: pointer;
  div:first-child {
    width: 40px;
    color: #333;
    padding-top:0.5px;
  }
  div:nth-child(2) {
    width: auto;
    text-transform: uppercase;
    color: #333;
    font-family: 'PoppinsMedium', 'NotoSanMedium';
    font-size: 15px;
  }
  div:nth-child(3) {
    width: 10px;
    margin-left: auto;
    img.img-collapse {
      width: 10px;
      height: auto;
      transform: rotate(0deg);
      transition: all .25s ease-in;
    }

    img.img-expend {
      width: 10px;
      height: auto;
      transform: rotate(90deg);
      transition: all .25s ease-out;
    }
  }
`;

export const BottomBorder = styled.hr`
  width: 100%;
  height: 2px;
  background-color: #26B2BC
`;

export const CertificateWrapperStyle = styled.div`
  button {
    text-transform: uppercase;
    width: 300px;
    margin-left: auto;
    height: 45px;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    background-color: black;
    letter-spacing: 1.5px;
    border-radius: 0px;
  }
`;
export const PreviousButtonWrapperStyle = styled.button`
  cursor: pointer;
  width: 180px;
  text-align: start;
  background: transparent;
  border-width: 0px;
  label {
    cursor: pointer;
    color: #777;
    text-decoration: underline;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';

    span {
      cursor: pointer;
    }
  }
`;

export const NextButtonWrapperStyle = styled.button`
  cursor: pointer;
  width: 180px;
  float: right;
  text-align: end;
  background: transparent;
  border-width: 0px;
  label {
    color: #777;
    text-decoration: underline;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    text-align: right;
    cursor: pointer; 
    span {
      cursor: pointer;
    }
  }
`;

export const SurveyCompleteStyle = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexItemStyle = styled.div`
    padding: 5px;
    width: 320px;
    height: auto;
    margin: 10px;
    line-height: 20px;
    color: white;
    font-weight: bold;
    font-size: 2em;
    text-align: center;

    div {
      h4 {
        font-size: 20px;
        margin-top: 20px;
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
        color: #333;
      }
      p {
        font-size: 14px;
        margin-top: 20px;
        color: #333;
      }

      button {
        width: 220px;
        height: 45px;
        margin-top: 30px;
        background-color: none;
      }
      .btn-secondary {
        color: #777;
        background-color: #FFF;
        border: 2px solid #dedede;
      }
      
    }


    img {
      width: 50px;
      height: auto;
    }
`;


export const QuizResultStyle = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StaticWidthWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  padding: 0;
  margin: auto;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const QuizSectionFlexStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 200px;
  margin-bottom: 20px;
`;

export const QuizFlexStyle = styled.div`
  text-align: left;
  display: flex;

  div:first-child {
    color: #707070;
    width: 50px;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    font-size: 20px;
  }

  div:nth-child(2) {
    color: #707070;
    width: 30px;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    font-size: 20px;
  }

  .green {
    color: #00BBC3;
  }
  .red {
    color: #B1363F;
  }

  div:nth-child(3) {
    width: 30px;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    font-size: 20px;
    margin-left: 20px;
  }
`;

export const QuizResultHeaderStyle = styled.div`
  font-size: 18px;
  color: #333;
  font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
  margin-bottom: 10px;
`;

export const ScoreHeaderStyle = styled.div`

`;
export const ScoreText = styled.div`
  font-size: 18px;
  color: #00BBC3;
  font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
  span.pass {
    padding-left: 20px;
    color: #00BBC3 !important;;
  }
  span.failed {
    padding-left: 20px;
    color: #B1363F !important;
  }
`;
export const DateText = styled.div`
  font-size: 15px;
  color: #00BBC3;
  font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
`;

export const RetakeQuizButton = styled.div`
  margin-top: 20px;
  button {
    width: 120px;
    hight: 45px;
    background-color:#00BBC3;
    color: #000;
    border-radius: 6px;
    border-width: 0px;
  }
`;

export const CertificateFlexContainer = styled.div`
  height: 100%;
  padding: 20px;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CertificateStyle = styled.div`
  width: 500px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  div:first-child {
    hight: 100%;
    img {
      object-fit: contain;
      margin-top: 0px;
      width: 100%;
      hight: auto;
    }

    @media (min-width: 300px) {
      width: 280px;
    }

    @media (min-width: 576px) {
      width: 300px;
    }

    @media (min-width: 768px) {
      width: 400px;
    }

    @media (min-width: 992px) {
      width: 400px;
    }

    @media (min-width: 1024px) {
      width: 500px;
    }
  }
  div:nth-child(2) {
    color: #333;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    font-size: 24px;
    margin:20px 0px;
  } 
  div:nth-child(3) {
    color: #333;
    font-size: 18px;
    margin:20px 0px;
  } 
  div:nth-child(4) {
    margin-top: 10px;
    button {
      text-transform: uppercase;
      background-color: #00BBC3;
      border-radius: 6px;
      color: #000;
      width: 220px;
      height: 45px;
      border-width: 0px;
    }
  } 
`;

export const WrapText = styled.span`
  color: black;
`;