import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import classnames from 'classnames';
import {Select, Form} from 'antd';
import {FormText} from 'reactstrap';

const FormItem = Form.Item;
const Option = Select.Option;

function AutoCompleteField(props) {
  const onChange = (value) => {
    const {
      input: {onChange: reduxFormChange},
      onChange,
    } = props;
    reduxFormChange(value);
    if (onChange) {
      onChange(value);
    }
  };

  const {
    tip,
    dataSource,
    placeholder,
    label,
    required,
    mode,
    defaultValue,
    disabled,
    className,
    onSearch,
    filterOption,
    defaultActiveFirstOption,
    showArrow,
    notFoundContent,
    meta: {touched, error},
    input: {value},
  } = props;
  const validateStatus = classnames(touched && error ? 'error' : 'success');

  let tmpValue = placeholder;
  if (defaultValue) tmpValue = defaultValue;
  if (value) tmpValue = value;

  if (defaultValue === null && !value) {
    tmpValue = null;
  }

  return (
    <Fragment>
      <FormItem help={error} label={label} required={required} validateStatus={validateStatus}>
        <Select
          value={tmpValue}
          showSearch
          mode={mode}
          placeholder={placeholder}
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch ? onSearch : null}
          defaultActiveFirstOption={defaultActiveFirstOption}
          showArrow={showArrow}
          notFoundContent={notFoundContent}
          allowClear={true}
          className={className}
          autoClearSearchValue={true}
          disabled={disabled}
          filterOption={
            filterOption === false
              ? false
              : (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {dataSource.map((value, index) => {
            return (
              <Option key={index} value={value.id}>
                {value.name}
              </Option>
            );
          })}
        </Select>
      </FormItem>
      {tip && (
        <FormText color="muted" className="mt-0 mb-2">
          {tip}
        </FormText>
      )}
    </Fragment>
  );
}

AutoCompleteField.propTypes = {
  tip: PropTypes.string,
  input: PropTypes.any,
  meta: PropTypes.any,
  onChange: PropTypes.func,
  dataSource: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  mode: PropTypes.string,
  defaultValue: PropTypes.string,
  // allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  isValuePlaceholder: PropTypes.bool,
  className: PropTypes.string,
  onSearch: PropTypes.func,
  filterOption: PropTypes.bool,
  defaultActiveFirstOption: PropTypes.bool,
  showArrow: PropTypes.bool,
  notFoundContent: PropTypes.bool,
};

AutoCompleteField.defaultProps = {
  mode: 'default',
};

function SelectAutoComplete(props) {
  return <Field component={AutoCompleteField} {...props} />;
}

export default SelectAutoComplete;
