/* eslint-disable indent */
import {
  CATEGORY_SET_REQUEST,
  CATEGORY_SET_SUCCESS,
  CATEGORY_SET_ERROR,
  CATEGORY_SET_CATEGORY,
  CATEGORY_SET_CATEGORIES,
} from './types';

const INIT_STATE = {
  categories: [],
  category: {},
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CATEGORY_SET_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case CATEGORY_SET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CATEGORY_SET_ERROR:
      return {...state, loading: false, error: action.payload.message};
    case CATEGORY_SET_CATEGORIES:
      return {...state, categories: action.payload.categories};
    case CATEGORY_SET_CATEGORY:
      return {...state, category: action.payload.category};
    default:
      return {...state};
  }
};
