import styled from 'styled-components';

export const MediaGroup = styled.div`
    margin-top: 20px;

    img {
        width: 60px;
        height: auto;
        margin-right: 30px;
        margin-top:0px;
    }

    h4 {
        font-size: 15px;
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    }

    .media-body {
        font-size: 14px;
        color: #333;
    }

    .media {
        margin-bottom: 30px;
    }
`;