
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import { getStoragePathImage } from '../../../lib';
import { Button } from 'reactstrap';
import { CertificateStyle, CertificateFlexContainer } from './styled';
import IntlMessages from '../../../lib/IntlMessages';
import { PRINT_CERTIFICATE } from '../../../constants/routes';

function Certificate({ certificate, id }) {
  return (
    <CertificateFlexContainer>
      <CertificateStyle>
        <div>
          <img src={getStoragePathImage(certificate.image)} alt="desc" />
        </div>
        <div>{certificate.title}</div>
        <div dangerouslySetInnerHTML={{ __html: certificate.description }} />
        <div>
          <a href={PRINT_CERTIFICATE.replace(':courseId', id)} target="_blank">
            <Button><IntlMessages id="download_certificate" /></Button>
          </a>
        </div>
      </CertificateStyle>
    </CertificateFlexContainer>
  );
}

Certificate.propTypes = {
  certificate: PropTypes.object,
  handleRetakeQuiz: PropTypes.func,
  activity: PropTypes.object,
  handlePrintCertificate: PropTypes.func,
  id: PropTypes.string
};
export default Certificate;
