// Libs
import React, {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {FLAG_EN, FLAG_KH, LOGO_V3} from '../../../constants/images';
import {Container, Collapse, Navbar, NavbarToggler, Nav, NavItem, Button} from 'reactstrap';
import {NavLink, useLocation} from 'react-router-dom';
import {ImagLogo, ImagFlag, Avatar, ContainerDefault} from './styled';
import {
  SING_IN,
  USER_PROFILE,
  ACCOUNT_SETTING,
  ABOUT_US,
  SING_OUT,
  USER_DASHBOARD,
  // CONTACT_US,
  VIEW_ALL_COURSES,
  QUESTION_ANSWER,
  PURCHASE_HISTORY,
} from '../../../constants/routes';
import {localeSet} from '../../../stores/root-action';
import {getLocalStorage, getStoragePathProfile} from '../../../lib';
import {Menu, Dropdown} from 'antd';
import IntlMessages from '../../../lib/IntlMessages';
import PropTypes from 'prop-types';
import _ from 'lodash';

function Header({menuSticky}) {
  const [isOpen, setIsOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const {user} = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {pathname, search} = useLocation();
  let paths = _.trim(pathname, '/').split('/')[0];

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const handleSwitchLang = useCallback(
    (lang) => {
      dispatch(localeSet(lang || 'en'));
    },
    [dispatch]
  );

  const menu = (
    <Menu className="dropdown-user-setting">
      <Menu.Item>
        <NavLink to={USER_PROFILE} className="nav-link">
          <IntlMessages id="profile" />
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to={USER_DASHBOARD} className="nav-link">
          <IntlMessages id="my_dashboard" />
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to={ACCOUNT_SETTING} className="nav-link">
          <IntlMessages id="account_setting" />
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to={PURCHASE_HISTORY} className="nav-link">
          <IntlMessages id="purchase_history" />
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to={ABOUT_US} className="nav-link">
          <IntlMessages id="help" />
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink to={SING_OUT} className="nav-link">
          <IntlMessages id="logout" />
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <ContainerDefault
      className={`nav-header ${menuSticky && 'sticky'} ${offset > 50 && 'tr-bg-info'}`}
    >
      <Container>
        <Navbar light expand="md">
          <NavLink to="/">
            <ImagLogo src={LOGO_V3} alt="Noimage" />
          </NavLink>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto text-danger menu-nav" navbar>
              <NavItem>
                <NavLink to="/" className={`nav-link ${paths === '' ? 'active-menu' : ''}`}>
                  <IntlMessages id="home" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to={`${VIEW_ALL_COURSES}?tab=all`}
                  className={`nav-link ${paths === 'courses' ? 'active-menu' : ''}`}
                >
                  <IntlMessages id="view_courses" />
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink to={ABOUT_US} className={`nav-link ${paths === 'about-us' ? 'active-menu' : ''}`}>
                  <IntlMessages id="about_us" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={CONTACT_US} className={`nav-link ${paths === 'contact-us' ? 'active-menu' : ''}`}>
                  <IntlMessages id="contact_us" />
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  to={QUESTION_ANSWER}
                  className={`nav-link ${paths === 'q-a' ? 'active-menu' : ''}`}
                >
                  <IntlMessages id="faqs" />
                </NavLink>
              </NavItem>
              {!getLocalStorage('Authorize') ? (
                <>
                  <NavItem className="mr-2">
                    <NavLink to={SING_IN}>
                      <Button color="dark" className="text-white btn-sm rounded-pill btn-c-login">
                        <IntlMessages id="log_in" />
                      </Button>
                    </NavLink>
                  </NavItem>
                </>
              ) : (
                <>
                  <NavItem className="nav-user-setting">
                    <Dropdown overlay={menu} placement="bottomLeft">
                      <Avatar src={getStoragePathProfile(user.image)} alt="NoImage" />
                    </Dropdown>
                  </NavItem>
                </>
              )}
              <NavItem>
                <NavLink to={`${pathname}${search}`} className="nav-link">
                  <span onClick={() => handleSwitchLang('en')}>
                    <ImagFlag src={FLAG_EN} alt="Noimage" />
                  </span>{' '}
                  &nbsp;
                  <span onClick={() => handleSwitchLang('km')}>
                    <ImagFlag src={FLAG_KH} alt="Noimage" />
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </ContainerDefault>
  );
}

Header.propTypes = {
  menuSticky: PropTypes.bool,
};

export default Header;
