import React, {useState} from 'react';
import Layout from '../../components/layout';
import {ContainerDefault, Section, WrapElement, WrapTitleSection} from './styled';
import {Row, Col, Container} from 'reactstrap';
import IntlMessages from '../../lib/IntlMessages';
import {Button} from 'reactstrap';
import {useSelector} from 'react-redux';
import ModalLoadVideo from '../../components/video-modal';
import {Collapse, CollapseTitle, CollapseBody, CardHeader, CardContent, CardWrap} from './styled';
import {
  THUMBNAIL1,
  THUMBNAIL2,
  THUMBNAIL3,
  THUMBNAIL4,
  THUMBNAIL5,
  THUMBNAIL6,
  PLAY_ICON,
} from '../../constants/images';
import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

export default function QuestionAnswerPage() {
  const [visible, setVisible] = useState(false);
  const [videoPlayingUrl, setVideoPlayingUrl] = useState();
  const {user} = useSelector((state) => state.user);
  const [index, setIndex] = useState({});

  const handlePlayVideo = (videoUrl) => {
    setVisible(true);
    setVideoPlayingUrl(videoUrl);
  };

  return (
    <Layout>
      <ModalLoadVideo videoUrl={videoPlayingUrl} visible={visible} setVisible={setVisible} />
      {/* Header */}
      <ContainerDefault>
        <Section label="section1" className="bg-warning">
          <Container>
            <Row>
              <Col md="12">
                <WrapElement>
                  <h1 className="mb-2 text-white">
                    <IntlMessages id="frequently_asked_questions" />
                  </h1>
                  <WrapTitleSection className="text-white mt-4">
                    <IntlMessages id="hi" />
                    {` ${user.firstName ? user.firstName : ''} ${
                      user.lastName ? user.lastName : ''
                    }`}
                    ,
                  </WrapTitleSection>
                  <WrapTitleSection className="text-white">
                    <IntlMessages id="how_can_we_help_you" />
                  </WrapTitleSection>
                </WrapElement>
              </Col>
            </Row>
          </Container>
        </Section>
      </ContainerDefault>
      {/* Video Q|A */}
      <ContainerDefault>
        <Section label="section2">
          <Container>
            <Row>
              {gaVideos.map((item, index) => {
                return (
                  <Col md="4" className="mt-4 mb-3" key={index}>
                    <CardWrap className="card" onClick={() => handlePlayVideo(item.video)}>
                      <CardHeader style={{backgroundImage: `url(${item.thumbnail})`}}>
                        <img
                          style={{height: '30px', borderRadius: '50%'}}
                          src={PLAY_ICON}
                          alt="play_icon"
                        />
                      </CardHeader>
                      <CardContent className="text-warning">
                        <IntlMessages id={item.title} />
                      </CardContent>
                    </CardWrap>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Section>
      </ContainerDefault>
      {/* Q|A */}
      <ContainerDefault>
        <Section label="section2">
          <Container>
            <Row>
              {questions.map((item, i) => {
                return (
                  <Col md="12" className="mt-4 mb-4" key={index}>
                    <Collapse
                      className="border-info"
                      onClick={() => {
                        if (index[i]) {
                          delete index[i];
                          setIndex({...index});
                        } else setIndex({...index, [i]: true});
                      }}
                    >
                      <CollapseTitle>
                        <h2 className="text-info" style={{fontWeight: 'bold'}}>
                          Q.
                        </h2>
                      </CollapseTitle>
                      <CollapseBody className="text-info">
                        <IntlMessages id={item.question} />
                      </CollapseBody>
                    </Collapse>
                    <SlideDown className={'my-dropdown-slidedown'}>
                      {index[i] ? (
                        <Collapse className={'border-warning'}>
                          <CollapseTitle>
                            <h2 className="text-warning" style={{fontWeight: 'bold'}}>
                              A.
                            </h2>
                          </CollapseTitle>
                          <CollapseBody className="text-warning">
                            <IntlMessages id={item.answer} />
                          </CollapseBody>
                        </Collapse>
                      ) : null}
                    </SlideDown>
                  </Col>
                );
              })}
              <Col className="text-center">
                <a
                  href="https://form.typeform.com/to/BTZhGG7h?typeform-source=l.messenger.com"
                  target="blank"
                >
                  <Button className="mt-2 mb-4 btn btn-warning text-light">
                    <IntlMessages id="submit_your_questions" />
                  </Button>
                </a>
              </Col>
            </Row>
          </Container>
        </Section>
      </ContainerDefault>
    </Layout>
  );
}

const questions = [
  {
    question: 'question1',
    answer: 'answer1',
  },
  {
    question: 'question2',
    answer: 'answer2',
  },
  {
    question: 'question3',
    answer: 'answer3',
  },
  {
    question: 'question4',
    answer: 'answer4',
  },
  {
    question: 'question5',
    answer: 'answer5',
  },
];

const gaVideos = [
  {
    video: 'https://vimeo.com/639505093/0229ed3501',
    title: 'video1',
    thumbnail: THUMBNAIL1,
  },
  {
    video: 'https://vimeo.com/639505042/1ac3d4613a',
    title: 'video2',
    thumbnail: THUMBNAIL2,
  },
  {
    video: 'https://vimeo.com/639505171/7367f28e33',
    title: 'video3',
    thumbnail: THUMBNAIL3,
  },
  {
    video: 'https://vimeo.com/639505206/57462157dc',
    title: 'video4',
    thumbnail: THUMBNAIL4,
  },
  {
    video: 'https://vimeo.com/639505006/707d104317',
    title: 'video5',
    thumbnail: THUMBNAIL5,
  },
  {
    video: 'https://vimeo.com/639505241/562b46051f',
    title: 'video5',
    thumbnail: THUMBNAIL6,
  },
];
