import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import SpeakerList from './speaker-list.jsx';
import { ContentStyle } from './styled';
import IntlMessages from '../../../lib/IntlMessages';

const Tab = ({ about, resource, speakers }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <ContentStyle>
      <Nav tabs>
        <NavItem
          className={classnames({ active: activeTab === '1' })}
          onClick={() => {
            toggle('1');
          }}
        >
          <NavLink><IntlMessages id="about" /></NavLink>
        </NavItem>
        <NavItem
          className={classnames({ active: activeTab === '2' })}
          onClick={() => {
            toggle('2');
          }}
        >
          <NavLink><IntlMessages id="resources_and_activities" /></NavLink>
        </NavItem>
        <li className="slider" role="presentation"></li>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" md="7" xl="8" xxl="9">
              <p dangerouslySetInnerHTML={{ __html: about }} />
            </Col>
            <Col sm="12" md="5" xl="4" xxl="3">
              <SpeakerList speakers={speakers} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col md="8">
              <p dangerouslySetInnerHTML={{ __html: resource }} />
            </Col>
            <Col md="4">
              <SpeakerList speakers={speakers} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </ContentStyle>
  );
};

Tab.propTypes = {
  about: PropTypes.string,
  resource: PropTypes.string,
  speakers: PropTypes.array
};

export default Tab;
