import styled from 'styled-components';
// import { PEOPLES_EXPLAINING } from "@/constants/images";

export const WrapperBodyLeft = styled.div`
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 75% 100%;
`;

export const StyleWrapperSignIn = styled.div`
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 75% 100%;
  background-image: url(${(props) => props.backgroundImage});
`;
export const ImageCoverLogin = styled.img`
  width: 100%;
`;
export const WrapLoginForm = styled.div`
    box-shadow: 0 0 10px;
    background: white;
    padding: 2rem;
    margin-top:2rem;
    position: relative;
    z-index: 0;
`;
