import {
  setCollectionDoc,
  getCollectionSnapshots,
  updateCollectionDoc,
  getCollectionDoc,
  firestoreRef,
  // rsf,
  subscribeEventChannelCollection,
  FIRSTORE_TIMESTAMP,
  generateDocKey,
  getCollectionDocRealTime,
  firebaseApp,
} from '../../lib/firebase';
import {all, call, fork, put, takeLatest, select, take, cancelled} from 'redux-saga/effects';
import {
  configTypeForm,
  ACTIVITY_QUIZ,
  PayWayType,
  PaymentStatus,
} from '../../constants/default-values';

import {
  USER_UPDATE_USER,
  USER_GET_USER,
  USER_GET_USERS,
  USER_GET_USER_COURSES,
  USER_GET_USER_MODULES,
  USER_ADD_USER_COURSE,
  USER_ADD_USER_MODULE,
  USER_ADD_USER_ACTIVITY,
  USER_GET_USER_COURSE,
  USER_GET_USER_ACTIVITIES,
  USER_GET_FORM_DATA,
  USER_GET_FORM_RESULT,
  USER_GET_USER_PAYMENT,
} from './types';
import {setUsersError, setUsersSuccess, setUsersRequest, setUsers, setUser} from '../root-action';
import {
  setUserCourses,
  setUserModules,
  setUserActivities,
  setUserCourse,
  setFormResult,
  setFormData,
  setUserPayment,
} from './actions';
import Axios from 'axios';
import {setLoading} from '../app/actions';
import _ from 'lodash';
import {history} from '../../lib/utils/history';

export const getPurchaseHistoryAsync = async (ob, userId) => {
  try {
    return firestoreRef
      .doc(`users/${userId}`)
      .collection('userPayments')
      .orderBy('created', 'desc')
      .onSnapshot(ob);
  } catch (err) {
    console.log(err.message);
  }
};

export const getUserPaymentWithCohortAsync = async (userId, courseId, cohortId) => {
  try {
    let snapshots = await firestoreRef
      .doc(`users/${userId}`)
      .collection('userPayments')
      .where('courseId', '==', courseId)
      .where('cohortId', '==', cohortId)
      .get();

    if (snapshots.docs.length) {
      const data = snapshots.docs[0].data();
      return data?.status === PaymentStatus.VERIFIED;
    }

    return false;
  } catch (err) {
    console.log(err.message);
  }
};

export const requestUserPayment = async (userId, courseId, cohortId, data) => {
  // await getCollectionDoc("users", id);

  try {
    let ref = firestoreRef
      .doc(`users/${userId}`)
      .collection('userPayments')
      .where('courseId', '==', courseId);
    if (cohortId) {
      ref = ref.where('cohortId', '==', cohortId);
    }

    const userPaymentSnapshots = await ref.get();

    if (userPaymentSnapshots.docs.length) return userPaymentSnapshots.docs[0].data();

    const id = generateDocKey('userPayments');

    const insertData = {
      id,
      userId,
      courseId: courseId,
      cohortId: cohortId || '',
      status: PaymentStatus.PENDING,
      price: data.price,
      discount: data.discount,
      translate: {
        en: {title: data.translate.en.title},
        km: {title: data.translate.km.title},
      },
      payWayType: data.payWayType || PayWayType.MANUAL,
      created: firebaseApp.firestore.FieldValue.serverTimestamp(),
    };
    await firestoreRef
      .doc(`users/${userId}`)
      .collection('userPayments')
      .doc(id)
      .set({
        ...insertData,
      });

    return id;
  } catch (err) {
    console.log(err);
    console.log(err.message);
  }
};
export const getUserAsync = async (id) => await getCollectionDoc('users', id);
const getUserRealtimeAsync = async (id) => await getCollectionDocRealTime('users', id);

export const updateUsersAsync = async (user) => await updateCollectionDoc('users', user);

const setUserAsync = async (user) => {
  return await firestoreRef.doc(`users/${user.id}`).set({...user}, {merge: true});
};

const getUsersAsync = () => {
  let ref = firestoreRef.collection('users');
  return subscribeEventChannelCollection(ref);
};

export const getUserCoursesAsync = async (userId) =>
  await getCollectionSnapshots(`users/${userId}/userCourses`);

export const getUserModulesAsync = async (userId, courseId) => {
  let ref = firestoreRef.collectionGroup('userModules').where('userId', '==', userId);
  if (courseId) ref = ref.where('courseId', '==', courseId);
  return subscribeEventChannelCollection(ref);
};

export const getUserActivitiesAsync = async (userId, courseId) => {
  let ref = firestoreRef.collectionGroup('userActivities').where('userId', '==', userId);
  if (courseId) ref = ref.where('courseId', '==', courseId);
  return subscribeEventChannelCollection(ref);
};

export const addUserCourseAsync = async (
  {course, userId, option, activity},
  isUpdate = false,
  batch = null
) => {
  let path = `users/${userId}/userCourses`;
  let snapshots = await firestoreRef.collection(path).where('courseId', '==', course.id).get();

  option = _.cloneDeep(option);

  let isComplete = false;
  if (option) if (option.isComplete) isComplete = true;

  if (snapshots.size) {
    let doc = snapshots.docs[0].data();
    let id = doc.id;
    if (doc.isComplete) isComplete = true;

    if (!isUpdate) return id;

    let activityComplete = doc.activityComplete;
    if (option.activityCourseComplete) {
      if (doc.activityComplete + 1 < doc.activityCount) {
        activityComplete++;
      } else if (doc.activityComplete + 1 === doc.activityCount) {
        activityComplete++;
        isComplete = true;
      }

      if (activity)
        if (activity.type === ACTIVITY_QUIZ)
          if (doc.activityQuizComplete + 1 <= doc.activityQuizCount) {
            option.activityQuizComplete = doc.activityQuizComplete + 1;
          }
    }

    if (doc.isComplete && !doc.completedDate) {
      isComplete = true;
      option.completedDate = FIRSTORE_TIMESTAMP;
    }

    if (!isComplete) {
      option.updated = FIRSTORE_TIMESTAMP;
    }

    delete option.activityCourseComplete;
    if (batch)
      batch.update(firestoreRef.doc(`${path}/${id}`), {...option, isComplete, activityComplete});
    return id;
  }
  return await setCollectionDoc(`users/${userId}/userCourses`, {
    categoryId: '',
    courseId: course.id,
    isComplete: false,
    rank: course.rank,
    course: course.status,
    translate: {en: {title: course.translate.en.title}, km: {title: course.translate.km.title}},
    activityCount: course.activityCount,
    activityComplete: 0,
    moduleCount: course.moduleCount,
    activityQuizCount: course.activityQuizCount || 0,
    activityQuizComplete: 0,
    userId,
    created: FIRSTORE_TIMESTAMP,
    updated: FIRSTORE_TIMESTAMP,
  });
};

export const getUserCourseAsync = async (userId, courseId) => {
  let ref = firestoreRef
    .collectionGroup('userCourses')
    .where('userId', '==', userId)
    .where('courseId', '==', courseId);
  return subscribeEventChannelCollection(ref);
};

export const getUserPaymentAsync = async (userId, courseId, cohortId = null) => {
  let ref = firestoreRef
    .collectionGroup('userPayments')
    .where('userId', '==', userId)
    .where('courseId', '==', courseId);

  if (cohortId) ref = ref.where('cohortId', '==', cohortId);

  return subscribeEventChannelCollection(ref);
};

export const addUserModuleAsync = async (
  {userId, module, userCourseId, option},
  isUpdate = false,
  batch = null
) => {
  let path = `users/${userId}/userCourses/${userCourseId}/userModules`;
  let snapshots = await firestoreRef
    .collection(path)
    .where('courseId', '==', module.courseId)
    .where('moduleId', '==', module.id)
    .get();

  let isComplete = false;
  if (option) if (option.isComplete) isComplete = true;

  if (snapshots.size) {
    let doc = snapshots.docs[0].data();
    let id = doc.id;

    if (!isUpdate) return id;

    let activityComplete = doc.activityComplete;

    if (option.activityModuleComplete) {
      if (doc.activityComplete + 1 < doc.activityCount) {
        activityComplete++;
      } else if (doc.activityComplete + 1 === doc.activityCount) {
        activityComplete++;
        isComplete = true;
      }
    }

    if (doc.isComplete) isComplete = true;

    delete option.activityModuleComplete;

    if (batch)
      batch.update(firestoreRef.doc(`${path}/${id}`), {
        ...option,
        isComplete,
        activityComplete,
        updated: FIRSTORE_TIMESTAMP,
      });
    return id;
  }

  return await setCollectionDoc(`users/${userId}/userCourses/${userCourseId}/userModules`, {
    courseId: module.courseId,
    isComplete: false,
    moduleId: module.id,
    rank: module.rank,
    translate: {en: {title: module.translate.en.title}, km: {title: module.translate.km.title}},
    activityCount: module.activityCount,
    activityComplete: 0,
    userId,
    created: FIRSTORE_TIMESTAMP,
    updated: FIRSTORE_TIMESTAMP,
  });
};

export const addUserActivityAsync = async (
  {userCourseId, userModuleId, activity, userId, option},
  batch
) => {
  let path = `users/${userId}/userCourses/${userCourseId}/userModules/${userModuleId}/userActivities`;
  let snapshots = await firestoreRef
    .collection(path)
    .where('courseId', '==', activity.courseId)
    .where('moduleId', '==', activity.moduleId)
    .where('activityId', '==', activity.id)
    .get();

  let isComplete = false;

  if (option) if (option.isComplete) isComplete = true;

  if (snapshots.size) {
    let doc = snapshots.docs[0].data();
    let id = doc.id;

    batch.update(firestoreRef.doc(`${path}/${id}`), {...option, updated: FIRSTORE_TIMESTAMP});
    return id;
  }

  let id = generateDocKey(path);
  let data = {
    id,
    activityId: activity.id,
    courseId: activity.courseId,
    isComplete: isComplete,
    moduleId: activity.moduleId,
    rank: activity.rank,
    translate: {en: {title: activity.translate.en.title}, km: {title: activity.translate.km.title}},
    userId,
    created: FIRSTORE_TIMESTAMP,
    updated: FIRSTORE_TIMESTAMP,
  };

  batch.set(firestoreRef.doc(`${path}/${id}`), data);
  return id;
};

const addUserActivityQuizAsync = async ({
  userId,
  userCourseId,
  userModuleId,
  userActivityId,
  option,
}) => {
  let path = `/users/${userId}/userCourses/${userCourseId}/userModules/${userModuleId}/userActivities/${userActivityId}/userActivityQuizzes`;
  return await setCollectionDoc(path, {
    userId,
    userCourseId,
    userModuleId,
    userActivityId,
    created: FIRSTORE_TIMESTAMP,
    updated: FIRSTORE_TIMESTAMP,
    ...option,
  });
};

export const addUserAsync = async (user) => await setCollectionDoc('users', user);
export const logUserRegisterAsync = async (error) => await setCollectionDoc('userLog', error);

function* getUsersSaga() {
  const channel = yield call(getUsersAsync);
  try {
    while (true) {
      yield put(setUsersRequest());
      const users = yield take(channel);
      yield put(setUsers(users));
      yield put(setUsersSuccess());
    }
  } catch (error) {
    yield put(setUsersError(error.message));
  } finally {
    if (yield cancelled()) channel.close();
  }
}

function* getUserCoursesSaga() {
  try {
    const {userId} = yield select((state) => state.auth);
    yield put(setUsersRequest());
    const userCourses = yield call(getUserCoursesAsync, userId);
    yield put(setUserCourses(userCourses));
    yield put(setUsersSuccess());
  } catch (error) {
    put(setUsersError(error.message));
  }
}

function* getUserModulesSaga({payload}) {
  try {
    const {userId, courseId} = payload;
    const channel = yield call(getUserModulesAsync, userId, courseId);
    while (true) {
      yield put(setUsersRequest());
      const userModules = yield take(channel);
      yield put(setUserModules(userModules));
      yield put(setUsersSuccess());
    }
  } catch (error) {
    put(setUsersError(error.message));
  }
}

function* getUserActivitiesSaga({payload}) {
  try {
    const {userId, courseId} = payload;
    const channel = yield call(getUserActivitiesAsync, userId, courseId);
    while (true) {
      yield put(setUsersRequest());
      const userActivities = yield take(channel);
      yield put(setUserActivities(userActivities));
      yield put(setUsersSuccess());
    }
  } catch (error) {
    put(setUsersError(error.message));
  }
}

function* getUserCourseSaga({payload}) {
  try {
    const {userId, courseId} = payload;

    const channel = yield call(getUserCourseAsync, userId, courseId);
    while (true) {
      yield put(setUsersRequest());
      const userCourses = yield take(channel);
      yield put(setUserCourse(userCourses[0] || {}));
      yield put(setUsersSuccess());
    }
  } catch (error) {
    put(setUsersError(error.message));
  }
}

function* getUserPaymentSaga({payload}) {
  try {
    const {userId, courseId, cohortId} = payload;

    const channel = yield call(getUserPaymentAsync, userId, courseId, cohortId);
    while (true) {
      yield put(setUsersRequest());
      const userPayments = yield take(channel);
      yield put(setUserPayment(userPayments[0] || {}));
      yield put(setUsersSuccess());
    }
  } catch (error) {
    console.log(error.message);
    put(setUsersError(error.message));
  }
}

// function* getUserSaga({ payload }) {
//   try {
//     const { id } = payload;
//     yield put(setUsersRequest());
//     const user = yield call(getUserRealtimeAsync, id);
//     yield put(setUser(user));
//     yield put(setUsersSuccess());
//   } catch (error) {
//     yield put(setUsersError(error.message));
//   }
// }

function* getUserSaga({payload}) {
  try {
    const {id} = payload;
    const channel = yield call(getUserRealtimeAsync, id);
    while (true) {
      yield put(setUsersRequest());
      const user = yield take(channel);
      yield put(setUser(user));
      yield put(setUsersSuccess());
    }
  } catch (error) {
    yield put(setUsersError(error.message));
  }
}

function* updateUserSaga({payload}) {
  try {
    const {user: object} = payload;
    const {user} = yield select((state) => state.user);
    yield put(setUsersRequest());

    yield call(setUserAsync, object);
    yield put(setUser({...user, ...object}));
    yield put(setUsersSuccess());
    history.goBack();
  } catch (error) {
    yield put(setUsersError(error.message));
  }
}

function* addUserCourseSaga({payload}) {
  try {
    const {option} = payload;
    const {userId} = yield select((state) => state.auth);
    const {course} = yield select((state) => state.course);
    yield put(setUsersRequest());
    let batch = firestoreRef.batch();
    yield call(addUserCourseAsync, {course, userId, option}, true, batch);
    batch.commit();
    yield put(setUsersSuccess());
  } catch (error) {
    put(setUsersError(error.message));
  }
}

function* addUserModuleSaga({payload}) {
  try {
    const {data} = payload;
    yield put(setUsersRequest());
    yield call(addUserModuleAsync, data);
    yield put(setUsersSuccess());
  } catch (error) {
    put(setUsersError(error.message));
  }
}

export const getFormResultAsync = async (formId, responseId = '') => {
  let url = `https://us-central1-impacthublms.cloudfunctions.net/api/forms/${formId}/responses?page_size=1`;
  if (responseId) url = `${url}&&included_response_ids=${responseId}`;

  return await Axios({
    method: 'GET',
    url,
    ...configTypeForm,
  })
    .then((res) => {
      return res.data.data.items[0];
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getFormDataAsync = async (formId) =>
  await Axios({
    method: 'GET',
    url: `https://us-central1-impacthublms.cloudfunctions.net/api/forms/${formId}`,
    ...configTypeForm,
  }).then((res) => res.data.data);

function* getFormResultSaga({payload}) {
  try {
    const {formId, responseId} = payload;
    yield put(setUsersRequest());
    let formResult = yield call(getFormResultAsync, formId, responseId);
    yield put(setFormResult(formResult));
    yield put(setUsersSuccess());
  } catch (error) {
    put(setUsersError(error.message));
  }
}

function* getFormDataSaga({payload}) {
  try {
    const {formId} = payload;
    yield put(setUsersRequest());
    let formData = yield call(getFormDataAsync, formId);
    yield put(setFormData(formData));
    yield put(setUsersSuccess());
  } catch (error) {
    put(setUsersError(error.message));
  }
}

function* addUserActivitySaga({payload}) {
  try {
    const {option} = payload;
    const {userId} = yield select((state) => state.auth);
    const {course, module, activity} = yield select((state) => state.course);
    yield put(setUsersRequest());

    const optionActivity = _.cloneDeep(option);
    const optionCourse = _.cloneDeep(option);
    const optionModule = _.cloneDeep(option);

    let courseComplete = optionCourse.isComplete && !activity.isCompleteAlready;
    let moduleComplete = optionModule.isComplete && !activity.isCompleteAlready;

    let userCourseData = {
      course,
      userId,
      activity,
      option: {
        activityCourseComplete: courseComplete,
        lastActiveActivityId: activity.id,
        lastActiveModuleId: activity.moduleId,
        isCertificate: false,
      },
    };
    const userCourseId = yield call(addUserCourseAsync, userCourseData);

    let userModuleData = {
      userId,
      userCourseId,
      module,
      option: {
        activityModuleComplete: moduleComplete,
      },
    };

    const userModuleId = yield call(addUserModuleAsync, userModuleData);

    delete option.isSubmit;
    let batch = firestoreRef.batch();
    let userActivityId = yield call(
      addUserActivityAsync,
      {userId, userCourseId, userModuleId, activity, option},
      batch
    );

    yield call(addUserCourseAsync, userCourseData, true, batch);
    yield call(addUserModuleAsync, userModuleData, true, batch);

    batch.commit();

    if (activity.type === ACTIVITY_QUIZ && optionActivity.isSubmit)
      yield call(
        addUserActivityQuizAsync,
        {userId, userCourseId, userModuleId, userActivityId, option},
        batch
      );
    yield put(setLoading(false));
    yield put(setUsersSuccess());
  } catch (error) {
    put(setUsersError(error.message));
    console.log(error);
  }
}

function* watchGetUsers() {
  yield takeLatest(USER_GET_USERS, getUsersSaga);
}

function* watchGetUser() {
  yield takeLatest(USER_GET_USER, getUserSaga);
}

function* watchUpdateUser() {
  yield takeLatest(USER_UPDATE_USER, updateUserSaga);
}

function* watchGetUserCourses() {
  yield takeLatest(USER_GET_USER_COURSES, getUserCoursesSaga);
}

function* watchGetUserModules() {
  yield takeLatest(USER_GET_USER_MODULES, getUserModulesSaga);
}

function* watchGetUserActivities() {
  yield takeLatest(USER_GET_USER_ACTIVITIES, getUserActivitiesSaga);
}

function* watchGetUserCourse() {
  yield takeLatest(USER_GET_USER_COURSE, getUserCourseSaga);
}

function* watchGetUserPayment() {
  yield takeLatest(USER_GET_USER_PAYMENT, getUserPaymentSaga);
}

// function* watchGetUserCourseHistories() {
//   yield takeLatest(USER_GET_USER_COURSE_HISTORIES, getUserCourseHistorySaga);
// }

function* watchAddUserCourse() {
  yield takeLatest(USER_ADD_USER_COURSE, addUserCourseSaga);
}

function* watchAddUserModule() {
  yield takeLatest(USER_ADD_USER_MODULE, addUserModuleSaga);
}

function* watchAddUserActivity() {
  yield takeLatest(USER_ADD_USER_ACTIVITY, addUserActivitySaga);
}

function* watchGetFormData() {
  yield takeLatest(USER_GET_FORM_DATA, getFormDataSaga);
}

function* watchGetFormResult() {
  yield takeLatest(USER_GET_FORM_RESULT, getFormResultSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchGetUserCourses),
    fork(watchGetUserModules),
    // fork(watchGetUserCourseHistories),
    fork(watchGetUser),
    fork(watchUpdateUser),
    fork(watchAddUserCourse),
    fork(watchAddUserModule),
    fork(watchAddUserActivity),
    fork(watchGetUserActivities),
    fork(watchGetUserCourse),
    fork(watchGetUserPayment),
    fork(watchGetFormData),
    fork(watchGetFormResult),
  ]);
}
