// Libs
import React from 'react';
import { Switch, Route ,Redirect} from 'react-router-dom';

// Components
import Logout from '../views/user-management/sign-out';
import NotFound from '../components/not-found';
import Home from '../views/home';
import {
  SING_OUT,
  COURSE_DETAIL,
  // MASTER_COURSE_DETAIL,
  COURSE_LEARNING,
  // MASTER_CLASS_LEARNING,
  USER_DASHBOARD,
  ABOUT_US,
  USER_PROFILE,
  ACCOUNT_SETTING,
  USER_PROFILE_EDIT,
  USER_AVATAR_EDIT,
  EDITOR,
  PRINT_CERTIFICATE,
  CONTACT_US,
  VIEW_ALL_COURSES,
  QUESTION_ANSWER,
  SING_IN,
  PURCHASE_HISTORY
} from '../constants/routes';
import CourseDetail from '../views/course-detail';
// import MasterCourseDetail from '../views/master-course-detail';
import LearnDashboard from '../views/learn-dashboard';
// import LearnMasterClassDashboard from '../views/learn-master-class-dashboard';
import UserDashboard from '../views/user-management/user-dashboard';
import AboutUs from '../views/about-us';
import Profile from '../views/user-management/profile';
import InfoEdit from '../views/user-management/profile/form';
import AvatarEdit from '../views/user-management/profile/form-avatar';
import AccountSetting from '../views/user-management/account-setting';
import Editor from '../views/editor';
import Certificate from '../views/certificate';
import ContactUs from '../views/contact-us';
import Emailverification from '../views/user-management/email-verification';
import ViewAllCourses from '../views/view-courses/index';
import QuestionAnswerPage from '../views/​q-a';
import PurchaseHistory from '../views/user-management/purchase-history';

function Private() {
  return (
    <Switch>
      <Route exact path={SING_OUT} component={Logout} />
      <Redirect exact path={SING_IN} to="/" />
      <Route exact path="/" component={Home} />
      <Route exact path={ABOUT_US} component={AboutUs} />
      <Route exact path={CONTACT_US} component={ContactUs} />
      <Route exact path={COURSE_DETAIL} component={CourseDetail} />
      <Route exact path={QUESTION_ANSWER} component={QuestionAnswerPage} />
      {/* <Route exact path={MASTER_COURSE_DETAIL} component={MasterCourseDetail} /> */}
      <Route exact path={VIEW_ALL_COURSES} component={ViewAllCourses} />
      <Route exact path={COURSE_LEARNING} component={LearnDashboard} />
      {/* <Route exact path={MASTER_CLASS_LEARNING} component={LearnMasterClassDashboard} /> */}
      <Route exact path={USER_DASHBOARD} component={UserDashboard} />
      <Route exact path={USER_PROFILE} component={Profile} />
      <Route exact path={ACCOUNT_SETTING} component={AccountSetting} />
      <Route exact path={PURCHASE_HISTORY} component={PurchaseHistory} />
      <Route exact path={USER_PROFILE_EDIT} component={InfoEdit} />
      <Route exact path={USER_AVATAR_EDIT} component={AvatarEdit} />
      <Route exact path={EDITOR} component={Editor} />
      <Route exact path={PRINT_CERTIFICATE} component={Certificate} />
      <Route exact path="/auth-action" component={Emailverification} />
      <Route exact component={NotFound} />
    </Switch>
  );
}

export default Private;
