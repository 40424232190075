import React from 'react';
import {Container, Row, Col, Button} from 'reactstrap';
import {StylePartner, Border, StyleImage, StyleButton, PartnerWrapperStyle} from './styled';
import PropTypes from 'prop-types';
import IntlMessages from '../../../lib/IntlMessages';
import {getStoragePathImage} from '../../../lib';

function Partner({handleEnrollCourse, partnerImage}) {
  return (
    <PartnerWrapperStyle>
      <Container>
        <Row>
          <Col>
            <StylePartner>
              <h4>
                <IntlMessages id="our_partners" />
              </h4>
              <Border>
                <hr />
              </Border>
              <StyleImage>
                <img src={getStoragePathImage(partnerImage)} className="responsive" alt="partner" />
              </StyleImage>
              <StyleButton>
                <Button onClick={handleEnrollCourse}>
                  <span>
                    <IntlMessages id="learn_now" />
                  </span>
                </Button>
              </StyleButton>
            </StylePartner>
          </Col>
        </Row>
      </Container>
    </PartnerWrapperStyle>
  );
}

Partner.propTypes = {
  handleEnrollCourse: PropTypes.func,
  partnerImage: PropTypes.string,
};

export default Partner;
