import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import LearnHeader from './learn-header';
import LearnSection from './learn-section';
import LearnModule from './learn-module';
import { MainSectionBackground, BottomBorder, CertificateWrapperStyle } from './styled';
import NextPrevious from './next-previous';
import IntlMessages from '../../../lib/IntlMessages';

function MainSection({
  course,
  module,
  activity,
  modules,
  handleNext,
  handlePrevious,
  handleActivity,
  handleTypeForm,
  formResult,
  formData,
  handleStartCourse,
  handleRetakeQuiz,
  uniqId,
  isCertificate,
  handleCertificate,
  userId,
  handlePrintCertificate
}) {
  return (
    <MainSectionBackground>
      <Container fluid>
        <Row>
          <Col>
            <Row>
              <Col>
                <LearnHeader subTitle={course.title} title={module.title} />
              </Col>
            </Row>
            <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Col md="12" lg="8" >
                <LearnSection
                  course={course}
                  activity={activity}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  handleTypeForm={handleTypeForm}
                  formResult={formResult}
                  formData={formData}
                  handleStartCourse={handleStartCourse}
                  handleRetakeQuiz={handleRetakeQuiz}
                  uniqId={uniqId}
                  isCertificate={isCertificate}
                  handleCertificate={handleCertificate}
                  userId={userId}
                  handlePrintCertificate={handlePrintCertificate}
                />
              </Col>
              <Col md="12" lg="4" >
                <LearnModule
                  activity={activity}
                  course={course}
                  handleActivity={handleActivity}
                  module={module}
                  modules={modules}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12" lg="8" >
                <NextPrevious handleNext={handleNext} handlePrevious={handlePrevious} />
              </Col>
              <Col md="12" lg="4">
                <BottomBorder></BottomBorder>
                {
                  course.isComplete && (
                    <CertificateWrapperStyle>
                      <center><Button onClick={() => handleCertificate(course)}>
                        <IntlMessages id="view_certificate" />
                      </Button></center>
                    </CertificateWrapperStyle>
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </MainSectionBackground>
  );
}

MainSection.propTypes = {
  course: PropTypes.object,
  modules: PropTypes.array,
  module: PropTypes.object,
  activity: PropTypes.object,
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func,
  handleActivity: PropTypes.func,
  handleTypeForm: PropTypes.func,
  formResult: PropTypes.array,
  formData: PropTypes.object,
  handleStartCourse: PropTypes.func,
  handleRetakeQuiz: PropTypes.func,
  uniqId: PropTypes.string,
  isCertificate: PropTypes.bool,
  handleCertificate: PropTypes.func,
  userId: PropTypes.string,
  handlePrintCertificate: PropTypes.func
};

export default MainSection;
