export default {
  // login
  log_in: 'ចូលប្រើប្រាស់',
  email: 'អុីម៉ែល',
  password: 'លេខកូដសំងាត់',
  new_password: 'លេខកូដសំងាត់ថ្មី',
  current_password: 'លេខកូដសំងាត់បច្ចុប្បន្ន',
  update_password: 'កែប្រែលេខកូដសំងាត់ថ្មី',
  remembered: 'ចងចាំទុក',
  forgot_password: 'ភ្លេចលេខកូដសំងាត់ឬ?',
  not_have_account: 'មិនទាន់មានគណនីមែនទេ?',
  register_now: 'បង្កើតគណនីថ្មី',
  // reset email
  reset_password: 'កំណត់លេខកូដសំងាត់ថ្មីឡើងវិញតាមអុីម៉ែល',
  reset: 'កំណត់ឡើងវិញ',
  reset_complete: 'ការកំណត់ឡើងវិញទទួលបានជោគជ័យ',
  reset_complete_message: 'សូមឆែកអុីម៉ែលរបស់អ្នក​ រួចចុចតាមតំណភ្ជាប់ក្នុងអុីម៉ែលនោះដើម្បីបង្កើតលេខកូដសំងាត់សារជាថ្មី​។ ហើយចុចតាមតំណរភ្ជាប់ក្នុងអុីម៉ែលនេាះវានឹងនំាអ្នកទៅកាន់ផ្ទាំងថ្មីមួយដើម្បីបញ្ជូលលេខកូដសំងាត់សារជាថ្មី​៕',
  // sign up
  sign_up: 'បង្កើតគណនីថ្មី',
  account_info: '១ ព័ត៌មានផ្ទាល់ខ្លួន',
  additional_info: '២ ព័ត៌មានបន្ថែម',
  first_name: 'នាមខ្លួន',
  last_name: 'នាមត្រកូល',
  phone: 'លេខទូរស័ព្ទ',
  male: 'ប្រុស',
  female: 'ស្រី',
  password_confirmation: 'បញ្ជាក់លេខកូដសំងាត់',
  email_confirmation: 'បញ្ជាក់អុីម៉ែល',
  organization: 'ឈ្មោះសាលា/ស្ថាប័ន',
  type_organization: 'ប្រភេទស្ថាប័ន',
  position: 'តួនាទី',
  province: 'ខេត្ត/ក្រុង',
  gender: 'ភេទ',
  year_of_birth: 'ឆ្នាំកំណើត',
  next: 'បន្ទាប់',
  already_have_an_account: 'មានគណនីរួចហើយមែនទេ?',
  terms_of_use_and_privacy: 'ល័ក្ខខ័ណ្ឌនៃការប្រើប្រាស់ និង ឯកជនភាព',
  term_and_privacy_one: 'តាមរយៈការបង្កើតគណនីនេះ ខ្ញុំយល់ព្រមជាមួយនឹង',
  term_and_privacy_two: 'ហើយខ្ញុំយល់ព្រមទទួលយកព័ត៌មានផ្សព្វផ្សាយផ្សេងៗពី Gumtree និង ភាគីទីបីដទៃទៀត។ ',
  back: 'បកក្រោយ',
  save: 'រក្សាទុក',
  cancel: 'បេាះបង់',
  update_profile: 'កែប្រែព័ត៌មានផ្ទាល់ខ្លួន',
  update_avatar: 'កែប្រែរូបតំណាង',
  avatar: 'រូបតំណាង',
  setting: 'ការកំណត់',
  change_password: 'ប្តូរលេខកូដសំងាត់',
  session_expired: 'តំណភ្ជាប់អស់សុពលភាពហើយ',
  login_to_your_new_account: 'ចូលប្រើប្រាស់ គណនីថ្មីរបស់អ្នក',
  now_you_can_explore: 'អ្នកបានចុះឈ្មោះដោយជោគជ័យ! តោះចាប់ផ្តើមរៀនជាមួយ ImpactHub!',
  your_new_password_is_update: 'លេខកូដសំងាត់របស់អ្នកត្រូវបានកែប្រែរួចរាល់',
  log_in_to_start_learning_now: 'បញ្ជូលព័ត៌មានគណីនីអ្នក ដើម្បីចាប់ផ្តើមរៀនឥឡូវ​នេះ',
  registration_completed: 'ការចុះឈ្មោះបានបញ្ចប់ហើយ!',
  look_for_verification_email: 'តែ សូមទៅកាន់អ៊ីម៉ែលដែលអ្នកប្រើប្រាស់ដើម្បីចុះឈ្មោះ រួចចុចលើ តំណ នៅក្នុងអ៊ីម៉ែល ដើម្បីបញ្ជាក់គណនីរបស់អ្នក។ តំណ នេះ នឹងនាំអ្នកទៅកាន់ សារមួយទៀតនៅក្នុង web browser។',
  Did_not_get_the_email: 'ប្រសិនបើអ្នកពុំឃើញអ៊ីម៉ែលក្នុង Inbox សូមពិនិត្យមើលនៅក្នុង Spam Folder ។.',
  If_you_do_not_receive_the_email_contact: 'ប្រសិនបើអ្នកនៅតែពុំរកអ៊ីម៉ែលឃើញ សូមទំនាក់ទំនងមកកាន់ ',
  help_edemy_mail: 'help.edemy@gmail.com',
  back_to_home: 'ត្រឡប់ទៅទំព័រដើម',
}; 
