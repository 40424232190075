import React from 'react';
import Layout from '../../../components/layout';
import {Col, Row, Container} from 'reactstrap';
import {WrapLoginForm} from '../sign-in/styles';
import FormWrapper from '../../../components/redux-form/form';
import InputText from '../../../components/redux-form/input';
import {createSubmitAction} from '../../../lib';
import {Button, Icon} from 'antd';
import {resetPassword} from '../../../stores/root-action';
import {useDispatch, useSelector} from 'react-redux';
import WrapForm from '../../../components/wrap-form';
import IntlMessages from '../../../lib/IntlMessages';
import {translate} from '../../../lib/locale';
const currentYear = new Date().getFullYear();

const EmailForgetPassword = () => {
  const {isVerifySuccess} = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogin = (values) => {
    dispatch(resetPassword(values));
  };
  const onSubmit = (values, dispatch) => {
    const validationRule = {
      email: {
        required: true,
        type: 'email',
      },
    };
    return createSubmitAction(validationRule, handleLogin, null, false)(values, dispatch);
  };
  return (
    <Layout hideFooter={true}>
      <div style={{background: '#E6F4F4'}} className="w-100 container-login">
        <Container>
          <div className="">
            <Row>
              <Col sm="12" md="5" lg="6" className="w-100 mt-5" style={{margin: 'auto'}}>
                {!isVerifySuccess ? (
                  <WrapLoginForm>
                    <center>
                      <h2 className="text-black mb-4 mt-2 fs-26 ff-bold">
                        <IntlMessages id="reset_password" />
                      </h2>
                    </center>
                    <FormWrapper form="resetPassword" onSubmit={onSubmit}>
                      <InputText
                        name="email"
                        placeholder={translate('email')}
                        className="mb-1 input-login-custom"
                      />
                      <center>
                        <Button
                          htmlType="submit"
                          type="btn-sumit-login"
                          className="text-white bg-warning mt-3"
                        >
                          <IntlMessages id="reset" />
                        </Button>
                      </center>
                    </FormWrapper>
                  </WrapLoginForm>
                ) : (
                  <WrapForm
                    title={<Icon type="check-circle" theme="twoTone" twoToneColor="#01bdc6" />}
                  >
                    <div className="text-center">
                      <h2 className="text-center fs-26 ff-bold">
                        <IntlMessages id="reset_complete" />
                      </h2>
                      <small className="text-center">
                        <IntlMessages id="reset_complete_message" />
                      </small>
                    </div>
                  </WrapForm>
                )}
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="text-center p-5">
          <b>&copy; Edemy Co., Ltd {currentYear}</b>
        </Container>
      </div>
    </Layout>
  );
};

export default EmailForgetPassword;
