import styled from 'styled-components';

export const BackgroundGreyWrapper = styled.div`
    background-color: #F6F6F6;
`;

export const Group = styled.div`
    p {
        text-align: left;
    }

    @media (min-width: 300px) {
        position: relative;
        margin-bottom: 10px;
    }
    @media (min-width: 768px) {
        position: relative;
        margin-bottom: 10px;
    }

    @media (min-width: 1024px) {
        position: absolute;
        bottom: 10px;
    }
`;

export const Text = styled.p`
    color: #000;
    font-size: 15px;
    margin-left: 30px;
    margin-right: 20px;
    max-width: 650px;
`;

export const SubText = styled.p`
    color: #333;
    font-size: 12px;
    margin-left: 30px;
    margin-right: 10px;
`;

export const ToggleImage = styled.img`
    width: 100%;
    @media (min-width: 400px) {
        position: relative;
        display: block;
    }

    @media (min-width: 768px) {
        position: relative;
        display: block;
    }

    @media (min-width: 1024px) {
        display: none;
        position: relative;
    }
`;

export const QuoteImage = styled.img`
    width: 80px;
    padding: 10px;
`;

export const BlueBackgroundColor = styled.div`
    background-color: #00B0BB;
    width: 100%;
    height: 100%;
`;