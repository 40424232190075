export default {
  about_us: 'About Us',
  learn_how_to: 'Learn how to be a changemaker from anywhere in the world.',
  sub_learn_how_to: 'We believe high-quality training and learning should be accessible to everyone, no matter where you are in Cambodia. ',
  description_learn_how_to_one: 'We’ve been running award-winning programs for entrepreneurs and young leaders for over 5 years, and we created this platform to allow you, wherever you are, to access our training online. We want to scale the knowledge and resources we’ve built so they’re accessible for everyone, and can help more changemakers like you as you grow.',
  description_learn_how_to_two: 'All courses you’ll find here are designed by us and our diverse network of experts and entrepreneurs to build your entrepreneurial mindset and leadership and innovation skills with examples from around the world.',
  changemaker: 'Changemaker(n)',
  changemaker_description: 'A person who wants to change the way things are done in order to make the world better for people and the environment, and, by gathering knowledge and resources, makes that change happen.',
  learn_with_us: 'Learn with us to become an Impactful Changemaker and Professional in Cambodia',
  why_learn_with_us: 'Why learn with us?',
  lable_feature_one: 'High-Quality Content made for Cambodia',
  description_feature_one: ' Quality is our highest priority, and we include our diverse network of experts, leaders and entrepreneurs (both local and international) to provide the best case studies, resources and content to teach you new, 21st century skills. Plus, our content is fun!',
  lable_feature_tow: 'Learn in Your Language',
  description_feature_tow: 'We teach in Khmer language, but blend English terms in where necessary (e.g. when a direct translation is difficult) to ensure you have the best learning experience, and help you learn specific business terms. No more confusing translations!',
  lable_feature_three: 'Learn from anywhere, anytime',
  description_feature_three: 'Whether you are in the province or in Phnom Penh, on the bus or in a coffee shop, using a smartphone or a computer, you will be able to join our online courses. Choose your best rhythm to learn.',
  lable_feature_four: 'Graduate with a certificate',
  description_feature_four: 'Graduate with a verified certificate from Impact Hub Phnom Penh when you finish all the course content to share with your network',
  lable_feature_five: 'Online Community',
  description_feature_five: 'Meet other changemakers across the country in our online Facebook group. You can access more resources, ask questions, get support from our experts, build your network, and join virtual events and peer coaching circles.',
  lable_feature_six: 'Access to Support and Global network',
  description_feature_six: 'By becoming part of our learner community, you can access additional support/opportunities from Impact Hub Phnom Penh, and connect to our global network of 16,000 members from 110+ cities across the world',
  future_generation: 'ទំពាំងស្នងឬស្សី',
  we_are_the_future_of_cambodia: '"We are the FUTURE OF CAMBODIA that we want to see.”',
  guide_one: 'Browse all the courses here',
  guide_two: 'First time learning online? Check out the guide here',
  guide_three: 'Subscribe to our newsletter and never miss an update!',
  guide_four_question: 'Want your employees to grow their skills?',
  guide_four_answer: 'Allow your team members to receive the best experience out of the course with direct support from Impact Hub Phnom Penh. We are here to help. Reach out to us.',
  click_here: 'Click here'
}; 
