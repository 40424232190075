import React from 'react';
import PropTypes from 'prop-types';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {ModuleScroll} from './styled';
import ModuleList from './module-list';
function LearnModule({ course, modules, handleActivity, activity, module }){
  return (
    <ModuleScroll>
      <ModuleList
        {...course}
        activity={activity}
        handleActivity={handleActivity}
        module={module}
        modules={modules}
      />
    </ModuleScroll>
  );
}

LearnModule.propTypes = {
  course: PropTypes.object,
  modules: PropTypes.array,
  handleActivity: PropTypes.func,
  activity: PropTypes.object,
  module: PropTypes.object,
  handleCertificate: PropTypes.func
};
export default LearnModule;
