import React from 'react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { StyleSlider } from './styled';

function SliderComp({ children }) {
  // eslint-disable-next-line
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      style={{ display: currentSlide === 0 ? 'none' : ''}}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <Icon type="left" />
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      style={{ display: currentSlide === slideCount - 2 ? 'none' : ''}}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 2 ? true : false}
      type="button"
    >
      <Icon type="right" />
    </button>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 2,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
  };

  return (
    <StyleSlider {...settings}>
      {children}
    </StyleSlider>
  );
}

SliderComp.propTypes = {
  children: PropTypes.node,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
};

export default SliderComp;