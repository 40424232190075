import styled from 'styled-components';

export const ImagLogo = styled.img`
  width: 12rem;
`;

export const ImagFlag = styled.img`
  width: 24px;
`;

export const ImagFlage = styled.img`
  width: 2rem;
  margin-top: 0.6rem;
  cursor: pointer;
`;

export const Avatar = styled.img`
  width: 2rem;
  margin-top: 0.2rem;
  cursor: pointer;
`;
export const ContainerDefault = styled.div`
  width: 100%;
  background: black;
  /* background: #18a2b8; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */

  &.sticky {
    background: none;
    position: fixed;
    overflow: hidden;
    z-index: 1;
    top: 0px;
  }
  &.tr-bg-info {
    transition: 0.25s;
    /* background: #41bed0; */
    background: black;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  &.undefined {
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */
  }

  a.nav-link:hover {
    color: #fff !important;
  }
  @media (max-width: 765px) {
    &.sticky {
      background: #28a2b8;
    }
  }
`;
