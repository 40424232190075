import { createSelector } from 'reselect';
import _ from 'lodash';

const locale = state => state.locale.locale;
const provinces = state => state.province.provinces;

export const selectProvinces = createSelector(provinces, locale, (provinces, locale) => {
  const results = _.filter(provinces, province => {
    province.name = province.translate[locale].title;
    return province;
  });

  return _.sortBy(results, 'name');
});



