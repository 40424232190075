import { getCollectionSnapshotsRealTime, getCollectionDocRealTime } from '../../lib/firebase';
import { all, call, fork, put, takeLatest, take } from 'redux-saga/effects';
import { OTHER_GET_OTHER, OTHER_GET_OTHERS } from './types';
import { setOtherError, setOtherSuccess, setOther, setOtherRequest, setOthers } from './actions';

const getOthersAsync = async () => getCollectionSnapshotsRealTime('others');

const getOtherAsync = async (id) => getCollectionDocRealTime('others', id);

function* getOthersSaga() {
  try {
    const channel = yield call(getOthersAsync);
    while (true) {
      yield put(setOtherRequest());
      const others = yield take(channel);
      yield put(setOthers(others));
      yield put(setOtherSuccess());
    }
  } catch (error) {
    yield put(setOtherError(error.message));
  }
}

function* getOtherSaga({ payload }) {
  try {
    const { id } = payload;
    const channel = yield call(getOtherAsync, id);
    while (true) {
      yield put(setOtherRequest());
      const other = yield take(channel);
      yield put(setOther(other));
      yield put(setOtherSuccess());
    }
  } catch (error) {
    yield put(setOtherError(error.message));
  }
}

export function* watchGetOthers() {
  yield takeLatest(OTHER_GET_OTHERS, getOthersSaga);
}

export function* watchGetOther() {
  yield takeLatest(OTHER_GET_OTHER, getOtherSaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetOthers), fork(watchGetOther)]);
}
