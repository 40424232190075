import styled from 'styled-components';

export const Thumbnail = styled.div`
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  width: 100px !important;
  height: 60px !important;
  margin-left: 10px;
  opacity: 0.9;
  &.complete-note{
    border-bottom: 3px solid #17a2b8;
  }
  img{
    height: 30px !important;
    margin-top: 14%;
    border-radius: 50%;
  }
`;

export const Content = styled.div`
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis; 
  h6{
    margin-top: -10px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  div{
    font-size: 14px;
    margin-top: 0px;
    overflow: hidden;
    text-overflow: ellipsis; 
    line-height: 1.3;
  }
`;

