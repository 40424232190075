import { useEffect } from 'react';
import { logoutUser } from '../../../stores/root-action';
import { useDispatch } from 'react-redux';

function Logout() {
  const dipatch = useDispatch();
  useEffect(() => {
    dipatch(logoutUser());
  }, [dipatch]);
  return null;
}

export default Logout;
