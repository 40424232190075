import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { SurveyCompleteStyle, FlexItemStyle } from './styled';
import { ICON_DONE } from '../../../constants/images';
import { formatDateFirestore } from '../../../lib';
import IntlMessages from '../../../lib/IntlMessages';

function SurveyCompleted({ updated }) {
  return (
    <SurveyCompleteStyle>
      <FlexItemStyle>
        <img alt="NoImage" src={ICON_DONE} />
        <div>
          <h4>
            <IntlMessages id="survey_complete" />
          </h4>
          <p>
            <IntlMessages id="you_have_done_survey" />{' '}
            {formatDateFirestore(updated, 'DD MMMM YYYY')}
          </p>
          <Button>
            <IntlMessages id="retake_another_survey" />
          </Button>
        </div>
      </FlexItemStyle>
    </SurveyCompleteStyle>
  );
}

SurveyCompleted.propTypes = {
  updated: PropTypes.object,
};

export default SurveyCompleted;
