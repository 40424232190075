/*eslint-disable react/jsx-no-target-blank*/
/*eslint-disable react/prop-types */

import React, { Fragment } from 'react';
import Layout from '../../components/layout';
import _ from 'lodash';
import {
  ACTIVITY_VIDEO,
} from '../../constants/default-values';
import Portal from '../../components/portal';
import Loading from '../../components/loading';
import {
  MainSectionBackground,
  ModuleListStyle,
  ModuleScroll,
  ActionWrapper,
  VideoWrapper,
  ModuleItemWrapperStyle,
  ModuleButtonWrapperStyle,
  GroupTitle,
  Title,
  SmartLinkChild
} from './styled';
import { Container, Row, Col, Button } from 'reactstrap';
import ReactPlayer from 'react-player';
import IntlMessages from '../../lib/IntlMessages';
import StartCourse from '../learn-dashboard/main-section/start-course';
import Certificate from '../learn-dashboard/main-section/certificate';
import CardSpeakerProfile from '../../components/card-speaker-profile-list';
import CardVideo from '../../components/card-video-list';
import { Carousel } from 'antd';

function LearnMasterDashboard({
  loading,
  course,
  modules,
  activity,
  handleStartCourse,
  handleCertificate,
  handleActivity,
  handlePrintCertificate,
  worksheetUrl
}) {
  return (
    <Layout>
      {loading && (
        <Portal>
          <Loading />
        </Portal>
      )}
      <MainSectionBackground>
        <Container fluid>
          <Row>
            <Col>
              <Row>
                <Col>
                  <GroupTitle>
                    <Title className='underline'>
                      {course.title}
                    </Title>
                  </GroupTitle>
                </Col>
              </Row>
              <Row className='mb-3' style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Col md="12" lg="8" >
                  <ActionWrapper>
                    {/* <VideoWrapper>
                      <ReactPlayer
                        url={'https://vimeo.com/429931881/d950e67d79'}
                        width="100%"
                        height="100%"
                        className="player-wrapper"
                        controls={true}
                      />
                    </VideoWrapper> */}
                    {(() => {
                      if (course.isCertificate) return <Certificate handlePrintCertificate={handlePrintCertificate} {...course} />;
                      switch (activity.type) {
                      case ACTIVITY_VIDEO:
                        return (
                          <VideoWrapper>
                            <ReactPlayer
                              url={activity.video}
                              width="100%"
                              height="100%"
                              className="player-wrapper"
                              controls={true}
                              // key={`${activity.id}${uniqId}${getLocalStorage('lang')}`}
                            />
                          </VideoWrapper>
                        );
                      default:
                        return <StartCourse handleStartCourse={handleStartCourse} title={course.title} />;
                      }
                    })()}
                  </ActionWrapper>
                </Col>
                <Col md="12" lg="4" >
                  <ModuleScroll>
                    <div className='bg-white pb-2'>
                      <Carousel>
                        {course.speakers.map((speaker, i) => {
                          return (
                            <CardSpeakerProfile dataSource={speaker} key={i} />
                          );
                        })}
                      </Carousel>
                    </div>
                    <ModuleListStyle>
                      {modules &&
                        modules.map((itemModule, index) => {
                          return itemModule.activities.map(itemActivity => {
                            return (
                              <ModuleItemWrapperStyle key={index}>
                                <ModuleButtonWrapperStyle
                                  className={itemActivity.id === activity.id && itemActivity.moduleId === itemModule.id ? 'bg-active' : ''}
                                  onClick={() => handleActivity(itemModule, itemActivity)}
                                >
                                  <CardVideo dataSource={itemActivity} />
                                </ModuleButtonWrapperStyle>
                              </ModuleItemWrapperStyle>
                            );
                          });
                        })}
                      <Fragment>
                        {
                          course.isComplete && (
                            <ModuleItemWrapperStyle className='text-center'>
                              <Button className='btn btn-large btn-warning mt-3 mb-3' onClick={() => handleCertificate(course)}>
                                <IntlMessages id="view_certificate" />
                              </Button>
                            </ModuleItemWrapperStyle>
                          )
                        }
                      </Fragment>
                    </ModuleListStyle>
                  </ModuleScroll>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <div style={{ margin: 'auto' }}>
                    {_.get(activity, 'worksheet') && (
                      <SmartLinkChild target="_blank" href={worksheetUrl} rel="download worksheet">
                        <Button className='btn btn-info rounded-pill mr-3 mb-3 w-sm-100'>
                          <IntlMessages id="download_worksheet" />
                        </Button>
                      </SmartLinkChild>
                    )}
                    <SmartLinkChild href='https://www.facebook.com/groups/418353875573722' target='blank'>
                      <Button className='btn btn-large btn-info rounded-pill mb-3' >
                        <IntlMessages id="join_the_community" />
                      </Button>
                    </SmartLinkChild>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainSectionBackground>
    </Layout>
  );
}

export default LearnMasterDashboard;
