import React from 'react';
import {Row, Col, Label} from 'reactstrap';
import PropTypes from 'prop-types';
import Icon from '../../../assets/images/icon/symbol.svg';
import IntlMessages from '../../../lib/IntlMessages';
import {
  NextPreviousGroup, NextButtonIcon,NextButtonWrapperStyle,
  PreviousButtonIcon,PreviousButtonWrapperStyle
} from './styled';

function NextPrevious({handleNext, handlePrevious}) {
  return (
    <NextPreviousGroup>
      <Row>
        <Col>
          <PreviousButtonWrapperStyle onClick={handlePrevious}>
            <PreviousButtonIcon>
              <use xlinkHref={`${Icon}#icon-navigate_before`}></use>
            </PreviousButtonIcon>
            <Label>
              <IntlMessages id="previous" />
            </Label>
          </PreviousButtonWrapperStyle>
        </Col>
        <Col>
          <NextButtonWrapperStyle onClick={handleNext}>
            <Label>
              <IntlMessages id="next" />
            </Label>
            <NextButtonIcon>
              <use xlinkHref={`${Icon}#icon-navigate_next`}></use>
            </NextButtonIcon>
          </NextButtonWrapperStyle>
        </Col>
      </Row>
    </NextPreviousGroup>
  );
}

NextPrevious.propTypes = {
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func,
};

export default NextPrevious;
