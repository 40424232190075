/* eslint-disable react/jsx-no-target-blank*/

import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { WrapContainer, Section, WrapContainer2 } from './styles';
import useWindowSize from '../../hook/useWindowSize';
import { WrapTitleSection } from './styles';
import IntlMessages from '../../lib/IntlMessages';
import { SUBSCRIPT_NEWSLETTER } from '../../constants/default-values';
import { NavLink } from 'react-router-dom';
import { CONTACT_US } from '../../constants/routes';

function LastFeature() {
  const { width } = useWindowSize();

  return (
    <Fragment>
      <WrapContainer>
        <Section lable="four block">
          <Row>
            <div className={width > 800 ? 'container' : 'col-12'}>
              <div className={width > 800 ? 'row mt-5 mb-5' : ''}>
                <Col md="6" className="bg-info p-3">
                  <WrapTitleSection className="text-white fs-30 ff-bold">
                    <IntlMessages id="guide_one" />
                  </WrapTitleSection>
                  <a href="/" className="text-white ">
                    <IntlMessages id="click_here" />
                  </a>
                </Col>
                <Col md="6" className="bg-warning p-3">
                  <WrapTitleSection className="text-white fs-30 ff-bold ml-5">
                    <IntlMessages id="guide_two" />
                  </WrapTitleSection>
                  <a href="/" className="text-white ml-5">
                    <IntlMessages id="click_here" />
                  </a>
                </Col>
              </div>
            </div>
          </Row>
        </Section>
      </WrapContainer>
      <WrapContainer2>
        <Section lable="four block">
          <Row>
            <div className={width > 800 ? 'container' : 'col-12'}>
              <div className={width > 800 ? 'row mt-5 mb-5' : ''}>
                <Col md="6" className="bg-warning p-3">
                  <WrapTitleSection className="text-dark fs-30 ff-bold">
                    <IntlMessages id="guide_three" />
                  </WrapTitleSection>
                  <br />
                  <a href={SUBSCRIPT_NEWSLETTER} target="_blank" className="fs-18">
                    <IntlMessages id="click_here" />
                  </a>
                </Col>
                <Col md="6" className="bg-info p-3 text-dark">
                  <WrapTitleSection className="text-dark fs-30 ff-bold ml-5">
                    <IntlMessages id="guide_four_question" />
                  </WrapTitleSection>
                  <WrapTitleSection className="text-dark fs-18 ml-5">
                    <IntlMessages id="guide_four_answer" />
                  </WrapTitleSection>
                  <br />
                  <NavLink className="fs-18 ml-5" to={CONTACT_US}>
                    <IntlMessages id="click_here" />
                  </NavLink>
                </Col>
              </div>
            </div>
          </Row>
        </Section>
      </WrapContainer2>
    </Fragment>
  );
}

export default LastFeature;
