import {APP_FORM_SUBMIT, APP_SET_VIDEOS, APP_SET_IS_MODAL_VISIBLE, APP_SET_LOADING, APP_SET_UNIQID} from './types';

const INIT_STATE = {
  isSubmit: false,
  appVideos: [],
  isModalVisible: false,
  loading: false,
  uniqId: Math.random().toString(),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case APP_FORM_SUBMIT:
    return {...state, isSubmit: action.payload.isSubmit};
  case APP_SET_VIDEOS:
    return {...state, appVideos: action.payload.videos};
  case APP_SET_LOADING:
    return {...state, loading: action.payload.loading};
  case APP_SET_UNIQID:
    return {...state, uniqId: action.payload.uniqId};
  case APP_SET_IS_MODAL_VISIBLE:
    return {...state, isModalVisible: action.payload.isModalVisible};
  default:
    return {...state};
  }
};
