import styled from 'styled-components';

export const Title = styled.h2`
    color: #000;
    text-transform: upper-case;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    text-align: center;

    @media (min-width: 300px) {
        margin-top: 50px;
        margin-bottom: 20px;
    }

    @media (min-width: 768px) {
        margin-top: 50px;
        margin-bottom: 20px;
    }

    @media (min-width: 992px) {
        margin-top: 80px;
        margin-bottom: 20px;
    }   

    @media (min-width: 1024px) {
        margin-top: 80px;
        margin-bottom: 0px;
    }  
`;