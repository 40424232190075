import styled from 'styled-components';

export const SpanWrapper = styled.span`
    display: inline-block;
    margin: 5px;

    img {
        display: inline-block;
        width: 25px;
        height: auto;
    }

    label {
        margin-left: 8px;
        font-size: 15px;
    }
`;