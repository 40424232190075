import {
  SET_SLIDES,
  GET_SLIDES,
  SET_SLIDE_SUCCESS,
  SET_SLIDE_REQUEST,
  SET_SLIDE_ERROR,
} from './types';

export const setSlides = (slides) => ({
  type: SET_SLIDES,
  payload: { slides },
});

export const getSlides = () => ({
  type: GET_SLIDES,
});

export const setSlideSuccess = () => ({
  type: SET_SLIDE_SUCCESS,
});

export const setSlideRequest = () => ({
  type: SET_SLIDE_REQUEST,
});

export const setSlideError = (message) => ({
  type: SET_SLIDE_ERROR,
  payload: { message },
});
