import styled from 'styled-components';
import { LEFT_PHOTO } from '../../../../constants/images';

export const BackgroundPhoto = styled.div`
    background-image: url('${LEFT_PHOTO}');
    background-size: 100% auto;
    background-position: center;
    min-width: 100%;
    height: auto;
    img {
        visibility: hidden;
        width: 100%;
        height: auto;
    }
`;