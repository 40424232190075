import styled from 'styled-components';

export const Title = styled.h2`
    color: #000;
    text-transform: upper-case;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    text-align: center;
    margin-top: 50px;
    /* margin-bottom: 50px; */
`;
export const TitleWrapperStyle = styled.div`

    center {

        h4 {
            font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
            margin-bottom: 0px;
            margin-top: 50px;
        }

        hr {
            width: 50px;
            height: 2px;
            margin-top: 3px;
            background-color: #F97E7E;
        }
    }

    @media (min-width: 300px) {
        display: none;
    }

    @media (min-width: 768px) {
        display: none;
    }

    @media (min-width: 992px) {
        display: block;
    }   

    @media (min-width: 1024px) {
        display: block;
    }  
`;

export const TitleWrapperResponsive = styled.div`
    center {

        h4 {
            font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
            margin-bottom: 0px;
            margin-top: 20px;
        }

        hr {
            width: 50px;
            height: 2px;
            margin-top: 3px;
            background-color: #F97E7E;
        }
    }

    @media (min-width: 300px) {
        display: block;
    }

    @media (min-width: 768px) {
        display: block;
    }

    @media (min-width: 992px) {
        display: none;
    }   

    @media (min-width: 1024px) {
        display: none;
    }  
`;

export const Video = styled.div`
    /* background-color: red; */
    width: auto;
    @media (min-width: 300px) {
        height: 240px;
    }

    @media (min-width: 768px) {
        height: 280px;
    }

    @media (min-width: 992px) {
        height: 360px;
    }   

    @media (min-width: 1024px) {
        /* height: 360px; */
        height: 420px;
    }  
`;
export const ExpendableGroup = styled.small`
    color: #333;
    label {
        font-style: italic;
        color: #777
    }
`;
export const TitleNote = styled.h4`
    color: #000;
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    &.no-bt{
        margin-bottom: 0px;
    }
`;

export const ButtonContainer = styled.div`
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 0px;
    button {
        width: 160px;
        height: 40px;
        background-color: #000;
        border: 0px;
        height: 45px;
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    }
    button:hover {
        opacity: 0.8;
    }
`;

export const PartnerWrapperStyle = styled.div`
    background-color: #F5F5F5;
`;

export const StylePartner = styled.div`
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;

    h4 {
        color: #333;
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    }
`;

export const Border = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    hr {
        height: 2px;
        width: 100px;
        background-color: #FF5353;
    }
`;

export const StyleImage = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;

    @media (min-width: 300px) {
        img {
            width: 100%;
            height: 100%;
        }
    }

    @media (min-width: 992px) {
        img {
            width: 800px;
            height: auto;
            max-height: 150px;
        }
    }

`;

export const StyleButton = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
        position: relative;
        width: 280px;
        height: 45px;
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
        font-size:20px;
        background-color: #FD5353;
        color: white;
        border-width: 0px;
        border-radius: 0px;

        img {
            position: absolute;
            width: 30px;
            right: 50px;
            height: auto;
        }
    }

`;
export const FullBackground = styled.div`
    width: 100%;
    background-color: #38b0bb;
    /* padding-top: 30px; */
    padding-bottom: 30px;

    h4 {
        text-align: center;
    }
`;

export const MediaGroup = styled.div`
    margin-top: 0px;
    /* margin-bottom: 0px; */

    .media-body {
        font-size: 16px;
        color: white;
    }

    .media-item {
        display: flex;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        @media (min-width: 300px) {
            flex-direction: column;
        }

        @media (min-width: 768px) {
            flex-direction: column;
        }

        @media (min-width: 992px) {
            flex-direction: row;
        }

        @media (min-width: 1024px) {
            flex-direction: row;
        }  

        .media-image {
            height: 100%;
            width: 170px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-width: 300px) {
                margin-right: 0px;
                margin-bottom: 20px;
            }

            @media (min-width: 768px) {
                margin-right: 0px;
                margin-bottom: 20px;
            }

            @media (min-width: 992px) {
                margin-right: 20px;
                margin-bottom: 0px;
            }   

            @media (min-width: 1024px) {
                margin-right: 20px;
                margin-bottom: 0px;
            }  

            img {
                width: 100%;
                height: 100%;
                margin-top:0px;
            }
        }
        .media-text {
            height: 100%;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;

export const UnderLineText = styled.hr`
    height: 5px;
    width: 200px;
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    border-top: 0;
    margin-left: 20px;
    margin: auto;
    &.b-info{
        background-color: #00A2AD;
    }
    &.b-warning{
        background-color: #f9c108;
    }
`;