/* eslint-disable indent */
import {
  PROFILE_SET_REQUEST,
  PROFILE_SET_SUCCESS,
  PROFILE_SET_ERROR,
  PROFILE_SET_PROFILE,
  PROFILE_SET_PROFILES,
} from './types';

const INIT_STATE = {
  profiles: [],
  profile: {},
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROFILE_SET_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case PROFILE_SET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROFILE_SET_ERROR:
      return {...state, loading: false, error: action.payload.message};
    case PROFILE_SET_PROFILES:
      return {...state, profiles: action.payload.profiles};
    case PROFILE_SET_PROFILE:
      return {...state, profile: action.payload.profile};
    default:
      return {...state};
  }
};
