import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardTitle} from 'reactstrap';
import {CardWrapperStyle, ImagePlayButton, Badge} from './styled';
import {PLAY_ICON} from '../../constants/images';

function MasterCourseCard({dataSource, height}) {
  const {image, title, speaker} = dataSource;

  return (
    <CardWrapperStyle className="card-masterclass" style={{backgroundImage: `url(${image})`}}>
      <Card
        style={{
          background: 'linear-gradient(to top, #091525 -2%, #e2e2ec42 70%)',
          color: 'white',
          height: `${height ? '550px' : ''}`,
        }}
      >
        <CardBody>
          <div style={{marginTop: `${height ? '420px' : '110px'}`}}>
            <CardTitle>
              {!height && (
                <center>
                  <ImagePlayButton className="mb-2" src={PLAY_ICON} alt="play-icon" />
                </center>
              )}
            </CardTitle>
            <CardTitle style={{lineHeight: '1.5'}}>
              <span style={{fontSize: '16px'}}>{title}</span>
              <Badge />
              <span style={{fontSize: '14px', fontFamily: 'PoppinsRegular'}}>{speaker}</span>
            </CardTitle>
          </div>
        </CardBody>
      </Card>
    </CardWrapperStyle>
  );
}

MasterCourseCard.propTypes = {
  dataSource: PropTypes.object,
  height: PropTypes.string,
};

export default MasterCourseCard;
