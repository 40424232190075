import React from 'react';
import PropTypes from 'prop-types';
import {ScrollBoxModule} from './styled';
import ItemSession from '../item-session';

function Module({modules}) {
  return (
    <ScrollBoxModule>
      {modules.map((item) => (
        <ItemSession key={item.id} {...item} />
      ))}
    </ScrollBoxModule>
  );
}

Module.propTypes = {
  modules: PropTypes.array,
  handleEnrollCourse: PropTypes.func,
};
export default Module;
