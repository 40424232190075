import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import ActivityList from './activity-list';
import { useEffect } from 'react';
import { ModuleButtonWrapperStyle } from './styled';
import { MODULE_INDICATOR } from '../../../constants/images';
import { ModuleItemWrapperStyle } from './styled';
const ModuleItem = ({ title, index, activities, module, isActive, activity, data, handleActivity }) => {

  const [collapse, setCollapse] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    setIsLoad(false);
  }, [module]);

  const toggle = useCallback((active) => {
    if (!isLoad && active) {
      setCollapse(false);
      setIsLoad(true);
    } else
      setCollapse(!collapse);
  }, [collapse, isLoad]);

  return (
    <ModuleItemWrapperStyle>
      <ModuleButtonWrapperStyle onClick={() => toggle(isActive)}>
        <div>{`${index}.`}</div>
        <div>{title}</div>
        <div>
          <img className={((isActive && !isLoad) || collapse) ? 'img-expend' : 'img-collapse'} src={MODULE_INDICATOR} alt="indicator" />
        </div>
      </ModuleButtonWrapperStyle>
      <Collapse
        key={module.id}
        isOpen={(isActive && !isLoad) || collapse}
      >
        <ActivityList activities={activities} handleActivity={handleActivity} module={data} activity={activity} index={index} />
      </Collapse>
    </ModuleItemWrapperStyle>
  );
};

ModuleItem.propTypes = {
  activities: PropTypes.array,
  title: PropTypes.string,
  handleActivity: PropTypes.func,
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  activity: PropTypes.object,
  isActive: PropTypes.bool,
  index: PropTypes.number,
  module: PropTypes.object
};

export default ModuleItem;
