/* eslint-disable indent */
import { OTHER_SET_REQUEST, OTHER_SET_SUCCESS, OTHER_SET_ERROR, OTHER_SET_OTHER, OTHER_SET_OTHERS } from './types';

const INIT_STATE = {
  others: [],
  other: {},
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OTHER_SET_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case OTHER_SET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case OTHER_SET_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case OTHER_SET_OTHERS:
      return { ...state, others: action.payload.others };
    case OTHER_SET_OTHER: {
      return { ...state, other: action.payload.other };

    }
    default:
      return { ...state };
  }
};
