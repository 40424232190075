import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import * as serviceWorker from './serviceWorker';
import {GlobalStyle} from './theme/global.styles';

import {PersistGate} from 'redux-persist/integration/react';

// style
import './styles/index.scss';
import './index.css';

// Component
import App from './views';

// Store
import {store, persistor} from './stores/configure-store';
import theme from './theme/theme';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
