import React, { Component, Fragment } from 'react';
import ReactQuill from 'react-quill';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';

import PropTypes from 'prop-types';

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import { getStoragePath, uploadPhotoEditor } from '../../../lib';


const ReactQuillWrapper = styled(ReactQuill)`
  padding-bottom: 2rem;
  height: 500px;
`;

const ContentPreview = styled.div`
  width:100%;
  margin-bottom: 2rem;
  img {
    width: 100%;
  }
`;

const Textarea = styled.textarea`
  border-radius:0.2rem;
  border-color: #ccc;
  width: 100%;
  height: 515px;
`;

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.modules = {
      toolbar: {
        container: [
          // [{ font: [] }],
          // [{ size: ['small', false, 'large', 'huge'] }],
          // ['bold', 'italic', 'underline'],
          // [{ list: 'ordered' }, { list: 'bullet' }],
          // [{ align: [] }],
          // [{ color: [] }, { background: [] }],
          // ['clean'],
          // ['link', 'image'],
          // ['formula'],
          // [{ font: [] }],
          // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          // ["bold", "italic", "underline", "strike"], // toggled buttons
          // [
          //   "blockquote",
          //   // , "code-block"
          // ],
          // [{ header: 1 }, { header: 2 }, { header: 3 }], // custom button values
          // [{ list: "ordered" }, { list: "bullet" }],
          // // [{ script: "sub" }, { script: "super" }], // superscript/subscript
          // // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          // // [{ direction: "rtl" }], // text direction
          // // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          // // [{ align: [] }],
          // // ["formula"],
          // [
          //   // "link",
          //   "image",
          // ],
          // ["clean"], // remove formatting button,
          [
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            { header: 1 },
            { header: 2 },
            { list: 'ordered' },
            { list: 'bullet' },
            'image',
            { color: [] },
            { background: [] },
            'clean',
            'fullScreen',
          ],
        ],
        handlers: {
          image: this.imageHandler,
          fullScreen: () => {
            this.fullScreen();
          },
        },
      },
    };

    this.formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
      'formula',
      'align',
      'code-block',
      'direction',
      'color',
      'background',
    ];

    this.state = { visible: false, content: '', key: 1 };

    this.rteChange = this.rteChange.bind(this);
    this.imageHandler = this.imageHandler.bind(this);
    this.handleChangeRaw = this.handleChangeRaw.bind(this);
    this.handleClickShowRaw = this.handleClickShowRaw.bind(this);
  }

  handleChangeRaw(html) {
    this.setState({ content: html });
  }

  handleClickShowRaw() {
    const isEditingRaw = this.state.showRaw;
    this.setState({ showRaw: !isEditingRaw });
    this.syncViews(isEditingRaw);
  }

  rteChange = (content, delta, source, editor) => {
    let value = editor.getLength() <= 1 ? '' : editor.getHTML();
    this.setState({ content: value });
  };

  fullScreen = () => {
    this.setState({ visible: true });
  };

  imageHandler() {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const range = this.quill.getSelection(true);
      this.quill.setSelection(range.index + 1);
      const { path, type } = await uploadPhotoEditor(file);
      let link = getStoragePath(`editor/${path}.${type}`);
      this.quill.deleteText(range.index, 1);
      this.quill.insertEmbed(range.index, 'image', link);
    };
  }

  render() {
    return (
      <Fragment>
        <Row className="mt-5">
          <Col md="6" xs="12" style={{ paddingBottom: '3rem' }}>
            <ReactQuillWrapper
              ref={el => {
                this.quillRef = el;
              }}
              theme="snow"
              modules={this.modules}
              formats={this.formats}
              onChange={this.rteChange}
              value={this.state.content}
            />
          </Col>
          <Col md="6" xs="12">
            <Textarea
              className={'raw-editor'}
              onChange={(e) => this.handleChangeRaw(e.target.value)}
              value={this.state.content}
            />
          </Col>
        </Row>
        <ContentPreview className="preview">
          <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
        </ContentPreview>
      </Fragment>
    );
  }
}

RichTextEditor.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  formName: PropTypes.string,
  defaultValue: PropTypes.string,
  formChange: PropTypes.func,
  required: PropTypes.any,
  height: PropTypes.string,
  form: PropTypes.object,
};

RichTextEditor.defaultProps = {
  defaultValue: '<p></p>',
  height: '250',
};

export default RichTextEditor;
