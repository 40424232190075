import { createSelector } from 'reselect';
import _ from 'lodash';
import { VIMEO_VIDEO_TAG } from '../../constants/default-values';

// import { randomColor } from "@/helpers";
const appVideos = state => state.app.appVideos;
export const selectVideos = createSelector(appVideos, appVideos =>
  _.filter(appVideos, item => {
    return item.tags.includes(VIMEO_VIDEO_TAG);
  }),
);
