import styled, { keyframes } from 'styled-components';

export const ContainerDefault = styled.div`
    width: 100%;
`;
export const WrapPoint = styled.li``;
export const WrapUl = styled.ul``;
export const WrapSpan = styled.span``;
export const Section = styled.section`
    width: 100% !important;
    left: 0px !important;
    right: 0px !important;
`;

const example2 = keyframes`
  from {transform: scaleX(0);}
  to {transform: scaleX(1);}
`;

export const ContainerTab = styled.span`
    cursor: pointer;
    display:inline-block;
    &.active:after {
        display:block;
        content: '';
        border-bottom: solid 5px #f9c108;  
        animation-name: ${example2};
        animation-duration: .25s;
        animation-fill-mode: forwards;
    }
`;
export const TabHeader = styled.h2`
    @media (min-width: 200px) {
        font-size: 12px;
    }
    @media (min-width: 370px) {
        font-size: 14px;
    }
    @media (min-width: 768px) {
        font-size: 25px;
    }
     
`;
export const UnderLineText = styled.hr`
    height: 5px;
    width: 200px;
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    border-top: 0;
    margin-left: 20px;
    &.b-info{
        background-color: #00A2AD;
    }
    &.b-warning{
        background-color: #f9c108;
    }
    @media (max-width: 768px) {
        margin: auto;
    }
`;

export const Label = styled.h3`
    @media (max-width: 768px) {
        text-align: center;
    }
`;
export const WrapH1 = styled.h1`
    font-family: PoppinsBold;
    font-weight: bold;
    @media (max-width: 768px) {
        font-size: 25px;
    }
`;
export const WrapH3 = styled.h3`
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const WrapH5 = styled.h5`
    @media (max-width: 768px) {
        font-size: 18px;
    }
`;
export const WrapElement = styled.div`
    margin-top: 4rem;
    margin-bottom: 8rem;
    /* @media (max-width: 768px) {
        margin-top: 25px;
        margin-bottom: 25px;
    } */
  
`;