import styled from "styled-components";

export const PartnerWrapperStyle = styled.div`
  background-color: #f5f5f5;
`;

export const StylePartner = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  h4 {
    color: #333;
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
  }
`;

export const Border = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  hr {
    height: 2px;
    width: 100px;
    background-color: #ff5353;
  }
`;

export const StyleImage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (min-width: 300px) {
    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 992px) {
    img {
      width: 800px;
      height: auto;
      max-height: 150px;
    }
  }
`;

export const StyleButton = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    position: relative;
    width: 280px;
    height: 45px;
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    font-size: 20px;
    background-color: black;
    color: white;
    border-width: 0px;
    border-radius: 0px;

    img {
      position: absolute;
      width: 30px;
      right: 50px;
      height: auto;
    }
  }
`;
