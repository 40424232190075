/* eslint-disable indent */
import {
  AUTH_LOGIN_USER,
  AUTH_LOGIN_USER_SUCCESS,
  AUTH_LOGIN_USER_ERROR,
  AUTH_REGISTER_USER,
  AUTH_REGISTER_USER_SUCCESS,
  AUTH_LOGOUT_USER,
  AUTH_UPDATE_USER_ERROR,
  AUTH_UPDATE_USER_SUCCESS,
  AUTH_REGISTER_USER_ERROR,
  AUTH_VERIFY_EMAIL,
  AUTH_RESET_PASSWORD,
  AUTH_VERIFY_EMAIL_SUCCESS,
  AUTH_UPDATE_PASSWORD_SUCCESS,
  AUTH_SET_IS_ADMIN,
} from './types';
import { getLocalStorage } from '../../lib';

const INIT_STATE = {
  userId: getLocalStorage('userId'),
  isAdmin: false,
  loginType: '', // teacher , admin
  loading: false,
  isRegister: false,
  error: '',
  isVerifySuccess: false,
  isUpdatePassword: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case AUTH_LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userId: action.payload.userId,
        error: '',
      };
    case AUTH_LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
        error: action.payload.message,
      };
    case AUTH_SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload.isAdmin,
      };
    case AUTH_REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case AUTH_REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case AUTH_REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        // userId: action.payload.uid,
        isRegister: true,
        error: '',
      };
    case AUTH_UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case AUTH_UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case AUTH_LOGOUT_USER:
      return { ...state, userId: null, error: '' };
    case AUTH_VERIFY_EMAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case AUTH_RESET_PASSWORD:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case AUTH_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isVerifySuccess: true,
      };
    case AUTH_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdatePassword: true,
      };
    default:
      return { ...state };
  }
};
