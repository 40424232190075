import React, {Fragment} from 'react';
import Layout from '../layout';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {Container} from 'reactstrap';
import {Layout as LayoutAntd, Menu} from 'antd';
import {useLocation} from 'react-router-dom';

import {USER_PROFILE, ACCOUNT_SETTING} from '../../constants/routes';
import {useState} from 'react';
import {useEffect} from 'react';
import IntlMessages from '../../lib/IntlMessages';
const {Content, Sider} = LayoutAntd;

function UserSetting({children, title}) {
  const [activeMenu, setActiveMenu] = useState('');
  const {pathname} = useLocation();
  const path = pathname.split('/');
  useEffect(() => {
    setActiveMenu(path[2]);
  }, [path]);

  const menu = (
    <Fragment>
      <Menu className="sidbar mt-4" mode="inline" defaultSelectedKeys={['4']}>
        <span className="ml-4 fs-15 ff-bold">
          <IntlMessages id="setting" />
        </span>
        <hr />
        <Menu.Item key="1" className={activeMenu === 'profile' ? 'active' : ''}>
          <NavLink to={USER_PROFILE}>
            <span className="nav-text fs-15 ff-bold">
              <IntlMessages id="profile" />
            </span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2" className={activeMenu === 'setting' ? 'active' : ''}>
          <NavLink to={ACCOUNT_SETTING}>
            <span className="nav-text fs-15 ff-bold">
              <IntlMessages id="account_setting" />
            </span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </Fragment>
  );

  return (
    <Layout hideFooter={true}>
      <div style={{background: '#E6F4F4'}} className="w-100">
        <Container>
          <LayoutAntd className="constainer-user-setting">
            <Sider trigger={null} collapsible>
              {menu}
            </Sider>
            <LayoutAntd>
              <Content className="contend">
                <b className="fs-15 ff-bold">{title}</b>
                <hr />
                {children}
              </Content>
            </LayoutAntd>
          </LayoutAntd>
        </Container>
      </div>
    </Layout>
  );
}

UserSetting.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default UserSetting;
