import React from 'react';
import {Row, Col, Container} from 'reactstrap';
import PropTypes from 'prop-types';
import {Title} from './styled';

function CourseTitle({title}) {
  return (
    <Container>
      <Row>
        <Col>
          <Title>{title}</Title>
        </Col>
      </Row>
    </Container>
  );
}

CourseTitle.propTypes = {
  title: PropTypes.string,
};
export default CourseTitle;
