import {createSelector} from 'reselect';
import _ from 'lodash';

const others = (state) => state.other.others;
const locale = (state) => state.locale.locale;

export const selectOthers = createSelector(others, (others) =>
  _.filter(others, (other) => {
    other.name = other.translate.en.title;

    return other;
  })
);

export const genders = [
  {id: 'male', name_en: 'Male', name_km: 'ប្រុស'},
  {id: 'female', name_en: 'Female', name_km: 'ស្រី'},
  {id: 'other', name_en: 'Non-binary & Other', name_km: 'Non-binary ឬផ្សេងពីនេះ'},
];

export const selectGenders = createSelector(locale, (locale) => {
  return genders.map((gender) => {
    const name = _.get(gender, `name_${locale}`, '');
    return {id: gender.id, name};
  });
});

export const selectOrganizations = createSelector(others, locale, (others, locale) => {
  const results = others.filter((other) => {
    other.name = other.translate[locale].title;
    return other.type === 'ORGANIZATION';
  });
  return _.sortBy(results, 'name');
});

export const selectPositions = createSelector(others, locale, (others, locale) => {
  const results = others.filter((other) => {
    other.name = other.translate[locale].title;
    return other.type === 'POSITION';
  });
  return _.sortBy(results, 'name');
});
