/* eslint-disable indent */
import { LOCALE_SET_LOCALE } from './types';
import { setLocalStorage, getLocalStorage } from '../../lib';

let locale = getLocalStorage('lang', 'km');
let lng = 'en';
if (['en', 'km'].includes(locale)) {
  lng = locale;
}
const initialState = {
  locale: lng
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOCALE_SET_LOCALE:
      setLocalStorage('lang', action.payload.locale);
      return {
        locale: action.payload.locale
      };
    default:
      return { ...state };
  }
}
