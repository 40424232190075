import styled from 'styled-components';

export const Group = styled.div`
    background-color: #00B0BB;
    p {
        text-align: center;
        width: 100%;
    }
`;

export const Text = styled.p`
    color: #333;
`;

export const RightPhoto = styled.img`
    width: 100%;
    height: auto;
`;

export const TextWrapperStyle = styled.div`
    p {
        font-size: 15px;
        padding: 20px;
        max-width: 600px;
    }
`;

export const TextButtonStyle = styled.div`
    display: flex;

    button {
        background-color: transparent;
        border-width: 0;
        padding: 0px;
        display: flex;
        align-item: left;

        img {
            width: 60px;
            height: auto;
        }

        @media (min-width: 300px) {
            justify-content: right;
            align-items: center;
        }
        @media (min-width: 768px) {
            justify-content: right;
            align-items: center;
        }
        @media (min-width: 1024px) {
            justify-content: left;
            align-items: center;
        }
    }
    button:hover {
        background-color: transparent;
        border-width: 0;
        padding: 0px;
    }

    button:focus {
        background-color: transparent;
        border-width: 0;
        padding: 0px;
    }
    
    label {
        display: flex;
        margin-left: 10px;
        font-size: 12px;

        @media (min-width: 300px) {
            justify-content: left;
            align-items: center;
        }
        @media (min-width: 768px) {
            justify-content: left;
            align-items: center;
        }
        @media (min-width: 1024px) {
            justify-content: left;
            align-items: center;
        }
    }
    
    @media (min-width: 300px) {
        flex-flow: row wrap;
        justify-content: center;
        padding: 0;
        margin: 0 0 30px 0;

    }
    @media (min-width: 768px) {
        flex-flow: row wrap;
        justify-content: center;
        padding: 0;
        margin: 0 0 30px 0;
    }

    @media (min-width: 1024px) {
        flex-flow: row wrap;
        align-items: left;
        justify-content: flex-start;
        margin: 0 0 30px 20px;
    }

`;
export const WrapVideo = styled.div`
    padding: 0px !important;
    height: 500px;
    @media(max-width: 576px){
        height: auto;
        width: auto !important;
    }
`;