import React from 'react';
import PropTypes from 'prop-types';
import {Label} from 'reactstrap';
import {SpanWrapper} from './styed.js';

function Element({dataSource}) {
  return (
    <SpanWrapper>
      <img src={dataSource.icon} alt="icon" />
      <Label>{dataSource.title}</Label>
    </SpanWrapper>
  );
}

Element.propTypes = {
  dataSource: PropTypes.object,
};

export default Element;
