// Libs

import React from 'react';
import {Button} from 'antd';
import {Container, Row, Col} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';

// Components
import InputText from '../../../components/redux-form/input';
import FormWrapper from '../../../components/redux-form/form';
import {Checkbox} from 'antd';
import Title from '../../../components/title';

// Util
import {history} from '../../../lib/utils/history';

// Helper
import {createSubmitAction} from '../../../lib';
import {loginUser} from '../../../stores/root-action';
import {
  // COVERLOGIN,
  BOOKSHOW,
} from '../../../constants/images';
import {
  //ImageCoverLogin,
  WrapLoginForm,
} from './styles';
import Layout from '../../../components/layout';
import {NavLink} from 'react-router-dom';
import {REGISTER, FORGET_PASSWORD} from '../../../constants/routes';
import IntlMessages from '../../../lib/IntlMessages';
import {translate} from '../../../lib/locale';
import ReactPlayer from 'react-player';

const currentYear = new Date().getFullYear();

function SignIn() {
  const {error} = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogin = (values) => {
    dispatch(loginUser(values, history));
  };

  const onSubmit = (values, dispatch) => {
    const validationRule = {
      email: {
        required: true,
        type: 'email',
      },
      password: {
        required: true,
      },
    };
    return createSubmitAction(
      validationRule,
      handleLogin,
      null, // if no need custom values before submit pass null
      false // if function don't need dispatch pass false
    )(values, dispatch);
  };
  return (
    <Layout hideFooter={true}>
      {/* <ModalLoadVideo
        videoUrl={"https://vimeo.com/639505093/0229ed3501"}
        visible={visible}
        setVisible={setVisible}
      /> */}

      <div style={{background: '#E6F4F4'}} className="w-100 container-login">
        <Container>
          <div className="p-4">
            <Row>
              <Col md="6" className="">
                {/* <ImageCoverLogin src={COVERLOGIN} alt="NoImage" /> */}
                <ReactPlayer
                  url={'https://vimeo.com/639505093/0229ed3501'}
                  width="100%"
                  height="100%"
                  className="player-wrapper"
                  controls={true}
                />
              </Col>
              <Col sm="12" md="5" lg="4" className="w-100">
                <WrapLoginForm>
                  <center>
                    <h2 className="text-black mb-4 mt-2 fs-26 ff-bold">
                      <IntlMessages id="log_in" />
                    </h2>
                  </center>
                  {error && (
                    <div className="p-3 mb-3 border-2 border-danger  text-center text-muted rounded">
                      <Title className="text-danger" title="Wrong Credentials" />
                      <span className="text-danger">Invalid username or password.</span>
                    </div>
                  )}
                  {''}
                  <FormWrapper form="singIn" onSubmit={onSubmit}>
                    <InputText
                      name="email"
                      placeholder={translate('email')}
                      className="mb-1 input-login-custom"
                    />
                    <InputText
                      name="password"
                      type="Password"
                      placeholder={translate('password')}
                      className="input-login-custom"
                    />
                    <div className="mb-2">
                      <Checkbox className="fs-12">
                        <IntlMessages id="remembered" />
                      </Checkbox>
                      <NavLink
                        to={FORGET_PASSWORD}
                        className="text-info fs-12"
                        style={{float: 'right'}}
                      >
                        <IntlMessages id="forgot_password" />
                      </NavLink>
                    </div>
                    <center>
                      <Button
                        htmlType="submit"
                        type="btn-sumit-login"
                        className="text-white bg-warning mt-3"
                      >
                        <IntlMessages id="log_in" />
                      </Button>
                      <div className="mt-2">
                        <span className="fs-12">
                          <IntlMessages id="not_have_account" />{' '}
                          <NavLink to={REGISTER} className="text-info fs-12">
                            <IntlMessages id="register_now" />
                          </NavLink>
                        </span>
                      </div>
                    </center>
                  </FormWrapper>
                  <img src={BOOKSHOW} className="cover-img-login" alt="NoImage" />
                </WrapLoginForm>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="text-center mt-5">
          <b>&copy; Edemy Co.,Ltd {currentYear}</b>
        </Container>
      </div>
    </Layout>
  );
}
export default SignIn;
