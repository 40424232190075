import {
  COURSE_SET_COURSES,
  COURSE_GET_COURSE,
  COURSE_GET_COURSES,
  COURSE_SET_SUCCESS,
  COURSE_SET_REQUEST,
  COURSE_SET_ERROR,
  COURSE_SET_COURSE,
  COURSE_GET_MODULES,
  COURSE_GET_ACTIVITIES,
  COURSE_SET_MODULES,
  COURSE_SET_MODULE,
  COURSE_SET_ACTIVITIES,
  COURSE_SET_ACTIVITY,
  COURSE_GET_ACTIVITY,
  COURSE_SET_COHORT_USERS,
  COURSE_GET_COHORTS,
  COURSE_GET_COHORT_USERS,
  COURSE_SET_COHORTS,
  COURSE_ADD_COHORT_USER,
} from './types';

export const setCourses = (courses) => ({
  type: COURSE_SET_COURSES,
  payload: { courses },
});

export const setModules = (modules) => ({
  type: COURSE_SET_MODULES,
  payload: { modules },
});

export const setModule = (module) => ({
  type: COURSE_SET_MODULE,
  payload: { module },
});

export const setActivities = (activities) => ({
  type: COURSE_SET_ACTIVITIES,
  payload: { activities },
});

export const setCohorts = (cohorts) => ({
  type: COURSE_SET_COHORTS,
  payload: { cohorts },
});

export const setCohortUsers = (cohortUsers) => ({
  type: COURSE_SET_COHORT_USERS,
  payload: { cohortUsers },
});

export const setActivity = (activity) => ({
  type: COURSE_SET_ACTIVITY,
  payload: { activity },
});

export const setCourse = (course) => ({
  type: COURSE_SET_COURSE,
  payload: { course },
});

export const getCourses = (isAdmin = false) => ({
  type: COURSE_GET_COURSES,
  payload: { isAdmin }
});

export const getModules = (courseId) => ({
  type: COURSE_GET_MODULES,
  payload: { courseId },
});

export const addCohortUser = (userId, courseId, cohortId) => ({
  type: COURSE_ADD_COHORT_USER,
  payload: { userId, courseId, cohortId },
});

export const getActivities = (courseId) => ({
  type: COURSE_GET_ACTIVITIES,
  payload: { courseId },
});

export const getCohorts = (courseId) => ({
  type: COURSE_GET_COHORTS,
  payload: { courseId },
});

export const getCohortUsers = (courseId, userId) => ({
  type: COURSE_GET_COHORT_USERS,
  payload: { courseId, userId },
});

export const getActivity = (courseId, moduleId, activityId) => ({
  type: COURSE_GET_ACTIVITY,
  payload: { courseId, moduleId, activityId },
});

export const getCourse = (id) => ({
  type: COURSE_GET_COURSE,
  payload: { id },
});

export const setCourseSuccess = () => ({
  type: COURSE_SET_SUCCESS,
});

export const setCourseRequest = () => ({
  type: COURSE_SET_REQUEST,
});

export const setCourseError = (message) => ({
  type: COURSE_SET_ERROR,
  payload: { message },
});
