/* eslint-disable indent */
import {SET_SLIDE_REQUEST, SET_SLIDE_SUCCESS, SET_SLIDE_ERROR, SET_SLIDES} from './types';

const arrayFromJsonString = (str) => {
  try {
    return JSON.parse(str|| '[]');
  } catch (err) {
    return [];
  }
};

const INIT_STATE = {
  slides: arrayFromJsonString(localStorage.getItem('slides')),
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SLIDE_REQUEST:
      return {...state, loading: true, error: ''};
    case SET_SLIDE_SUCCESS:
      return {...state, loading: false};
    case SET_SLIDE_ERROR:
      return {...state, loading: false, error: action.payload.message};
    case SET_SLIDES:
      return {...state, slides: action.payload.slides};
    default:
      return {...state};
  }
};
