import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import IntlMessages from '../../../lib/IntlMessages';
import {
  QuizResultStyle, QuizFlexStyle, QuizSectionFlexStyle, StaticWidthWrapper,
  ScoreText, DateText, QuizResultHeaderStyle, RetakeQuizButton
} from './styled';
import { formatDateFirestore } from '../../../lib';
import { QUIZ_COMPLETE_PERCENTAGE } from '../../../constants/default-values';

function QuizResult({ dataSources, handleRetakeQuiz, activity }) {

  return (
    <QuizResultStyle>
      <StaticWidthWrapper>
        <QuizSectionFlexStyle className="r-100">
          <QuizResultHeaderStyle>
            <IntlMessages id="your_answer" />
          </QuizResultHeaderStyle>
          {dataSources.map((item, i) => (
            <QuizFlexStyle key={i}>
              <div>{`${i + 1}`}</div>
              <div>{item.answer}</div>
              <div className={item.isCorrect ? 'green' : 'red'} dangerouslySetInnerHTML={{ __html: item.isCorrect ? '&#10004;' : '&#10008;' }}></div>
            </QuizFlexStyle>
          ))}
        </QuizSectionFlexStyle>
        <QuizSectionFlexStyle className="r-100">
          <QuizResultHeaderStyle>
            <IntlMessages id="completed_date" />
          </QuizResultHeaderStyle>
          <DateText>{formatDateFirestore(activity.updated, 'DD MMMM YYYY HH:mm:ss')}</DateText>
        </QuizSectionFlexStyle>
        <QuizSectionFlexStyle className="r-100">
          <QuizResultHeaderStyle>
            <IntlMessages id="total_score" />
          </QuizResultHeaderStyle>
          <ScoreText>{`${activity.score}/${activity.totalQuestion}`}<span className={activity.percentage >= QUIZ_COMPLETE_PERCENTAGE ? 'pass' : 'failed'}>
            {activity.percentage >= QUIZ_COMPLETE_PERCENTAGE ? <IntlMessages id="passed" /> : <IntlMessages id="failed" />}
          </span></ScoreText>
          {!activity.isComplete && (
            <RetakeQuizButton>
              <Button onClick={handleRetakeQuiz}>
                <IntlMessages id="retake_quiz" />
              </Button>
            </RetakeQuizButton>
          )}
        </QuizSectionFlexStyle>
      </StaticWidthWrapper>
    </QuizResultStyle>
  );
}

QuizResult.propTypes = {
  dataSources: PropTypes.array,
  handleRetakeQuiz: PropTypes.func,
  activity: PropTypes.object,
};
export default QuizResult;
