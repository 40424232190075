import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { VideoWrapper } from './styled';

function Video({ video }) {
  return (
    <VideoWrapper>
      <ReactPlayer
        url={video}
        width="100%"
        height="100%"
        className="player-wrapper"
        controls={true}
      />
    </VideoWrapper>
  );
}

Video.propTypes = {
  video: PropTypes.string,
};
export default Video;
