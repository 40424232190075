import React from 'react';
//import {UncontrolledCollapse, Label} from 'reactstrap';
import PropTypes from 'prop-types';
import {ExpendableGroup,WrapperStyle} from './styled';

function ExpendableText({about}) {
  return (
    <WrapperStyle>
      <ExpendableGroup dangerouslySetInnerHTML={{ __html: about }}></ExpendableGroup>
    </WrapperStyle>
  );
}

ExpendableText.propTypes = {
  about: PropTypes.string,
};
export default ExpendableText;
