import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Row, Col, Container, Button} from 'reactstrap';
import Layout from '../../components/layout';
import {IMAGE404} from '../../constants/images';
import {Image404} from './styled';

class PageNotFound extends Component {
  render() {
    const {status} = this.props;
    const Text = () => {
      return (
        <p className="text-center font-weight-light mb-0 lead">
          {status === 404 ? (
            <Fragment>
              <p>The page you are looking for might have been</p>
              <p>removed had its name changed or is temporary unavailable.</p>
            </Fragment>
          ) : (
            'Something went wrong !'
          )}
        </p>
      );
    };

    const Description = () => {
      return (
        <p className="text-center font-weight-light lead">
          <span className="ml-1 font-weight-bold">
            <Button outline color="info">
              <Link to="/" className="text-info">
                Back to Home
              </Link>
            </Button>
          </span>
        </p>
      );
    };

    const {fullHeight} = this.props;
    return (
      <Layout hideFooter={true}>
        {fullHeight ? (
          <Container fluid>
            <Row className="h-100 p-3">
              <Col className="m-auto text-center">
                <Image404 src={IMAGE404} alt="Noimage" />
                <Text />
                <Description className="mb-5" />
                <br />
                <b>&#169; LEARN 2020</b>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row className="p-3">
              <Col lg="6" md="6" sm="12" xs="12" className="m-auto">
                <Image404 src={IMAGE404} alt="Noimage" />
                <Text />
                <Description />
                <br />
                <b>&#169; LEARN 2020</b>
              </Col>
            </Row>
          </Container>
        )}
      </Layout>
    );
  }
}

PageNotFound.propTypes = {
  name: PropTypes.string,
  status: PropTypes.number,
  fullHeight: PropTypes.bool,
};

PageNotFound.defaultProps = {
  fullHeight: true,
  status: 404,
};

export default PageNotFound;
