import homePage from '../view/home_km';
import aboutUs from '../view/about_us_km';
import footer from '../view/footer_km';
import userAuthentication from '../view/user_authentication_km';
import report from '../view/report_km';
import courseDetail from '../view/course_detail_km';
import learn from '../view/learn_km';
import contactUs from '../view/contact_us_km';
import qa from '../view/qa_kh';

export default {
  ...homePage,
  ...aboutUs,
  ...footer,
  ...userAuthentication,
  ...report,
  ...courseDetail,
  ...learn,
  ...contactUs,
  ...qa,
};
