export default {
  question1: 'I cannot register my account. It keeps showing error messages.',
  question2: 'Can I change from English to Khmer midway through the session?',
  question3: 'Can I pause the course?',
  question4: 'Can I get a certificate if I don\'t finish the course?',
  question5: 'What should I do if there is an error?',

  answer1: 'Please make sure your email account is valid.',
  answer2: 'Yes, you can. You will not lose any progress when changing from English to Khmer or vice versa.',
  answer3: 'Yes, you can. You can always resume your course any time, but we would prefer you to stick to your timeline so that you can learn it effectively :D',
  answer4: 'Sadly, no :( You are required to finish each session and activities from the course to earn your certificate.',
  answer5: 'You can report the bug or error in the Contact Us section below, or you can email us at: chhim.tithamatak@impacthub.net or 010 908988.',
  frequently_asked_questions: 'Frequently Asked Questions',
  hi: 'Hi',
  submit_your_questions: 'SUBMIT YOUR QUESTIONS',
  how_can_we_help_you: 'how can we help you?',
  video1: 'How to register an account?',
  video2: 'How to start a course?',
  video3: 'How to get access to Online Community?',
  video4: 'How to change from English to Khmer?',
  video5: 'Where to find and download my certificate?',
  video6: 'How to Report a bug, or problem?'
};