export default {
  session_title: 'មេរៀន:',
  about: 'អំពី',
  next: 'បន្ទាប់ទៀត',
  previous: 'ថយក្រោយ',
  resources_and_activities: 'ឯកសារ​',
  speakers: 'វាគ្មិន',
  view_certificate: 'View Certificate',
  download_certificate: 'Certificate',
  survey_complete: 'ចប់សម្រង់មតិ',
  you_have_done_survey: 'អ្នកបានបំពេញសម្រង់មតិចប់នៅថ្ងៃទី ',
  retake_another_survey: 'បំពេញសម្រង់មតិម្តងទៀត',
  your_answer: 'ចម្លើយរបស់អ្នក',
  completed_date: 'កាលបរិច្ឆេទ',
  total_score: 'ពិន្ទុសរុប',
  failed: 'ពុំទាន់ជាប់',
  passed: 'បានជាប់',
  retake_quiz: 'ធ្វើសំណួរឡើងវិញ'
};