// Libs
import React, {useEffect} from 'react';
import {Router} from 'react-router-dom';
import {IntlProvider} from 'react-intl';
import PropTypes from 'prop-types';
import {hot} from 'react-hot-loader/root';
import {useDispatch, useSelector} from 'react-redux';
import {addLocaleData} from 'react-intl';
import en from 'react-intl/locale-data/en';
import km from 'react-intl/locale-data/km';

// Components
import RouteComponent from '../routes';

// Helper
import {history} from '../lib/utils/history';
import AppLocale from '../lang';
import {
  localeSet,
  getCourses,
  checkAuthUser,
  getOthers,
  getProvinces,
  getProfiles,
  userPresence,
  getSlides,
} from '../stores/root-action';

function App() {
  const {locale} = useSelector((state) => state.locale);
  const {isAdmin} = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuthUser());
    addLocaleData(locale === 'en' ? en : km);
    dispatch(localeSet(locale));
  }, [dispatch, locale]);

  const currentAppLocale = AppLocale[locale];

  useEffect(() => {
    dispatch(getOthers());
    dispatch(getProvinces());
    dispatch(getProfiles());
    dispatch(userPresence());
    dispatch(getSlides());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCourses(isAdmin));
  }, [dispatch, isAdmin]);

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <Router history={history}>
        <RouteComponent />
      </Router>
    </IntlProvider>
  );
}

App.propTypes = {
  locale: PropTypes.string,
};

export default hot(App);
