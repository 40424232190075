import {createSelector} from 'reselect';
import _ from 'lodash';
import {calculatePercentage, indexToLetter} from '../../lib';
import {ACTIVITY_QUIZ} from '../../constants/default-values';

const locale = (state) => state.locale.locale;
const users = (state) => state.user.users;
const user = (state) => state.user.user;
const modules = (state) => state.course.modules;
const activities = (state) => state.course.activities;
const userCourses = (state) => state.user.userCourses;
const userCourse = (state) => state.user.userCourse;
const userPayment = (state) => state.user.userPayment;
const userModules = (state) => state.user.userModules;
const userActivities = (state) => state.user.userActivities;
const formResult = (state) => state.user.formResult;
const formData = (state) => state.user.formData;

export const selectorUsers = createSelector(users, (users) =>
  _.map(users, (user) => {
    return user;
  })
);

const translateTitle = (value, locale) => {
  if (typeof value === 'string' || !value) return '';
  return _.get(value, `translate.${locale}.title`, '');
};

export const selectorUser = createSelector(user, locale, (user, locale) => {
  if (user.id) {
    // user.name = `${user.firstName} ${user.lastName}`;
    // user.organizationTypeName = user.organizationType.translate[locale].title;
    // user.city = user.province.translate[locale].title;
    // user.positionName = user.position.translate[locale].title;
    // user.phone = user.phoneNumber;
    // user.sex = user.gender === 'male' ? (locale === 'en' ? 'Male' : 'ប្រុស') : (locale === 'en' ? 'Female' : 'ស្រី');
    // user.dataOfBirth = user.birthYear;
    // user.organizationName = user.organization;

    user.name = `${user.firstName} ${user.lastName}`;
    user.organizationTypeName = translateTitle(user.organizationType, locale);
    user.city = translateTitle(user.province, locale);
    user.positionName = translateTitle(user.position, locale);
    user.phone = user.phoneNumber;
    user.sex = `${user.gender}`;
    user.dataOfBirth = user.birthYear;
    user.organizationName = user.organization;
    return user;
  }
  return user;
});

export const selectManipulationUserProfile = createSelector(user, (user) => {
  if (user.id) {
    user.firstName = `${user.firstName}`;
    user.lastName = `${user.lastName}`;
    user.organization = `${user.organization}`;
    user.phoneNumber = `${user.phoneNumber}`;
    user.organizationType = user.organizationType.id;
    user.position = user.position.id;
    user.province = user.province.id;
    user.gender = `${user.gender}`;
    user.birthYear = `${user.birthYear}`;
    return user;
  }
  return user || {};
});

export const selectorUserCourses = createSelector(userCourses, (userCourses) =>
  _.map(userCourses, (userCourse) => {
    return userCourse;
  })
);

export const getScore = (formData) => {
  let score = 0;

  formData.logic.forEach((item) => {
    item.actions.forEach((item2) => {
      if (_.get(item2, 'action') === 'jump') {
        if (_.get(item2, 'condition.op') === 'equal') {
          if (_.get(item2, 'condition.vars[1].value')) {
            score = _.get(item2, 'condition.vars[1].value');
            return score;
          }
        }
      }
    });
  });

  return score;
};

export const selectorQuizResults = createSelector(formResult, formData, (formResult, formData) => {
  if (!formData) return [];
  if (!formData.logic) return [];

  if (!formResult) return [];
  if (!formResult.answers) return [];

  const score = getScore(formData);

  const results = formData.fields
    // .filter(item => ['yes_no', 'multiple_choice', 'file_upload', 'long_text'].includes(item.type))
    .map((field) => {
      let isCorrect = false;
      let indexAnswer = -1;
      if (field.properties) {
        if (field.type === 'multiple_choice') {
          let tmp = formResult.answers.find((answer) => field.id === answer.field.id);
          tmp = tmp || {};
          let objAnswer = {};

          if (_.get(field, 'properties.choices')) {
            objAnswer = field.properties.choices.find((item, index) => {
              if (tmp.choice) {
                indexAnswer = index;
                return item.id === tmp.choice.id;
              }

              return false;
            });
          }

          objAnswer = objAnswer || {};
          formData.logic.forEach((form) => {
            if (field.ref === form.ref) {
              form.actions.forEach((action) => {
                if (action.action === 'add') {
                  if (_.get(action, 'details.value.value')) {
                    if (_.get(action, 'condition.op') === 'is') {
                      if (objAnswer.ref === _.get(action, 'condition.vars[1].value')) {
                        isCorrect = true;
                      }
                      // isCorrect = objAnswer.ref === _.get(action, 'condition.vars[1].value');
                    } else if (_.get(action, 'condition.op') === 'always') {
                      isCorrect = true;
                    }
                  }
                }
              });
            }
          });
        } else if (field.type === 'yes_no') {
          let foundAnswer = formResult.answers.find((item) => item.field.ref === field.ref);
          let foundLogic = {};
          if (foundAnswer) {
            foundLogic = formData.logic.find((item) => item.ref === foundAnswer.field.ref);
            indexAnswer = foundAnswer.boolean;
            foundLogic.actions.forEach((action) => {
              if (action.action === 'add') {
                if (_.get(action, 'details.value.value')) {
                  if (_.get(action, 'condition.op') === 'always') {
                    isCorrect = true;
                  } else if (_.get(action, 'condition.op') === 'is')
                    if (foundAnswer.boolean === _.get(action, 'condition.vars[1].value')) {
                      isCorrect = true;
                    }
                }
              }
            });
          }
        } else {
          isCorrect = true;
        }
      }
      return {
        id: field.id,
        title: field.title,
        answer: indexToLetter(indexAnswer),
        isCorrect,
      };
    });

  return results.filter((item, i) => {
    return i < score;
  });
});

export const selectorUserCourse = createSelector(userCourse, (userCourse) => userCourse);
export const selectorUserPayment = createSelector(userPayment, (userPayment) => userPayment);

export const selectorUserModules = createSelector(userModules, (userModules) =>
  _.reduce(
    userModules,
    (acc, cur) => {
      acc[cur.moduleId] = cur;
      return acc;
    },
    {}
  )
);

export const selectorUserActivities = createSelector(
  userActivities,
  locale,
  (userActivities, locale) => {
    return _.reduce(
      userActivities,
      (acc, cur) => {
        // acc[`${cur.moduleId}_${cur.activityId}`] = { ...cur, title: cur.translate[locale].title };
        acc[`${cur.moduleId}_${cur.activityId}`] = {...cur, title: cur.translate[locale].title};
        return acc;
      },
      {}
    );
  }
);

export const selectorUserCoursesReport = createSelector(
  selectorUserCourses,
  selectorUserActivities,
  modules,
  activities,
  locale,
  (userCourses, userActivities, modules, activities, locale) => {
    let moduleObject = _.reduce(
      modules,
      (acc, cur) => {
        acc[cur.id] = cur.rank;
        return acc;
      },
      {}
    );

    let activityQuizzesByCourses = _.reduce(
      activities,
      (acc, cur) => {
        if (!acc[cur.courseId]) acc[cur.courseId] = [];

        if (acc[cur.courseId] && cur.type === ACTIVITY_QUIZ) {
          // acc[cur.courseId].push({ ...userActivities[cur.id], ...cur, title: cur.translate[locale].title, mRank: moduleObject[cur.moduleId] });
          acc[cur.courseId].push({
            ...userActivities[`${cur.moduleId}_${cur.id}`],
            ...cur,
            title: cur.translate[locale].title,
            mRank: moduleObject[cur.moduleId],
          });
        }

        return acc;
      },
      {}
    );

    return _.map(userCourses, (userCourse) => {
      const {
        activityCount,
        activityComplete,
        activityQuizCount,
        activityQuizComplete,
        courseId,
        translate,
      } = userCourse;
      let quizzes = activityQuizzesByCourses[courseId] || [];
      quizzes = _.sortBy(quizzes, 'mRank');

      return {
        ...userCourse,
        activityPercentage: calculatePercentage(activityCount, activityComplete),
        activityQuizPercentage:
          activityQuizCount === activityQuizComplete
            ? 100
            : calculatePercentage(activityQuizCount, activityQuizComplete),
        quizzes,
        title: translate[locale].title,
      };
    });
  }
);
