import React from 'react';
import PropTypes from 'prop-types';
import {GroupTitle, Title, Subtitle} from './styled';
import IntlMessages from '../../../lib/IntlMessages';

function LearnHeader({title, subTitle}) {
  return (
    <GroupTitle>
      <Title><IntlMessages id="session_title" /> {title}</Title>
      <Subtitle>{subTitle}</Subtitle>
    </GroupTitle>
  );
}

LearnHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default LearnHeader;
