import styled from 'styled-components';

export const MainSectionBackground = styled.div`
  background-color: #f8f8f8;
`;

export const BottomBorder = styled.hr`
  width: 100%;
  height: 2px;
  background-color: #26B2BC;
`;

export const CertificateWrapperStyle = styled.div`
  button {
    text-transform: uppercase;
    width: 300px;
    margin-left: auto;
    height: 45px;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    background-color: black;
    letter-spacing: 1.5px;
    border-radius: 0px;
  }
`;
export const ActionWrapper = styled.div`
  width: 100%;
  background-color: #FFF;
  height: 100%;
`;
export const VideoWrapper = styled.div`
  @media (min-width: 300px) {
    width: auto;
    height: 240px;
  }

  @media (min-width: 576px) {
    width: auto;
    height: 360px;
  }

  @media (min-width: 768px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 992px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 1024px) {
    width: auto;
    height: 460px;
  }

  @media (min-width: 1200px) {
    width: auto;
    height: 460px;
  }

  @media (min-width: 1400px) {
    width: auto;
    height: 560px;
  }

  @media (min-width: 1500px) {
    width: auto;
    height: 560px;
  }

  @media (min-width: 1600px) {
    width: auto;
    height: 700px;
  }
`;
export const ModuleScroll = styled.div` 
  position: relative;
  background: #f9f9f9;
  overflow: auto;
  border: 1px solid #e0e0e0;
  display: block;
  @media (min-width: 300px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 576px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 768px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 992px) {
    width: auto;
    height: 540px;
  }

  @media (min-width: 1024px) {
    width: auto;
    height: 460px;
  }

  @media (min-width: 1200px) {
    width: auto;
    height: 460px;
  }

  @media (min-width: 1400px) {
    width: auto;
    height: 560px;
  }

  @media (min-width: 1500px) {
    width: auto;
    height: 560px;
  }

  @media (min-width: 1600px) {
    width: auto;
    height: 700px;
  }
`;
export const ModuleListStyle = styled.div`

  &.sticky-head{
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* align-items: baseline !important; */
    padding: 12px;
  }
`;

export const ModuleItemWrapperStyle = styled.div``;

export const ModuleButtonWrapperStyle = styled.div`
  width: 100%;
  padding: 8px 12px;
  display: flex;
  cursor: pointer;
  &.bg-active{
    background: #f9c10830;
  }
  div:first-child {
    color: #333;
    padding-top:0.5px;
    img{
      height: auto;
      transform: rotate(0deg);
      transition: all .25s ease-in;
    }
  }
`;

export const GroupTitle = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

`;

export const Title = styled.span`
    color: #000000;
    font-size: 26px;
    font-weight: bold;
    &.underline {
       position: relative; 
    }
    &.underline:after {
      position: absolute;
      content: '';
      height: 4px;
      bottom: -4px;
      left: 0;
      right: 0;
      width: 60%;
      background: #f9c108;
      //animation
      -o-transition:.5s;
      -ms-transition:.5s;
      -moz-transition:.5s;
      -webkit-transition:.5s;
      transition:.5s;
    }
    &.underline:hover:after {
      width: 80%;
      background: #f9c108;
    }
    @media (max-width: 1000px) {
      font-size: 18px;
    }
`;

export const Subtitle = styled.h6`
  color: #333;
`;

export const SmartLinkChild = styled.a`
  @media (max-width: 500px) {
    button{
      width: 100%;
    }
  }
`;