import styled, {css} from "styled-components";
import {Button} from "antd";

export const Video = styled.div`
  background-color: none;
  width: auto;
  @media (min-width: 300px) {
    height: 240px;
  }

  @media (min-width: 768px) {
    height: 280px;
  }

  @media (min-width: 992px) {
    height: 360px;
  }

  @media (min-width: 1024px) {
    height: 360px;
  }
`;

export const TitleWrapperStyle = styled.div`
  center {
    h4 {
      font-family: "PoppinsSemiBold", "NotoSanSemiBold";
      margin-bottom: 0px;
      margin-top: 50px;
    }

    hr {
      width: 50px;
      height: 2px;
      margin-top: 3px;
      background-color: #f97e7e;
    }
  }

  @media (min-width: 300px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: none;
  }

  @media (min-width: 992px) {
    display: block;
  }

  @media (min-width: 1024px) {
    display: block;
  }
`;

export const TitleWrapperResponsive = styled.div`
  center {
    h4 {
      font-family: "PoppinsSemiBold", "NotoSanSemiBold";
      margin-bottom: 0px;
      margin-top: 20px;
    }

    hr {
      width: 50px;
      height: 2px;
      margin-top: 3px;
      background-color: #f97e7e;
    }
  }

  @media (min-width: 300px) {
    display: block;
  }

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 992px) {
    display: none;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 0px;

  button {
    width: 100%;
    background-color: #f6a607;
    border: 0px;
    height: 45px;
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
  }

  .btn-enroll,
  .btn-enroll:hover {
    background: #d85d5d;
  }

  button:hover {
    width: 100%;
    background-color: #f6a607;
    border: 0px;
    height: 45px;
  }
`;

export const WrapperSlider = styled.div`
  color: #000000;
  margin-bottom: 16px;

  .slick-track {
    padding: 0;
  }
  .slick-track > .slick-slide > div > div {
    border: 2px solid #b3b3b3;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;

    h4 {
      font-family: "PoppinsSemiBold", "NotoSanSemiBold";
      font-size: 20px;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
      i {
        vertical-align: baseline;
      }
    }
  }
  .slick-list {
    margin: 0 -5px;
    overflow: visible;
    clip-path: inset(-20px -4px 0px 0px);
  }
  .slick-slide > div {
    margin: 0 5px;
    position: relative;

    .active {
      border: 2px solid #53bac4 !important;
    }
    .tick-mark {
      border-radius: 50%;
      background: #53bac4;
      width: 20px;
      height: 20px;
      font-size: 10px;
      vertical-align: text-bottom;
      position: absolute;
      top: -8px;
      right: -8px;
      text-align: center;
      color: #ffffff;
    }
  }
`;

export const WrapperCourseSelected = styled.div`
  border: 2px solid #53bac4;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    font-size: 20px;
    margin-bottom: 0;
  }
  p {
    color: #000000;
    font-size: 14px;
    margin-bottom: 0;
    i {
      vertical-align: baseline;
    }
  }
`;

const Enroll = css`
  background: #d75c5d;
`;
const AlreadyEnroll = css`
  background: #c3c4c3;
`;

const LearnMore = css`
  background: #53b9c4;
`;

const Continue = css`
  background: #faa82a;
`;

const Finished = css`
  background: #c3c4c3 !important;
`;

export const CohortButton = styled(Button)`
  width: 100%;
  border-radius: 200px;
  color: #fff;
  margin-top: 10px;
  ${({status}) => {
    if (status === "ENROLL") return Enroll;
    if (status === "ALREADY_ENROLL") return AlreadyEnroll;
    if (status === "LEARN_MORE") return LearnMore;
    if (status === "CONTINUE") return Continue;
    if (status === "FINISHED") return Finished;
  }}

  &:hover {
    color: #fff;
    border: none;
    ${({status}) => {
      if (status === "ENROLL") return Enroll;
      if (status === "ALREADY_ENROLL") return AlreadyEnroll;
      if (status === "LEARN_MORE") return LearnMore;
      if (status === "CONTINUE") return Continue;
      if (status === "FINISHED") return Finished;
    }}
  }

  &[disabled] {
    color: #fff;
    ${Finished}
  }
`;

export const WrapPaidButton = styled.div`
  .amount-pay {
    font-size: 20px;
    font-weight: bold;
    color: black;
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
  }

  .discount-price {
    background: #ecaa3b;
    border-radius: 5px;
    /* padding: 0px; */
    /* line-height: 30px; */
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-top: 4px;
    font-size: 13px;
    margin-top: 8px;
    color: #000000;
  }

  .original-price {
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    color: #c4c4c4;
    font-size: 12px;
    margin-top: -5px;
    margin-bottom: 10px;
  }
`;
