import React, {useEffect} from 'react';
import {useLocation, NavLink} from 'react-router-dom';
import {getParamSearch} from '../../../lib/utils';
import {Row, Col, Container} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {verifyEmail, setNewPassword} from '../../../stores/root-action';
import Layout from '../../../components/layout';
import FormWrapper from '../../../components/redux-form/form';
import InputText from '../../../components/redux-form/input';
import {createSubmitAction, translate} from '../../../lib';
import {Icon} from 'antd';
import {Button} from 'reactstrap';
import WrapForm from '../../../components/wrap-form';
import {SING_IN} from '../../../constants/routes';
import IntlMessages from '../../../lib/IntlMessages';
import {Fragment} from 'react';
const currentYear = new Date().getFullYear();

const Emailverification = () => {
  const {error} = useSelector((state) => state.auth);
  const {isUpdatePassword} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {search} = useLocation();
  const {mode, oobCode} = getParamSearch(search);

  useEffect(() => {
    dispatch(verifyEmail(oobCode));
  }, [oobCode, dispatch]);

  const handSetNewPassword = (values) => {
    const newPassword = values.password;
    dispatch(setNewPassword(oobCode, newPassword));
  };

  const onSubmit = (values, dispatch) => {
    const validationRule = {
      password: {
        required: true,
        min: 6,
      },
      passwordConfirmation: {
        required: true,
        equalsTo: 'password',
        min: 6,
      },
    };
    return createSubmitAction(validationRule, handSetNewPassword, null, false)(values, dispatch);
  };

  const formResetPassword = (
    <WrapForm title={translate('change_password')}>
      <FormWrapper form="resetPassword" onSubmit={onSubmit}>
        <InputText
          type="Password"
          name="password"
          autocomplete="off"
          placeholder={translate('password')}
          className="input-login-custom"
        />
        <InputText
          type="Password"
          name="passwordConfirmation"
          placeholder={translate('password_confirmation')}
          className="input-login-custom"
        />
        <center>
          <Button htmlType="submit" type="btn-sumit-login" className="text-white bg-warning mt-3">
            <IntlMessages id="reset" />
          </Button>
        </center>
      </FormWrapper>
    </WrapForm>
  );

  const messageVerifyEmail = (
    <Fragment>
      <WrapForm
        title={
          error ? (
            <Icon type="exclamation-circle" className="text-warning" />
          ) : (
            <Icon type="check-circle" theme="twoTone" twoToneColor="#01bdc6" />
          )
        }
      >
        {error ? (
          <h2 className="text-center text-warning">
            <IntlMessages id="session_expired" />
          </h2>
        ) : (
          <div className="text-center">
            <h2 className="text-center">
              <IntlMessages id="reset_complete" />
            </h2>
            <small className="text-center">
              <IntlMessages id="now_you_can_explore" />
            </small>
            <br />
            <br />
            <NavLink to={SING_IN} className="text-info">
              <Button outline color="secondary">
                <IntlMessages id="login_to_your_new_account" />
              </Button>
            </NavLink>
          </div>
        )}
      </WrapForm>
    </Fragment>
  );

  const messageUpdatePassword = (
    <WrapForm>
      <div className="text-center">
        <h4 className="text-center">
          <IntlMessages id="your_new_password_is_update" />
        </h4>
        <small className="text-center">
          <IntlMessages id="log_in_to_start_learning_now" />
        </small>
        <br />
        <br />
        <NavLink to="/sign-in" className="text-info">
          <Button outline color="secondary">
            <IntlMessages id="log_in" />
          </Button>
        </NavLink>
      </div>
    </WrapForm>
  );

  return (
    <Layout hideFooter={true}>
      <div style={{background: '#E6F4F4'}} className="w-100 container-login">
        <Container>
          <div className="">
            <Row>
              <Col md="5" sm="12" className="p-5" style={{margin: 'auto'}}>
                {(() => {
                  if (isUpdatePassword) {
                    return messageUpdatePassword;
                  } else if (mode === 'resetPassword') {
                    return formResetPassword;
                  } else {
                    return messageVerifyEmail;
                  }
                })()}
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="text-center p-5">
          <b>&copy; Edemy Co., Ltd {currentYear}</b>
        </Container>
      </div>
    </Layout>
  );
};

export default Emailverification;
