import styled from 'styled-components';

export const HeaderSlider = styled.div`
  font-size: 40px;
  color: #FBB630;
  text-align: center;
  font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const CenterButton = styled.div`
    font-size: 40px;
    color: #FBB630;
    text-align: center;
    margin-top: 20px;

    Button {
        color: #FFFFFF;
        font-size:18px;
        background-color: #00BBC3;
    }
`;
export const FlexItemButton = styled.div`
    display:flex;
    flex-direction: row;
    button {
      margin-top: 50px;
      width: 260px;
      height: 40px;
      font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
      background-color: #FBB623;
      font-size: 16px;
      border-width: 0px;
      border-radius: 0px;
      box-shadow: 2px 3px 1px 1px #88888838;
      margin:auto;
    }
`;

export const CustomSlider = styled.div` 
  margin: 20px;
  a:hover {
    text-decoration: none;
    color: #000;
  }

  .slick-slide > div{
    width: 100%;
  }

  .slick-dots li button:before {
    font-size: 12px;
  } 
  .slick-dots li.slick-active button:before {
      color: #00BBC3;
  }  
  .slick-prev:before,
  .slick-next:before {
    color: black !important;
    font-size: 30px !important;
  }

  .slick-track{
    display: flex;
    .slick-slide{
        display: flex;
        height: auto;
        background-color: #F4F4F4;
        margin: 10px 10px;
    }
  }
`;

export const WrapResponsiveBlog = styled.div`
  @media (max-width: 1000px) {
    &.slide-bloc{
      display: block;
    }
    &.list-bloc{
      display: none;
    }
  }

  @media (min-width: 1000px) {
    &.slide-bloc{
      display: none;
    }
    &.list-bloc{
      display: block;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  width:100%;
`;
export const Element = styled.div`
  width: 250px; 
  margin: 10px;
`;

export const Badge = styled.div`
  width: 10px;
  height: 10px;
  background: #00BBC3;
  border-radius: 5px;
  margin: auto;
`;