import React, {Fragment, useCallback} from 'react';
import {Modal} from 'antd';
import ReactPlayer from 'react-player';
import {WrapVideo} from './styled';
import PropTypes from 'prop-types';

function ModalLoadVideo({visible, setVisible, videoUrl}) {
  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <Fragment>
      <Modal
        width="900px"
        height="700px"
        onCancel={handleCancel}
        visible={visible}
        footer={null}
        title={null}
        destroyOnClose={true}
        className="modal-custom"
      >
        <WrapVideo className="col-md-12">
          <ReactPlayer
            url={videoUrl}
            width="100%"
            height="100%"
            className="player-wrapper"
            controls={true}
          />
        </WrapVideo>
      </Modal>
    </Fragment>
  );
}

ModalLoadVideo.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  videoUrl: PropTypes.string,
};

export default ModalLoadVideo;
