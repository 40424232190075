import styled from 'styled-components';

export const WrapText = styled.b`
  color: black;
`;

export const Profile = styled.img`
  width: 10rem;
  margin-top: 2rem;
`;

export const SelectProfile = styled.img`
  width: 5rem;
  margin-top: 2rem;
  float: left;
  cursor: pointer;
`;
