import React from 'react';
import {Media} from 'reactstrap';
import PropTypes from 'prop-types';
import {MediaGroup} from './styled';

function SpeakerItem({photo, name, position}) {
  return (
    <MediaGroup>
      <Media>
        <Media left>
          <Media object src={photo} alt="Placeholder" className="rounded-circle" />
        </Media>
        <Media body>
          <Media heading>{name}</Media>
          {position}
        </Media>
      </Media>
    </MediaGroup>
  );
}

SpeakerItem.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
};
export default SpeakerItem;
