/* eslint-disable indent */
import {
  USER_SET_REQUEST,
  USER_SET_SUCCESS,
  USER_SET_ERROR,
  USER_SET_USERS,
  USER_SET_USER,
  USER_SET_USER_COURSES,
  USER_SET_USER_COURSE,
  USER_SET_USER_MODULES,
  USER_SET_USER_ACTIVITIES,
  USER_SET_FORM_DATA,
  USER_SET_FORM_RESULT,
  USER_SET_USER_PAYMENT,
} from './types';

const INIT_STATE = {
  formResult: {},
  formData: {},
  userActivities: [],
  userActivity: {},
  userCourses: [],
  userCourse: {},
  userPayment: {},
  userModules: [],
  userModule: {},
  user: {},
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_SET_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case USER_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case USER_SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case USER_SET_USERS:
      return {...state, users: action.payload.users};
    case USER_SET_USER:
      return {...state, user: action.payload.user};
    case USER_SET_USER_COURSES:
      return {...state, userCourses: action.payload.userCourses};
    case USER_SET_USER_COURSE:
      return {...state, userCourse: action.payload.userCourse};
    case USER_SET_USER_PAYMENT:
      return {...state, userPayment: action.payload};
    case USER_SET_USER_MODULES:
      return {...state, userModules: action.payload.userModules};
    case USER_SET_USER_ACTIVITIES:
      return {...state, userActivities: action.payload.userActivities};
    case USER_SET_FORM_DATA:
      return {...state, formData: action.payload.formData};
    case USER_SET_FORM_RESULT:
      return {...state, formResult: action.payload.formResult};
    default:
      return {...state};
  }
};

/**

setData({user})


 */
