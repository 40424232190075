import styled from 'styled-components';
import {BANNER_BG} from '../../../constants/images';
import { Carousel } from 'antd';

export const BackgroundCover = styled.div`
    height: 100vh;
    min-height: 40rem;
    padding-top: 4.5rem;
    padding-bottom: 0;
    /* background: url('${BANNER_BG}'); */
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
`;

export const BreakThroughImage = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    background: red;
`;

export const CoverFlexWrapperStyle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
`;

export const FlexItemSlogant = styled.div`
    display: flex;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 300px) {
        align-item: center;
        justify-content: center;
    }

    @media (min-width: 768px) {
        align-item: center;
        justify-content: center;
    }
`;

export const ResponsiveSubSlogant = styled.p`
    margin-top: 15px;
    color: #505050;
    letter-spacing: 1.5px;
    font-size: 23px;
    text-align: left;
    @media (max-width: 300px) {
        text-align: center;
        font-size: 18px;
    }
    @media (max-width: 768px) {
        text-align: center;
        font-size: 18px;
    }
`;

export const TextLink = styled.div`
    font-size: 14px;
    width: 100%;
    margin-left: 10px;
    color: #020f20;
    letter-spacing: 1.5px;
    &a .text-link{
        border-bottom: 1px solid #020f20;
    }
    @media (max-width: 768px) {
        margin-top: 18px;
        text-align: center;
    } 
`;

export const FlexItemImage = styled.div`
    max-width: 100%;
    height: auto;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (min-width: 300px) {
        align-item: center;
        justify-content: center;
    }

    @media (min-width: 768px) {
        align-item: center;
        justify-content: center;
    }
`;

export const ResponsiveImageItem = styled.img`
    height: 100%;
    display: flex;
    margin-left: 0px;
    @media (min-width: 300px) {
        width: 300px;
        align-item: center;
        justify-content: center;
    }

    @media (min-width: 768px) {
        width: 350px;
    }

    @media (min-width: 992px) {
        width: 400px;
    }

    @media (min-width: 1024px) {
        width: 500px;
    }  
`;

export const ResponsiveSlogantText = styled.p`
    font-family: Edo;
    width: auto;
    display: flex;
    font-size: 50px;
    color: #031020;
    margin-bottom: 0px;
    line-height: 1.1;
    @media (max-width: 768px) {
        display: block;
        align-item: center;
        justify-content: center;
        font-size: 30px;
        text-align: center;
    } 
`;

export const FlexItemButton = styled.div`
    display:flex;
    flex-direction: row;
    button {
        width: 150px;
        height: 40px;
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
        font-size: 16px;
        border-width: 0px;
        border-radius: 0px;
        box-shadow: 2px 3px 1px 1px #88888838;
        @media (max-width: 768px) {
            margin: auto;
        }
    }
`;
export const WrapSignUpBlog = styled.div`
    display: flex;
    width: 100%;
    @media (max-width: 768px) {
        justify-content: center;
        margin: 'auto';
        display: block;
        align-items: center
    }
`;

export const CarouselWrap = styled(Carousel)`
    @media (max-width: 768px) {
        &.desktop_slide{
            display: none;
        }
        &.mobile_slide{
            display: block;
            min-height: 100px;
        }
    }
`;