// Libs
import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Components
import Public from './public';
import Private from './private';
import NotFound from '../components/not-found';
import PrivateRoute from '../components/private-route';
import ErrorBoundary from '../components/error-boundary';

function RouteComponent() {
  return (
    <ErrorBoundary>
      <Switch>
        <PrivateRoute component={Private} authenticationComponent={Public} />
        <Route component={NotFound} />
      </Switch>
    </ErrorBoundary>
  );
}

export default RouteComponent;
