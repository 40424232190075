import styled from 'styled-components';

export const Flip = styled.div`
    margin-top:30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FrontCard = styled.div`
    position: relative;
    width: 255px;
    height: 420px;
    background-color: #FBB623;
`;

export const BackCard = styled.div`
    width: 255px;
    position: relative;
    background-color: #fbefef;
    height: 420px;
`;

export const BackCardWrapperStyle = styled.div`
    p:first-child {
        font-size: 15px;
        color: #000;
        padding: 10px 10px 0px 10px;
    }
`;

export const ImageWrapperStyle = styled.div`
    img {
        width: 255px;
        max-height: 380px;
    }
`;

export const MediaWrapperStyle = styled.div`
    width: auto;
    position: absolute;
    bottom: 8px;
    padding: 8px;
    left: 8px;
    right: 8px;
    background-color: #00A2AD;

    h6 {
        font-size: 13px;
        margin-bottom: 0px;
    }

    p {
        font-size: 11px;
        margin-top: 3px;
        color: #333;
        line-height: 1.5em;
        margin-bottom: 0px;
    }
`;