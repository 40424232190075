import styled from 'styled-components';

export const HeaderSlider = styled.div`
  font-size: 40px;
  /* color: #FBB630; */
  text-align: center;
  font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
  margin-top: 50px;
  margin-bottom: 50px;
  hr {
        height: 8px;
        width: 230px;
        background-color: #00A2AD;
        margin-top: 0;
        margin-bottom: 0;
        border: 0;
        border-top: 0;
        margin: auto;
    }
`;

export const CenterButton = styled.div`
    font-size: 40px;
    color: #FBB630;
    text-align: center;
    margin-top: 20px;

    Button {
        color: #FFFFFF;
        font-size:18px;
        background-color: #00BBC3;
    }
`;
export const FlexItemButton = styled.div`
    display:flex;
    flex-direction: row;
    button {
        margin-top: 50px;
        width: 260px;
        height: 40px;
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
        background-color: #FBB623;
        font-size: 16px;
        border-width: 0px;
        border-radius: 0px;
        box-shadow: 2px 3px 1px 1px #88888838;
        margin:auto;
    }
`;