import {useParams, useLocation, useHistory} from 'react-router-dom';
import {getParamSearch} from '../lib';

function useRouter() {
  const {courseId} = useParams();
  const location = useLocation();
  const history = useHistory();
  let {moduleId, activityId, userId} = getParamSearch(location.search);

  const pathname = location.pathname;

  return {
    courseId,
    pathname,
    history,
    moduleId,
    activityId,
    userId,
  };
}

export default useRouter;
