export default {
  session_title: 'SESSION TITLE:',
  about: 'About',
  next: 'Next',
  previous: 'Previous',
  resources_and_activities: 'Resources',
  speakers: 'Speakers',
  view_certificate: 'View Certificate',
  download_certificate: 'Certificate',
  survey_complete: 'Survey Completed',
  you_have_done_survey: 'You have done survey last on ',
  retake_another_survey: 'Retake Another Survey',
  your_answer: 'Your answers',
  completed_date: 'Completed date',
  total_score: 'Total score',
  failed: 'NOT PASSED',
  passed: 'PASSED',
  retake_quiz: 'Retake Quiz'
};