import styled from 'styled-components';

export const SubSectionStyle = styled.div`
    margin-top: 50px;
    margin-bottom: 50px;
`;

export const MediaGroup = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;

    img {
        width: 60px;
        height: auto;
        margin-right: 30px;
        margin-top:0px;
    }

    h4 {
        font-size: 16px;
        font-weight: bold;
        font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
    }

    .media-body {
        font-size: 14px;
    }

    .media {
        margin-bottom: 30px;
    }
`;

export const SpeakerTitle = styled.h4`
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
`;

export const SpeakerBackgroundStyle = styled.div`
    margin-bottom: 50px;
    background-color: #F5F5F5;
    padding: 20px;
`;

// Tab
export const ContentStyle = styled.div`
    .nav.nav-tabs {
        position: relative;
        border-bottom: 4px solid #F0F0F0;
        padding-bottom: 30px;
        padding-left: 80px;
        padding-right: 80px;
    }

    .nav-item:first-child {
        float: left;
        width: 100px;
        text-align: left;
    }
    .nav-item:nth-child(2) {
        float: left;
        width: 100px;
        text-align: left;
    }

    .nav-tabs .nav-link.active {
        color: #495057;
        border-bottom: 0px solid black;
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
    }

    .nav-tabs .nav-link:hover {
        color: #495057;
        border-bottom-width: 0px;
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
        margin-bottom: 0px;
    }

    .nav-tabs .nav-item {
        margin-bottom: 0px;
        display: block;
        padding: 0px 0px;
        text-decoration: none;
        color: #666;
        transition: color .15s;
    }

    .nav-tabs .nav-link {
        font-family:'PoppinsSemiBold', 'NotoSanSemiBold';
        font-size: 20px;
        color: #333;
        border: 0px solid transparent;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .nav-item:first-child.active ~ .slider {
		left: 95px;
	}

    .nav-item:nth-child(2).active ~ .slider {
		left: 200px;
        width: 100px;
	}

    .slider {
        position: absolute;
        top: 50px;
        left: 95px;
        width: 80px;
        height: 6px;
        background-color: #00BBC3;
        transition: left .25s;
    }

    .tab-content {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 50px;
        
        ul {
            margin-left: 20px;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }
`;

