import React, {useState, useEffect} from 'react';
import {Col} from 'reactstrap';
import {Modal, Spin} from 'antd';
import {Link} from 'react-router-dom';
import Input from '../../../components/redux-form/input';
import SelectAutoComplete from '../../../components/redux-form/select';

import FormWrapper from '../../../components/redux-form/form';
import {GRUOPCOVERREGISTER} from '../../../constants/images';
// import MessageSuccess from "./messageSuccess";
import {useDispatch, useSelector} from 'react-redux';
import {
  createSubmitAction,
  yearGeneratorToCurrentYear,
  translate,
  manipulateData,
} from '../../../lib';
import {registerUser} from '../../../stores/auth/actions';
import Layout from '../../../components/layout';
import {selectGenders, selectOrganizations, selectPositions} from '../../../stores/other/selectors';
import {selectProvinces} from '../../../stores/root-selector';
import {SING_IN} from '../../../constants/routes';
import IntlMessages from '../../../lib/IntlMessages';
const dateYears = yearGeneratorToCurrentYear(1960);

const UserRegister = () => {
  const organizations = useSelector(selectOrganizations);
  const provinces = useSelector(selectProvinces);
  const genders = useSelector(selectGenders);
  const positions = useSelector(selectPositions);
  const [activeTab, setActiveTab] = useState();
  const [dataInfo, setDataInfo] = useState();
  const dispatch = useDispatch();
  // const {isRegister} = useSelector((state) => state.auth);
  const [oneClickDisable, setOneClickDisable] = useState(false);
  const [textToLowerCase, setTextToLowerCase] = useState('');
  const handleNexPageActive = () => {
    setActiveTab(!activeTab);
  };

  const handleSuccessInfo = (values) => {
    handleNexPageActive();
    setDataInfo(values);
  };

  useEffect(() => {
    if (oneClickDisable) {
      setTimeout(() => {
        setOneClickDisable(!oneClickDisable);
      }, 3000);
    }
  }, [oneClickDisable]);

  const handleSuccessSignUp = async (values) => {
    setOneClickDisable(true);
    const {city, position, organizationType} = values;
    let dataProvince = manipulateData(provinces, city);
    let dataPosition = manipulateData(positions, position);
    let dataOrganization = manipulateData(organizations, organizationType);

    let tmp = {
      province: dataProvince,
      position: dataPosition,
      organizationType: dataOrganization,
    };
    let data = {...dataInfo, ...values, ...tmp};
    delete data.passwordConfirmation;
    data.email = data.email.trim();
    dispatch(registerUser(data));
  };
  // [dispatch, dataInfo, provinces, positions, organizations]
  // );

  const onSubmitInfo = (values, dispatch) => {
    const validationRule = {
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
      email: {
        required: true,
      },
      phoneNumber: {
        required: true,
      },
      password: {
        required: true,
        min: 6,
      },
      passwordConfirmation: {
        required: true,
        equalsTo: 'password',
        min: 6,
      },
    };

    return createSubmitAction(validationRule, handleSuccessInfo, null, false)(values, dispatch);
  };

  const onSubmitOrganization = (values, dispatch) => {
    const validationRule = {
      organization: {
        required: true,
      },
      birthYear: {
        required: true,
      },
      city: {
        required: true,
      },
      gender: {
        required: true,
      },
      organizationType: {
        required: true,
      },
      position: {
        required: true,
      },
    };
    return createSubmitAction(validationRule, handleSuccessSignUp, null, false)(values, dispatch);
  };
  return (
    <Layout hideFooter={true}>
      <Col>
        <Modal
          visible={oneClickDisable}
          footer={null}
          closeIcon={'.'}
          className="modal-customer-layout"
        >
          <center>
            <Spin />
          </center>
        </Modal>
      </Col>
      <Col xs="11" md="5" sm="11" className="bg-light container-register" style={{margin: 'auto'}}>
        <div>
          <center>
            <img className="img-register" src={GRUOPCOVERREGISTER} alt="Register" />
          </center>
        </div>
        <div style={{display: !activeTab ? 'block' : 'none'}}>
          <div className="tab">
            <div className="tab-header">
              <h2 className="text-center mt-4 mb-4 fs-26 ff-bold">
                <IntlMessages id="sign_up" />
              </h2>
              <div className="text-center header-tab">
                <b className="btn-tab-info activ-tab fs-12">
                  <IntlMessages id="account_info" />
                </b>
                <b className="btn-tab-register ml-5 fs-12">
                  <IntlMessages id="additional_info" />
                </b>
              </div>
            </div>
            <div className="tab-body mt-4 mb-0">
              <FormWrapper form="singInfo" onSubmit={onSubmitInfo}>
                <Input
                  name="firstName"
                  placeholder={translate('first_name')}
                  className="input-login-custom"
                />
                <Input
                  name="lastName"
                  placeholder={translate('last_name')}
                  className="input-login-custom"
                />

                <Input
                  name="phoneNumber"
                  placeholder={translate('phone')}
                  autocomplete="off"
                  className="input-login-custom"
                />
                <Input
                  name="email"
                  placeholder={translate('email')}
                  className="input-login-custom"
                  defaultValue={textToLowerCase}
                  onChange={(e) => setTextToLowerCase(e.toLowerCase())}
                />
                <Input
                  type="Password"
                  name="password"
                  autocomplete="off"
                  placeholder={translate('password')}
                  className="input-login-custom"
                />
                <Input
                  type="Password"
                  name="passwordConfirmation"
                  placeholder={translate('password_confirmation')}
                  className="input-login-custom"
                />
                <p className="fs-12">
                  <IntlMessages id="term_and_privacy_one" />{' '}
                  <a href="/" className="text-info">
                    <IntlMessages id="terms_of_use_and_privacy" />
                  </a>
                  <br />
                  {/* <IntlMessages id="term_and_privacy_two" /> */}
                </p>
                <div className="tab-footer text-center mt-4">
                  <button className="btn-tab" htmlType="submit">
                    <IntlMessages id="next" />
                  </button>
                  <p className="mt-4 fs-12">
                    <IntlMessages id="already_have_an_account" />
                    <Link to={SING_IN} className="text-info">
                      <IntlMessages id="log_in" />
                    </Link>
                  </p>
                </div>
              </FormWrapper>
            </div>
          </div>
        </div>
        <div style={{display: !activeTab ? 'none' : 'block'}}>
          <div className="tab" style={{display: 'block'}}>
            <div className="tab-header">
              <h2 className="text-center mt-4 mb-4 fs-26 ff-bold">
                <IntlMessages id="sign_up" />
              </h2>
              <div className="text-center header-tab">
                <b className="btn-tab-info fs-12">
                  <IntlMessages id="account_info" />
                </b>
                <b className="btn-tab-register ml-5 activ-tab fs-12">
                  <IntlMessages id="additional_info" />
                </b>
              </div>
            </div>
            <div className="tab-body mt-4">
              <FormWrapper form="singInOrganization" onSubmit={onSubmitOrganization}>
                <Input
                  name="organization"
                  placeholder={translate('organization')}
                  className="input-login-custom"
                />
                <SelectAutoComplete
                  name="organizationType"
                  placeholder={translate('type_organization')}
                  className="input-login-custom"
                  dataSource={organizations}
                />
                <SelectAutoComplete
                  dataSource={positions}
                  name="position"
                  placeholder={translate('position')}
                  className="input-login-custom"
                />
                <SelectAutoComplete
                  dataSource={provinces}
                  name="city"
                  placeholder={translate('province')}
                  className="input-login-custom"
                  // filterOption={false}
                />
                <SelectAutoComplete
                  name="gender"
                  dataSource={genders}
                  placeholder={translate('gender')}
                  className="input-login-custom"
                />
                <SelectAutoComplete
                  dataSource={dateYears}
                  name="birthYear"
                  placeholder={translate('year_of_birth')}
                  className="input-login-custom"
                />
                <p className="fs-12">
                  <IntlMessages id="term_and_privacy_one" />{' '}
                  <a href="/" className="text-info">
                    <IntlMessages id="terms_of_use_and_privacy" />
                  </a>
                  <br />
                  {/* <IntlMessages id="term_and_privacy_two" /> */}
                </p>
                <div className="tab-footer text-center mt-4">
                  <span
                    className="btn-tab-back mr-2"
                    htmlType="button"
                    onClick={handleNexPageActive}
                  >
                    <IntlMessages id="back" />
                  </span>
                  <button
                    className={oneClickDisable ? 'btn-tab-back' : 'btn-tab-save'}
                    htmlType="submit"
                    disabled={oneClickDisable}
                    // onClick={() => setOneClickDisable(true)}
                  >
                    <IntlMessages id="save" />
                  </button>
                  <p className="mt-4 fs-12">
                    <IntlMessages id="already_have_an_account" />
                    <Link to={SING_IN} className="text-info">
                      <IntlMessages id="log_in" />
                    </Link>
                  </p>
                </div>
              </FormWrapper>
            </div>
          </div>
          {/* {isRegister && <MessageSuccess />} */}
        </div>
      </Col>
      <center>
        <div style={{height: '30px', width: 'auto'}} />
      </center>
    </Layout>
  );
};

export default UserRegister;
