import styled from "styled-components";

export const WrapContent = styled.div`
  color: black;

  table th {
    font-size: 15px;
  }

  table td {
    font-size: 13px;
  }
`;
