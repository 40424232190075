import React from 'react';
import PropTypes from 'prop-types';
import {SurveyCompleteStyle, WrapText} from './styled';
import {Button} from 'reactstrap';
import IntlMessages from '../../../lib/IntlMessages';

function StartCourse({handleStartCourse, title}) {
  return (
    <SurveyCompleteStyle>
      <div className="text-center">
        <WrapText className="fs-26">
          <IntlMessages id="welcome_course" />
          {`“${title}”`}
        </WrapText>
        <br />
        <WrapText className="">
          <IntlMessages id="are_you_ready" />
        </WrapText>
        <br />
        <Button color="warning" className="mt-5" onClick={handleStartCourse}>
          <IntlMessages id="start_learning_now" />
        </Button>
      </div>
    </SurveyCompleteStyle>
  );
}

StartCourse.propTypes = {
  handleStartCourse: PropTypes.func,
  title: PropTypes.string,
};
export default StartCourse;
