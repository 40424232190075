import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as formReducer } from 'redux-form';
import authReducer from './auth/reducer';
import localeReducer from './locale/reducer';
import userReducer from './user/reducer';
import appReducer from './app/reducer';
import categoryReducer from './category/reducer';
import otherReducer from './other/reducer';
import courseReducer from './course/reducer';
import provinceReducer from './province/reducer';
import profileReducer from './profile/reducer';
import slideReducer from './slide/reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  category: categoryReducer,
  course: courseReducer,
  form: formReducer,
  locale: localeReducer,
  other: otherReducer,
  province: provinceReducer,
  profile: profileReducer,
  user: userReducer,
  slide: slideReducer
});

export default persistReducer(persistConfig, rootReducer);
