// Libs
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';
// Components

function Layout({children, hideFooter, menuSticky}) {
  
  return (
    <Fragment>
      <Header menuSticky={menuSticky} />
      {children}
      {!hideFooter && <Footer />}
    </Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  hideFooter: PropTypes.bool,
  menuSticky: PropTypes.bool,
};

export default Layout;
