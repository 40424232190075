/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, message, Spin} from 'antd';
import domToImage from 'dom-to-image';
import PropTypes from 'prop-types';
import {PayWayType} from '../../../constants/default-values';
import {Col, Row} from 'reactstrap';
import {WrapPaymentUI, WrapModal, WrapPaymentComplete} from './styled';
import {requestUserPayment} from '../../../stores/user/sagas';
import {
  CHECK_ICON,
  IMPACTHUB_PAYMENT_LOGO,
  PRINT_ICON,
  ICON_ABAPAY,
  ICON_KHQR,
} from '../../../constants/images';
import {formatDate} from '../../../lib/utils';
import Axios from 'axios';
import {firestoreRef} from '../../../lib/firebase';

function PaymentModal({visible, onCancel, user, course}) {
  const [payType, setPayType] = useState(PayWayType.ABA_PAY);
  const [visiblePaymentComplete, setVisiblePaymentComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(course?.price);
  const [hash, setHash] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [requestTime, setRequestTime] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [items, setItems] = useState('');

  const [merchantId, setMerchantId] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [paymentOption, setPaymentOption] = useState('abapay');

  // useEffect(() => {
  //   firestoreRef
  //     .collectionGroup("userPayments")
  //     .where("id", "==", "jlFQCoJjc9o1LBt7eeQP")
  //     .get()
  //     .then((res) => {
  //       console.log(1111111, res.docs.length);
  //     });
  // }, []);

  useEffect(() => {
    if (transactionId && paymentStatus !== 'VERIFIED') {
      let unsubscribe = firestoreRef
        .collectionGroup('userPayments')
        .where('id', '==', transactionId)
        .onSnapshot({
          next: (snapshot) => {
            if (snapshot.docs.length) {
              if (snapshot.docs[0].data().status === 'VERIFIED') {
                onCancel();
                setVisiblePaymentComplete(true);
              }
            }
          },
        });
      return unsubscribe;
    }
  }, [transactionId, paymentStatus, onCancel]);

  useEffect(() => {
    if (user?.id && course?.id) {
      const amount = parseFloat(course.price - course.discount).toFixed(2);
      setAmount(amount);
      setEmail(user?.email);
      setFirstName(user?.firstName);
      setLastName(user?.lastName);
      setPhoneNumber(user?.phoneNumber);
      // setItems(
      //   btoa(JSON.stringify([{name: course?.title, quantity: '1', price: amount.toString()}]))
      // );
    }
  }, [user, course]);

  const requestPaymentData = async () => {
    try {
      setLoading(true);
      const userPayment = await requestUserPayment(user.id, course.id, course.cohortId, {
        price: course.price,
        discount: course.discount,
        translate: course.translate,
        payWayType: payType,
      });

      const transactionId = userPayment.id;

      const response = await Axios.post(
        'https://us-central1-impacthublms.cloudfunctions.net/payment/request-payment',
        {
          amount,
          transactionId,
          paymentOption,
          phoneNumber,
          email,
          lastName,
          firstName,
          courseTitle: course.title,
          prod: process.env.REACT_APP_PROD,
        },
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_CLOUD_FUNCTION_API_KEY}`,
          },
        }
      );

      const data = response.data.data;

      setHash(data.hash);
      setMerchantId(data.merchantId);
      setTransactionId(data.transactionId);
      setPaymentStatus(userPayment.status);
      setRequestTime(data.requestTime);
      setApiUrl(data.apiUrl);
      setItems(data.items);

      // const timeout = setTimeout(() => {
      // eslint-disable-next-line no-undef
      AbaPayway.checkout();
      // clearTimeout(timeout);
      // }, 500);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      message.error('Opp! Something went wrong! try again!');
      setLoading(false);
    }
  };

  const onChangePayType = (value) => {
    setPayType(value);
  };

  const downloadImage = (key) => {
    var node = document.getElementById(key);
    domToImage
      .toPng(node)
      .then(function (dataUrl) {
        // setReportImage(dataUrl);

        var a = document.createElement('a');
        a.href = dataUrl;
        a.download = `${key}.png`;
        a.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  const downloadReceipt = () => {
    downloadImage('receipt');
  };

  // const downloadInvoice = () => {
  //   downloadImage('invoice');
  // };

  // const onRequestPayment = async () => {
  //   await requestUserPayment(user.id, course.id, course.cohortId, {
  //     price: course.price,
  //     discount: course.discount,
  //     translate: course.translate,
  //     payWayType: PayWayType.ABA_PAY,
  //   });
  // };

  // const handleAbaPay = () => {
  //   // onCancel();
  //   // setVisiblePaymentComplete(true);
  // };

  return (
    <>
      <WrapModal
        width="600px"
        //   height="700px"
        maskClosable={false}
        onCancel={() => setVisiblePaymentComplete(false)}
        visible={visiblePaymentComplete}
        footer={null}
        title={null}
        destroyOnClose={true}
        //   className="modal-custom"
      >
        <WrapPaymentComplete>
          <Row>
            <Col>
              <div className="checkout">CHECK OUT</div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <div className="payment-complete">Payment completed</div>
              <div className="wrap-success">
                <div className="round">
                  <img src={CHECK_ICON} />
                </div>
              </div>
              <div>
                <Link to="/">
                  <Button className="home-page-button">GO TO HOMEPAGE</Button>
                </Link>
              </div>
            </Col>
            <Col className="show-info">
              <div id="receipt" style={{marginTop: '10px'}}>
                <div className="text-center">
                  <img src={IMPACTHUB_PAYMENT_LOGO} width="100" />
                </div>
                <div className="text-center" style={{fontSize: '10px'}}>
                  <div>
                    #17 - Street 306, Beung Keng Kang 1,
                    <br /> Chamkarmorn, Phnom Penh
                  </div>
                  <div>015 674 048 | 010 908 988</div>
                </div>
                <div className="border-dash"></div>
                <div>
                  <table width="100%" style={{fontSize: '12px'}}>
                    <tbody>
                      <tr>
                        <td>Date</td>
                        <td className="text-right">{formatDate('DD/MM/YYYY hh:mm A')}</td>
                      </tr>
                      {/* <tr>
                        <td>User Account</td>
                        <td>{user?.email}</td>
                      </tr> */}
                      <tr>
                        <td>Course</td>
                        <td className="text-right">{course?.title}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="border-dash"></div>
                  <div className="complete-total">
                    <div>Total</div>
                    <div>USD {parseFloat(course.price - course.discount).toFixed(2)}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-center mt-4">
                  <img src={PRINT_ICON} width="40" className="pointer" onClick={downloadReceipt} />
                  <Button type="link" style={{color: '#F6A607'}} onClick={downloadReceipt}>
                    PRINT INVOICE
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </WrapPaymentComplete>
      </WrapModal>
      <WrapModal
        width="1000px"
        //   height="700px"
        maskClosable={false}
        onCancel={onCancel}
        visible={visible && !visiblePaymentComplete}
        footer={null}
        title={null}
        destroyOnClose={true}
        //   className="modal-custom"
      >
        <Spin spinning={loading}>
          <WrapPaymentUI>
            <form
              method="POST"
              target="aba_webservice"
              action={apiUrl}
              id="aba_merchant_request"
              className="d-none"
            >
              <input
                name="hash"
                placeholder="hash"
                value={hash}
                id="hash"
                onChange={(e) => setHash(e.target.value)}
              />
              <input
                name="items"
                placeholder="Items"
                value={items}
                id="items"
                onChange={(e) => setItems(e.target.value)}
              />
              <input
                name="tran_id"
                placeholder="tran_id"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                id="tran_id"
              />
              <input
                name="amount"
                placeholder="amount"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />

              <input
                name="req_time"
                placeholder="requestTime"
                value={requestTime}
                onChange={(e) => setRequestTime(e.target.value)}
              />
              <input
                name="merchant_id"
                placeholder="merchant_id"
                value={merchantId}
                onChange={(e) => setMerchantId(e.target.value)}
              />
              <input
                name="firstname"
                placeholder="firstname"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                name="lastname"
                placeholder="lastname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <input
                name="phone"
                placeholder="phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />

              <input
                name="email"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <input
                type="input"
                name="payment_option"
                // style="margin: 14px;float: left;"
                checked
                value={paymentOption}
                onChange={(e) => setPaymentOption(e.target.value)}
              />
            </form>
            {/* <div>
              <button
                onClick={() => {
                  onCancel();
                  setVisiblePaymentComplete(true);
                }}
              >
                Show Complete
              </button>
            </div> */}
            <Row>
              <Col md={4} xs={12}>
                <div className="pay-tab">
                  <div
                    onClick={() => {
                      onChangePayType(PayWayType.ABA_PAY);
                      setPaymentOption('abapay');
                    }}
                    className={`aba-payment pointer ${
                      payType === PayWayType.ABA_PAY ? 'payment-active' : ''
                    }`}
                  >
                    <div style={{display: 'flex'}}>
                      <div>
                        <img src={ICON_ABAPAY} width="55" />
                      </div>
                      <div>
                        <span style={{marginLeft: '10px'}}>ABA PAY</span>
                        <p style={{fontSize: '10px', marginLeft: '10px', marginBottom: '0px'}}>
                          Scan to pay with ABA Mobile
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      onChangePayType(PayWayType.KHQR);
                      setPaymentOption('bakong');
                    }}
                    className={`pointer d-flex other-payment ${
                      payType === PayWayType.KHQR ? 'payment-active' : ''
                    }`}
                  >
                    <div style={{display: 'flex'}}>
                      <div>
                        <img src={ICON_KHQR} width="60" />
                      </div>
                      <div>
                        <span style={{marginLeft: '8px', marginTop: '10px'}}>KHQR</span>
                        <p style={{fontSize: '10px', marginLeft: '10px', marginBottom: '0px'}}>
                          Scan to pay with member bank app
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={8} xs={12}>
                <Row>
                  {payType === PayWayType.ABA_PAY && (
                    <div className="col-md-6 col-xs-12 payment-detail">
                      <div>
                        <img src={ICON_ABAPAY} width={100} />
                      </div>
                      {/* <div className="notice">Notice:</div> */}
                      <div className="notice-detail" style={{marginTop: '10px'}}>
                        *NOTICE: By choosing ABA Pay as the form of payment, you will get access to
                        the course immediately after the successful transaction.
                        <br />
                        <br />
                        If the course you are buying is the cohort course. you will wait to start
                        the course on the given date.
                      </div>
                      <div className="text-right pt-5">
                        <Button
                          className="pay-now-button"
                          onClick={requestPaymentData}
                          disabled={!user?.id || !course?.id}
                        >
                          PAY NOW
                        </Button>
                      </div>
                      <div className="text-right pay-more-info">
                        By clicking on Pay Now, you confirm that you have read and agreed to our
                        &nbsp;<a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
                      </div>
                    </div>
                  )}
                  {payType === PayWayType.KHQR && (
                    <div className="col-md-6 col-xs-12 payment-detail">
                      <div>
                        <img src={ICON_KHQR} width={110} />
                      </div>
                      {/* <div>
                      <img src={LOGO_ACILIDA} height={50} />
                      <img src={LOGO_WING} height={50} className="ml-3" />
                    </div>
                    <div className="telegram-section">
                      <div className="telegram">
                        <div className="bg-telegram">
                          <img src={LOGO_TELEGRAM} />
                        </div>
                        <div className="telephone">+85510908988</div>
                      </div>
                    </div> */}
                      {/* <div className="notice">Notice:</div> */}
                      {/* <div className="notice-detail">
                      Please send this invoice along with your payment transfer receipt to Impact
                      Hub’s Telegram.
                      <br />
                      <br />
                      By choosing any form of payment above, Sala Impact team need 24 hours or less
                      to crosscheck your receipt and approve your purchase. Should you have any
                      concerns, feel free to reach out to us at 010908988!
                    </div> */}
                      <div className="notice-detail" style={{marginTop: '10px'}}>
                        *NOTICE: By choosing KHQR as the form of payment, you will get access to the
                        course immediately after the successful transaction.
                        <br />
                        <br />
                        If the course you are buying is the cohort course. you will wait to start
                        the course on the given date.
                      </div>
                      {/* <div className="text-right pt-5">
                      <a href="https://t.me/RavuthOeurn" target="__blank">
                        <Button
                          className="submit-payment-button"
                          disabled={!user?.id || !course?.id}
                          onClick={onRequestPayment}
                        >
                          SUBMIT PAYMENT
                        </Button>
                      </a>
                    </div> */}
                      <div className="text-right pt-5">
                        <Button
                          className="pay-now-button"
                          onClick={requestPaymentData}
                          disabled={!user?.id || !course?.id}
                        >
                          SUBMIT
                        </Button>
                      </div>
                      <div className="text-right pay-more-info">
                        By clicking on Pay Now, you confirm that you have read and agreed to our
                        &nbsp;<a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6 col-xs-12">
                    <div className="order-detail">Order Details</div>
                    <div className="pay-amount-detail" id="invoice">
                      <table className="table table-borderless">
                        {/* <tr>
                        <td>Date:</td>
                        <td className="text-right">{formatDate("DD/MM/YYYY hh:mm A")}</td>
                      </tr> */}
                        {/* <tr>
                        <td>Email:</td>
                        <td className="text-right">{user?.email}</td>
                      </tr> */}
                        <tr style={{borderBottom: '1px solid', paddingBottom: '10px'}}>
                          <td style={{paddingBottom: '15px'}}>{course?.title}</td>
                          <td className="text-right">USD {parseFloat(course?.price).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td style={{paddingTop: '15px'}}>Sub total</td>
                          <td className="text-right" style={{paddingTop: '15px'}}>
                            USD {parseFloat(course?.price).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>Discount</td>
                          <td className="text-right text-danger">
                            - USD ${parseFloat(course?.discount).toFixed(2)}
                          </td>
                        </tr>
                      </table>
                      <div className="show-info-result">
                        <div className="text">Total</div>
                        <div className="amount">
                          USD {parseFloat(course.price - course.discount).toFixed(2)}
                        </div>
                      </div>
                    </div>
                    {/* {payType === PayWayType.KHQR && (
                      <div className="text-center mt-2">
                        <img
                          src={PRINT_ICON}
                          width="30"
                          className="pointer"
                          onClick={downloadInvoice}
                        />
                        <Button type="link" style={{color: "#F6A607"}} onClick={downloadInvoice}>
                          Download Invoice
                        </Button>
                      </div>
                    )} */}
                  </div>
                </Row>
              </Col>
            </Row>
          </WrapPaymentUI>
        </Spin>
      </WrapModal>
    </>
  );
}

PaymentModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  user: PropTypes.any,
  course: PropTypes.any,
};

export default PaymentModal;
