import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Tab from './tab';
import {SubSectionStyle} from './styled';
function SubSection({activity,course}) {
  return (
    <SubSectionStyle>
      <Container fluid>
        <Row>
          <Col md="12" className="p-0"> 
            <Tab {...activity} speakers={course.speakers}/>
          </Col>
        </Row>
      </Container>
    </SubSectionStyle>
  );
}

SubSection.propTypes = {
  course: PropTypes.object,
  activity: PropTypes.object,
};

export default SubSection;
