import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { VIMEO_API_TOKEN, VIMEO_PER_PAGE } from '../../constants/default-values';
import _ from 'lodash';
import { APP_GET_VIDEOS } from './types';
import { setVideos } from '../root-action';

const getVideoAsync = async () => {
  return await axios
    .get('https://api.vimeo.com/me/videos?per_page=25&page=1', {
      headers: {
        Authorization: 'Bearer ' + VIMEO_API_TOKEN
      }
    })
    .then(d => {
      const { data } = d;
      return Math.ceil(data.total / VIMEO_PER_PAGE);
    })
    .then(totalPage => {
      let tmp = [];
      for (let i = 1; i <= totalPage; i++) {
        tmp.push(getVideosByPage(i));
      }
      return Promise.all(tmp).then(values => {
        return _.flatten(values);
      });
    })
    .catch(err => console.log(err));
};

export const getVideosByPage = async page => {
  return await axios
    .get(`https://api.vimeo.com/me/videos?per_page=${VIMEO_PER_PAGE}&page=${page}`, {
      headers: {
        Authorization: 'Bearer ' + VIMEO_API_TOKEN
      }
    })
    .then(d => {
      const { data } = d;
      let videos = [];

      data.data.forEach(r => {
        let videoId = r.uri.replace('/videos/', '');
        videoId = videoId.split('/')[0];
        let tags = r.tags.map(item => item.name);
        if (tags)
          videos.push({
            id: videoId,
            name: r.name,
            link: r.link,
            tags
          });
      });
      return videos;
    })
    .catch(err => console.log(err));
};

function* fetctVideos() {
  try {
    let videos = yield call(getVideoAsync);
    videos = videos ? videos : [];
    yield put(setVideos(videos));
  } catch (error) {
    console.log(error.message);
  }
}

export function* watchGetVideos() {
  yield takeLatest(APP_GET_VIDEOS, fetctVideos);
}

export default function* rootSaga() {
  yield all([fork(watchGetVideos)]);
}
