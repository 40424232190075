import _ from 'lodash';

export const findProvince = (state, action) => {
  let found = _.find(state.levels, { id: action.payload.levelId });
  return { ...state, level: found ? found : {} };
};

export const addProvince = (state, action) => {
  return { ...state, levels: [{ ...action.payload }, ...state.levels] };
};

export const updateProvince = (state, action) => {
  let results = _.map(state.levels, item => {
    if (action.payload.id === item.id) return { ...item, ...action.payload };
    return item;
  });
  return { ...state, levels: results };
};

export const deleteProvince = (state, action) => {
  let results = _.filter(state.levels, item => {
    if (action.payload.id === item.id) return false;
    return true;
  });
  return { ...state, levels: results };
};
