import {
  PROVINCE_SET_PROVINCES,
  PROVINCE_GET_PROVINCE,
  PROVINCE_GET_PROVINCES,
  PROVINCE_SET_SUCCESS,
  PROVINCE_SET_REQUEST,
  PROVINCE_SET_ERROR,
  PROVINCE_SET_PROVINCE,
  PROVINCE_ADD_PROVINCE,
  PROVINCE_SET_PROVINCE_BY_ID,
  PROVINCE_UPDATE_PROVINCE,
  PROVINCE_DELETE_PROVINCE,
  PROVINCE_SET_ADD_PROVINCE,
  PROVINCE_SET_UPDATE_PROVINCE,
  PROVINCE_SET_DELETE_PROVINCE,
} from './types';

export const setProvinces = provinces => ({
  type: PROVINCE_SET_PROVINCES,
  payload: { provinces },
});

export const setProvince = province => ({
  type: PROVINCE_SET_PROVINCE,
  payload: { province },
});

export const setProvinceById = provinceId => ({
  type: PROVINCE_SET_PROVINCE_BY_ID,
  payload: { provinceId },
});

export const setAddProvince = province => ({
  type: PROVINCE_SET_ADD_PROVINCE,
  payload: { ...province },
});

export const setUpdateProvince = province => ({
  type: PROVINCE_SET_UPDATE_PROVINCE,
  payload: { ...province },
});

export const setDeleteProvince = id => ({
  type: PROVINCE_SET_DELETE_PROVINCE,
  payload: { id },
});

export const addProvince = province => ({
  type: PROVINCE_ADD_PROVINCE,
  payload: { province },
});

export const deleteProvince = id => ({
  type: PROVINCE_DELETE_PROVINCE,
  payload: { id },
});

export const updateProvince = province => ({
  type: PROVINCE_UPDATE_PROVINCE,
  payload: { province },
});

export const getProvinces = () => ({
  type: PROVINCE_GET_PROVINCES,
});

export const getProvince = id => ({
  type: PROVINCE_GET_PROVINCE,
  payload: { id },
});

export const setProvinceSuccess = () => ({
  type: PROVINCE_SET_SUCCESS,
});

export const setProvinceRequest = () => ({
  type: PROVINCE_SET_REQUEST,
});

export const setProvinceError = message => ({
  type: PROVINCE_SET_ERROR,
  payload: { message },
});
