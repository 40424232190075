import appLocaleData from 'react-intl/locale-data/km';
import kmMessages from '../locales/km_KH';

const KmLang = {
  messages: {
    ...kmMessages,
  },
  locale: 'km-KH',
  data: appLocaleData,
};
export default KmLang;
