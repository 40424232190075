import React, {useEffect, useState} from 'react';
import {getPurchaseHistoryAsync} from '../../../stores/user/sagas';
import {translate} from '../../../lib';
import Layout from '../../../components/layout';
import {Container} from 'reactstrap';
import {Layout as LayoutAntd} from 'antd';
import {useSelector} from 'react-redux';
import {formatDateFirestore} from '../../../lib/utils';
import {COURSE_DETAIL} from '../../../constants/routes';
import {PaymentStatus} from '../../../constants/default-values';

import {WrapContent} from './styled';
import {Link} from 'react-router-dom';
const {Content} = LayoutAntd;

function PurchaseHistory() {
  // const dispatch = useDispatch();
  const [payments, setPayments] = useState([]);
  const {userId} = useSelector((state) => state.auth);
  // const {historyz} = useRouter();

  useEffect(() => {
    const un = getPurchaseHistoryAsync(
      {
        next: (snapshots) => {
          const data = snapshots.docs.map((doc) => doc.data());
          setPayments(data);
        },
      },
      userId
    );

    return () => un;
  }, [userId]);

  // const x = () => {
  //   console.log();
  //   history.push("/course/detail/tpVyfm0JCun7oKwmErom");
  // };

  return (
    <Layout hideFooter={true}>
      <div style={{background: '#E6F4F4'}} className="w-100">
        <Container>
          <LayoutAntd className="constainer-user-setting">
            <LayoutAntd>
              <Content className="contend">
                <WrapContent>
                  <b className="fs-15 ff-bold">{translate('purchase_history')}</b>
                  <hr />
                  <table className="table">
                    <tbody>
                      <tr className="thead-light">
                        <th>Name</th>
                        <th>Date</th>
                        <th>Total Price</th>
                        <th>Payment Type</th>
                        <th>Status</th>
                      </tr>
                      {payments.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <Link to={COURSE_DETAIL.replace(':courseId', item.courseId)}>
                                {item?.translate?.km?.title}
                              </Link>
                            </td>
                            <td>{formatDateFirestore(item.created, 'MMM DD, YYYY')}</td>
                            <td>${parseFloat(item.price - item.discount).toFixed(2)}</td>
                            <td>{item.payWayType}</td>
                            <td
                              style={{
                                color: item.status === PaymentStatus.VERIFIED ? 'green' : '#F6A607',
                              }}
                            >
                              {item.status}
                            </td>
                          </tr>
                        );
                      })}
                      <tr></tr>
                    </tbody>
                  </table>
                </WrapContent>
              </Content>
            </LayoutAntd>
          </LayoutAntd>
        </Container>
      </div>
    </Layout>
  );
}

export default PurchaseHistory;
