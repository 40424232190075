import React, {useCallback, useEffect} from 'react';
import {Row, Col, Button} from 'reactstrap';
import {useSelector, useDispatch} from 'react-redux';
import {change} from 'redux-form';
import LayoutUserSetting from '../../../components/user-setting-layout';
import FormWrapper from '../../../components/redux-form/form';
import Input from '../../../components/redux-form/input';
import SelectAutoComplete from '../../../components/redux-form/select';
import {
  yearGeneratorToCurrentYear,
  createSubmitAction,
  translate,
  manipulateData,
} from '../../../lib';
import {selectManipulationUserProfile, selectProvinces} from '../../../stores/root-selector';
import {selectPositions, selectOrganizations, selectGenders} from '../../../stores/other/selectors';
import {getUser, updateUser} from '../../../stores/root-action';
import IntlMessages from '../../../lib/IntlMessages';

const dateYears = yearGeneratorToCurrentYear(1960);

const formName = 'singInOganization';
function UserSettingEdit() {
  const provinces = useSelector(selectProvinces);
  const positions = useSelector(selectPositions);
  const genders = useSelector(selectGenders);
  const organizationTypes = useSelector(selectOrganizations);
  const dataUserMain = useSelector(selectManipulationUserProfile);
  const id = useSelector((state) => state.auth.userId);
  const dispatch = useDispatch();

  useEffect(() => {
    Object.entries(dataUserMain).forEach(([key, value]) => {
      dispatch(change(formName, key, value));
    });
  }, [dataUserMain, dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, [dispatch, id]);

  const handleSuccess = useCallback(
    async (values) => {
      const {province, position, organizationType} = values;
      let dataProvince = manipulateData(provinces, province);
      let dataPosition = manipulateData(positions, position);
      let dataOrganization = manipulateData(organizationTypes, organizationType);

      const data = {
        id: id,
        province: dataProvince,
        position: dataPosition,
        organizationType: dataOrganization,
        birthYear: values.birthYear,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        gender: values.gender,
        organization: values.organization,
      };
      dispatch(updateUser({...data}));
    },
    [dispatch, provinces, positions, organizationTypes, id]
  );

  const onSubmit = useCallback(
    (values, dispatch) => {
      const validationRule = {
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        phoneNumber: {
          required: true,
        },
        organizationType: {
          required: true,
        },
        position: {
          required: true,
        },
        province: {
          required: true,
        },
        gender: {
          required: true,
        },
        birthYear: {
          required: true,
        },
      };

      return createSubmitAction(validationRule, handleSuccess, null, false)(values, dispatch);
    },
    [handleSuccess]
  );

  return (
    <LayoutUserSetting title={translate('update_profile')}>
      <Row>
        <Col md="6">
          <FormWrapper form={formName} onSubmit={onSubmit}>
            <Input
              name="firstName"
              placeholder={translate('first_name')}
              className="input-login-custom"
            />
            <Input
              name="lastName"
              placeholder={translate('last_name')}
              className="input-login-custom"
            />
            <Input
              name="phoneNumber"
              placeholder={translate('phone')}
              className="input-login-custom"
            />
            <Input
              name="organization"
              placeholder={translate('organization')}
              className="input-login-custom"
            />
            <SelectAutoComplete
              name="organizationType"
              placeholder={translate('type_organization')}
              className="input-login-custom"
              dataSource={organizationTypes}
            />
            <SelectAutoComplete
              dataSource={positions}
              name="position"
              placeholder={translate('position')}
              className="input-login-custom"
            />
            <SelectAutoComplete
              dataSource={provinces}
              name="province"
              placeholder={translate('province')}
              className="input-login-custom"
            />
            <SelectAutoComplete
              name="gender"
              dataSource={genders}
              placeholder={translate('gender')}
              className="input-login-custom"
            />
            <SelectAutoComplete
              dataSource={dateYears}
              name="birthYear"
              placeholder={translate('year_of_birth')}
              className="input-login-custom"
            />
            <Button color="info" className="" htmlType="submit">
              <IntlMessages id="update_profile" />
            </Button>
          </FormWrapper>
        </Col>
      </Row>
    </LayoutUserSetting>
  );
}

export default UserSettingEdit;
