/* eslint-disable indent */
import {
  PROVINCE_SET_REQUEST,
  PROVINCE_SET_SUCCESS,
  PROVINCE_SET_ERROR,
  PROVINCE_SET_PROVINCE,
  PROVINCE_SET_PROVINCES,
  PROVINCE_SET_PROVINCE_BY_ID,
  PROVINCE_SET_ADD_PROVINCE,
  PROVINCE_SET_UPDATE_PROVINCE,
  PROVINCE_SET_DELETE_PROVINCE,
} from './types';
import { addProvince, updateProvince, deleteProvince, findProvince } from './utils';

const INIT_STATE = {
  provinces: [],
  province: {},
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROVINCE_SET_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case PROVINCE_SET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROVINCE_SET_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case PROVINCE_SET_PROVINCES:
      return { ...state, provinces: action.payload.provinces };
    case PROVINCE_SET_PROVINCE:
      return { ...state, province: action.payload.province };
    case PROVINCE_SET_ADD_PROVINCE:
      return addProvince(state, action);
    case PROVINCE_SET_UPDATE_PROVINCE:
      return updateProvince(state, action);
    case PROVINCE_SET_DELETE_PROVINCE:
      return deleteProvince(state, action);
    case PROVINCE_SET_PROVINCE_BY_ID:
      return findProvince(state, action);
    default:
      return { ...state };
  }
};
