import React from 'react';
import {Row, Col, Container} from 'reactstrap';
import {MainTitle, ContainerWrapperStyle} from './styled';
import SectionLeft from './sectionLeft';
import SectionRight from './sectionRight';
import SectionBottom from './sectionBottom';
import IntlMessages from '../../../lib/IntlMessages';
import {RIGHT_PHOTO} from '../../../constants/images';

function Main() {
  return (
    <ContainerWrapperStyle>
      <Container fluid>
        <Row>
          <Col>
            <MainTitle>
              <h2 className='text-deep-blue'>
                <IntlMessages id="impact_hub_online_learning" />
              </h2>
              <hr className='text-deep-blue bg-deep-blue'/>
            </MainTitle>
          </Col>
        </Row>
        <Row>
          <Col md="6" sm="12">
            <SectionLeft />
          </Col>
          <Col md="6" sm="12">
            <SectionRight />
          </Col>
          <Col md="12">
            <SectionBottom />
          </Col>
        </Row>
        <Row className="row-top-up">
          <Col md="6"></Col>
          <Col md="6">
            <img src={RIGHT_PHOTO} alt="popover" />
          </Col>
        </Row>
      </Container>
    </ContainerWrapperStyle>
  );
}

export default Main;
