export default {
  about_us: 'អំពីពួកយើង',
  learn_how_to: 'រៀនធ្វើជាអ្នកកែប្រែសង្គមវ័យក្មេង (Changmaker) ពីគ្រប់ទីកន្លែងនៅក្នុងពិភពលោក',
  sub_learn_how_to: 'ពួកយើងជឿជាក់ថាមនុស្សគ្រប់រូបគួរតែទទួលបានការបណ្តុះបណ្តាល និងការសិក្សាដែលមានគុណភាពខ្ពស់ ដែលមិនគិតថានៅទីណាក្នុងប្រទេសកម្ពុជានោះទេ។',
  description_learn_how_to_one: 'ពួកយើងបានបង្កើតនិងរៀបចំកម្មវិធីដែលមានការទទួលស្គាល់និងឈ្នៈពានរង្វាន់ជាច្រើន សម្រាប់សហគ្រិន និងអ្នកដឹកនាំក្មេងៗអស់រយៈពេល៥ឆ្នាំមកហើយ។ ពួកយើងបានបង្កើតវេទិកាទាំងនេះឡើងដើម្បីផ្តល់ឱកាសអោយអ្នកទាំងអស់គ្នា​ មិនថានៅទីណានោះទេ អាច ចូលរួមជាមួយការបណ្តុះបណ្តាលអនឡាញរបស់ពួកយើងបាន។ ពួកយើងចង់ចែករំលែកចំនេះដឹង និងពត៌មានផ្សេងៗដែលយើងបានបង្កើតឡើង ដើម្បីផ្តល់ភាពងាយស្រួលដល់អ្នកកែប្រែសង្គមវ័យក្មេងដូចជាអ្នកទាំងអស់គ្នា ដែលចង់រៀន និងអភិវឌ្ឍខ្លួនឯងបន្ថែមទៀត។',
  description_learn_how_to_two: 'រាល់វគ្គសិក្សារបស់យើងគឺត្រូវបានរៀបចំឡើងដោយយកចិត្តទុកដាក់ ជាមួយគុណភាពមេរៀនដែលទទួលបានមកប្រទេសផ្សេងៗជាច្រើន ហើយបានរៀបចំបង្កើតឡើងដោយអ្នកជំនាញ និងសហគ្រិនរបស់យើងជាច្រើនដើម្បីបង្កើននិងអភិវឌ្ឍផ្នត់គំនិតសហគ្រិនភាព ភាពជាអ្នកដឹកនាំ និងនវានុវត្តន៍ថ្មីៗ របស់អ្នក។',
  changemaker: 'អ្នកកែប្រែសង្គមវ័យក្មេង',
  changemaker_description: 'ជាបុគ្គលដែលចង់កែប្រែពិភពលោកអោយក្លាយទៅជាកន្លែងមួយដ៏ល្អប្រសើរជាងមុនសម្រាប់បរិស្ថាននិងមនុស្សគ្រប់រូបដោយប្រមូលផ្តុំនូវពត៌មាននិងចំនេះដឹងផ្សេងៗដើម្បីចែករំលែកទៅមនុស្សដទៃទៀត។',
  learn_with_us: 'ចូលរួមរៀនជាមួយពួកយើងដើម្បីក្លាយទៅជាអ្នកកែប្រែសង្គមវ័យក្មេង(Changemaker)ដ៏មានឥទ្ធិពល និងពោរពេញទៅដោយវិជ្ជាជីវៈម្នាក់នៅប្រទេសកម្ពុជា',
  why_learn_with_us: 'ហេតុអ្វីអ្នកត្រូវរៀនជាមួយពួកយើង?',
  lable_feature_one: 'មានមាតិកាមេរៀនដែលមានគុណភាពខ្ពស់',
  description_feature_one: 'គុណភាពគឺជាកត្តាចំបងបំផុតសម្រាប់ពួកយើង ហើយមេរៀនរបស់ពួកយើងគឺត្រូវបានរៀបរាងដោយ អ្នកជំនាញ អ្នកដឹកនាំ និងសហគ្រិនជាច្រើន ទាំងក្នុងនិងក្រៅប្រទេស ដើម្បីបង្កើតមេរៀនដែលមានអត្ថប្រយោជន៍ ទុកបង្រៀនអ្នកទាំងអស់គ្នាពីជំនាញសំខាន់ៗក្នុងសតវត្សរ៍ទី ២១នេះ។ ជាងនេះទៅទៀត មេរៀនរបស់ពួកយើងគឺគួរអោយចាប់អារម្មណ៌និងសប្បាយក្នុងការរៀន៕',
  lable_feature_tow: 'រៀនក្នុងភាសាជាតិ',
  description_feature_tow: 'ពួកយើងបង្រៀនជាភាសាខ្មែរដោយលាយជាមួយពាក្យគន្លឹះអង់គ្លេសខ្លៈៗ​ដើម្បីធានាអោយអ្នកទាំងអស់គ្នាទទួលបានបទពិសោធន៍ការរៀនដ៏ល្អមួយហើយនឹងជួយអ្នកអោយរៀនអំពីពាក្យគន្លឹះដែលត្រឹមត្រូវដើម្បីការពារកុំអោយមានការបកប្រែមិនត្រឹមត្រូវ៕',
  lable_feature_three: 'អ្នកអាចរៀនបានគ្រប់ពេលវេលា គ្រប់ទីកន្លែង',
  description_feature_three: 'មិនថាអ្នកនៅខេត្តឬនៅភ្នំពេញ កំពុងតែធ្វើដំណើរតាមឡានក្រុងឬនៅហាងកាហ្វេ កំពុងប្រើប្រាស់ទូរស័ព្ទឬកំព្យូទ័រនោះទេ អ្នកអាចចូលរួមរៀនអនឡាញជាមួយពួកយើងបាន។',
  lable_feature_four: 'ទទួលបានវិញ្ញាបនបត្របញ្ជាក់ការសិក្សា',
  description_feature_four: 'អ្នកនឹងទទួលបានវិញ្ញាបនបត្របញ្ជាក់ការសិក្សាពី Impact Hub Phnom Penh ពេលដែលអ្នកបញ្ចប់ការសិក្សាជាមួយនឹងមេរៀនរបស់ពួកយើង៕',
  lable_feature_five: 'សហគមន៍អនឡាញ',
  description_feature_five: 'ជួបជាមួយនឹងអ្នកកែប្រែសង្គមវ័យក្មេង (Changemaker) ជាច្រើននៅក្នុងសហគមន៍អនឡាញរបស់យើង ហើយអ្នកអាចនឹងទទួលបានពត៌មាន ការជួយបង្ហាត់បង្រៀនពីអ្នកជំនាញរបស់ពួកយើង អ្នកក៏អាចសួរសំណួរ បង្កើតបណ្តាញរបស់អ្នក និងចូលរួមកម្មវិធីលើប្រព័ន្ធអុីនធឺណេតរបស់យើងជាច្រើនទៀតផង៕',
  lable_feature_six: 'ទទួលបានការគាំទ្រនិងឳកាសភ្ជាប់ទំនាក់ទំនងជាមួយអ្នកដឹកនាំ និងសហគ្រិនជាច្រើននៅលើសកលលោក',
  description_feature_six: 'ពេលដែលអ្នកចូលរួមរៀនជាមួយពួកយើង អ្នកអាចទទួលបានឳកាសផ្សេងៗជាច្រើនទៀតជាមួយImpact Hub Phnom Penh ហើយពួកយើងក៏អាចជួយភ្ជាប់អ្នកជាមួយបណ្តាញរបស់ពួកយើងដែលមានសមាជិកជាង១៦០០០នាក់មកពី១១០ទីក្រុងពាសពេញពិភពលោកផងដែរ៕',
  future_generation: 'ទំពាំងស្នងឬស្សី',
  we_are_the_future_of_cambodia: '"We are the FUTURE OF CAMBODIA that we want to see.”',
  guide_one: 'ស្វែករកមេរៀននៅទីនេះ',
  guide_two: 'ទើបតែចាប់ផ្តើមរៀនអនឡាញលើកដំបូង? សូមមើលការណែនាំរបស់យើងនៅទីនេះ',
  guide_three: 'ចុះឈ្មោះជាមួយព្រឹត្តិប័ត្រព័ត៌មានរបស់ពួកយើងដើម្បីទទួលបានពត៌មានថ្មីៗ',
  guide_four_question: 'តើអ្នកត្រូវការការពង្រឹងសមត្ថភាពរបស់ក្រុមការងារអ្នកដែរឬទេ?',
  guide_four_answer: 'Impact Hub Phnom Penh នឹងផ្តល់បទពិសោធន៌រៀនដ៏ល្អដោយផ្ទាល់ទៅដល់សមាជិករបស់អ្នកពីដោយគ្រាន់តែចុចទីនេះ!',
  click_here: 'ចុចទីនេះ'
}; 
