import React, {useState} from 'react';
import {Row, Col, Container} from 'reactstrap';
import {
  WrapContainer,
  WrapTitleSection,
  Paragraph,
  FlexLeftSideStyle,
  FlexRightSideStyle,
  Span,
} from './styled';
import IntlMessages from '../../../lib/IntlMessages';
import {HOME_SUB_FOOTER_TEXTS} from '../../../constants/home';
import {CONTACT_US} from '../../../constants/routes';
import {NavLink} from 'react-router-dom';
import {VIDEO_QA_GUIDE} from '../../../constants/default-values';
import ModalLoadVideo from '../../../components/video-modal';

function Guided() {
  const [visible, setVisible] = useState(false);
  const handleModal = () => {
    setVisible(true);
  };
  return (
    <Container fluid className="p-0 pt-5">
      <WrapContainer>
        {visible && (
          <ModalLoadVideo videoUrl={VIDEO_QA_GUIDE} visible={visible} setVisible={setVisible} />
        )}
        <Row>
          <Col xs="12" sm="12" md="6" className="bg-sky">
            <FlexLeftSideStyle>
              <WrapTitleSection>
                <IntlMessages id={HOME_SUB_FOOTER_TEXTS[0].title} />
              </WrapTitleSection>
              <Paragraph>
                <IntlMessages id={HOME_SUB_FOOTER_TEXTS[0].description} />
                <Span onClick={handleModal} className="ml-1 mr-1 ff-bold">
                  <IntlMessages id="check_out_here" />
                </Span>
                <IntlMessages id="check_out_here_continue" />
              </Paragraph>
            </FlexLeftSideStyle>
          </Col>
          <Col xs="12" sm="12" md="6" className="bg-yellow">
            <FlexRightSideStyle>
              <WrapTitleSection>
                <IntlMessages id={HOME_SUB_FOOTER_TEXTS[1].title} />
              </WrapTitleSection>
              <Paragraph>
                <IntlMessages id={HOME_SUB_FOOTER_TEXTS[1].description} />
                <NavLink
                  to={CONTACT_US}
                  style={{textDecoration: 'underline'}}
                  className="ml-1 ff-bold"
                >
                  <IntlMessages id="reach_out_to_us_here" />
                </NavLink>
              </Paragraph>
            </FlexRightSideStyle>
          </Col>
        </Row>
      </WrapContainer>
    </Container>
  );
}

export default Guided;
