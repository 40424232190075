import styled from 'styled-components';

export const WrapForm = styled.div`
  box-shadow: 0 0 10px;
  background: white;
  padding: 2rem;
  margin-top:2rem;
  position: relative;
  z-index: 0;
  margin-top: 7rem;
  max-width: 30rem;
`;
export const ImageFrame = styled.img`
  position: absolute;
  left: 6rem;
  top: -9rem;
  z-index: 1;
  height: 10rem;
  @media(max-width: 1000px){
    display: none;
  }
`;
