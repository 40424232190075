// COURSE
export const COURSE_SET_REQUEST = 'COURSE_SET_REQUEST';
export const COURSE_SET_SUCCESS = 'COURSE_SET_SUCCESS';
export const COURSE_SET_ERROR = 'COURSE_SET_ERROR';
export const COURSE_SET_COURSES = 'COURSE_SET_COURSES';
export const COURSE_GET_COURSES = 'COURSE_GET_COURSES';
export const COURSE_SET_COURSE = 'COURSE_SET_COURSE';
export const COURSE_GET_COURSE = 'COURSE_GET_COURSE';
export const COURSE_GET_MODULES = 'COURSE_GET_MODULES';
export const COURSE_GET_MODULE = 'COURSE_GET_MODULE';
export const COURSE_SET_MODULES = 'COURSE_SET_MODULES';
export const COURSE_SET_MODULE = 'COURSE_SET_MODULE';
export const COURSE_GET_ACTIVITIES = 'COURSE_GET_ACTIVITIES';
export const COURSE_GET_ACTIVITY = 'COURSE_GET_ACTIVITY';
export const COURSE_SET_ACTIVITIES = 'COURSE_SET_ACTIVITIES';
export const COURSE_SET_ACTIVITY = 'COURSE_SET_ACTIVITY';

export const COURSE_GET_COHORTS = 'COURSE_GET_COHORTS';
export const COURSE_GET_COHORT_USERS = 'COURSE_GET_COHORT_USERS';
export const COURSE_SET_COHORTS = 'COURSE_SET_COHORTS';
export const COURSE_SET_COHORT_USERS = 'COURSE_SET_COHORT_USERS';
export const COURSE_ADD_COHORT_USER = 'COURSE_ADD_COHORT_USER';

