import {
  TESTIMONIAL_ONE, TESTIMONIAL_TWO,
  TESTIMONIAL_THREE, TESTIMONIAL_FOUR
} from './images';

export const HOME_DATA_SOURCES = [
  {
    title: 'reasonable_one',
    description: 'answer_one'
  },
  {
    title: 'reasonable_two',
    description: 'answer_two'
  },
  {
    title: 'reasonable_three',
    description: 'answer_three'
  },
];

export const HOME_TESTIMONIALS = [
  {
    description: 'testimonial_one_description',
    photo: TESTIMONIAL_ONE,
    fullName: 'testimonial_one_full_name',
    position: 'testimonial_one_position'
  },
  {
    description: 'testimonial_two_description',
    photo: TESTIMONIAL_TWO,
    fullName: 'testimonial_two_full_name',
    position: 'testimonial_two_position'
  },
  {
    description: 'testimonial_three_description',
    photo: TESTIMONIAL_THREE,
    fullName: 'testimonial_three_full_name',
    position: 'testimonial_three_position'
  },
  {
    description: 'testimonial_four_description',
    photo: TESTIMONIAL_FOUR,
    fullName: 'testimonial_four_full_name',
    position: 'testimonial_four_position'
  },
];

export const HOME_SUB_FOOTER_TEXTS = [
  {
    title: 'first_time_learning_online',
    description: 'first_time_learning_online_answer'
  },
  {
    title: 'want_to_insert_the_course_to_your_company',
    description: 'want_to_insert_the_course_to_your_company_answer'
  }
];
