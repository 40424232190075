// logo

// export const ICON_DASHBOARD = require("../assets/images/icon/dashboard.png");

export const IMAGE_DEFAULT = require('../assets/images/default/no_image.png');
export const LOGO = require('../assets/images/default/logo-old.png');
export const LOGO_V2 = require('../assets/images/default/logo-v2.png');
export const LOGO_V3 = require('../assets/images/default/logo-v3.png');
export const FLAG_EN = require('../assets/images/default/flag-en.png');
export const FLAG_KH = require('../assets/images/default/flag-kh.png');
export const BOOK_ICON = require('../assets/images/icon/book.png');
export const LABEL_ICON = require('../assets/images/icon/label.png');
export const COVERLOGIN = require('../assets/images/default/cover_login.png');
export const BOOKSHOW = require('../assets/images/default/bookshow.svg');
export const GRUOPCOVERREGISTER = require('../assets/images/default/groupimgregister.png');
export const SCHOOLLOGO = require('../assets/images/default/schoollogo.png');
export const AVATAR = require('../assets/images/default/avatar.png');
export const CERTIFICATE_DOWNLOAD_ICON = require('../assets/images/default/certificate.png');
export const CERTIFICATE = require('../assets/images/course/certificate.png');
export const LOGO_IMPACT_HUB = require('../assets/images/default/logo_impact_hub.png');
export const SIGNATURE = require('../assets/images/default/signature.png');
export const STAMP = require('../assets/images/default/stamp.png');
export const LOGO_EDEMY = require('../assets/images/default/logo_edemy.png');

//Home Page Image
export const BANNER_BG = require('../assets/images/home/home_page_cover.png');
export const BANNER_TEXT = require('../assets/images/home/text_ breakthrough.png');
//export const BANNER_SLOGANT = require('../assets/images/home/banner_slogant.png');
export const COURSE_PHOTO = require('../assets/images/home/course_photo.png');
export const LEFT_PHOTO = require('../assets/images/home/presentation.png');
export const RIGHT_PHOTO = require('../assets/images/home/right_photo.jpg');
export const QUOTATION = require('../assets/images/home/quote.png');
export const PLAY_BUTTON = require('../assets/images/home/play-button.png');
export const PLAY_ICON = require('../assets/images/home/play-icon.png');

//Course Page Image
export const WIDGET_ONLINE_COURSE = require('../assets/images/course/course_online.png');
export const WIDGET_SPEAKER = require('../assets/images/course/course_speaker_vicheka.jpg');
export const WIDGET_PARTNER = require('../assets/images/course/21st_century_partner.png');
export const COURSE_POINTER = require('../assets/images/course/pointer.png');
export const IMAGE404 = require('../assets/images/default/404.png');

//about Page
export const FEATURE1 = require('../assets/images/default/feature1.png');
export const FEATURE2 = require('../assets/images/default/feature2.png');
export const FEATURE3 = require('../assets/images/default/feature3.png');
export const FEATURE4 = require('../assets/images/default/feature4.png');
export const FEATURE5 = require('../assets/images/default/feature5.png');
export const FEATURE6 = require('../assets/images/default/feature6.png');

//learn page
export const MODULE_INDICATOR = require('../assets/images/learn/indicator.png');
export const ICON_DONE = require('../assets/images/learn/icon_done.png');
export const ICON_SURVEY = require('../assets/images/learn/icon_survey.png');
export const ICON_VIDEO = require('../assets/images/learn/icon_video.png');
export const ICON_SLIDE = require('../assets/images/learn/icon_slide.png');
export const ICON_QUIZ = require('../assets/images/learn/icon_quiz.png');

export const EN_FLAG = require('../assets/images/icon/en-flag.png');
export const KM_FLAG = require('../assets/images/icon/km-flag.png');

// Avatar Image
export const AVATAR1 = require('../assets/images/profile/avatar1.png');
export const AVATAR2 = require('../assets/images/profile/avatar2.png');
export const AVATAR3 = require('../assets/images/profile/avatar3.png');
export const AVATAR4 = require('../assets/images/profile/avatar4.png');
export const AVATAR5 = require('../assets/images/profile/avatar5.png');
export const AVATAR6 = require('../assets/images/profile/avatar6.png');
export const AVATAR7 = require('../assets/images/profile/avatar7.png');
export const AVATAR8 = require('../assets/images/profile/avatar8.png');
export const AVATAR9 = require('../assets/images/profile/avatar9.png');
export const AVATAR10 = require('../assets/images/profile/avatar10.png');
export const AVATAR11 = require('../assets/images/profile/avatar11.png');
export const AVATAR12 = require('../assets/images/profile/avatar12.png');

//Testimonial Home

export const TESTIMONIAL_ONE = require('../assets/images/home/testimonial_one.jpg');
export const TESTIMONIAL_TWO = require('../assets/images/home/testimonial_two.jpg');
export const TESTIMONIAL_THREE = require('../assets/images/home/testimonial_three.jpg');
export const TESTIMONIAL_FOUR = require('../assets/images/home/testimonial_four.jpg');

// Partners
export const PARTNER_AUTRALIAN = require('../assets/images/home/partners/australian.png');
export const PARTNER_BRITISH = require('../assets/images/home/partners/british.png');
export const PARTNER_EDEMY = require('../assets/images/home/partners/edemy.png');
export const PARTNER_EZECOM = require('../assets/images/home/partners/ezecom.png');
export const PARTNER_SMART = require('../assets/images/home/partners/smart.png');
export const PARTNER_SPRING = require('../assets/images/home/partners/spring.png');
export const PARTNER_UNIVERSITY = require('../assets/images/home/partners/university.png');

//Thumbnail video q|a page
export const THUMBNAIL1 = require('../assets/images/cover/1.png');
export const THUMBNAIL2 = require('../assets/images/cover/2.png');
export const THUMBNAIL3 = require('../assets/images/cover/3.png');
export const THUMBNAIL4 = require('../assets/images/cover/4.png');
export const THUMBNAIL5 = require('../assets/images/cover/5.png');
export const THUMBNAIL6 = require('../assets/images/cover/6.png');

export const LOGO_ABA_SMALL = require('../assets/images/payment/aba-pay-small-logo.png');
export const LOGO_ABA_BIG = require('../assets/images/payment/aba-pay-big-logo.png');
export const LOGO_WING = require('../assets/images/payment/wing-logo.png');
export const LOGO_ACILIDA = require('../assets/images/payment/acilida-logo.png');
export const LOGO_OTHER_PAY = require('../assets/images/payment/other-pay.png');
export const ABA_PAY_WORD = require('../assets/images/payment/aba-pay-word.png');
export const LOGO_TELEGRAM = require('../assets/images/payment/telegram-logo.png');
export const IMPACTHUB_PAYMENT_LOGO = require('../assets/images/payment/impacthub-logo.png');
export const CHECK_ICON = require('../assets/images/payment/check-icon.png');
export const PRINT_ICON = require('../assets/images/payment/print.png');
export const ICON_ABAPAY = require('../assets/images/payment/ABAPAY.png');
export const ICON_KHQR = require('../assets/images/payment/KHQR.png');
export const ABA_FOOTER = require('../assets/images/payment/aba-footer.png');
