import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Container, Button} from 'reactstrap';
import {FlexItemButton, ResponsiveSlogantText, ResponsiveSubSlogant, CarouselWrap} from './styled';
import {getLocalStorage} from '../../../lib';
import IntlMessages from '../../../lib/IntlMessages';
// import {BANNER_BG} from '../../../constants/images';
import {useSelector} from 'react-redux';
import {getStoragePathImage} from '../../../lib';
import {selectSlidesDesktop, selectSlidesMobile} from '../../../stores/slide/selectors';

function Banner({handleGetStart}) {
  const slidesDesktop = useSelector(selectSlidesDesktop);
  const slidesMobile = useSelector(selectSlidesMobile);

  return (
    <Container fluid>
      <Row>
        <Col className="p-0">
          {slidesDesktop.length ? (
            <div>
              {/* desktop slide */}
              <CarouselWrap className="desktop_slide" autoplay dots={false} autoplaySpeed={5000}>
                {[...slidesDesktop, ...slidesDesktop].map((item, index) => {
                  return (
                    <div key={index} className="img-container">
                      <img
                        className="img-container"
                        src={getStoragePathImage(item.url)}
                        alt="No_Cover"
                      />
                      <div className="blog-quote">
                        <ResponsiveSlogantText>
                          <IntlMessages id="your_limit" />
                        </ResponsiveSlogantText>
                        {!getLocalStorage('Authorize') && (
                          <Fragment>
                            <ResponsiveSubSlogant>
                              <IntlMessages id="slogant" />
                            </ResponsiveSubSlogant>
                            <FlexItemButton>
                              <Button
                                onClick={handleGetStart}
                                color="dark"
                                style={{background: '#031020'}}
                                className="text-white btn-sm rounded-pill btn-c-login"
                              >
                                <IntlMessages id="sign_up" />
                              </Button>
                            </FlexItemButton>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  );
                })}
              </CarouselWrap>
              {/* mobile slide */}
              <CarouselWrap className="mobile_slide" autoplay dots={false} autoplaySpeed={5000}>
                {[...slidesMobile, ...slidesMobile].map((item, index) => {
                  return (
                    <div key={index} className="img-container">
                      <img
                        className="img-container"
                        src={getStoragePathImage(item.url)}
                        alt="No_Cover"
                      />
                      <div className="blog-quote">
                        <ResponsiveSlogantText>
                          <IntlMessages id="your_limit" />
                        </ResponsiveSlogantText>
                        {!getLocalStorage('Authorize') && (
                          <Fragment>
                            <ResponsiveSubSlogant>
                              <IntlMessages id="slogant" />
                            </ResponsiveSubSlogant>
                            <FlexItemButton>
                              <Button
                                onClick={handleGetStart}
                                color="dark"
                                className="text-white btn-sm rounded-pill btn-c-login"
                              >
                                <IntlMessages id="sign_up" />
                              </Button>
                            </FlexItemButton>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  );
                })}
              </CarouselWrap>
            </div>
          ) : (
            <Fragment>
              <div className="img-container">
                {/* <img className="img-container" src={BANNER_BG} alt="No_Cover" /> */}
                <div className="blog-quote">
                  <ResponsiveSlogantText>
                    <IntlMessages id="your_limit" />
                  </ResponsiveSlogantText>
                  {!getLocalStorage('Authorize') && (
                    <Fragment>
                      <ResponsiveSubSlogant>
                        <IntlMessages id="slogant" />
                      </ResponsiveSubSlogant>
                      <FlexItemButton>
                        <Button
                          onClick={handleGetStart}
                          color="dark"
                          className="text-white btn-sm rounded-pill btn-c-login"
                        >
                          <IntlMessages id="sign_up" />
                        </Button>
                      </FlexItemButton>
                    </Fragment>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </Col>
      </Row>
    </Container>
  );
}

Banner.propTypes = {
  handleGetStart: PropTypes.func,
  isEnrolled: PropTypes.bool,
};

export default Banner;
