import React from 'react';
import {Row, Col, Container} from 'reactstrap';
import {WrapElement, WrapTitleSection, CustomHr} from './styles';
import IntlMessages from '../../lib/IntlMessages';

function SectionAnswer() {
  return (
    <Container>
      <Row>
        <Col md="12">
          <WrapElement className="mt-5 mb-5">
            <WrapTitleSection className="mb-5 text-center text-warning fs-30 ff-bold">
              <small className="text-dark">
                <IntlMessages id="sub_learn_how_to" />
              </small>
              <CustomHr className="bg-info" />
            </WrapTitleSection>
            {/* 11111 */}
            <p style={{textAlign: 'justify'}} className="fs-16">
              <IntlMessages id="description_learn_how_to_one" />
              <br />
              <br />
              <IntlMessages id="description_learn_how_to_two" />
            </p>
            {/* 22222 */}
            <WrapTitleSection className="mt-5 fs-24">
              <IntlMessages id="changemaker" />
            </WrapTitleSection>
            <p style={{textAlign: 'justify'}} className="fs-16">
              <IntlMessages id="changemaker_description" />
            </p>
            {/* 333333 */}
            <WrapTitleSection className="mb-5 mt-5 text-center text-bold text-warning fs-30 ff-bold">
              <IntlMessages id="learn_with_us" />
              <CustomHr className="bg-info" />
              <small className="text-dark">
                <IntlMessages id="why_learn_with_us" />
              </small>
            </WrapTitleSection>
          </WrapElement>
        </Col>
      </Row>
    </Container>
  );
}

export default SectionAnswer;
