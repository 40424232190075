/*eslint-disable react/jsx-no-target-blank*/

// Libs
import React from 'react';
import {Row, Container, Col} from 'reactstrap';
import {ABA_FOOTER, LOGO, LOGO_EDEMY} from '../../../constants/images';
import {ImagLogo, CustomHr, SocialIcon, ContainerDefault} from './styled';
import IntlMessages from '../../../lib/IntlMessages';
import {JOIN_FB_GROUP, ASK_QUESTION, PARTNERSHIP, EDEMY_WEB} from '../../../constants/footer';
import {NavLink} from 'react-router-dom';
import {ABOUT_US, CONTACT_US} from '../../../constants/routes';
const currentYear = new Date().getFullYear();

function Footer() {
  return (
    <ContainerDefault className="pt-5 pb-3 text-white" style={{background: 'black'}}>
      <Container>
        <Row>
          <Col md="3" className="">
            <ImagLogo className="logo" src={LOGO} alt="logo" />
            <br />
            <br />
            <p className="fs-15">
              #17, St.306
              <br />
              Phnom Penh
              <br />
              Cambodia
              <br />
              <br />
              <a href="tel:+85510908988" className="text-white">
                (+855)10 908 988
              </a>
              <br />
              <a href="mailto:chhim.tithamatak@impacthub.net" className="text-white">
                chhim.tithamatak@impacthub.net
              </a>
            </p>
          </Col>
          <Col md="3">
            <Row>
              <Col md="12" className="mb-20">
                <center>
                  <b className="ff-bold fs-18">
                    <IntlMessages id="learn" />
                  </b>
                </center>
                <CustomHr className="bg-warning" />
                <NavLink to={ABOUT_US} className="text-white fs-15">
                  <IntlMessages id="about_us" />
                </NavLink>
                <br />
                <NavLink to={CONTACT_US} className="text-white fs-15">
                  <IntlMessages id="contact_us" />
                </NavLink>
                <br />
                {/* <GuideAction className="ml-3 text-white fs-15 guide-action" onClick={handleModal}>
                  <IntlMessages id="platform_guide" />
                </GuideAction> */}
              </Col>
            </Row>
          </Col>
          <Col md="3" className="mb-20">
            <center>
              <b className="ff-bold fs-18">
                <IntlMessages id="community" />
              </b>
            </center>
            <CustomHr className="bg-warning" />
            <a className="text-white fs-15" href={JOIN_FB_GROUP} target="_blank">
              <IntlMessages id="join_facebook_group" />
            </a>
            <br />
            {/* Button share to facebook */}
            <div
              className="fb-share-button"
              data-href="https://impacthubphnompenh.online/"
              data-layout="icon_link"
              data-size="small"
            >
              <a
                target="_blank"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fimpacthubphnompenh.online%2F&amp;src=sdkpreparse"
                className="fb-xfbml-parse-ignore text-white "
              >
                <IntlMessages id="share_with_your_friends" />
              </a>
            </div>

            <a className="text-white fs-15" href={ASK_QUESTION} target="_blank">
              <IntlMessages id="ask_question" />
            </a>
            <br />
            <NavLink to={CONTACT_US} className="text-white fs-15">
              <IntlMessages id="request_new_course" />
            </NavLink>
            <br />
            <NavLink to={CONTACT_US} className="text-white fs-15">
              <IntlMessages id="report_bug" />
            </NavLink>
          </Col>
          <Col md="3" className="">
            <center>
              <b className="ff-bold fs-18">
                <IntlMessages id="collaborate" />
              </b>
            </center>
            <CustomHr className="bg-warning" />
            <a className="text-white fs-15" href={PARTNERSHIP} target="_blank">
              <IntlMessages id="partner_with_as" />
            </a>
            <br />
            <NavLink to={CONTACT_US} className="text-white fs-15">
              <IntlMessages id="get_support_from_us" />
            </NavLink>
            <div className="row">
              <a className="text-white" href={EDEMY_WEB} target="_blank">
                <SocialIcon src={LOGO_EDEMY} alt="NoImage" />
              </a>
            </div>
            <p className="fs-15">
              {currentYear} &copy; All Rights Reserved.
              <br /> Privacy Policy | Terms of Use
            </p>
            <p>
              We accept: <img src={ABA_FOOTER} alt="aba logo" height={30} />
            </p>
          </Col>
        </Row>
      </Container>
    </ContainerDefault>
  );
}

export default Footer;
