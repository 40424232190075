export default {
  content: 'មាតិកា',
  start_course: 'ចាប់ផ្តើម',
  continue_course: 'បន្ត',
  complete_course: 'បានបញ្ចប់',
  enroll_course: 'ចុះឈ្មោះ',
  about_the_course: 'អំពីវគ្គសិក្សានេះ:',
  course_methodology: 'Course Methodology:',
  watch_the_video_or_click: 'មើលវីដេអូ​ ចុចអាននៅទីនេះ',
  apply_now: 'ដាក់ពាក្យរៀនឥឡូវនេះ',
  learn_now: 'រៀនឥឡូវនេះ',
  testimonials: 'Testimonials',
  our_partners: 'ដៃគូរសហការ',
  speakers: 'វាគ្មិន',
  subtitle: 'Is the course if for me? How to get best out of the course?',
  session: 'មេរៀន',
  welcome_course: 'ស្វាគមន៍មកកាន់វគ្គ ',
  are_you_ready: 'ត្រៀមខ្លួនរៀនរួចរាល់ហើយ? ចុះប៊ូតុងខាងក្រោមដើម្បីផ្តើម។',
  start_learning_now: 'ចាប់ផ្តើមរៀន',
  enroll_now: 'ចុះឈ្មោះ អីឡូវនេះ',
  already_enroll: 'អ្នកបានចុះឈ្មោះរួចរាល់ហើយ!',
  learn_more: 'យល់ដឹងបន្ថែម',
  continue: 'បន្ត',
  finished: 'បានបញ្ចប់!',
  start_class: 'ចាប់ផ្តើមរៀន',
  course: 'វគ្គសិក្សា',
  master_class: 'ថ្នាក់ជំនាញ'
};