import React from 'react';
import {Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import IntlMessages from '../../../lib/IntlMessages';
import Layout from '../../../components/layout';

const MessageSuccess = () => {
  return (
    <Layout hideFooter={true}>
      <Col xs="11" md="5" sm="11" className="bg-light container-register" style={{margin: 'auto'}}>
        <div className="container-success">
          <div className="tab-header conten-success-register">
            <h2 className="text-center mt-4 mb-4 fs-26 ">
              <IntlMessages id="registration_completed" />
            </h2>
            <div className="conten-success-register">
              <p className="fs-14">
                <IntlMessages id="look_for_verification_email" />
                <br />
                <br />
                <IntlMessages id="Did_not_get_the_email" />
                <br />
                <br />
                <IntlMessages id="If_you_do_not_receive_the_email_contact" />
                <a href="mailto: help.edemy@gmail.com" className="text-info">
                  <IntlMessages id="help_edemy_mail" />
                </a>
              </p>
            </div>
            <div className="text-center">
              <Link to="/">
                <button className="btn btn-register-login">
                  <IntlMessages id="back_to_home" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Col>
    </Layout>
  );
};

export default MessageSuccess;
