import React from 'react';
import {BackgroundPhoto} from './styled';
import {LEFT_PHOTO} from '../../../../constants/images';

function SectionLeft() {
  return (
    <BackgroundPhoto>
      <img src={LEFT_PHOTO} alt="NoImage" />
    </BackgroundPhoto>
  );
}

export default SectionLeft;
