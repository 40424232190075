import React from 'react';
import {message, Modal} from 'antd';
import {translate} from './locale';
const {confirm} = Modal;

export const showDeleteMessage = (callback = () => {}) => {
  confirm({
    title: 'Are you want to delete this record.',
    content: <p>You can not undo it.</p>,
    okText: translate('yes'),
    okType: 'danger',
    cancelText: translate('no'),
    onOk() {
      callback();
      message.success('deleted-success');
    },
  });
};

export const showDeleteMessageDetail = (message = '', callback = () => {}) => {
  confirm({
    title: 'Are you want to delete this record.',
    content: message,
    okText: translate('yes'),
    okType: 'danger',
    cancelText: translate('no'),
    onOk() {
      callback();
      message.success('deleted-success');
    },
  });
};

export const showUpdateMessage = (callback = () => {}) => {
  message.success(translate('updated-success'));
  callback();
};

export const showCreateMessage = (callback = () => {}) => {
  callback();
  message.success('created-success');
};

export const showErrorMessage = (msg = 'The current password is invalid', callback = () => {}) => {
  callback();
  message.error(msg);
};
