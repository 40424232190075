// import logger from "redux-logger";
import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from "redux-logger";
import rootReducer from './root-reducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

// if (process.env.NODE_ENV !== "production") {
//   // middlewares.push(logger);
// }
let comp = composeWithDevTools(applyMiddleware(...middlewares));

if (process.env.NODE_ENV === 'production') {
  comp = applyMiddleware(...middlewares);
}


export const store = createStore(rootReducer, comp);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default { store };
