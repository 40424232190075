export default {
  contact_sub_title_contact_us: 'Welcome to Impact Hub Phnom Penh - Online Learning Platform!',
  contact_body_description_contact_us: 'We are here to support you from the beginning until the end of your learning with us. You can always contact us if you wish to:',
  contact_ask_us_questions_supports: 'Ask us questions/supports: ',
  contact_ask_us_questions_supports_des: 'Please feel free to reach out to us if you face any challenges in learning the course from the registration until graduation. Before reaching out to us, please make sure to check here on how to use a platform. You can also contact us if the course content is not clear to you and you are seeking for more explanation. Remember we are here to help!',
  contact_report_a_bug: 'Report a bug: ',
  contact_report_a_bug_des: 'We thrive to be the best online learning platform in delivering high-quality content to you. Should you meet any technical issues in learning the course, please let us know. Our team will work our best in fixing it as soon as possible.',
  contact_request_a_new_topic: 'Request a new topic: ',
  contact_request_a_new_topic_des: 'We are here listening to you. By telling us what you wish to learn and the skills you want to improve, we are on the right track in delivering the best content for Cambodian like you.',
  contact_insert_the_course_to_the_company: 'Insert the course to the company: ',
  contact_insert_the_course_to_the_company_des: 'If you wish to insert the interesting course into company staff’s capacity development, you are at the right place. We are happy to take the course into the next level while providing direct and one-to-one support to your team members.',
  contact_partner_with_us: 'Partner with us: ',
  contact_partner_with_us_des: 'We are open to collaborate with any sectors from private to public in delivering soft skills and entrepreneurial mindset to all youth and young professionals across the nation.',
  contact_others: 'Others',
}; 
