/* AUTH */
export const AUTH_LOGIN_USER = 'AUTH_LOGIN_USER';
export const AUTH_LOGIN_USER_SUCCESS = 'AUTH_LOGIN_USER_SUCCESS';
export const AUTH_UPDATE_PASSWORD = 'AUTH_UPDATE_PASSWORD';
export const AUTH_LOGIN_USER_ERROR = 'AUTH_LOGIN_USER_ERROR';
export const AUTH_REGISTER_USER = 'AUTH_REGISTER_USER';
export const AUTH_REGISTER_USER_SUCCESS = 'AUTH_REGISTER_USER_SUCCESS';
export const AUTH_REGISTER_USER_ERROR = 'AUTH_REGISTER_USER_ERROR';
export const AUTH_UPDATE_USER_ERROR = 'AUTH_UPDATE_USER_ERROR';
export const AUTH_UPDATE_USER_SUCCESS = 'AUTH_UPDATE_USER_SUCCESS';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';
export const AUTH_CHECK_AUTHICATED_USER = 'AUTH_CHECK_AUTHICATED_USER';
export const AUTH_UPDATE_PROFILE = 'AUTH_UPDATE_PROFILE';
export const AUTH_SET_EMAIL = 'AUTH_SET_EMAIL';
export const AUTH_SET_PASSWORD = 'AUTH_SET_PASSWORD';
export const AUTH_VERIFY_EMAIL = 'AUTH_VERIFY_EMAIL';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_SET_NEW_PASSWORD = 'AUTH_SET_NEW_PASSWORD';
export const AUTH_VERIFY_EMAIL_SUCCESS = 'AUTH_VERIFY_EMAIL_SUCCESS';
export const AUTH_UPDATE_PASSWORD_SUCCESS = 'AUTH_UPDATE_PASSWORD_SUCCESS';
export const AUTH_CHECK_OLD_PASSWORD_AND_UPDATE_PASSWORD = 'AUTH_CHECK_OLD_PASSWORD_AND_UPDATE_PASSWORD';
export const AUTH_SET_USER_PRESENCE = 'AUTH_SET_USER_PRESENCE';
export const AUTH_SET_IS_ADMIN = 'AUTH_SET_IS_ADMIN';
export const AUTH_GET_USER_ADMIN = 'AUTH_GET_USER_ADMIN';



