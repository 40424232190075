import React from 'react';
import PropTypes from 'prop-types';
import {ActivityListStyle} from './styled';
import ActivityItem from './activity-item';

const ActivityList = ({activities,index, activity, module, handleActivity}) => {
  return (
    <ActivityListStyle>
      {activities &&
        activities.map((item,actIndex) => (
          <ActivityItem
            key={item.id}
            {...item}
            index = {index}
            actIndex = {actIndex+1}
            data={item}
            module={module}
            handleActivity={handleActivity}
            activity={activity}
          />
        ))}
    </ActivityListStyle>
  );
};

ActivityList.propTypes = {
  activities: PropTypes.array,
  handleActivity: PropTypes.func,
  module: PropTypes.object,
  activity: PropTypes.object,
  index: PropTypes.number
};

export default ActivityList;
