import React from 'react';
import { Form as AntForm } from 'antd';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

function FormRedux(props) {
  const { onSubmit, children, handleSubmit, className } = props;
  return (
    <AntForm onSubmit={handleSubmit(onSubmit)} className={className}>
      {children}
    </AntForm>
  );
}

FormRedux.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  className: PropTypes.string,
};

export default reduxForm()(FormRedux);
