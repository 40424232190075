import {
  setCollectionDoc,
  updateCollectionDoc,
  deleteCollectionDoc,
  getCollectionSnapshotsRealTime,
  getCollectionDocRealTime,
} from '../../lib/firebase';
import { all, call, fork, put, takeLatest, take } from 'redux-saga/effects';
import {
  setProfileRequest,
  setProfiles,
  setProfileSuccess,
  setProfileError,
  setUpdateProfile,
  setDeleteProfile,
  setProfile,
} from './actions';
import {
  PROFILE_GET_PROFILE,
  PROFILE_ADD_PROFILE,
  PROFILE_UPDATE_PROFILE,
  PROFILE_DELETE_PROFILE,
  PROFILE_GET_PROFILES,
} from './types';

const getProfilesAsync = async () => getCollectionSnapshotsRealTime('profiles', [], ['rank', 'asc']);

const addProfileAsync = async (profile) => await setCollectionDoc('profiles', profile);

const updateProfileAsync = async (profile) => await updateCollectionDoc('profiles', profile);

const deleteProfileAsync = async (id) => await deleteCollectionDoc('profiles', id);

const getProfileAsync = async (id) => getCollectionDocRealTime('profiles', id);

function* fetchProfilesSaga() {
  try {
    const channel = yield call(getProfilesAsync);
    while (true) {
      yield put(setProfileRequest());
      const profiles = yield take(channel);
      yield put(setProfiles(profiles));
      yield put(setProfileSuccess());

    }
  } catch (error) {
    yield put(setProfileError(error.message));
  }
}

function* addProfileSaga({ payload }) {
  try {
    const { profile } = payload;
    yield put(setProfileRequest());
    yield call(addProfileAsync, profile);
    yield put(setProfileSuccess());
  } catch (error) {
    yield put(setProfileError(error.message));
  }
}

function* updateProfileSaga({ payload }) {
  try {
    const { profile } = payload;
    yield put(setProfileRequest());
    yield call(updateProfileAsync, profile);
    yield put(setUpdateProfile(profile));
    yield put(setProfileSuccess());
  } catch (error) {
    yield put(setProfileError(error.message));
  }
}

function* deleteProfileSaga({ payload }) {
  try {
    const { id } = payload;
    yield put(setProfileRequest());
    yield call(deleteProfileAsync, id);
    yield put(setDeleteProfile(id));
    yield put(setProfileSuccess());
  } catch (error) {
    yield put(setProfileError(error.message));
  }
}

function* fetchProfileSaga({ payload }) {
  try {
    const { id } = payload;
    yield put(setProfileRequest());
    const profile = yield call(getProfileAsync, id);
    yield put(setProfile(profile));
    yield put(setProfileSuccess());
  } catch (error) {
    yield put(setProfileError(error.message));
  }
}

function* watchGetProfiles() {
  yield takeLatest(PROFILE_GET_PROFILES, fetchProfilesSaga);
}

function* watchGetProfile() {
  yield takeLatest(PROFILE_GET_PROFILE, fetchProfileSaga);
}

function* watchAddProfile() {
  yield takeLatest(PROFILE_ADD_PROFILE, addProfileSaga);
}

function* watchUpdateProfile() {
  yield takeLatest(PROFILE_UPDATE_PROFILE, updateProfileSaga);
}

function* watchDeleteProfile() {
  yield takeLatest(PROFILE_DELETE_PROFILE, deleteProfileSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetProfiles),
    fork(watchGetProfile),
    fork(watchAddProfile),
    fork(watchUpdateProfile),
    fork(watchDeleteProfile),
  ]);
}
