import React, { useEffect, useCallback } from 'react';
import { Row, Container } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import GradePage from '../../../components/report-learning/grade-page';
import { useHistory } from 'react-router-dom';
import Layout from '../../../components/layout';
import { getUserCourses, getUserActivities, getActivities, getModules } from '../../../stores/root-action';
import { selectorUserCoursesReport } from '../../../stores/root-selector';
import { WrapComponent, H2 } from '../../../components/report-learning/styled';
import IntlMessages from '../../../lib/IntlMessages';
import { PRINT_CERTIFICATE } from '../../../constants/routes';

function LearningProgress() {
  const userCourses = useSelector(selectorUserCoursesReport);
  const { userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getUserCourses());
    dispatch(getModules());
    dispatch(getActivities());
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(getUserActivities(userId));
    }
  }, [dispatch, userId]);

  const handleCertificate = useCallback((course) => {
    history.push(PRINT_CERTIFICATE.replace(':courseId', course.courseId));
  }, [history]);

  return (
    <Layout>
      <Container fluid className="my-dashboard-container pt-3 pb-3">
        <Row>
          <Container className="mt-3">
            <WrapComponent>
              <H2 className="fs-24 ff-bold">
                <IntlMessages id="my_dashboard" />
              </H2>
            </WrapComponent>
          </Container>
        </Row>
      </Container>
      <GradePage dataSource={userCourses} handleCertificate={handleCertificate} />
    </Layout>
  );
}

export default LearningProgress;
