import {
  setCollectionDoc,
  updateCollectionDoc,
  deleteCollectionDoc,
  getCollectionSnapshotsRealTime,
  getCollectionDocRealTime
} from '../../lib/firebase';
import { all, call, fork, put, takeLatest, take } from 'redux-saga/effects';
import {
  setProvinceRequest,
  setProvinces,
  setProvinceSuccess,
  setProvinceError,
  setUpdateProvince,
  setDeleteProvince,
  setProvince
} from './actions';
import { PROVINCE_GET_PROVINCE, PROVINCE_ADD_PROVINCE, PROVINCE_UPDATE_PROVINCE, PROVINCE_DELETE_PROVINCE, PROVINCE_GET_PROVINCES } from './types';
const getProvincesAsync = async () => getCollectionSnapshotsRealTime('provinces');

const addProvinceAsync = async province => await setCollectionDoc('provinces', province);

const updateProvinceAsync = async province => await updateCollectionDoc('provinces', province);

const deleteProvinceAsync = async id => await deleteCollectionDoc('provinces', id);

const getProvinceAsync = async id => await getCollectionDocRealTime('provinces', id);

function* fetchProvincesSaga() {
  try {
    const channel = yield call(getProvincesAsync);
    while (true) {
      yield put(setProvinceRequest());
      const provinces = yield take(channel);
      yield put(setProvinces(provinces));
      yield put(setProvinceSuccess());
    }
  } catch (error) {
    yield put(setProvinceError(error.message));
  }
}

function* addProvinceSaga({ payload }) {
  try {
    const { province } = payload;
    yield put(setProvinceRequest());
    yield call(addProvinceAsync, province);
    yield put(setProvinceSuccess());
  } catch (error) {
    yield put(setProvinceError(error.message));
  }
}

function* updateProvinceSaga({ payload }) {
  try {
    const { province } = payload;
    yield put(setProvinceRequest());
    yield call(updateProvinceAsync, province);
    yield put(setUpdateProvince(province));
    yield put(setProvinceSuccess());
  } catch (error) {
    yield put(setProvinceError(error.message));
  }
}

function* deleteProvinceSaga({ payload }) {
  try {
    const { id } = payload;
    yield put(setProvinceRequest());
    yield call(deleteProvinceAsync, id);
    yield put(setDeleteProvince(id));
    yield put(setProvinceSuccess());
  } catch (error) {
    yield put(setProvinceError(error.message));
  }
}

function* fetchProvinceSaga({ payload }) {
  try {
    const { id } = payload;
    const channel = yield call(getProvinceAsync, id);
    while (true) {
      yield put(setProvinceRequest());
      const province = yield call(channel);
      yield put(setProvince(province));
      yield put(setProvinceSuccess());
    }
  } catch (error) {
    yield put(setProvinceError(error.message));
  }
}

export function* watchGetProvinces() {
  yield takeLatest(PROVINCE_GET_PROVINCES, fetchProvincesSaga);
}

export function* watchGetProvince() {
  yield takeLatest(PROVINCE_GET_PROVINCE, fetchProvinceSaga);
}

export function* watchAddProvince() {
  yield takeLatest(PROVINCE_ADD_PROVINCE, addProvinceSaga);
}

export function* watchUpdateProvince() {
  yield takeLatest(PROVINCE_UPDATE_PROVINCE, updateProvinceSaga);
}

export function* watchDeleteProvince() {
  yield takeLatest(PROVINCE_DELETE_PROVINCE, deleteProvinceSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetProvinces),
    fork(watchGetProvince),
    fork(watchAddProvince),
    fork(watchUpdateProvince),
    fork(watchDeleteProvince)
  ]);
}
