import React from 'react';
import PropTypes from 'prop-types';
import {IconCardFeature, BodyCard} from './styles';
import IntlMessages from '../../lib/IntlMessages';

function CardFeature({icon, label, description, descriptionColor}) {
  return (
    <div className="m-1 mb-5">
      <center>
        <IconCardFeature src={icon} alt="No Image" />
      </center>
      <BodyCard className="bg-warning mb-3 mt-3 p-3">
        <h5 className="text-center ff-bold fs-20">
          <IntlMessages id={label} />
        </h5>
      </BodyCard>
      <p className="text-center fs-16" style={{color: descriptionColor}}>
        <IntlMessages id={description} />
      </p>
    </div>
  );
}

CardFeature.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
};

export default CardFeature;
