import React, {Fragment} from 'react';
import {} from 'reactstrap';
import PropTypes from 'prop-types';
import {Title} from './styled';
import SpeakerItem from '../speaker-item';
import IntlMessages from '../../../lib/IntlMessages';

function CourseSpeaker({speakers}) {
  return (
    <Fragment>
      <Title><IntlMessages id="speakers" /></Title>
      {speakers && speakers.map((item, i) => <SpeakerItem key={i} {...item} />)}
    </Fragment>
  );
}

CourseSpeaker.propTypes = {
  speakers: PropTypes.array,
};

export default CourseSpeaker;
