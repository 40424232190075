import React from 'react';
import PropTypes from 'prop-types';
import {ModuleListStyle, Avatar, LabelSpeaker, Badge, SmallText, Element, Flex} from './styled';

function CardSpeakerProfile({dataSource}) {
  const {photo, name, position, description} = dataSource;

  return (
    <ModuleListStyle>
      <Element className="ml-2 mr-2">
        <Avatar src={photo} />
      </Element>
      <Element style={{flexGrow: '1'}}>
        <Flex style={{display: 'flex'}}>
          <div>
            <LabelSpeaker>{name}</LabelSpeaker>
          </div>
        </Flex>
        <Flex style={{alignItems: 'baseline', marginTop: '-5px'}}>
          <Badge />
          <div>
            <SmallText>{position}</SmallText>
          </div>
        </Flex>
        <div style={{marginTop: '5px'}}>
          <SmallText>{description}</SmallText>
        </div>
      </Element>
    </ModuleListStyle>
  );
}

CardSpeakerProfile.propTypes = {
  dataSource: PropTypes.object,
};

export default CardSpeakerProfile;
