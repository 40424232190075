import {Modal} from "antd";
import styled from "styled-components";

export const WrapPaymentUI = styled.div`
  .aba-payment,
  .other-payment {
    padding: 10px;
    border: 5px solid #eeeeee;
    background-color: #eeeeee;
  }

  .other-payment {
    margin-top: 10px;
  }

  .other-payment-text {
    font-size: 15px;
    margin-left: 25px;
    color: #999b9b;
    /* background-color: #eeeeee; */
  }

  .payment-active {
    border: 5px solid #c4c4c4;
    background-color: #fff;
  }

  .notice {
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    font-size: 15px;
    font-weight: bold;
    color: #000;
    margin-top: 10px;
  }

  .notice-detail {
    font-size: 12px;
  }

  .pay-now-button {
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    color: #000;
    background-color: #f6a607;
    margin-bottom: 5px;
  }

  .pay-more-info {
    font-size: 10px;
    padding-left: 20px;
  }

  .payment-detail {
    background-color: #eeeeee;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .order-detail {
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    text-transform: uppercase;
    font-weight: bolder;
    color: #000;
    /* margin-bottom: 10px; */
  }

  .pay-amount-detail {
    background-color: #eeeeee;
    padding: 10px;
    border-radius: 10px;
  }

  .show-info-result {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    /* margin-bottom: 20px; */

    padding-top: 20px;
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    /* text-transform: uppercase; */
    color: #000;
    /* border-top: 1px solid #000; */
    font-size: 15px;

    .text {
      flex-grow: 1;
    }
  }

  .telegram-section {
    margin-top: 40px;
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    font-size: 20px;
    .telegram {
      display: flex;
      background-color: #d4d4d4;

      .bg-telegram {
        padding: 10px;
        background-color: #38afe3;
      }

      .telephone {
        padding: 10px;
        flex-grow: 1;
        text-align: center;
      }
    }
  }

  .submit-payment-button {
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    color: #000;
    background-color: #f6a607;
    margin-bottom: 5px;
    margin-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .pointer {
    cursor: pointer;
  }

  .table td,
  .table th {
    padding: 0.3rem;
    vertical-align: top;
  }

  @media only screen and (max-width: 600px) {
    .pay-tab {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      margin-top: 20px;

      .aba-payment {
        width: 49%;
        /* height: 65px; */
        padding-bottom: 5px;
      }

      .other-payment {
        width: 49%;
        margin-top: 0px;
        /* height: 65px; */

        img {
          height: 34px;
        }
      }
    }
    .payment-detail {
      margin-right: 15px;
      margin-left: 15px;
    }

    .order-detail {
      margin-top: 20px;
    }
  }
`;

export const WrapModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 25px;
  }

  .ant-modal-body {
    /* padding-top: 80px; */
    /* padding-bottom: 80px; */
  }
`;

export const WrapPaymentComplete = styled.div`
  .border-dash {
    border-width: 1px;
    border-style: dashed;
    border-color: #ddd;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .complete-total {
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
  }

  .home-page-button {
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    color: #000;
    background-color: #f6a607;
    margin-bottom: 5px;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
    border-radius: 5px;
  }

  .payment-complete {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bolder;
    margin-top: 30px;
  }

  .wrap-success {
    text-align: center;
    .round {
      background-color: #f6a607;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 25px;

      img {
        margin-top: 30px;
      }
    }
  }

  .checkout {
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    font-size: 30px;
    color: #000;
    border-bottom: 1px solid #ddd;
  }

  .show-info.col {
    border-left: 1px solid #ddd;

    @media only screen and (max-width: 600px) {
      border-left: 0px solid #ddd;
      border-top: 1px dashed #ddd;
      margin-top: 20px;
    }
  }
`;
