import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { ContainerDefault, Section, ContainerTab, TabHeader, UnderLineText, Label } from './styles';
import { Container, Row, Col, Button } from 'reactstrap';
import SectionOne from './section_one';
import IntlMessages from '../../lib/IntlMessages';
import { useSelector } from 'react-redux';
import { selectorCourses } from '../../stores/course/selectors';
import { Link } from 'react-router-dom';
import MasterCourseCard from '../../components/master-course-card';
import CourseBoxShow from '../../components/course-card';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { VIEW_ALL_COURSES, COURSE_DETAIL } from '../../constants/routes';

const ConvertMasterClass = (dataSource, type) =>{
  let data = dataSource.filter((item)=>item.type === type);
  return data;
};

const ConvertCourse = (dataSource, type) =>{
  let data = dataSource.filter((item)=>item.type !== type);
  return data;
};

const active = {
  ALL: 'all',
  COURSE: 'course',
  MASTER_CLASS: 'masterclass',
};

function ContactUs() {
  const useQuery = () => parse(useLocation().search);
  const history = useHistory();
  let { tab } = useQuery();
  const courses = useSelector(selectorCourses);
  const masterCourses = ConvertCourse(courses,  'MASTERCLASS');
  const masterClasses = ConvertMasterClass(courses,  'MASTERCLASS');
  const [tabActive, setTabActive] = useState('');

  useEffect(() => {
    setTabActive(tab);
    if(!tab){
      let route = `${VIEW_ALL_COURSES}?tab=all`;
      history.push(route);
    }
  }, [tab, history]);

  const handleChangeTab = useCallback((active) => {
    let route = `${VIEW_ALL_COURSES}?tab=${active}`;
    history.push(route);
  }, [history]);

  return (
    <Layout>
      {/* Section One */}
      <ContainerDefault>
        <Section label="section1" className="bg-warning">
          <Container>
            <SectionOne />
          </Container>
        </Section>
      </ContainerDefault>
      {/* Section Two */}
      <ContainerDefault>
        <Section label="section2" className="" style={{ background: 'rgb(241, 241, 241)' }}>
          <Container>
            <Row>
              <Col>
                <TabHeader className="pt-4 pb-4">
                  <ContainerTab onClick={() => handleChangeTab(active.ALL)} className={`${tabActive === active.ALL && 'active'}`}>
                    <IntlMessages id="explore_all" />
                  </ContainerTab>
                  <ContainerTab onClick={() => handleChangeTab(active.COURSE)} className={`${tabActive === active.COURSE && 'active'} ml-4`}>
                    <IntlMessages id="view_all_course" />
                  </ContainerTab>
                  <ContainerTab onClick={() => handleChangeTab(active.MASTER_CLASS)} className={`${tabActive === active.MASTER_CLASS && 'active'} ml-4`}>
                    <IntlMessages id="view_all_masterclass" />
                  </ContainerTab>
                </TabHeader>
              </Col>
            </Row>
          </Container>
        </Section>
      </ContainerDefault>
      {/* Section Three */}
      <ContainerDefault>
        {tabActive !== active.MASTER_CLASS &&
          <Section label="section2">
            <Container>
              <Label className="pt-4 text-warning">
                <ContainerTab>
                  <IntlMessages id="course" />
                </ContainerTab>
              </Label>
              <UnderLineText className='b-info'/>
              {tabActive === active.ALL ? 
                <Row className="mb-3">
                  {/* Show in view all page */}
                  {masterCourses &&
                  masterCourses.filter((item,index)=> index < 6).map((item, index) => {
                    return (
                      <Col md="4" sm="6" key={index} className="mt-3" style={{position: 'relative'}}>
                        <Link to={`/course/detail/${item.id}`}>
                          <CourseBoxShow dataSource={item} />
                        </Link>
                      </Col>
                    );
                  })}
                </Row>: 
                <Row className="mb-3">
                  {/* Show in view courses all page */}
                  {masterCourses &&
                  masterCourses.map((item, index) => {
                    return (
                      <Col md="4" sm="6" key={index} className="mt-3" style={{position: 'relative'}}>
                        <Link to={`/course/detail/${item.id}`}>
                          <CourseBoxShow dataSource={item} />
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              }
              {tabActive === active.ALL && 
              <Row>
                <Col className='text-center'>
                  <Button onClick={() => handleChangeTab(active.COURSE)} className='btn btn-info pl-5 pr-5 text-center mt-4 mb-4'>
                    <IntlMessages id="view_all_course" />
                  </Button>
                </Col>
              </Row>
              }
            </Container>
          </Section>
        }
        {tabActive !== active.COURSE &&
          <Section label="section3" className="">
            <Container>
              <Label className="pt-4 text-warning">
                <IntlMessages id="master_class" />
              </Label>
              <UnderLineText className='b-info'/>
              {tabActive === active.ALL ? 
                <Row className="mb-3">
                  {/* Show in view all page */}
                  {masterClasses &&
                    masterClasses.filter((item,index)=> index < 8).map((item, index) => {
                      const { name, photo} = item.speakerData;
                      return (
                        <Col md="3" sm="6" key={index} className="mt-4">
                          <Link to={`${COURSE_DETAIL.replace(':courseId', item.id)}`}>
                            <MasterCourseCard dataSource={{image: photo, title: item.title, speaker: name }} />
                          </Link>
                        </Col>
                      );
                    })}
                </Row>: 
                <Row className="mb-3">
                  {/* Show in master classes page */}
                  {masterClasses &&
                    masterClasses.map((item, index) => {
                      const { name, photo} = item.speakerData;
                      return (
                        <Col md="3" sm="6" key={index} className="mt-4">
                          <Link to={`${COURSE_DETAIL.replace(':courseId', item.id)}`}>
                            <MasterCourseCard dataSource={{image: photo, title: item.title, speaker: name }} />
                          </Link>
                        </Col>
                      );
                    })}
                </Row>
              }
              {tabActive === active.ALL && 
              <Row>
                <Col className='text-center'>
                  <Button onClick={() => handleChangeTab(active.MASTER_CLASS)} className='btn btn-warning pl-5 pr-5 text-center mt-3 mb-4'>
                    <IntlMessages id="view_all_masterclass" />
                  </Button>
                </Col>
              </Row>
              }
            </Container>
          </Section>
        }
      </ContainerDefault>
    </Layout>
  );
}

export default ContactUs;
