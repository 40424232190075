import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { change } from 'redux-form';
import LayoutUserSetting from '../../../components/user-setting-layout';
import FormWrapper from '../../../components/redux-form/form';
import { WrapText, Profile, SelectProfile } from './styled';
import { selectProfiles } from '../../../stores/profile/selectors';
import { getStoragePathProfile, createSubmitAction, translate } from '../../../lib';
import InputRedux from '../../../components/redux-form/input';
import { updateUser } from '../../../stores/root-action';
import IntlMessages from '../../../lib/IntlMessages';
const formName = 'updateProfileForm';
function UserSettingEdit() {
  const [imageProfile, setImageProfile] = useState('');
  const { user } = useSelector((state) => state.user);
  const profiles = useSelector(selectProfiles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.id) {
      let image = user.image;
      dispatch(change(formName, 'image', image));
      dispatch(change(formName, 'id', user.id));
      setImageProfile(image);
    }
  }, [user, dispatch]);

  const handleSuccess = useCallback(
    async (values) => {
      dispatch(updateUser(values));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    (values, dispatch) => {
      const validationRule = {
        image: {
          required: true,
        },
      };

      return createSubmitAction(validationRule, handleSuccess, null, false)(values, dispatch);
    },
    [handleSuccess]
  );

  const changeProfile = useCallback(
    (value) => {
      setImageProfile(value.image);
      dispatch(change(formName, 'image', value.image));
    },
    [dispatch]
  );
  return (
    <LayoutUserSetting title={translate('profile')}>
      <Row>
        <Col md="12">
          <Row>
            <Col md="3">
              <WrapText>
                <IntlMessages id="avatar" />
              </WrapText>
            </Col>
            <Col md="9">
              <Profile src={getStoragePathProfile(imageProfile)} alt="NoImage" />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <WrapText>
                <IntlMessages id="update_avatar" />
              </WrapText>
            </Col>
            <Col md="9">
              <Row>
                {profiles.map((item, index) => {
                  const { image } = item;
                  return (
                    <Col md="3" key={index}>
                      <SelectProfile
                        src={getStoragePathProfile(image)}
                        alt="NoImage"
                        onClick={() => changeProfile(item)}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormWrapper form={formName} onSubmit={onSubmit}>
                <InputRedux name="image" className="d-none" />
                <InputRedux name="id" className="d-none" />
                <Button color="info" className="" type="submit">
                  <IntlMessages id="update_avatar" />
                </Button>
              </FormWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutUserSetting>
  );
}

export default UserSettingEdit;
