import React from 'react';
import PropTypes from 'prop-types';
import {Group, Title, Body} from './styled';
import IntlMessages from '../../../../lib/IntlMessages';

function Paragraph({dataSource}) {
  return (
    <Group>
      <Title>
        <IntlMessages id={dataSource.title} />
      </Title>
      <Body>
        <IntlMessages id={dataSource.description} />
      </Body>
    </Group>
  );
}

Paragraph.propTypes = {
  dataSource: PropTypes.object,
};

export default Paragraph;
