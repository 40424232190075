import styled from 'styled-components';

export const ModuleListStyle = styled.div`
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  @media (max-width: 1000px) {
    width: 55px;
    height: 55px;
  }
`;
export const LabelSpeaker = styled.h6`
  border-bottom: 3px solid #f9c108;
  font-weight: bold;
  font-family: 'PoppinsMedium','NotoSanMedium';
  font-size: 15px;
  /* @media (max-width: 1000px) {
    font-size: 12px;
  } */
`;
export const SmallText = styled.div`
  line-height: 1.3;
  font-size: 14px;
`;

export const Badge = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background: #f9c108;
  margin-right: 10px;
`;
export const Element = styled.div``;
export const Flex = styled.div`
  display: flex;
`;