import styled from 'styled-components';

export const CustomSlider = styled.div` 
    margin: 20px !important;
    a:hover {
        text-decoration: none;
        color: #000;
    }

    .slick-dots li button:before {
        font-size: 12px;
    } 
    .slick-dots li.slick-active button:before {
        color: #00BBC3;
    }  
    .slick-prev:before,
    .slick-next:before {
        color: black !important;
        font-size: 30px !important;
    }

    .slick-track{
        display: flex;
        .slick-slide{
            display: flex;
            height: auto;
            /* background-color: #F4F4F4; */
            margin: 10px 10px;
        }
    }
`;
