import styled from 'styled-components';

export const WrapContainer = styled.div`
  width: 100%;
  overflow-x:hidden;
  min-height: 10rem;
  padding: 0px;

  .bg-sky {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #1BB0BB;
    display: flex;
    height: auto;
    align-items: top;

    @media (min-width: 300px) {
      padding-left: 0px;
      padding-right: 0px;
      justify-content: center;
    }

    @media (min-width: 768px) {
      padding-left: 0px;
      padding-right: 0px;
      justify-content: center;
    }

    @media (min-width: 992px) {
      padding-right: 50px;
      justify-content: flex-end;
    }

    @media (min-width: 1024px) {
      padding-right: 50px;
      justify-content: flex-end;
    }  
  }

  .bg-yellow {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 50px;
    background-color: #FBB92E;
    display: flex;
    height: auto;
    align-items: top;

    @media (min-width: 300px) {
      padding-right: 0px;
      padding-left: 0px;
      justify-content: center;
    }

    @media (min-width: 768px) {
      padding-right: 0px;
      padding-left: 0px;
      justify-content: center;
    }

    @media (min-width: 992px) {
      padding-left: 50px;
      justify-content: flex-start;
    }

    @media (min-width: 1024px) {
      padding-left: 50px;
      justify-content: flex-start;
    }  
  }
`;

export const Paragraph = styled.div`
  color: #000;
`;

export const FlexRightSideStyle = styled.div`
  @media (min-width: 300px) {
    width: 300px;
  }

  @media (min-width: 768px) {
    width: 400px;
  }

  @media (min-width: 992px) {
    width: 400px;
  }

  @media (min-width: 1024px) {
    width: 500px;
  }  
`;

export const FlexLeftSideStyle = styled.div`
  
  @media (min-width: 300px) {
    width: 300px;
  }

  @media (min-width: 768px) {
    width: 400px;
  }

  @media (min-width: 992px) {
    width: 400px;
  }

  @media (min-width: 1024px) {
    width: 500px;
  }  
`;

export const WrapTitleSection = styled.h5`
  letter-spacing 0.1 rem;
  color: #000;
  font-family:'PoppinsSemiBold', 'NotoSanSemiBold' ;
`;
export const Span = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;