import {
  CATEGORY_SET_CATEGORIES,
  CATEGORY_GET_CATEGORY,
  CATEGORY_GET_CATEGORIES,
  CATEGORY_SET_SUCCESS,
  CATEGORY_SET_REQUEST,
  CATEGORY_SET_ERROR,
  CATEGORY_SET_CATEGORY,
} from './types';

export const setCategories = (categories) => ({
  type: CATEGORY_SET_CATEGORIES,
  payload: {categories},
});

export const setCategory = (category) => ({
  type: CATEGORY_SET_CATEGORY,
  payload: {category},
});

export const getCategories = () => ({
  type: CATEGORY_GET_CATEGORIES,
});

export const getCategory = (id) => ({
  type: CATEGORY_GET_CATEGORY,
  payload: {id},
});

export const setCategorySuccess = () => ({
  type: CATEGORY_SET_SUCCESS,
});

export const setCategoryRequest = () => ({
  type: CATEGORY_SET_REQUEST,
});

export const setCategoryError = (message) => ({
  type: CATEGORY_SET_ERROR,
  payload: {message},
});
