import React from 'react';
import {Row, Col, Container} from 'reactstrap';
import PropTypes from 'prop-types';
import {FullWidthBackground} from './styled';
import CourseWidget from '../course-widget/index';
import AboutCourse from '../about-course';

function CourseBody({course, handleEnrollCourse}) {
  return (

    <FullWidthBackground>
      <Container>
        <Row>
          <Col md="8">
            <AboutCourse course={course} handleEnrollCourse={handleEnrollCourse} />
          </Col>
          <Col md="4">
            <CourseWidget course={course} />
          </Col>
        </Row>
      </Container>
    </FullWidthBackground>

  );
}

CourseBody.propTypes = {
  course: PropTypes.object,
  handleEnrollCourse: PropTypes.func,
};

export default CourseBody;
