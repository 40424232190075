import {
  firestoreRef,
  authRef,
  firebaseApp,
  secondaryApp,
  databaseRef,
  FIRSTORE_TIMESTAMP,
} from '../../lib/firebase';
import {all, call, fork, put, takeLatest, select} from 'redux-saga/effects';
import {
  registerUserSuccess,
  registerUserError,
  loginUserSuccess,
  logoutUser,
  loginUserError,
  updateUserSuccess,
  updateUserError,
  setUser,
  verifyEmailSuccess,
} from '../root-action';
import {message} from 'antd';
import {history} from '../../lib/utils/history';
import {setLocalStorage, showUpdateMessage, showErrorMessage, removeUndefined} from '../../lib';
import {
  AUTH_REGISTER_USER,
  AUTH_LOGIN_USER,
  AUTH_LOGOUT_USER,
  AUTH_UPDATE_PASSWORD,
  AUTH_CHECK_AUTHICATED_USER,
  AUTH_UPDATE_PROFILE,
  AUTH_VERIFY_EMAIL,
  AUTH_RESET_PASSWORD,
  AUTH_SET_NEW_PASSWORD,
  AUTH_CHECK_OLD_PASSWORD_AND_UPDATE_PASSWORD,
  AUTH_SET_USER_PRESENCE,
  AUTH_GET_USER_ADMIN,
} from './types';
import {addUserAsync, getUserAsync, logUserRegisterAsync, updateUsersAsync} from '../user/sagas';
import {setUserPayment, updateUser} from '../user/actions';
import {setIsModalVisible} from '../app/actions';
import {updatePasswordSuccess, setIsAdmin} from './actions';

export const loginWithEmailPasswordAsync = async (email, password) =>
  await authRef
    .signInWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

export const registerWithEmailPasswordAsync = async (email, password) =>
  await authRef.createUserWithEmailAndPassword(email, password);
// .then((authUser) => authUser)
// .catch((error) => error);

export const registerSecondaryWithEmailPasswordAsync = async (email, password) =>
  await secondaryApp
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => {
      secondaryApp.auth().signOut();
      return authUser;
    })
    .catch((error) => error);

const updatePasswordAsync = async (password) => {
  return await authRef.currentUser.updatePassword(password);
};

export const getUserAdminAsync = async (userId) => {
  const userAdmin = await firestoreRef.doc(`userAdmin/${userId}`).get();
  return userAdmin.exists;
};

const reauthenticateWithCredentialAsync = (currentPassword) => {
  var user = authRef.currentUser;
  var credential = firebaseApp.auth.EmailAuthProvider.credential(
    firebaseApp.auth().currentUser.email,
    currentPassword
  );
  return user.reauthenticateWithCredential(credential);
};

const sendEmailVerificationToUser = () => {
  authRef.currentUser.sendEmailVerification();
};

export const handleVerifyEmailAsync = async (actionCode) => {
  return await authRef.applyActionCode(actionCode);
};

export const resetPasswordAsync = async (email) => {
  return await authRef.sendPasswordResetEmail(email);
};

export const setNewPasswordAsync = async (actionCode, newPassword) => {
  return await authRef.confirmPasswordReset(actionCode, newPassword);
};

function* registerWithEmailPassword({payload}) {
  const {user} = payload;
  try {
    const registerUser = yield call(registerWithEmailPasswordAsync, user.email, user.password);
    // if (!registerUser.message) {
    user.id = registerUser.user.uid;
    delete user.password;
    delete user.confirm_password;
    yield call(addUserAsync, removeUndefined({...user, isVerify: false}));
    yield put(registerUserSuccess());
    yield call(sendEmailVerificationToUser);
    history.push('/register/message/success');
    // } else {
    // message.error(registerUser.message);
    // // console.log('[error]', registerUser.message);
    // const userLogInfo = authRef.currentUser;
    // if (userLogInfo) {
    //   yield call(logUserRegisterAsync, {
    //     userId: userLogInfo.uid,
    //     email: userLogInfo.email,
    //     message: registerUser.message
    //   });
    // }
    // yield put(registerUserError(registerUser.message));
    // }
  } catch (error) {
    message.error(error.message);
    yield call(logUserRegisterAsync, {
      email: user.email,
      data: JSON.stringify(user),
      message: error.message,
    });
    yield put(registerUserError(error.message));
  }
}

const logoutAsync = async () => {
  await authRef
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);
};

const authStateChanged = () => {
  return new Promise((resolve) => {
    firebaseApp.auth().onAuthStateChanged(function (user) {
      if (user) {
        resolve(user);
      } else {
        resolve(null);
      }
    });
  });
};

const actionUserPresence = async (userId) => {
  if (userId) {
    var amOnline = databaseRef.ref('.info/connected');
    var onlineUserRef = databaseRef.ref(`onlineUser/${userId}`);

    amOnline.on('value', function (snapshot) {
      if (snapshot.val()) {
        onlineUserRef.onDisconnect().remove();
        onlineUserRef.set({token: Math.random().toString()});
      }
    });
  }
};

function* checkAuthStateChanged() {
  try {
    let user = yield call(authStateChanged);
    if (user) {
      let userId = user.uid;
      setLocalStorage('Authorize', true);
      setLocalStorage('userId', userId);
      let loginUser = yield call(getUserAsync, userId);
      yield put(setUser(loginUser));
      let isAdmin = yield call(getUserAdminAsync, userId);
      yield put(setIsAdmin(isAdmin));
      yield put(loginUserSuccess(userId));
      if (!user.emailVerified) yield put(logoutUser(false));
    } else {
      yield put(logoutUser(false));
    }
  } catch (error) {
    console.log(error);
  }
}

// function* checkAuthStateChanged() {
//   try {
//     let user = yield call(authStateChanged);
//     if (user) {
//       let userId = user.uid;
//       setLocalStorage('Authorize', true);
//       setLocalStorage('userId', userId);
//       let loginUser = yield call(getUserAsync, userId);
//       let isAdmin = yield call(getUserAdminAsync, userId);
//       console.log({ isAdmin })
//       yield put(setIsAdmin(isAdmin));
//       yield put(setUser(loginUser));
//       yield put(loginUserSuccess(userId));

//       // if (!user.emailVerified) yield put(logoutUser(false));
//     } else {
//       yield put(logoutUser(false));
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }

const saveVerifyAsync = async () => {
  let user = authRef.currentUser;
  if (user) {
    firestoreRef
      .collection('verifications')
      .get()
      .then(function (querySnapshot) {
        let itemVerify = [];
        querySnapshot.forEach((data) => {
          itemVerify.push(data.data());
        });
        let user = authRef.currentUser;
        let validateDuplicate = itemVerify.find((item) => item.userId === user.uid);
        const data = {
          userId: user.uid,
          email: user.email,
          created: FIRSTORE_TIMESTAMP,
        };
        if (!validateDuplicate) {
          firestoreRef.collection('verifications').add(data);
        }
      });
  }
};

function* loginWithEmailPassword({payload}) {
  try {
    const {email, password} = payload.user;
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      let user = authRef.currentUser;
      if (user.emailVerified) {
        yield call(saveVerifyAsync);
        setLocalStorage('Authorize', true);
        setLocalStorage('userId', user.uid);
        yield put(loginUserSuccess(user.uid));

        const loginUser = yield call(getUserAsync, user.uid);
        yield put(setUser(loginUser));
        let isAdmin = yield call(getUserAdminAsync, user.uid);
        yield put(setIsAdmin(isAdmin));
        if (user.emailVerified) {
          let addVerifyField = {
            id: user.uid,
            isVerify: true,
          };
          yield call(updateUsersAsync, addVerifyField);
        }
        // history.push('/');
        history.goBack();
        // const timeout = setTimeout(() => {
        //   // window.location.reload();
        //   clearTimeout(timeout);
        // }, 1000);
      } else {
        yield put(logoutUser());
        message.error('Email is not verified');
      }
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
    console.log(error);
  }
}

// function* loginWithEmailPassword({ payload }) {
//   try {
//     const { email, password } = payload.user;
//     const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
//     if (!loginUser.message) {
//       let user = authRef.currentUser;
//       yield call(saveVerifyAsync);
//       setLocalStorage('Authorize', true);
//       setLocalStorage('userId', user.uid);
//       history.push('/');
//       window.location.reload();
//     } else {
//       yield put(loginUserError(loginUser.message));
//     }
//   } catch (error) {
//     yield put(loginUserError(error));
//     console.log(error);
//   }
// }

function* logout({payload}) {
  const {isRedirect} = payload;
  try {
    yield call(logoutAsync);
    localStorage.removeItem('Authorize');
    localStorage.removeItem('userId');
    yield put(setIsAdmin(false));
    yield put(setUser({}));
    yield put(setUserPayment({}));

    if (isRedirect) {
      history.push('/');
      // window.location.reload();
    }
  } catch (error) {
    console.log(error);
  }
}

function* doUpdatePassword({payload}) {
  const {password} = payload;
  try {
    yield call(updatePasswordAsync, password);
    yield put(updateUserSuccess());
    yield put(logoutUser());
  } catch (error) {
    yield put(updateUserError(error.message));
  }
}

function* getAdminUserSaga({payload}) {
  const {userId} = payload;
  try {
    const isAdmin = yield call(getUserAdminAsync, userId);
    yield put(setIsAdmin(isAdmin));
  } catch (error) {
    console.log(error.message);
  }
}

function* checkOldPasswordAndUpdatePasswordSaga({payload}) {
  const {oldPassword, newPassword} = payload;

  try {
    yield call(reauthenticateWithCredentialAsync, oldPassword);
    yield call(updatePasswordAsync, newPassword);
    yield put(updateUserSuccess());
    showUpdateMessage();
  } catch (error) {
    yield put(updateUserError(error.message));
    console.log({error});
    showErrorMessage();
  }
}

function* updateProfileSaga({payload}) {
  const {profile} = payload;
  let password = profile.password;
  try {
    delete profile.password;
    yield put(updateUser(profile));
    yield put(setUser(profile));
    message.success('Update success!');
    try {
      if (password) {
        yield call(updatePasswordAsync, password);
        yield put(logoutUser());
      }
    } catch (error) {
      message.error('Session too old please sign-in again! for change password!');
      console.log(error);
      return false;
    }
    yield put(setIsModalVisible(false));
  } catch (error) {
    yield put(updateUserError(error.message));
  }
}

function* handleVerifyEmail({payload}) {
  const {actionCode} = payload;
  try {
    yield call(handleVerifyEmailAsync, actionCode);
  } catch (error) {
    yield put(registerUserError(error.message));
  }
}

function* resetPassword({payload}) {
  const {email} = payload;
  try {
    yield call(resetPasswordAsync, email.email);
    yield put(verifyEmailSuccess());
  } catch (error) {
    console.log(error);
    yield put(registerUserError(error.message));
    message.warning('This email not found, Please your your email again.');
  }
}
function* setNewPassword({payload}) {
  const {actionCode, newPassword} = payload;
  try {
    yield call(setNewPasswordAsync, actionCode, newPassword);
    yield put(updatePasswordSuccess());
  } catch (error) {
    message.error('You has problem with reset new password expired, or has already been used. ');
    console.log(error);
  }
}

function* userPresenceSaga() {
  try {
    const {userId} = yield select((state) => state.auth);
    yield call(actionUserPresence, userId);
  } catch (error) {
    yield console.log(error.message);
  }
}

export function* watchRegisterUser() {
  yield takeLatest(AUTH_REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
  yield takeLatest(AUTH_LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
  yield takeLatest(AUTH_LOGOUT_USER, logout);
}

export function* watchUpdatePassword() {
  yield takeLatest(AUTH_UPDATE_PASSWORD, doUpdatePassword);
}

export function* watchAuthStateChange() {
  yield takeLatest(AUTH_CHECK_AUTHICATED_USER, checkAuthStateChanged);
}

export function* watchUpdateProfile() {
  yield takeLatest(AUTH_UPDATE_PROFILE, updateProfileSaga);
}

export function* watchhandleVerifyEmail() {
  yield takeLatest(AUTH_VERIFY_EMAIL, handleVerifyEmail);
}

export function* watchResetPassword() {
  yield takeLatest(AUTH_RESET_PASSWORD, resetPassword);
}

export function* watchSetNewPassword() {
  yield takeLatest(AUTH_SET_NEW_PASSWORD, setNewPassword);
}

export function* watchCheckOldPasswordAndUpdatePassword() {
  yield takeLatest(
    AUTH_CHECK_OLD_PASSWORD_AND_UPDATE_PASSWORD,
    checkOldPasswordAndUpdatePasswordSaga
  );
}

export function* watchUserPresence() {
  yield takeLatest(AUTH_SET_USER_PRESENCE, userPresenceSaga);
}

export function* watchGetAdminUser() {
  yield takeLatest(AUTH_GET_USER_ADMIN, getAdminUserSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchUpdatePassword),
    fork(watchAuthStateChange),
    fork(watchUpdateProfile),
    fork(watchhandleVerifyEmail),
    fork(watchResetPassword),
    fork(watchSetNewPassword),
    fork(watchCheckOldPasswordAndUpdatePassword),
    fork(watchUserPresence),
    fork(watchGetAdminUser),
  ]);
}
