// PROFILE
export const PROFILE_SET_REQUEST = 'PROFILE_SET_REQUEST';
export const PROFILE_SET_SUCCESS = 'PROFILE_SET_SUCCESS';
export const PROFILE_SET_ERROR = 'PROFILE_SET_ERROR';
export const PROFILE_SET_PROFILES = 'PROFILE_SET_PROFILES';
export const PROFILE_GET_PROFILES = 'PROFILE_GET_PROFILES';
export const PROFILE_SET_PROFILE = 'PROFILE_SET_PROFILE';
export const PROFILE_SET_ADD_PROFILE = 'PROFILE_SET_ADD_PROFILE';
export const PROFILE_SET_UPDATE_PROFILE = 'PROFILE_SET_UPDATE_PROFILE';
export const PROFILE_SET_DELETE_PROFILE = 'PROFILE_SET_DELETE_PROFILE';
export const PROFILE_GET_PROFILE = 'PROFILE_GET_PROFILE';
export const PROFILE_ADD_PROFILE = 'PROFILE_ADD_PROFILE';
export const PROFILE_DELETE_PROFILE = 'PROFILE_DELETE_PROFILE';
export const PROFILE_UPDATE_PROFILE = 'PROFILE_UPDATE_PROFILE';
export const PROFILE_SET_PROFILE_BY_ID = 'PROFILE_SET_PROFILE_BY_ID';
