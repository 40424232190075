import { SubmissionError } from 'redux-form';
import _ from 'lodash';

/*

ex:

validate = {
  id: required,
  min: true,
  max: false,
  type: email,
  equalTo: 'password',
  existed: true
}

*/

export const isValidEmail = email => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export const isValidYearRange = value => {
  return !/\b(20|[2-9][0-9])\d{2}-(20|[2-9][0-9])\d{2}\b/i.test(value);
};

export const validate = (rules, values) => {
  let errors = {};
  values = values ? values : {};

  for (let field in rules) {
    let rule = rules[field];
    let type = rule['type'];
    let value = '';
    if (!_.isNil(values[field]))
      if (_.isString(values[field])) value = values[field].trim();
      else value = values[field];
    let required = rule['required'] ? true : false;
    let existed = rule['existed'] ? true : false;
    let equalsTo = rule['equalsTo'] ? rule['equalsTo'] : null;
    let min = rule['min'] ? rule['min'] : null;
    let max = rule['max'] ? rule['max'] : null;
    let minNumber = rule['minNumber'] ? rule['minNumber'] : null;
    let maxNumber = rule['maxNumber'] ? rule['maxNumber'] : null;
    let accepts = rule['accepts'] ? rule['accepts'] : [];

    if (required && !value) {
      if (value !== false)
        errors[field] = rule['message'] || 'This field is required';
    }

    if (existed && value) {
      errors[field] = rule['message'] || 'This field value already token';
    }

    if (accepts.length && value) {
      if (!_.includes(accepts, value))
        errors[field] =
          rule['message'] || `This field accept only ${accepts.toString()}`;
    }

    if (max && value) {
      if (value.length > max)
        errors[field] =
          rule['message'] || `This field maximum ${max} character`;
    }

    if (min && value) {
      if (value.length < min)
        errors[field] =
          rule['message'] || `This field is at least ${min} character`;
    }

    if (maxNumber && value) {
      if (value > maxNumber)
        errors[field] =
          rule['message'] || `This field is bigger than ${maxNumber}`;
    }

    if (minNumber && value) {
      if (value < minNumber)
        errors[field] =
          rule['message'] || `This field is smaller than ${minNumber}`;
    }

    if (equalsTo && value) {
      if (values[equalsTo] !== value) {
        const message =
          equalsTo.message || `${field} and ${equalsTo} do not match!`;
        errors[field] = message;
        errors[equalsTo] = message;
      }
    }

    if (required && type === 'email' && value && isValidEmail(value)) {
      errors[field] = rule['message'] || 'Invalid email address';
    }
  }

  return Object.keys(errors).length ? errors : null;
};

export const createSubmitAction = (
  rules,
  action,
  beforeSubmit,
  shouldDispatch = true,
  beforeSubmissionError,
) => (values, dispatch) => {
  let error = null;

  error = validate(rules, values);

  console.log(error);
  if (error != null) {
    if (beforeSubmissionError) {
      beforeSubmissionError(values);
    }
    throw new SubmissionError(error);
  } else {
    let newValues = values;
    if (beforeSubmit) {
      newValues = beforeSubmit(values);
    }
    if (!shouldDispatch) {
      return action(newValues);
    }
    return dispatch(action(newValues));
  }
};

export const checkDuplicate = (objects, values, id, field) => {
  const status = objects.find(object => {
    let check = false;
    if (object[field] === values[field]) {
      check = true;
      if (object.id === id && object[field] === values[field]) check = false;
    }
    return check;
  });
  return status ? true : false;
};

export const checkDuplicateCode = (objects, values, id) => {
  return checkDuplicate(objects, values, id, 'code');
};

export const checkDuplicateStudentCode = (objects, values, id) => {
  return checkDuplicate(objects, values, id, 'studentCode');
};

export const checkDuplicateEmail = (objects, values, id) => {
  return checkDuplicate(objects, values, id, 'email');
};
