export default {
  content: 'Content',
  start_course: 'Start',
  continue_course: 'Continue',
  complete_course: 'Completed',
  enroll_course: 'Enroll',
  about_the_course: 'About the course:',
  course_methodology: 'Course Methodology:',
  watch_the_video_or_click: 'Watch the video or click here to read',
  apply_now: 'Apply Now',
  learn_now: 'Learn Now',
  testimonials: 'Testimonials',
  our_partners: 'Our Partners',
  speakers: 'Speakers',
  subtitle: 'Is the course if for me? How to get best out of the course?',
  session: 'Session',
  welcome_course: 'Welcome to ',
  are_you_ready: 'Are you ready to learn? Click the button below to start learning.',
  start_learning_now: 'Start Learning Now',
  enroll_now: 'Enroll Now',
  already_enroll: 'You\'re enrolled!',
  learn_more: 'Learn More',
  continue: 'Continue',
  finished: 'Finished!',
  start_class: 'START CLASS',
  course: 'Courses',
  master_class: 'Masterclass'
};