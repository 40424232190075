import styled from 'styled-components';
import Slider from 'react-slick';

export const StyleSlider = styled(Slider)`
  .slick-prev::before {
    content: none;
  }
  .slick-prev {
    color: #b3b3b3;
    font-size: 20px !important;
    z-index: 1;
  }
  .slick-next::before {
    content: none;
  }
  .slick-next {
    color: #b3b3b3;
    font-size: 20px !important;
    z-index: 1;
  }
`;
