import {parse} from 'query-string';
import moment from 'moment';
import _ from 'lodash';

import U from 'uuid';
import {
  STORAGE_PREFIX_PATH,
  STORAGE_UPLOAD_ICON_PATH,
  STORAGE_UPLOAD_IMAGE_PATH,
  USER_ROLE_ADMIN,
  USER_ROLE_EDITOR,
  USER_ROLE_CREATOR,
} from '../constants/default-values';
import {IMAGE_DEFAULT} from '../constants/images';
import {FIRSTORE_TIME_MILLI} from './firebase';

export const visibleTab = (function () {
  var stateKey,
    eventKey,
    keys = {
      hidden: 'visibilitychange',
      webkitHidden: 'webkitvisibilitychange',
      mozHidden: 'mozvisibilitychange',
      msHidden: 'msvisibilitychange',
    };
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey];
      break;
    }
  }
  return function (c) {
    if (c) document.addEventListener(eventKey, c);
    return !document[stateKey];
  };
})();

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const getStoragePath = (imagePath) => {
  if (!imagePath) return IMAGE_DEFAULT;
  return STORAGE_PREFIX_PATH.replace('@@@', imagePath.replace('/', '%2F'));
};

export const getStoragePathIcon = (obj) => {
  if (!obj.icon) return IMAGE_DEFAULT;
  if (obj.icon.search(STORAGE_UPLOAD_ICON_PATH) !== -1) return obj.icon;
  return getStoragePath(`${STORAGE_UPLOAD_ICON_PATH}/${obj.icon}.${obj.iconType}`);
};

export const getStoragePathImage = (path) => {
  if (!path) return IMAGE_DEFAULT;
  if (path.search(STORAGE_UPLOAD_IMAGE_PATH) !== -1) return path;
  return getStoragePath(`${STORAGE_UPLOAD_IMAGE_PATH}/${path}`);
};

export const getStoragePathProfile = (path) => {
  if (!path) path = 'avatar1.png';
  return getStoragePath(`profiles/${path}`);
};

export const calculatePercentage = (total, number) => {
  if (!number) return 0;
  if (!total) return 0;
  return Math.ceil((number / total) * 100);
};

export const getLocationPathname = (history, replacePath = '/canvases/') => {
  return history.location.pathname.replace(replacePath, '');
};

export const getLocationParamSearch = (history) => {
  return parse(history.location.search);
};

export const getParamSearch = (search) => {
  return parse(search);
};

export const formatDateFirestore = (objDate, format = 'DD/MM/YYYY') => {
  if (!objDate) return moment().format(format);
  return moment(objDate.seconds * 1000).format(format);
};

export const formatDate = (format = 'DD/MM/YYYY') => {
  return moment().format(format);
};

export const findObject = (objects, id, compareId) => {
  let tmObj = objects.find((obj) => {
    return obj[id] === compareId;
  });

  return tmObj;
};

export const findObjects = (objects, keys = [], compareIds = []) => {
  let tmObj = objects.find((obj) => {
    let bool = false;
    keys.forEach((key, i) => {
      bool = obj[key] === compareIds[i];
    });
    return bool;
  });

  return tmObj;
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ':' + now.getMinutes();
};

export const getCurrentYear = () => {
  const now = new Date();
  return now.getFullYear();
};

export const randomColor = () => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
};

export const sortString = (item1, item2) => {
  if (item1 < item2) return -1;
  if (item1 > item2) return 1;
  return 0;
};

export const generateFileName = (type) => {
  let name = U.v4();
  let nameAndExt = name + type.replace('image/', '.');
  return {
    name,
    nameAndExt,
  };
};

export const removeUndefined = (data = {}) => {
  for (let key in data) {
    if (data[key] === undefined) {
      delete data[key];
    }
  }

  return data;
};

export const replaceUndefined = (data = {}, value = '') => {
  for (let key in data) {
    if (data[key] === undefined) {
      data[key] = value;
    }
    if (data[key]) {
      if (_.isObject(data[key])) {
        for (let key1 in data[key]) {
          if (data[key][key1] === undefined) {
            data[key][key1] = value;
          }
          if (data[key][key1]) {
            if (_.isObject(data[key][key1])) {
              for (let key2 in data[key][key1]) {
                if (data[key][key1][key2] === undefined) {
                  data[key][key1][key2] = value;
                }
              }
            }
          }
        }
      }
    }
  }

  return data;
};

export const rowClick = (index, cls) => {
  try {
    let element = document.querySelectorAll(
      `.ant-modal-wrap .ant-modal-content .${cls} table tbody tr td .ant-checkbox-input,.ant-modal-wrap .ant-modal-content .${cls} table tbody tr td .ant-radio-input`
    );
    element[index].click();
  } catch (err) {
    console.error(JSON.stringify(err));
  }
};

export const getLocalStorage = (key, value = '') => {
  return !localStorage.getItem(key) ? value : localStorage.getItem(key);
};

export const setLocalStorage = (key, value) => {
  value = value ? value : '';
  localStorage.setItem(key, value);
};

export const getSessionStorage = (key, value = '') => {
  return !sessionStorage.getItem(key) ? value : sessionStorage.getItem(key);
};

export const setSessionStorage = (key, value) => {
  value = value ? value : '';
  sessionStorage.setItem(key, value);
};

export const disabledDragDrop = (value, user) => {
  let disabled = true;
  if (value && isAdmin(user)) {
    disabled = false;
  }

  return disabled;
};

export const isAdmin = (user) => {
  return user.roleId === USER_ROLE_ADMIN;
};

export const isEditor = (user) => {
  return user.roleId === USER_ROLE_EDITOR;
};

export const isCreator = (user) => {
  return user.roleId === USER_ROLE_CREATOR;
};

export const isOwner = (user, content) => {
  return user.id === content.createdBy;
};

export const yearGeneratorToCurrentYear = (startYear) => {
  let courrentDate = new Date().getFullYear() - 1;
  const datas = [];
  for (let i = startYear; i <= courrentDate; i++) {
    let tmp = {
      id: i.toString(),
      name: i.toString(),
    };
    datas.push(tmp);
  }
  return datas || [];
};

export const getReferer = (item) => {
  let referer = item.metadata.referer.split('?')[1];
  return getParamSearch(referer);
};

export const getDataOther = (id, datas) => {
  const name = datas.filter((data) => {
    return data.id === id;
  });
  return name;
};

export const sleep = (duration) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(duration);
    }, duration);
  });
};

export const indexToLetter = (index) => {
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

  if (typeof index === 'boolean') {
    return index ? 'Y' : 'N';
  }
  return letters[index] || 'N/A';
};

export const manipulateData = (collectionData, objId) => {
  if (collectionData && objId) {
    const obj = collectionData.find((data) => {
      return data.id === objId;
    });
    const {translate, id} = obj;
    return {translate, id};
  }
};

const compareBetweenDateAddMonth = (
  startDate,
  endDate,
  format = 'DD MMM YYYY',
  isAddMonth = true
) => {
  // const currentDate = moment(moment('28-12-2021', 'DD-MM-YYYY'));
  const currentDate = moment(
    moment(FIRSTORE_TIME_MILLI).format('DD-MM-YYYY'),
    'DD-MM-YYYY'
  ).valueOf();

  if (!startDate || !endDate) return false;

  const start = moment(startDate, format).valueOf();
  // const end = moment(endDate, format).add(3, 'months').valueOf();
  let end = moment(endDate, format).valueOf();

  if (isAddMonth) end = moment(endDate, format).add(3, 'months').valueOf();

  if (currentDate >= start && currentDate <= end) {
    return true;
  }

  return false;
};

export const compareBetweenDate = (
  startDate,
  endDate,
  format = 'DD MMM YYYY',
  isAddMonth = true
) => {
  return compareBetweenDateAddMonth(startDate, endDate, format, isAddMonth);
};

export const compareBetweenDateDefault = (
  startDate,
  endDate,
  format = 'DD MMM YYYY',
  isAddMonth = false
) => {
  return compareBetweenDateAddMonth(startDate, endDate, format, isAddMonth);
};

export const compareDate = (date, compare = 'gt', format = 'DD MMM YYYY') => {
  if (!date) return false;

  // const currentDate = moment(moment('28-12-2021', 'DD-MM-YYYY'));
  const currentDate = moment(moment(FIRSTORE_TIME_MILLI).format('DD-MM-YYYY'), 'DD-MM-YYYY');
  let currentDateValue = currentDate.valueOf();

  const dateValue = moment(date, format).add(3, 'months').valueOf();
  if (compare === 'gt') return currentDateValue > dateValue;
  if (compare === 'lt') return currentDateValue < dateValue;

  return false;
};

// export const compareStartDate = (date, format = "DD MMM YYYY") => {
//   if (!date) return false;

//   // const currentDate = moment(moment('23-09-2021', 'DD-MM-YYYY'));
//   const currentDate = moment(moment(FIRSTORE_TIME_MILLI).format('DD-MM-YYYY'), 'DD-MM-YYYY');
//   let currentDateValue = currentDate.valueOf();

//   const end = moment(date, format).valueOf();
//   if (currentDateValue < end) {
//     return true;
//   }

//   return false;
// }
