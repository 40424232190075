import React from 'react';
import PropTypes from 'prop-types';
import { VideoWrapper } from './styled';

function Slide({ slideUrl }) {
  return (
    <VideoWrapper>
      <iframe
        title="slide"
        src={slideUrl}
        width="100%"
        height="100%"
        scrolling="no"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      ></iframe>
    </VideoWrapper>
  );
}

Slide.propTypes = {
  slideUrl: PropTypes.string,
};

export default Slide;
