import React from 'react';
import PropTypes from 'prop-types';
import {Media} from 'reactstrap';

function CourseMedia({icon, title, description}) {
  return (
    <Media>
      <Media left href="#">
        <Media object src={icon} alt="Placeholder" />
      </Media>
      <Media body>
        <Media heading>{title}</Media>
        {description}
      </Media>
    </Media>
  );
}

CourseMedia.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default CourseMedia;
