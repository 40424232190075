export default {
  // login
  log_in: 'Log In',
  email: 'Email',
  password: 'Password',
  new_password: 'New Password',
  current_password: 'Current Password',
  update_password: 'Update Your Password',
  remembered: 'Remembered',
  forgot_password: 'Forgot your password',
  not_have_account: 'Don\'t have account?',
  register_now: 'Register now',
  // reset email
  reset_password: 'Reset Password By Email',
  reset: 'Reset',
  reset_complete: 'Completed',
  reset_complete_message: 'Check your email and click the link in that email to reset your new password. ',
  // sign up
  sign_up: 'Sign Up',
  account_info: '1 Account info',
  additional_info: '2 Additional info',
  first_name: 'First name',
  last_name: 'Last name',
  phone: 'Phone',
  male: 'Male',
  female: 'Female',
  password_confirmation: 'Password Confirmation',
  email_confirmation: 'Email Confirmation',
  organization: 'Name of School/Organization',
  type_organization: 'Type of organization',
  position: 'Position/Role',
  province: 'City/province',
  gender: 'Gender',
  year_of_birth: 'Year of birth',
  next: 'Next',
  already_have_an_account: 'Already have an account?',
  terms_of_use_and_privacy: ' Terms of Use and Privacy.',
  term_and_privacy_one: 'By signing up I agree to our',
  term_and_privacy_two: 'Policy and I consent to receiving marketing from Gumtree and third party offers.',
  back: 'Back',
  save: 'Save',
  cancel: 'Cancel',
  update_profile: 'Update Your Profile',
  update_avatar: 'Update your profile photo',
  avatar: 'Avatar',
  setting: 'Setting',
  change_password: 'Change Password',
  session_expired: 'Session Expired',
  login_to_your_new_account: 'Login to your new account',
  now_you_can_explore: 'You\'re all set.Now you can explore our available courses online.',
  your_new_password_is_update: 'Your new password is update.',
  log_in_to_start_learning_now: 'Log in to start learning now.',
  registration_completed: 'Registration Completed!',
  look_for_verification_email: 'Look for verification email in your inbox and click the link in the email to confirm. A confirmation message will appear in your web browser.',
  Did_not_get_the_email: 'Did not get the email? Check your spam folder.',
  If_you_do_not_receive_the_email_contact: 'If you do not receive the email, contact ',
  help_edemy_mail: 'help.edemy@gmail.com',
  back_to_home: 'Back to Home',
};
