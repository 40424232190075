import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as typeformEmbed from '@typeform/embed';
import { TypeFormView, VideoWrapper } from './styled';
import { ACTIVITY_QUIZ, ACTIVITY_SURVEY } from '../../../constants/default-values';
import QuizResult from './quiz-result';
import SurveyCompleted from './survey-completed';

function TypeForm({
  typeformId,
  type,
  isRetake,
  handleTypeForm,
  courseId,
  isComplete,
  formResult,
  handleRetakeQuiz,
  activity,
  uniqId,
  userId
}) {
  useEffect(() => {
    const embedElement = document.querySelector('.js-typeform-embed');
    if (embedElement && typeformId) {
      typeformEmbed.makeWidget(
        embedElement,
        `https://form.typeform.com/to/${typeformId}?course_id=${courseId}&user_id=${userId}&key=${uniqId}`,
        {
          opacity: 55,
          buttonText: 'Answer this!',
          hideScrollbars: true,
          onSubmit: function (event) {
            handleTypeForm(event.response_id);
          },
          onReady: function () { },
        }
      );
    }
  }, [typeformId, courseId, handleTypeForm, uniqId, userId]);

  if (type === ACTIVITY_QUIZ && isRetake === false)
    return (
      <QuizResult
        key={activity.id + uniqId}
        dataSources={formResult}
        handleRetakeQuiz={handleRetakeQuiz}
        activity={activity}
      />
    );

  if (isComplete && type === ACTIVITY_SURVEY)
    return <SurveyCompleted key={activity.id + uniqId} {...activity} />;

  return (
    <VideoWrapper>
      <TypeFormView className="js-typeform-embed" />
    </VideoWrapper>
  );
}

TypeForm.propTypes = {
  typeformId: PropTypes.string,
  courseId: PropTypes.string,
  handleTypeForm: PropTypes.func,
  isComplete: PropTypes.bool,
  formResult: PropTypes.array,
  type: PropTypes.string,
  handleRetakeQuiz: PropTypes.func,
  activity: PropTypes.object,
  uId: PropTypes.string,
  isRetake: PropTypes.bool,
  uniqId: PropTypes.string,
  userId: PropTypes.string
};

export default TypeForm;
