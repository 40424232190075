import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Thumbnail, Content} from './styled';
import {PLAY_ICON} from '../../constants/images';
import {getStoragePathImage} from '../../lib';

function CardVideo({dataSource}) {
  const {isComplete, thumbnail, title, description} = dataSource;

  return (
    <Fragment>
      <Thumbnail
        className={isComplete ? 'complete-note' : ''}
        style={{backgroundImage: `url(${getStoragePathImage(thumbnail)})`}}
      >
        <center>
          <img src={PLAY_ICON} alt="thumbnail" />
        </center>
      </Thumbnail>
      <Content style={{width: '73%'}}>
        <h6>{title}</h6>
        <div>{description}</div>
      </Content>
    </Fragment>
  );
}

CardVideo.propTypes = {
  dataSource: PropTypes.object,
};

export default CardVideo;
