import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import {getLocalStorage} from '../../lib';

const PrivateRoute = ({
  component: Component,
  authenticationComponent: AuthenticationComponent,
  ...rest
}) => {
  return (
    <Route
      render={(props) =>
        getLocalStorage('Authorize') ? (
          <Component isAuthorize={true} {...props} />
        ) : (
          <AuthenticationComponent isAuthorize={false} {...props} />
        )
      }
      {...rest}
    />
  );
};

PrivateRoute.propTypes = {
  rest: PropTypes.any,
  component: PropTypes.any,
  authenticationComponent: PropTypes.any,
};

export default PrivateRoute;
