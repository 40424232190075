import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';

import Banner from './banner';
import Slider from './slider';
import MasterCourse from './master-course';
import Main from './main';
import Sponsor from './sponsor';
import Reference from './reference';
import Guided from './guided';
import {selectorCourses} from '../../stores/course/selectors';
import Layout from '../../components/layout';
import useRouter from '../../hook/useRouter';
import {getLocalStorage} from '../../lib';
import {REGISTER} from '../../constants/routes';

const ConvertMasterClass = (dataSource, type) => {
  let data = dataSource.filter((item) => item.type === type);
  return data;
};

const ConvertCourse = (dataSource, type) => {
  let data = dataSource.filter((item) => item.type !== type);
  return data;
};

function Home() {
  const courses = useSelector(selectorCourses);
  const masterClasses = ConvertMasterClass(courses, 'MASTERCLASS');
  const masterCourses = ConvertCourse(courses, 'MASTERCLASS');

  const {history} = useRouter();

  const handleGetStart = useCallback(() => {
    if (!getLocalStorage('Authorize')) history.push(REGISTER);
  }, [history]);

  return (
    <Layout menuSticky={true}>
      <Banner handleGetStart={handleGetStart} />
      <Slider dataSources={masterCourses} />
      <MasterCourse dataSources={masterClasses} />
      <Main />
      <Sponsor />
      <Reference handleGetStart={handleGetStart} />
      <Guided />
    </Layout>
  );
}

export default Home;
