import styled from 'styled-components';

export const ExpendableGroup = styled.div`
    color: #333;
    label {
        font-style: italic;
        color: #777
    }
`;

export const WrapperStyle = styled.div`
    ol,ul {
        list-style-position: inside;
    }
`;