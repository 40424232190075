/* eslint-disable indent */
import {
  COURSE_SET_REQUEST,
  COURSE_SET_SUCCESS,
  COURSE_SET_ERROR,
  COURSE_SET_COURSE,
  COURSE_SET_COURSES,
  COURSE_SET_ACTIVITIES,
  COURSE_SET_MODULES,
  COURSE_SET_ACTIVITY,
  COURSE_SET_MODULE,
  COURSE_SET_COHORTS,
  COURSE_SET_COHORT_USERS,
} from './types';

import _ from 'lodash';

const INIT_STATE = {
  activities: [],
  activity: {},
  courses: [],
  course: {},
  modules: [],
  module: {},
  cohorts: [],
  cohort: {},
  cohortUsers: [],
  cohortUser: {},
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case COURSE_SET_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case COURSE_SET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case COURSE_SET_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case COURSE_SET_COURSES:
      return { ...state, courses: action.payload.courses };
    case COURSE_SET_COURSE:
      return _.cloneDeep({ ...state, course: action.payload.course });
    case COURSE_SET_ACTIVITIES:
      return _.cloneDeep({ ...state, activities: action.payload.activities });
    case COURSE_SET_COHORTS:
      return _.cloneDeep({ ...state, cohorts: action.payload.cohorts });
    case COURSE_SET_COHORT_USERS:
      return _.cloneDeep({ ...state, cohortUsers: action.payload.cohortUsers });
    case COURSE_SET_ACTIVITY:
      return _.cloneDeep({ ...state, activity: action.payload.activity });
    case COURSE_SET_MODULES:
      return _.cloneDeep({ ...state, modules: action.payload.modules });
    case COURSE_SET_MODULE:
      return _.cloneDeep({ ...state, module: action.payload.module });
    default:
      return state;
  }
};
