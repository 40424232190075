import styled from 'styled-components';

export const Title = styled.h4`
    color: #000;
    margin-top: 30px;
    margin-bottom: 15px;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
`;

export const Video = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    height: 300px;
`;

export const TitleMethodology = styled.h4`
    color: #000;
    margin-top: 30px;
    margin-bottom: 5px;
    font-family: 'PoppinsSemiBold', 'NotoSanSemiBold';
`;

export const SubtitleWrapperStyle = styled.p`
    color: #333;
    margin-top: 0px;
`;