//import firebase from "firebase";
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
// import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ReduxSagaFirebase from 'redux-saga-firebase';
import { eventChannel } from 'redux-saga';
import { getLocalStorage } from './utils';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
// firebase.firestore().enablePersistence({experimentalForceOwningTab: true});
export const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
// firebase.firestore().settings({timestampsInSnapshots: true});
export const firestoreRef = firebase.firestore();
export const databaseRef = firebase.database();
export const storageRef = firebase.storage();
export const authRef = firebase.auth();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();

export const FIRSTORE_TIMESTAMP = firebase.firestore.FieldValue.serverTimestamp();
export const DATABASE_TIMESTAMP = firebase.database.ServerValue.TIMESTAMP;
export const FIRSTORE_TIME_MILLI = firebase.firestore.Timestamp.now().toMillis();
export const firebaseApp = firebase;
export const rsf = new ReduxSagaFirebase(firebase);

export const downloadViaUrl = (url) => {
  const storageRef = firebase.storage().ref();
  var starsRef = storageRef.child(url);
  return starsRef.getDownloadURL()
    .then((url) => {
      return url;
    })
    .catch((error) => {
      console.log(error);
      console.log(error.message);
    });
};

// downloadFullExample();

export const generateDocKey = (ref) => {
  const _ref = firestoreRef.collection(ref).doc();
  const newKey = _ref.id;
  return newKey;
};

export const readIds = async (collection, ids) => {
  const reads = ids.map((id) => collection.doc(id).get());
  const result = await Promise.all(reads);
  return result.map((v) => v.data());
};

export const generateCourseDocKey = ({ courseId, sectionId }) => {
  return generateDocKey(`courses/${courseId}/sections/${sectionId}/activities`);
};


export const subscribeEventChannelCollection = (ref) => {
  return eventChannel((emitter) => {
    ref.onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
      if (snapshot.docChanges().length) {
        const results = snapshot.docs.map((doc) => {
          return { ...doc.data() };
        });
        emitter(results);
      }
    });

    return () => ref;
  });
};

export const subscribeEventChannelDoc = (ref) => {
  return eventChannel((emitter) => {
    ref.onSnapshot((snapshot) => {
      if (snapshot.exists) {
        emitter({ ...snapshot.data() });
      } else
        emitter({});
    });

    return () => ref;
  });
};

const conditionAndOrderBy = (ref, conditions, orderBy) => {
  conditions.forEach((item) => {
    const [field, condition, value] = item;
    ref = ref.where(field, condition, value);
  });
  if (orderBy.length) ref = ref.orderBy(orderBy[0], orderBy[1]);

  return ref;
};


export const courseCollectionRef = ({ courseId, sectionId, activityId }) => {
  let ref = firestoreRef.collection('courses').doc(courseId);
  if (sectionId) ref = ref.collection('sections').doc(sectionId);
  if (activityId) ref = ref.collection('activities').doc(activityId);
  return ref;
};

export const setCollectionDoc = async (collectionName, data) => {
  const id = data.id ? data.id : generateDocKey(collectionName);
  await firestoreRef
    .collection(collectionName)
    .doc(id)
    .set(
      {
        ...data,
        id,
        created: FIRSTORE_TIMESTAMP,
        createdBy: getLocalStorage('userId'),
      },
      { merge: true }
    );
  return id;
};

export const setSubCollectionDoc = async (dbPath, data) => {
  const id = data.id ? data.id : generateDocKey(dbPath);
  await firestoreRef.doc(`${dbPath}/${id}`).set({
    ...data,
    id,
    created: FIRSTORE_TIMESTAMP,
    createdBy: getLocalStorage('userId'),
  });
  return id;
};

export const updateCollectionDoc = async (collectionName, data) => {
  return await firestoreRef
    .collection(collectionName)
    .doc(data.id)
    .update({
      ...data,
      updatedBy: getLocalStorage('userId'),
    });
};

export const updateBatchCollectionDoc = async (collectionName, data) => {
  let batch = firestoreRef.batch();
  data.forEach((item) => {
    let { id } = item;
    let ref = firestoreRef.collection(collectionName).doc(id);
    batch.update(ref, { ...item, updatedBy: getLocalStorage('userId') });
  });
  return batch.commit();
};

export const deleteCollectionDoc = async (collectionName, id) =>
  await firestoreRef.collection(collectionName).doc(id).delete();

export const getCollectionDoc = async (collectionName, id) => {
  let ref = firestoreRef.collection(collectionName);
  let doc = await ref.doc(id).get();
  if (!doc.exists) return {};
  return { ...doc.data(), id: doc.id };
};

export const getCollectionDocRealTime = (collectionName, id) => {
  let ref = firestoreRef.collection(collectionName).doc(id);
  return subscribeEventChannelDoc(ref);
};

export const getCollectionDocs = async (docPath) => {
  let ref = firestoreRef.doc(docPath);
  let snapshots = await ref.get();
  return getDocsData(snapshots);
};

export const getCollectionSnapshots = async (collectionName, conditions = [], orderBy = []) => {
  let ref = firestoreRef.collection(collectionName);
  let snapshots = await conditionAndOrderBy(ref, conditions, orderBy).get();
  return getDocsData(snapshots);
};

export const getCollectionSnapshotsRealTime = (collectionName, conditions = [], orderBy = []) => {
  let ref = firestoreRef.collection(collectionName);

  return subscribeEventChannelCollection(conditionAndOrderBy(ref, conditions, orderBy));
};

export const getCollectionGroupSnapshots = async (collectionName, conditions = [], orderBy = []) => {
  let ref = firestoreRef.collectionGroup(collectionName);
  let snapshots = await conditionAndOrderBy(ref, conditions, orderBy).get();
  return getDocsData(snapshots);
};

export const getCollectionGroupSnapshotsRealtime = async (collectionName, conditions = [], orderBy = []) => {
  let ref = firestoreRef.collectionGroup(collectionName);
  return subscribeEventChannelCollection(conditionAndOrderBy(ref, conditions, orderBy));
};

export const getSubCollectionSnapshots = async (data = [], conditions = [], orderBy = []) => {
  let ref = collectionRef(data);
  return await conditionAndOrderBy(ref, conditions, orderBy).get().then((snapshots) => {
    let data = [];

    snapshots.forEach((doc) => {
      data.push(doc.data());
    });

    return data;
  });
};

export const getSubCollectionDoc = async (dbPath) => {
  let doc = await firestoreRef.doc(dbPath).get();
  return doc.data() ? doc.data() : {};
};

export const deleteSubCollection = async (data = []) => {
  let ref = firestoreRef;
  data.forEach((item) => {
    if (item.length === 3) ref = ref.collection(item[0]).doc(item[1]).collection(item[2]);
    else if (item.length === 2) ref = ref.doc(item[1]).collection(item[2]);
  });

  return await ref.get().then((snapshots) => {
    const batch = firestoreRef.batch();
    snapshots.forEach((doc) => {
      batch.delete(ref.doc(doc.id));
    });

    return batch.commit();
  });
};

export const collectionRef = (data = []) => {
  let ref = firestoreRef;
  data.forEach((item) => {
    if (item.length === 2) ref = ref.collection(item[0]).doc(item[1]);
    else if (item.length === 1) ref = ref.collection(item[0]);
  });

  return ref;
};

export const getDocsData = (snapshots) => {
  return snapshots.docs.map((doc) => doc.data());
};