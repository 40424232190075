import {getCollectionSnapshotsRealTime} from '../../lib/firebase';
import {all, call, fork, put, takeLatest, take} from 'redux-saga/effects';
import {GET_SLIDES} from './types';
import {setSlideError, setSlideSuccess, setSlideRequest, setSlides} from './actions';

const getSlidesAsync = async () =>
  getCollectionSnapshotsRealTime('backgroundSlides', [], ['created', 'desc']);

function* getSlidesSaga() {
  try {
    const channel = yield call(getSlidesAsync);
    while (true) {
      yield put(setSlideRequest());
      const slides = yield take(channel);
      const tmpSlides = slides.map((item) => {
        return {id: item.id, url: item.url, device: item.device};
      });

      localStorage.setItem('slides', JSON.stringify(tmpSlides));

      yield put(setSlides(tmpSlides));
      yield put(setSlideSuccess());
    }
  } catch (error) {
    yield put(setSlideError(error.message));
  }
}

export function* watchGetSlides() {
  yield takeLatest(GET_SLIDES, getSlidesSaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetSlides)]);
}
