/** App */
export const APP_LOADING = 'APP_LOADING';
export const APP_FORM_SUBMIT = 'APP_FORM_SUBMIT';
export const APP_GET_VIDEOS = 'APP_GET_VIDEOS';
export const APP_SET_VIDEOS = 'APP_SET_VIDEOS';
export const APP_SET_IS_MODAL_VISIBLE = 'APP_SET_IS_MODAL_VISIBLE';
export const APP_SET_LOADING = 'APP_SET_LOADING';
export const APP_SET_UNIQID = 'APP_SET_UNIQID';



