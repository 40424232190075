import {
  APP_FORM_SUBMIT,
  APP_SET_VIDEOS,
  APP_GET_VIDEOS,
  APP_SET_IS_MODAL_VISIBLE,
  APP_SET_LOADING,
  APP_SET_UNIQID,
} from './types';

export const setIsSubmit = (isSubmit) => ({
  type: APP_FORM_SUBMIT,
  payload: {isSubmit},
});

export const setLoading = (loading) => ({
  type: APP_SET_LOADING,
  payload: {loading},
});

export const setUniqId = (uniqId) => ({
  type: APP_SET_UNIQID,
  payload: {uniqId},
});

export const setIsModalVisible = (isModalVisible) => ({
  type: APP_SET_IS_MODAL_VISIBLE,
  payload: {isModalVisible},
});

export const setVideos = (videos) => ({
  type: APP_SET_VIDEOS,
  payload: {videos},
});

export const getVideos = () => ({
  type: APP_GET_VIDEOS,
});
