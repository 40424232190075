import {
  AUTH_LOGIN_USER,
  AUTH_LOGIN_USER_SUCCESS,
  AUTH_LOGOUT_USER,
  AUTH_REGISTER_USER,
  AUTH_REGISTER_USER_SUCCESS,
  AUTH_LOGIN_USER_ERROR,
  AUTH_REGISTER_USER_ERROR,
  AUTH_UPDATE_PASSWORD,
  AUTH_UPDATE_USER_ERROR,
  AUTH_UPDATE_USER_SUCCESS,
  AUTH_CHECK_AUTHICATED_USER,
  AUTH_UPDATE_PROFILE,
  AUTH_SET_EMAIL,
  AUTH_SET_PASSWORD,
  AUTH_VERIFY_EMAIL,
  AUTH_RESET_PASSWORD,
  AUTH_SET_NEW_PASSWORD,
  AUTH_VERIFY_EMAIL_SUCCESS,
  AUTH_UPDATE_PASSWORD_SUCCESS,
  AUTH_CHECK_OLD_PASSWORD_AND_UPDATE_PASSWORD,
  AUTH_SET_USER_PRESENCE,
  AUTH_GET_USER_ADMIN,
  AUTH_SET_IS_ADMIN,
} from './types';



export const getUserAdmin = (userId) => ({
  type: AUTH_GET_USER_ADMIN,
  payload: { userId },
});

export const setIsAdmin = (isAdmin) => ({
  type: AUTH_SET_IS_ADMIN,
  payload: { isAdmin },
});

export const loginUser = (user, history) => ({
  type: AUTH_LOGIN_USER,
  payload: { user, history },
});

export const userPresence = () => ({
  type: AUTH_SET_USER_PRESENCE,
});

export const loginUserSuccess = (userId) => ({
  type: AUTH_LOGIN_USER_SUCCESS,
  payload: { userId },
});

export const updatePassword = (password) => ({
  type: AUTH_UPDATE_PASSWORD,
  payload: { password },
});

export const checkOldPasswordAndUpdatePassword = (oldPassword, newPassword) => ({
  type: AUTH_CHECK_OLD_PASSWORD_AND_UPDATE_PASSWORD,
  payload: { oldPassword, newPassword },
});

export const updateUserError = (message) => ({
  type: AUTH_UPDATE_USER_ERROR,
  payload: { message },
});

export const updateUserSuccess = () => ({
  type: AUTH_UPDATE_USER_SUCCESS,
});

export const loginUserError = (message) => ({
  type: AUTH_LOGIN_USER_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: AUTH_REGISTER_USER,
  payload: { user, history },
});

export const registerUserSuccess = () => ({
  type: AUTH_REGISTER_USER_SUCCESS,
});

export const registerUserError = (message) => ({
  type: AUTH_REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = (isRedirect = true) => ({
  type: AUTH_LOGOUT_USER,
  payload: { isRedirect },
});

export const checkAuthUser = () => ({
  type: AUTH_CHECK_AUTHICATED_USER,
});

export const updateProfile = (profile) => ({
  type: AUTH_UPDATE_PROFILE,
  payload: { profile },
});

export const setEmail = (email) => ({
  type: AUTH_SET_EMAIL,
  payload: { email },
});

export const setPassword = (password) => ({
  type: AUTH_SET_PASSWORD,
  payload: { password },
});

export const verifyEmail = (actionCode) => ({
  type: AUTH_VERIFY_EMAIL,
  payload: { actionCode },
});

export const resetPassword = (email) => ({
  type: AUTH_RESET_PASSWORD,
  payload: { email },
});

export const setNewPassword = (actionCode, newPassword) => ({
  type: AUTH_SET_NEW_PASSWORD,
  payload: { actionCode, newPassword },
});

export const verifyEmailSuccess = () => ({
  type: AUTH_VERIFY_EMAIL_SUCCESS,
});

export const updatePasswordSuccess = () => ({
  type: AUTH_UPDATE_PASSWORD_SUCCESS,
});
