import { useEffect, useState, useCallback } from 'react';

const responsiveSize = size => {
  let width = 20;
  if (size < 480) {
    width = 100;
  } else if (size < 750) {
    width = 50;
  } else if (size < 970) {
    width = 33;
  } else if (size < 1024) {
    width = 25;
  }
  return width;
};

function useWindowSize() {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => {
    const width = isClient ? window.innerWidth : undefined;
    return {
      width: width,
      height: isClient ? window.innerHeight : undefined,
      size: responsiveSize(width),
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default useWindowSize;
