import styled from "styled-components";

export const FullWidthButton = styled.div`
  margin-bottom: 20px;

  button#expendable {
    width: 100%;
    text-transform: uppercase;
    background-color: #f8f8f8;
    border: 1px solid #e2e2e2;
    border-radius: 0;
    font-size: 18px;
    color: #666;
    font-family: "PoppinsSemiBold", "NotoSanSemiBold";
    margin-bottom: 0px;
  }

  div {
    margin-top: 0px;
    color: #333;
  }

  div {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      position: relative;
      width: 280px;
      height: 45px;
      font-family: "PoppinsSemiBold", "NotoSanSemiBold";
      font-size: 20px;
      background-color: #000;
      color: white;
      border-width: 0px;
      border-radius: 0px;

      img {
        position: absolute;
        width: 30px;
        right: 50px;
        height: auto;
      }
    }
  }

  ol,
  ul {
    list-style-position: inside;
  }
`;
