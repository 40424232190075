import React, {Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import PropTypes from 'prop-types';
import CourseMedia from '../course-media';
import CourseSpeaker from '../course-speaker';

function CourseWidget({course}) {
  return (
    <Fragment>
      <Row>
        <Col>
          <CourseMedia {...course} />
        </Col>
      </Row>
      <Row>
        <Col>
          <CourseSpeaker {...course} />
        </Col>
      </Row>
    </Fragment>
  );
}

CourseWidget.propTypes = {
  course: PropTypes.object,
};

export default CourseWidget;
