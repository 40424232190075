import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Row, Col, Button} from 'reactstrap';
import LayoutUserSetting from '../../../components/user-setting-layout';
import {WrapInput} from './styled';
import InputRedux from '../../../components/redux-form/input';
import FormWrapper from '../../../components/redux-form/form';
import {createSubmitAction, translate} from '../../../lib';
import {checkOldPasswordAndUpdatePassword} from '../../../stores/root-action';
import IntlMessages from '../../../lib/IntlMessages';

function AccountSetting() {
  const dispatch = useDispatch();

  const handleSuccess = useCallback(
    async (values) => {
      dispatch(checkOldPasswordAndUpdatePassword(values.oldPassword, values.newPassword));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    (values, dispatch) => {
      const validationRule = {
        oldPassword: {
          required: true,
          min: 6,
          max: 18,
        },
        newPassword: {
          required: true,
          equalsTo: 'newConfirmPassword',
          min: 6,
          max: 18,
        },
        newConfirmPassword: {
          required: true,
          min: 6,
          max: 18,
        },
      };

      return createSubmitAction(validationRule, handleSuccess, null, false)(values, dispatch);
    },
    [handleSuccess]
  );

  return (
    <LayoutUserSetting title={translate('account_setting')}>
      <FormWrapper form="formUpdatePassword" onSubmit={onSubmit}>
        <Row>
          <Col md="6">
            <WrapInput>
              <InputRedux
                name="oldPassword"
                type="Password"
                placeholder={translate('current_password')}
              />
              <br />
              <InputRedux
                name="newPassword"
                type="Password"
                placeholder={translate('new_password')}
              />
              <br />
              <InputRedux
                name="newConfirmPassword"
                type="Password"
                placeholder={translate('password_confirmation')}
              />
              <br />
              <Button color="info" size="sm">
                <IntlMessages id="update_password" />
              </Button>
            </WrapInput>
          </Col>
        </Row>
      </FormWrapper>
    </LayoutUserSetting>
  );
}

export default AccountSetting;
