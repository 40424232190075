import {
  USER_GET_USERS,
  USER_SET_USERS,
  USER_SET_SUCCESS,
  USER_SET_REQUEST,
  USER_SET_ERROR,
  USER_ADD_USER,
  USER_SET_USER,
  USER_GET_USER,
  USER_UPDATE_USER,
  USER_SET_UPDATE_USER,
  USER_SET_ADD_USER,
  USER_SET_USER_COURSES,
  USER_SET_USER_COURSE,
  USER_GET_USER_COURSES,
  USER_GET_USER_COURSE,
  USER_SET_USER_MODULES,
  USER_SET_USER_ACTIVITIES,
  USER_GET_USER_MODULES,
  USER_GET_USER_COURSE_HISTORIES,
  USER_ADD_USER_COURSE,
  USER_ADD_USER_MODULE,
  USER_ADD_USER_ACTIVITY,
  USER_GET_USER_ACTIVITIES,
  USER_GET_FORM_RESULT,
  USER_SET_FORM_RESULT,
  USER_SET_FORM_DATA,
  USER_GET_FORM_DATA,
  USER_SET_USER_PAYMENT,
  USER_GET_USER_PAYMENT,
} from './types';

export const setUsers = (users) => ({
  type: USER_SET_USERS,
  payload: {users},
});

export const setUserModules = (userModules) => ({
  type: USER_SET_USER_MODULES,
  payload: {userModules},
});

export const setUserActivities = (userActivities) => ({
  type: USER_SET_USER_ACTIVITIES,
  payload: {userActivities},
});

export const setUser = (user) => ({
  type: USER_SET_USER,
  payload: {user},
});

export const setUserCourses = (userCourses) => ({
  type: USER_SET_USER_COURSES,
  payload: {userCourses},
});

export const setAddUser = (user) => ({
  type: USER_SET_ADD_USER,
  payload: {...user},
});

export const setUpdateUser = (user) => ({
  type: USER_SET_UPDATE_USER,
  payload: {...user},
});

export const addUser = (user) => ({
  type: USER_ADD_USER,
  payload: {user},
});

export const addUserCourse = (option = {}) => ({
  type: USER_ADD_USER_COURSE,
  payload: {option},
});

export const addUserModule = (data) => ({
  type: USER_ADD_USER_MODULE,
  payload: {data},
});

export const addUserActivity = (option) => ({
  type: USER_ADD_USER_ACTIVITY,
  payload: {option},
});

export const getFormResult = (formId, responseId = '') => ({
  type: USER_GET_FORM_RESULT,
  payload: {formId, responseId},
});

export const getFormData = (formId) => ({
  type: USER_GET_FORM_DATA,
  payload: {formId},
});

export const setFormData = (formData) => ({
  type: USER_SET_FORM_DATA,
  payload: {formData},
});

export const setFormResult = (formResult) => ({
  type: USER_SET_FORM_RESULT,
  payload: {formResult},
});

export const getUsers = () => ({
  type: USER_GET_USERS,
});

export const getUserModules = (userId, courseId) => ({
  type: USER_GET_USER_MODULES,
  payload: {userId, courseId},
});

export const getUserActivities = (userId, courseId) => ({
  type: USER_GET_USER_ACTIVITIES,
  payload: {userId, courseId},
});

export const getUserCourseHistories = () => ({
  type: USER_GET_USER_COURSE_HISTORIES,
});

export const getUser = (id) => ({
  type: USER_GET_USER,
  payload: {id},
});

export const getUserCourses = () => ({
  type: USER_GET_USER_COURSES,
});

export const getUserCourse = (userId, courseId) => ({
  type: USER_GET_USER_COURSE,
  payload: {userId, courseId},
});

export const getUserPayment = (userId, courseId, cohortId = null) => ({
  type: USER_GET_USER_PAYMENT,
  payload: {userId, courseId, cohortId},
});

export const updateUser = (user) => ({
  type: USER_UPDATE_USER,
  payload: {user},
});

export const setUserCourse = (userCourse) => ({
  type: USER_SET_USER_COURSE,
  payload: {userCourse},
});

export const setUserPayment = (data) => ({
  type: USER_SET_USER_PAYMENT,
  payload: data,
});

export const setUsersSuccess = () => ({
  type: USER_SET_SUCCESS,
});

export const setUsersRequest = () => ({
  type: USER_SET_REQUEST,
});

export const setUsersError = (message) => ({
  type: USER_SET_ERROR,
  payload: {message},
});
