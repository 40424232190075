import styled from 'styled-components';

export const ContainerButton = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 50px;

    button {
        background-color: #000;
        width: 230px;
        font-size:18px;
        border: 0px;
        letter-spacing: 2px;
        border-radius: 0px;
    }
`;