export default {
  contact_sub_title_contact_us: 'សូមស្វាគមន៌មកកាន់គេហទំព័ររៀនអនឡាញរបស់ Impact Hub Phnom Penh!',
  contact_body_description_contact_us: 'ពួកយើងនឹងនៅទីនេះដើម្បីជួយអ្នកក្នុងការរៀនអនឡាញតាំងពីដើមរហូតដល់ចប់ ហើយអ្នកអាចទំនាក់ទំនងមកពួកយើងបានគ្រប់ពេលវេលា។',
  contact_ask_us_questions_supports: 'មានសំណួរអ្វីចង់សួរអាចសួរបាន៖ ',
  contact_ask_us_questions_supports_des: 'អ្នកអាចទាក់ទងមកពួកយើងបានគ្រប់ពេលវេលាបើសិនជាជួបបញ្ហាអ្វីមួយកំឡុងពេលរៀន។ មុននឹងអ្នកទំនាក់ទំនងមកពួកយើង សូមចូលទីនេះសិនដើម្បីចាប់ផ្តើម ហើយអ្នកអាចទាក់ទងមកពួកយើងពេលដែលអ្នកមិនសូវយល់ពីមេរៀនឬត្រូវការការពន្យល់បន្ថែម។ កុំបារម្ភអី ពួកយើងនឹងចាំជួយអ្នកជានិច្ច៕',
  contact_report_a_bug: 'រាយការណ៌បញ្ហាផ្សេងៗ​៖ ',
  contact_report_a_bug_des: 'ពួកយើងចង់បង្កើតកន្លែងរៀនអនឡាញដ៏ល្អមួយសម្រាប់អ្នក ដូច្នេះហើយបើសិនជាអ្នកជួបប្រទះបញ្ហាណាមួយពេលកំពុងរៀន អ្នកអាចរាយការណ៌មកពួកយើងភ្លាមៗ។ ក្រុមការងាររបស់ពួកយើងនឹងធ្វើការកែតម្រូវអោយបានលឿនជាទីបំផុត៕',
  contact_request_a_new_topic: 'ស្នើសុំប្រធានបទផ្សេងៗ៖ ',
  contact_request_a_new_topic_des: 'បើសិនជាអ្នកទាំងអស់គ្នាមានប្រធានបទ ឬក៏មេរៀនផ្សេងៗទៀតដែលចង់រៀន អ្នកអាចប្រាប់ពួកយើងបាន ហើយពួកយើងនឹងព្យាយាមរៀបចំនិងបង្កើតមេរៀនដែលអាចជួយអោយអ្នកអភិវឌ្ឍខ្លួនឯងបន្ថែមទៀត៕',
  contact_insert_the_course_to_the_company: 'ពង្រឹងសម្ថភាពក្រុមការងាររបស់អ្នក៖ ',
  contact_insert_the_course_to_the_company_des: '​បើសិនជាអ្នកចង់ពង្រឹងសម្ថភាពក្រុមការងាររបស់អ្នកដោយប្រើប្រាស់មេរៀនរបស់ពួកយើង ពួកយើងរីករាយក្នុងការជួយដោយផ្ទាល់ទៅដល់ក្រុមការងាររបស់អ្នក។',
  contact_partner_with_us: 'ចូលរួមធ្វើជាដៃគូរបស់ពួកយើង៖ ',
  contact_partner_with_us_des: 'ពួកយើងរីករាយក្នុងការសហការជាមួយអ្នក មិនថាមកពីវិស័យសាធារណៈឬក៏វិស័យឯកជននោះទេ ក្នុងការផ្តល់ជូនជំនាញទន់ផ្សេងៗ និងផ្នត់គំនិតសហគ្រិនភាពទៅដល់យុវជនទូទាំងប្រទេស។',
  contact_others: 'ផ្សេងៗទៀត',
}; 
