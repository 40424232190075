import React from 'react';
import {Media} from 'reactstrap';
import PropTypes from 'prop-types';
import {MediaGroup} from './styled';

function TestimonialItem({testimonialPhoto, testimonialDescription}) {
  return (
    <MediaGroup>
      <Media className="media-item">
        <Media className="media-image">
          <Media object src={testimonialPhoto} alt="Placeholder" className="rounded-circle" />
        </Media>
        <Media
          body
          className="media-text"
          dangerouslySetInnerHTML={{__html: testimonialDescription}}
        />
      </Media>
    </MediaGroup>
  );
}

TestimonialItem.propTypes = {
  testimonialDescription: PropTypes.string,
  handleEnrollCourse: PropTypes.string,
  testimonialPhoto: PropTypes.string,
};

export default TestimonialItem;
