import {createSelector} from 'reselect';
import _ from 'lodash';
// import moment from 'moment';
import {getStoragePathImage, formatDateFirestore, compareBetweenDateDefault} from '../../lib';
import {
  selectorUserModules,
  selectorUserActivities,
  selectorUserCourse,
  selectorUserPayment,
} from '../user/selectors';
import {COURSE_TYPE_COHORT, PaymentStatus} from '../../constants/default-values';

const selectCourse = (state) => state.course;
const course = (state) => state.course.course;
const userId = (state) => state.auth.userId;
const cohorts = (state) => state.course.cohorts;
const cohortUsers = (state) => state.course.cohortUsers;
const locale = (state) => state.locale.locale;

export const selectorCourses = createSelector([selectCourse, locale], (course, locale) =>
  _.filter(course.courses, (course) => {
    course.title = course.translate[locale].title;
    course.tag = course.translate[locale].tag;
    course.type = course.type || 'REGULAR';
    course.description = course.translate[locale].description;
    course.image = getStoragePathImage(course.image);
    course.speaker =
      course.speakers && course.speakers.length && course.speakers[0].translate[locale].name;
    course.speakerData = course.speakers.map((item) => {
      return {
        photo: getStoragePathImage(item.photo),
        name: item.translate[locale].name,
      };
    })[0];
    course.elements = course.elements.map((item) => {
      return {
        ...item,
        icon: getStoragePathImage(item.icon),
        title: item.translate[locale].title,
      };
    });
    return course;
  })
);

export const selectorModules = createSelector(
  selectCourse,
  selectorUserCourse,
  selectorUserModules,
  locale,
  (course, userCourse, userModules, locale) => {
    let results = _.filter(course.modules, (module) => {
      module.title = module.translate[locale].title;
      module.description = module.translate[locale].description;
      module.isComplete = userModules[module.id] ? userModules[module.id].isComplete : false;
      module.isActive = userCourse.id && module.id === userCourse.lastActiveModuleId;
      return module;
    });

    return results;
  }
);

export const selectorCohortUserInMasterClass = createSelector(
  cohortUsers,
  userId,
  course,
  (cohortUsers, userId, course) => {
    if (!userId || !course.id) return false;

    let found = cohortUsers.find((cu) => {
      return userId === cu.userId && cu.courseId === course.id;
    });
    return found ? true : false;
  }
);

export const selectorCohorts = createSelector(
  cohorts,
  cohortUsers,
  userId,
  (cohorts, cohortUsers, userId) => {
    let results = _.map(cohorts, (item, i) => {
      let found = cohortUsers.find((cu) => {
        return userId === cu.userId && item.id === cu.cohortId;
      });
      item.isRegistered = found ? true : false;
      item.title = `Cohort ${i + 1}`;
      item.startDateFormat = formatDateFirestore(item.startDate, 'DD MMM YYYY');
      item.endDateFormat = formatDateFirestore(item.endDate, 'DD MMM YYYY');
      return item;
    });

    return results;
  }
);

// export const selectorCohortUsers = createSelector(
//   cohortUsers,
//   userId,
//   (cohortUsers, userId) => {
//     let results = _.map(cohortUsers, (item, i) => {
//       return item;
//     });

//     return results;
//   }
// );

export const selectorActivity = createSelector(selectCourse, (course) => course.activity);
export const selectorModule = createSelector(selectCourse, (course) => course.module);

export const selectorActivities = createSelector(
  selectCourse,
  selectorUserCourse,
  selectorUserActivities,
  locale,
  (course, userCourse, userActivities, locale) => {
    return course.activities.reduce((acc, cur) => {
      cur.isRetake = true;
      cur.isComplete = false;
      cur.responseId = '';
      cur.userActivityId = '';
      cur.updated = '';
      cur.score = 0;
      cur.percentage = 0;
      cur.totalQuestion = 0;
      cur.isCompleteAlready = false;

      let mixId = `${cur.moduleId}_${cur.id}`;
      if (userActivities[mixId]) {
        cur.isRetake = userActivities[mixId].isRetake;
        cur.isComplete = userActivities[mixId].isComplete;
        cur.responseId = userActivities[mixId].responseId;
        cur.userActivityId = userActivities[mixId].id;
        cur.updated = userActivities[mixId].updated;
        cur.score = userActivities[mixId].score;
        cur.percentage = userActivities[mixId].percentage;
        cur.totalQuestion = userActivities[mixId].totalQuestion;
        cur.isCompleteAlready = userActivities[mixId].isComplete;
      }

      cur.worksheet = cur.translate[locale].worksheet;
      cur.thumbnail = cur.translate[locale].thumbnail;
      cur.description = cur.translate[locale].description;
      cur.about = cur.translate[locale].about;
      cur.resource = cur.translate[locale].resource;
      cur.title = cur.translate[locale].title;
      cur.video = cur.translate[locale].video;
      cur.slideUrl = cur.translate[locale].slideUrl;
      cur.typeformId = cur.translate[locale].typeformId;
      cur.isActive = userCourse.id && cur.id === userCourse.lastActiveActivityId;

      if (acc[cur['moduleId']]) acc[cur['moduleId']] = [...acc[cur['moduleId']], cur];
      else acc[cur['moduleId']] = [cur];
      return acc;
    }, {});
  }
);

export const selectorModulesActivities = createSelector(
  selectorModules,
  selectorActivities,
  (modules, activities) =>
    _.map(modules, (module) => {
      module.activities = activities[module.id] ? activities[module.id] : [];
      return module;
    })
);

export const selectorCourse = createSelector(
  [selectCourse, selectorUserCourse, selectorUserPayment, selectorCohorts, locale],
  (selectCourse, userCourse, userPayment, cohorts, locale) => {
    const {course} = selectCourse;
    if (!course.id) return course;
    const speakers = course.speakers.map((item) => {
      return {
        ...item,
        photo: getStoragePathImage(item.photo),
        name: item.translate[locale].name,
        position: item.translate[locale].position,
        description: item.translate[locale].description,
      };
    });
    const widgets = course.widgets.map((item) => {
      return {
        ...item,
        icon: getStoragePathImage(item.icon),
        title: item.translate[locale].title,
        description: item.translate[locale].description,
      };
    });
    let certificate = {};
    if (course.certificate) {
      const certTran = course.certificate.translate[locale];
      certificate = {
        ...course.certificate,
        aboutDescription: certTran.aboutDescription,
        aboutTitle: certTran.aboutTitle,
        description: certTran.description,
        title: certTran.title,
      };
    }

    let isComplete = false;
    let lastActiveActivityId = '';
    let lastActiveModuleId = '';
    let isEnrolled = false;
    let isCertificate = false;

    let isUserPaid = false;

    if (userCourse.id) {
      isComplete = userCourse.isComplete;
      lastActiveActivityId = userCourse.lastActiveActivityId;
      lastActiveModuleId = userCourse.lastActiveModuleId;
      isEnrolled = true;
      isCertificate = userCourse.isCertificate;
    }

    if (userPayment?.status === PaymentStatus.VERIFIED) {
      isUserPaid = true;
    }

    if (course.type === COURSE_TYPE_COHORT) {
      const found = cohorts.find((item) => {
        // return (moment().isAfter(moment(formatDateFirestore(item.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'), 'day') &&
        //   moment().isBefore(moment(formatDateFirestore(item.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'), 'day'));

        return compareBetweenDateDefault(item.startDateFormat, item.endDateFormat);
      });

      course.startDateFormat = '';
      course.endDateFormat = '';
      course.cohortId = '';
      course.isRegistered = false;
      course.privacy = '';
      if (found) {
        course.startDateFormat = found.startDateFormat;
        course.endDateFormat = found.endDateFormat;
        course.cohortId = found.id;
        course.privacy = found.privacy;
        course.isRegistered = found.isRegistered;
      }
    }

    return {
      ...course,
      title: course.translate[locale].title,
      about: course.translate[locale].about,
      description: course.translate[locale].description,
      label: course.translate[locale].label,
      tag: course.translate[locale].tag,
      type: course.type || 'REGULAR',
      introVideo: course.introVideo,
      methodVideo: course.methodVideo,
      transcript: course.translate[locale].transcript,
      testimonialDescription: course.testimonial.translate[locale].description,
      testimonialPhoto: getStoragePathImage(course.testimonial.photo),
      speakers,
      widgets,
      isComplete,
      isUserPaid,
      lastActiveActivityId,
      lastActiveModuleId,
      isEnrolled,
      isCertificate,
      certificate,
      // startDateFormat: formatDateFirestore(course.startDate, 'DD MMM YYYY'),
      // endDateFormat: formatDateFirestore(course.endDate, 'DD MMM YYYY'),
      // price: course.price ? `$${course.price}` : 'Free',
      price: course.price,
    };
  }
);
