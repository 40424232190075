import {
  firestoreRef,
  subscribeEventChannelCollection,
  subscribeEventChannelDoc,
  setCollectionDoc,
} from '../../lib/firebase';

import {all, call, fork, put, takeLatest, take} from 'redux-saga/effects';

import {
  COURSE_GET_COURSE,
  COURSE_GET_COURSES,
  COURSE_GET_MODULES,
  COURSE_GET_ACTIVITIES,
  COURSE_GET_ACTIVITY,
  COURSE_GET_COHORTS,
  COURSE_GET_COHORT_USERS,
  COURSE_ADD_COHORT_USER,
} from './types';
import {
  setCourseError,
  setCourseSuccess,
  setCourse,
  setCourseRequest,
  setCourses,
  setModules,
  setActivities,
  setActivity,
  setCohorts,
  setCohortUsers,
} from './actions';

const getCoursesAsync = async (isAdmin) => {
  let ref = firestoreRef.collection('courses');
  if (!isAdmin) ref = ref.where('status', '==', 1);
  ref = await ref.orderBy('rank', 'asc');
  return subscribeEventChannelCollection(ref);
};

const getModulesAsync = async (courseId) => {
  if (courseId) {
    let ref = firestoreRef.collection(`courses/${courseId}/modules`).orderBy('rank', 'asc');
    return subscribeEventChannelCollection(ref);
  }

  let ref = firestoreRef.collectionGroup('modules').orderBy('rank', 'asc');
  return subscribeEventChannelCollection(ref);
};

// const getModulesAsync = async (courseId) => {
//   if (courseId)
//     return await getCollectionSnapshots(`courses/${courseId}/modules`, [], ['rank', 'asc']);

//   return await getCollectionGroupSnapshots('modules', [], ['rank', 'asc']);
// };

const getActivitiesAsync = async (courseId) => {
  let ref = firestoreRef.collectionGroup('activities');
  if (courseId) ref = ref.where('courseId', '==', courseId);

  ref = ref.orderBy('rank', 'asc');
  return subscribeEventChannelCollection(ref);
};

const getCohortsAsync = async (courseId, userId) => {
  let ref = firestoreRef.collectionGroup('cohorts');
  if (courseId) ref = ref.where('courseId', '==', courseId);
  if (userId) ref = ref.where('userId', '==', userId);

  ref = ref.orderBy('created', 'asc');
  return subscribeEventChannelCollection(ref);
};

const getCohortUsersAsync = async (courseId) => {
  let ref = firestoreRef.collectionGroup('cohortUsers');
  if (courseId) ref = ref.where('courseId', '==', courseId);

  // ref = ref.orderBy('rank', 'asc');
  return subscribeEventChannelCollection(ref);
};

const addCohortUserAsync = async (userId, courseId, cohortId) => {
  let cohortUser = await firestoreRef
    .collection(`courses/${courseId}/cohorts/${cohortId}/cohortUsers`)
    .where('userId', '==', userId)
    .get();

  if (cohortUser.docs.length) return false;

  return await setCollectionDoc(`courses/${courseId}/cohorts/${cohortId}/cohortUsers`, {
    userId,
    courseId,
    cohortId,
  });
};

const getCourseAsync = async (id) => {
  let ref = firestoreRef.doc(`courses/${id}`);
  return subscribeEventChannelDoc(ref);
};

const getActivityAsync = async (courseId, moduleId, activityId) => {
  let ref = firestoreRef.collection(
    `courses/${courseId}/modules/${moduleId}/activities/${activityId}`
  );
  return subscribeEventChannelDoc(ref);
};

function* getCoursesSaga({payload}) {
  const {isAdmin} = payload;
  const channel = yield call(getCoursesAsync, isAdmin);
  try {
    while (true) {
      yield put(setCourseRequest());
      const courses = yield take(channel);

      yield put(setCourses(courses));
      yield put(setCourseSuccess());
    }
  } catch (error) {
    yield put(setCourseError(error.message));
  }
}

function* getCourseSaga({payload}) {
  try {
    const {id, isAdmin} = payload;
    const channel = yield call(getCourseAsync, id, isAdmin);
    while (true) {
      yield put(setCourseRequest());
      const course = yield take(channel);
      yield put(setCourse(course));
      yield put(setCourseSuccess());
    }
  } catch (error) {
    yield put(setCourseError(error.message));
    console.log(error);
  }
}

function* getModulesSaga({payload}) {
  try {
    const {courseId} = payload;
    const channel = yield call(getModulesAsync, courseId);
    while (true) {
      yield put(setCourseRequest());
      const modules = yield take(channel);
      yield put(setModules(modules));
      yield put(setCourseSuccess());
    }
  } catch (error) {
    yield put(setCourseError(error.message));
    console.log(error);
  }
}

function* getActivitiesSaga({payload}) {
  try {
    const {courseId} = payload;
    const channel = yield call(getActivitiesAsync, courseId);
    while (true) {
      yield put(setCourseRequest());
      const activities = yield take(channel);
      yield put(setActivities(activities));
      yield put(setCourseSuccess());
    }
  } catch (error) {
    yield put(setCourseError(error.message));
    console.log(error);
  }
}

function* getCohortsSaga({payload}) {
  try {
    const {courseId} = payload;
    const channel = yield call(getCohortsAsync, courseId);
    while (true) {
      yield put(setCourseRequest());
      const cohorts = yield take(channel);
      yield put(setCohorts(cohorts));
      yield put(setCourseSuccess());
    }
  } catch (error) {
    yield put(setCourseError(error.message));
    console.log(error);
  }
}

function* getCohortUsersSaga({payload}) {
  try {
    const {courseId, userId} = payload;
    const channel = yield call(getCohortUsersAsync, courseId, userId);
    while (true) {
      yield put(setCourseRequest());
      const cohortUsers = yield take(channel);
      yield put(setCohortUsers(cohortUsers));
      yield put(setCourseSuccess());
    }
  } catch (error) {
    yield put(setCourseError(error.message));
    console.log(error);
  }
}

function* getActivitySaga({payload}) {
  try {
    const {courseId, moduleId, activityId} = payload;
    const channel = yield call(getActivityAsync, courseId, moduleId, activityId);
    while (true) {
      yield put(setCourseRequest());
      const activity = yield take(channel);
      yield put(setActivity(activity));
      yield put(setCourseSuccess());
    }
  } catch (error) {
    yield put(setCourseError(error.message));
  }
}

function* addCohortUserSaga({payload}) {
  try {
    const {userId, courseId, cohortId} = payload;
    yield put(setCourseRequest());
    yield call(addCohortUserAsync, userId, courseId, cohortId);
    yield put(setCourseSuccess());
  } catch (error) {
    console.log(error.message);
    yield put(setCourseError(error.message));
  }
}

function* watchGetCourses() {
  yield takeLatest(COURSE_GET_COURSES, getCoursesSaga);
}

function* watchGetCourse() {
  yield takeLatest(COURSE_GET_COURSE, getCourseSaga);
}

function* watchGetModules() {
  yield takeLatest(COURSE_GET_MODULES, getModulesSaga);
}

function* watchGetActivities() {
  yield takeLatest(COURSE_GET_ACTIVITIES, getActivitiesSaga);
}

function* watchGetCohorts() {
  yield takeLatest(COURSE_GET_COHORTS, getCohortsSaga);
}

function* watchGetCohortUsers() {
  yield takeLatest(COURSE_GET_COHORT_USERS, getCohortUsersSaga);
}

function* watchGetActivity() {
  yield takeLatest(COURSE_GET_ACTIVITY, getActivitySaga);
}

function* watchAddCohortUser() {
  yield takeLatest(COURSE_ADD_COHORT_USER, addCohortUserSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCourses),
    fork(watchGetCourse),
    fork(watchGetModules),
    fork(watchGetActivities),
    fork(watchGetCohorts),
    fork(watchGetCohortUsers),
    fork(watchGetActivity),
    fork(watchAddCohortUser),
  ]);
}
