export default {
  home: 'ទំព័រដើម',
  exploration: 'ស្វែងរក',
  log_in: 'ចូល',
  sign_up: 'ចុះឈ្មោះ',
  about_us: 'អំពីយើង',
  profile: 'ព័ត៌មានផ្ទាល់ខ្លួន',
  my_dashboard: 'ផ្ទាំងទិន្នន័យរបស់ខ្ញុំ',
  account_setting: 'ការកំណត់គណនី',
  purchase_history: 'ប្រវត្តិបង់ប្រាក់',
  logout: 'ចាកចេញ',
  help: 'ជំនួយ',
  en: 'EN',
  kh: 'ខ្មែរ',
  what_do_you_want_to_learn_today: 'តើអ្នកចង់រៀនអ្វីនៅថ្ងៃនេះ?',
  impact_hub_online_learning: 'គេហទំព័ររៀនអនឡាញ​ Impact Hub',
  impact_hub_online_learning_answer: 'Impact Hub Phnom Penh បានជួយដល់អ្នកកែប្រែវ័យក្មេង (Changemaker) អស់រយៈពេល៣ឆ្នាំមកហើយ ដែលមានសហគ្រិនវ័យក្មេងៗជាង៣០០នាក់ដែលមករៀនជាមួយពួកយើងដើម្បីបង្កើតអាជីវកម្មរបស់ពួកគេ។ ពួកយើងគិតថា វាជាពេលវេលាដ៏ល្អក្នុងការចែករំលែកមេរៀន ពត៌មាន និងសហគមន៌របស់ពួកយើងអោយអ្នកដទៃទៀតនៅក្នុងប្រទេសកម្ពុជាបានស្គាល់ និងយកទៅសិក្សាបន្ថែមតាមរយៈការរៀនជាអនឡាញ។ មិនថាអ្នកចង់បង្កើតអាជីវកម្មនៅខេត្តបាត់ដំបង ឬជាអ្នកដឹកនាំនៅខេត្តសៀមរាប ឬមិនថាអ្នកកំពុងតែស្វែងរកកំលាំងជម្រុញលើកទឹកចិត្តនៅខេត្តកំពង់ចាមនោះទេ អ្នកអាចរៀន លូតលាស់ និងជះឥទ្ធិពលល្អទៅកាន់សង្គមជាមួយពួកយើង',
  click_play_to_learn_how_to_use_the_platform: 'សូមចុច PLAY ដើម្បីរៀនប្រើប្រាស់គេហទំព័រនេះ',
  frankly: '“ខ្ញុំចង់ចែករំលែកកម្មវិធីនេះទៅមិត្តភក្តិរបស់ខ្ញុំទាំងអស់ព្រោះវាជាការគេហទំព័ររៀនអនឡាញដំបូងបំផុតបង្កើតឡើងដើម្បីប្រជាជនកម្ពុជាដើម្បីមានឳកាសក្នុងការអភិវឌ្ឍខ្លួនឯង។ ជាងនេះទៅទៀត ខ្ញុំចង់អោយកម្មវិធីនេះបន្តទៅមុខទៀត ហើយមានមេរៀននិងពត៌មានថ្មីសម្រាប់មនុស្សគ្រប់រូប”',
  bottom_frankly: 'Samean Vin, នាយកប្រតិបត្តិនៅអង្គការ ខ្មែរស្ម័គ្រចិត្ត ដើម្បីអភិវឌ្ឍន៌សហគមន៌',
  reasonable_one: 'មានមាតិកាមេរៀនដែលមានគុណភាពខ្ពស់',
  answer_one: 'គុណភាពគឺជាកត្តាចំបងបំផុតសម្រាប់ពួកយើង ហើយមេរៀនរបស់ពួកយើងគឺត្រូវបានរៀបរាងដោយ អ្នកជំនាញ អ្នកដឹកនាំ និងសហគ្រិនជាច្រើន ទាំងក្នុងនិងក្រៅប្រទេស ដើម្បីបង្កើតមេរៀនដែលមានអត្ថប្រយោជន៍ ទុកបង្រៀនអ្នកទាំងអស់គ្នាពីជំនាញសំខាន់ៗក្នុងសតវត្សរ៍ទី ២១នេះ។ ជាងនេះទៅទៀត មេរៀនរបស់ពួកយើងគឺគួរអោយចាប់អារម្មណ៌និងសប្បាយក្នុងការរៀន៕',
  reasonable_two: 'រៀនក្នុងភាសាជាតិ',
  answer_two: 'ពួកយើងបង្រៀនជាភាសាខ្មែរដោយលាយជាមួយពាក្យគន្លឹះអង់គ្លេសខ្លៈៗ​ដើម្បីធានាអោយអ្នកទាំងអស់គ្នាទទួលបានបទពិសោធន៍ការរៀនដ៏ល្អមួយហើយនឹងជួយអ្នកអោយរៀនអំពីពាក្យគន្លឹះដែលត្រឹមត្រូវដើម្បីការពារកុំអោយមានការបកប្រែមិនត្រឹមត្រូវ៕',
  reasonable_three: 'ទទួលបានវិញ្ញាបនបត្របញ្ជាក់ការសិក្សា',
  answer_three: 'អ្នកនឹងទទួលបានវិញ្ញាបនបត្របញ្ជាក់ការសិក្សាពី Impact Hub Phnom Penh ពេលដែលអ្នកបញ្ចប់ការសិក្សាជាមួយនឹងមេរៀនរបស់ពួកយើង៕',
  testimonial_one_description: '“ដំបូងខ្ញុំគិតថាការបើកអាជីវកម្មគឺជារឿងធំស៊ាំញុំ។ បន្ទាប់ពីរៀនវគ្គសិក្សានេះ ខ្ញុំបានផ្លាស់ប្តូរគំនិតរបស់ខ្ញុំទាំងស្រុង។”',
  testimonial_one_full_name: 'ស៊ីន វណ្ណដារ៉ា',
  testimonial_one_position: 'ប្រធាន​នៃ CAM - Meet Corner Toastmasters',
  testimonial_two_description: '“ខ្ញុំបានដឹងហើយថាការបណ្តុះបណ្តាលតាមឬរៀនតាមអនឡាញនេះគឺមានអត្ថប្រយោជន៍សម្រាប់ខ្ញុំក្នុងការរៀនអំពីផ្តល់គំនិតនៃការដឹកនាំដ៏ល្អ។ ក្នុងជំហាននីមួយៗ គេបានបង្ហាញច្បាស់លាស់នៃផ្នត់គំនិតរបស់អ្នកដឹកនាំជាមួយនឹងវីដេអូដែលជួយពន្យល់បន្ថែម។”',
  testimonial_two_full_name: 'Roeub Poch Nita',
  testimonial_two_position: 'ជំនួយការទិន្នន័យនៅសាកលវិទ្យាល័យស្រាវជ្រាវ CO LLC',
  testimonial_three_description: '“ខ្ញំុពិតជាមានអារម្មណ៍សប្បាយរីករាយណាស់ដែរបានចូលរួមវគ្គបណ្ដុះបណ្ដាលនេះ វា​ពិតជាមានអត្ថប្រយោជន៍ណាស់សម្រាប់ខ្ញំុ និងផ្ដល់នូវគំនិត ព្រមទាំងទទួលបាននូវបទពិសោធន៍ជាច្រើនតាមរយៈការសិក្សាមួយនេះ។​ ហើយក៏ចង់អោយបន្តមានវគ្គសិក្សាបែបនេះទៀត។”',
  testimonial_three_full_name: 'Nget Sovanmaly',
  testimonial_three_position: 'ដែលបានរៀនចប់វគ្គសិក្សាភាពជាអ្នកដឹកនាំនាសតវត្សន៍ទី២១',
  testimonial_four_description: '“ខ្ញុំបានរៀនពីភាពខុសគ្នារវាងអាជីវកម្មនិងសហគ្រិនភាព។ ខ្ញុំចូលចិត្តវគ្គសិក្សានេះខ្លាំងបំផុត ... បើប្រៀបធៀបនឹងវគ្គផ្សេងៗទៀតដែលខ្ញុំបានរៀន។ ខ្ញុំមិនអាចនឹកស្រមៃពីមុនទេប៉ុន្តែវគ្គសិក្សានេះបានអនុញ្ញាតឱ្យខ្ញុំបង្កើនការយល់ដឹងអំពីបញ្ហានិងឱកាសនិងវិធីដែលយើងអាចជាផ្នែកមួយនៃដំណោះស្រាយ។ ឥឡូវនេះខ្ញុំបានដឹងថាពិភពលោកគឺធំប៉ុណ្ណា ក៏ដូចជាបញ្ហា។”',
  testimonial_four_full_name: 'Muy Nging',
  testimonial_four_position: 'និសិត្សឆ្នាំទី៣ នៃសាកលវិទ្យាល័យភូមិន្ទនីតិសាស្ត្រនិងវិទ្យាសាស្ត្រសេដ្ឋកិច្ច ',
  first_time_learning_online: 'ទើបតែចាប់ផ្តើមរៀនអនឡាញលើកដំបូង? សូមមើលការណែនាំរបស់យើងនៅទីនេះ',
  first_time_learning_online_answer: 'នៅពេលដែលអ្នកមានអ៊ិនធឺណេត អ្នកអាចរៀនអ្វីៗគ្រប់យ៉ាង និងបានគ្រប់ពេលវេលា ហើយអ្នកក៏អាចក្លាយទៅជាបុគ្គលដែលអ្នកប៉ងប្រាថ្នា។ តែវាអាចជាបញ្ហាប្រឈមប្រសិនបើអ្នកទើបតែចាប់ផ្តើមរៀនតាមអ៊ិនធឺរណែតលើកដំបូង។',
  want_to_insert_the_course_to_your_company: 'តើអ្នកត្រូវការការពង្រឹងសមត្ថភាពរបស់ក្រុមការងារអ្នកដែរឬទេ?',
  want_to_insert_the_course_to_your_company_answer: 'Impact Hub Phnom Penh នឹងផ្តល់បទពិសោធន៌រៀនដ៏ល្អដោយផ្ទាល់ទៅដល់សមាជិករបស់អ្នកពីដោយគ្រាន់តែ',
  reach_out_to_us_here: 'ចុចទីនេះ!',
  enjoy_learning_course: 'សូមរៀនដោយរីករាយ!',
  get_started_for_free: 'ចាប់ផ្តើមដោយមិនគិតថ្លៃ',
  get_started: 'តោះ ចាប់ផ្តើម!',
  sponsorship: 'ទទួលបានសេចក្តីទុកចិត្តពី​ដៃគូរសហការ​',
  slogant: 'បង្កើតគណនីរៀនថ្ងៃនេះ និងរៀនដោយឥតគិតថ្លៃ!!',
  your_limit: 'BREAKTHROUGH YOUR LIMIT!',
  check_out_here: 'សូមចូលទៅកាន់ទីនេះ',
  check_out_here_continue: 'ដើម្បីមើលការណែនាំអំពីរបៀបរៀននៅលើវេទិកាអនឡាញរបស់យើងនិងគន្លឹះដើម្បីរៀនតាមអ៊ិនធរណេតប្រកបដោយប្រសិទ្ធភាព។',
  view_all_course: 'វគ្គសិក្សាទាំងអស់',
  view_courses: 'ស្វែងរកមេរៀន',
  view_all_masterclass: 'ថ្នាក់ជំនាញទាំងអស់',
  explore_all: 'បង្ហាញទាំងអស់',
  faqs: 'FAQs',
  view_courses_first: 'Knowledge isn\'t power,',
  view_courses_two: 'ការប្រើប្រាស់ចំណេះដឹងគឺជាឥទ្ធិពលវិជ្ជមាន',
  view_courses_three: 'ជ្រើសរើសប្រភេទនៃកម្មវិធីសិក្សាដែលសំបូរបែបជាមួយវីដេអូ អត្ថបទ ឯកសារបន្ថែម និងវគ្គសិក្សាដែលអ្នកចាប់អារម្មណ៍ក្នុងការរៀន។',
  download_worksheet: 'DOWNLOAD WORKSHEET',
  join_the_community: 'JOIN THE COMMUNITY',
};
