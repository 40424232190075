/*eslint-disable react/jsx-no-target-blank */
import React, {Fragment} from 'react';
import {Row, Col, Container, Button} from 'reactstrap';
import PropTypes from 'prop-types';
import IntlMessages from '../../../lib/IntlMessages';
import {VIEW_ALL_COURSES, COURSE_DETAIL} from '../../../constants/routes';
import {Link} from 'react-router-dom';
import {CustomSlider, WrapResponsiveBlog, Flex, Element, Badge} from './styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MasterCourseCard from '../../../components/master-course-card';
function MasterCourse({dataSources}) {
  let predictSlideNo = dataSources.length < 5 ? dataSources.length - 1 : 5;

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: predictSlideNo,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const SlideBlog = (
    <Row>
      <Col>
        <CustomSlider>
          <Slider {...settings} arrows={true}>
            {dataSources.map((item) => {
              const {photo, name} = item.speakerData;
              return (
                <Fragment key={item.id}>
                  {item.comingSoon && (
                    <a target="_blank" href={item.typeformRedirect}>
                      <MasterCourseCard
                        dataSource={{image: photo, title: item.title, speaker: name}}
                      />
                    </a>
                  )}
                  {!item.comingSoon && (
                    <Link to={`${COURSE_DETAIL.replace(':courseId', item.id)}`}>
                      <MasterCourseCard
                        dataSource={{image: photo, title: item.title, speaker: name}}
                      />
                    </Link>
                  )}
                </Fragment>
              );
            })}
          </Slider>
          <div style={{display: 'flex', width: '80px', marginTop: '15px', margin: 'auto'}}>
            <Badge />
            <Badge />
            <Badge />
          </div>
        </CustomSlider>
      </Col>
    </Row>
  );

  const ListBlock = (
    <Row>
      <Col>
        <Flex>
          {dataSources.map((item) => {
            const {photo, name} = item.speakerData;
            return (
              <Element key={item.id}>
                {item.comingSoon && (
                  <a target="_blank" href={item.typeformRedirect}>
                    <MasterCourseCard
                      dataSource={{image: photo, title: item.title, speaker: name}}
                    />
                  </a>
                )}
                {!item.comingSoon && (
                  <Link to={`${COURSE_DETAIL.replace(':courseId', item.id)}`}>
                    <MasterCourseCard
                      dataSource={{image: photo, title: item.title, speaker: name}}
                    />
                  </Link>
                )}
              </Element>
            );
          })}
        </Flex>
      </Col>
    </Row>
  );

  return (
    <Container className="mt-5">
      {dataSources.length < 5 ? (
        <Fragment>
          {/* Slide */}
          <WrapResponsiveBlog className="slide-bloc">{SlideBlog}</WrapResponsiveBlog>
          {/* List */}
          <WrapResponsiveBlog className="list-bloc">{ListBlock}</WrapResponsiveBlog>
        </Fragment>
      ) : (
        <Fragment>
          {/* Slide */}
          <WrapResponsiveBlog>{SlideBlog}</WrapResponsiveBlog>
        </Fragment>
      )}
      <Row>
        <Col className="mt-5">
          <center>
            <Link
              to={`${VIEW_ALL_COURSES}?tab=masterclass`}
              style={{width: '100%'}}
              className="text-white"
            >
              <Button className="text-center view-course-button-customer text-deep-blue bg-deep-blue">
                <IntlMessages id="view_all_masterclass" />
              </Button>
            </Link>
          </center>
        </Col>
      </Row>
    </Container>
  );
}

MasterCourse.propTypes = {
  dataSources: PropTypes.array,
};

export default MasterCourse;
